import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import SecondaryButton from "../buttons/SecondaryButton";
import DividerLine from "../divider/DividerLine";
import ApproveButton from "../buttons/ApproveButton";
import PrimaryButton from "../buttons/PrimaryButton";
import ProgressBar from "../progress-bar/ProgressBar";
import DeleteButton from "../buttons/DeleteButton";
import GradeLabel from "../labels/GradeLabel";
import BaseImage from "../images/BaseImage";
import { Tooltip } from "react-tooltip";

const EtudeApplicationCard = (props) => {
  const navigate = useNavigate();
  const { etudeApplicationDTO } = props;

  let borderColor;
  let deadlineAtColor;
  let showProgressBar;
  let showRemainingDays;
  let showWaitingForEvaluate;
  let canNavigate;

  if (!etudeApplicationDTO) return;
  const {
    catchxamApplicationDTO,
    activityDTOList,
    completionStatus,
    completionPercentage,
  } = etudeApplicationDTO;
  const { catchxamDTO } = catchxamApplicationDTO;
  const { name, description, coterieType, level } = catchxamDTO;

  borderColor = "border-catchup-blue-100";
  deadlineAtColor = "text-catchup-red";
  showRemainingDays = false;
  canNavigate = true;
  showWaitingForEvaluate = false;

  if (completionStatus === "NOT_STARTED") {
    showProgressBar = false;
  } else if (completionStatus === "ON_GOING") {
    showProgressBar = true;
  } else if (completionStatus === "TIME_UP") {
    showProgressBar = true;
  } else if (completionStatus === "COMPLETED") {
    showProgressBar = false;
    borderColor = "border-catchup-green";
    deadlineAtColor = "text-catchup-gray-400";
  }

  return (
    <div
      className={`${borderColor} bg-catchup-white border-2 rounded-catchup-xlarge p-4 mx-2 my-4`}
    >
      <div className="flex-1 flex flex-row gap-x-2">
        <div>
          <BaseImage size="large" src="/icons/etude.png" alt="etude" />
        </div>
        <div className="flex-1 flex flex-row justify-between items-center flex-wrap">
          <div className="flex-1 flex flex-col">
            <p className="font-semibold text-catchup-blue-500 text-lg">
              {i18n.t("etude")}
            </p>
          </div>
          <div className="flex flex-row">
            <GradeLabel title={`${i18n.t("grade")}: ${level}`} />
          </div>
        </div>
      </div>
      <div className="my-2">
        <DividerLine />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <p className="font-semibold">{name}</p>
          <div className="flex flex-row items-center gap-x-2 text-catchup-gray-300">
            <div>
              <p>{i18n.t(coterieType)}</p>
            </div>
            <img
              className="w-1 h-1"
              src="/icons/small-dot-gray.png"
              alt="small-dot-gray"
            />
            <div>
              <p>
                {activityDTOList.length} {i18n.t("activity")}
              </p>
            </div>
          </div>
        </div>
        <div>
          <Tooltip id="info-description" />
          <div className="mr-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataToolTipId="info-description"
              dataToolTipContent={description}
              dataToolTipPlace="top"
              dataToolTipVariant="info"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        {showProgressBar ? (
          <div className="w-full my-1">
            <ProgressBar
              remainingTimeInSeconds={100 - completionPercentage * 100}
              totalTimeInSeconds={100}
              height="h-3"
              color="bg-catchup-blue-400"
              borderColor="border-catchup-blue-400"
            />
          </div>
        ) : (
          <div className="w-full my-1">
            <div className="h-3" />
          </div>
        )}
      </div>
      <div className="my-2">
        <DividerLine />
      </div>

      {canNavigate ? (
        completionStatus === "COMPLETED" ? (
          <div className="flex-1 flex flex-col mt-2">
            <ApproveButton
              title={i18n.t(completionStatus)}
              size="unlimited"
              textOnly={true}
              onClick={() => {}}
            />
          </div>
        ) : completionStatus === "NOT_STARTED" ||
          completionStatus === "ON_GOING" ? (
          <div className="flex-1 flex flex-col mt-2">
            <PrimaryButton
              title={
                completionStatus === "NOT_STARTED"
                  ? i18n.t("START")
                  : i18n.t("CONTINUE")
              }
              size="unlimited"
              onClick={() => {
                navigate(`/etudes/applications/${etudeApplicationDTO.id}`);
              }}
            />
          </div>
        ) : completionStatus === "TIME_UP" ? (
          <div className="flex-1 flex flex-col mt-2">
            <DeleteButton
              title={i18n.t(completionStatus)}
              textOnly={true}
              size="unlimited"
              onClick={() => {}}
            />
          </div>
        ) : null
      ) : null}
      {showWaitingForEvaluate ? (
        <div className="flex-1 flex flex-col mt-2">
          <SecondaryButton
            title={i18n.t("waiting_for_evaluation")}
            size="unlimited"
            textOnly={true}
            onClick={() => {}}
          />
        </div>
      ) : null}
    </div>
  );
};

export default EtudeApplicationCard;
