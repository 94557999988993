const SelectionTab = (props) => {
  const {
    optionList,
    selectedId,
    handleSelectOnClick,
    selectedTextColor,
    selectedBorderColor,
    textColor,
    borderColor,
  } = props;

  return (
    <div className="flex flex-row items-center gap-x-4 gap-y-2 flex-wrap mb-5 text-lg text-center">
      {optionList.map((option, index) => (
        <div
          key={index}
          className={`${
            selectedId === option.id
              ? selectedTextColor
                ? selectedTextColor
                : "text-catchup-blue-500"
              : textColor
              ? textColor
              : "text-catchup-gray-300"
          } ${
            selectedId === option.id
              ? selectedBorderColor
                ? selectedBorderColor
                : "border-catchup-blue-500"
              : borderColor
              ? borderColor
              : "border-catchup-gray-50"
          } border-b-2 transition-all duration-300 p-3 cursor-pointer`}
          onClick={() => {
            handleSelectOnClick(option.id);
          }}
        >
          {option.title}
        </div>
      ))}
    </div>
  );
};

export default SelectionTab;
