const BaseTitle = (props) => {
  const { title, totalItemCount, itemName, description } = props;

  return (
    <div className="flex flex-col gap-y-2">
      <p className="text-2xl font-medium">
        {title}
        {totalItemCount && itemName ? (
          <span className="p-2 text-base text-catchup-blue-600 border border-catchup-blue-300 rounded-catchup-3xlarge mx-2 bg-catchup-blue-100">
            {totalItemCount} {itemName}
          </span>
        ) : null}
      </p>
      {description ? <p className="">{description}</p> : null}
    </div>
  );
};

export default BaseTitle;
