import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createActivityAnswerByActivityIdCatchxamApplicationId,
  createActivityProgressByActivityIdCatchxamApplicationId,
  patchActivityAnswerWithData,
  patchActivityProgress,
  queryActivityAnswerListByCatchxamApplicationId,
  retrieveActivityWithDataById,
  retrieveCatchxamApplicationById,
  retrieveCatchxamApplicationProgress,
} from "../../requests/CatchtivityRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import CatchtivityFooter from "../../components/footers/CatchtivityFooter";
import { useApp } from "../../context/AppContextProvider";
import {
  checkIfAnswerIsEmpty,
  constructActivityAnswerMap,
  constructActivityAnswerStateList,
  retrieveActivityAnswerFromAnswerList,
  retrieveClockTimeLeft,
  retrieveTotalTimeSpentInMinutes,
} from "../../utilization/CatchtivityUtilization";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import { queryMainFileByIdList } from "../../requests/StorageRequests";
import { queryPlanWeekListByParams } from "../../requests/PlanRequests";
import moment from "moment";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import ActivityEmptyContent from "../../components/activities/empty-content/ActivityEmptyContent";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import BaseImage from "../../components/images/BaseImage";
import BaseModal from "../../components/modal/BaseModal";
import DashboardHeader from "../../components/headers/DashboardHeader";
import ContentTransition from "../../components/transitions/ContentTransition";
import CatchtivityProgressBar from "../../components/progress-bar/CatchtivityProgressBar";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";
import ScreenCard from "../../components/cards/ScreenCard";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const CatchxamApplicationScreen = () => {
  const { catchxamApplicationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [activityNumber, setActivityNumber] = useState(null);
  const [catchxamApplication, setCatchxamApplication] = useState(null);
  const [activity, setActivity] = useState(null);
  const [activityAnswerDTOList, setActivityAnswerDTOList] = useState([]);
  const [activityAnswerDTOListLoading, setActivityAnswerDTOListLoading] =
    useState(true);
  const [catchxamApplicationProgressDTO, setCatchxamApplicationProgressDTO] =
    useState(null);
  const [
    catchxamApplicationProgressDTOLoading,
    setCatchxamApplicationProgressDTOLoading,
  ] = useState(true);
  const [currentActivityInitialTimestamp, setCurrentActivityInitialTimestamp] =
    useState(new Date().getTime());
  const [activityLoading, setActivityLoading] = useState({
    self: false,
    answer: false,
    progress: false,
  });
  const [planWeekList, setPlanWeekList] = useState([]);
  const [planWeekMap, setPlanWeekMap] = useState({});
  const [planWeekLoading, setPlanWeekLoading] = useState({
    planWeek: false,
    documents: false,
  });
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  useEffect(() => {
    if (pageState === 0) {
      if (isFullScreen === true) {
        setIsFullScreen(false);
      }
    } else {
      if (isFullScreen === false) {
        setIsFullScreen(true);
      }
    }
  }, [pageState]);

  useEffect(() => {
    const retrieveAndSetCatchxamApplication = async () => {
      const { data, err } = await retrieveCatchxamApplicationById(
        catchxamApplicationId
      );
      if (err) {
        console.log(err);
        return;
      }
      setCatchxamApplication(data);
    };
    const retrieveAndSetCatchxamApplicationProgress = async () => {
      setCatchxamApplicationProgressDTOLoading(true);
      const { data, err } = await retrieveCatchxamApplicationProgress({
        id: catchxamApplicationId,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setCatchxamApplicationProgressDTO(data);
      setCatchxamApplicationProgressDTOLoading(false);
    };
    const retrieveAndSetActivityAnswerList = async () => {
      setActivityAnswerDTOListLoading(true);
      const { data, err } =
        await queryActivityAnswerListByCatchxamApplicationId({
          catchxamApplicationId,
          userId: userInformation.id,
          userProfileId: userProfile.id,
        });
      if (err) {
        console.log(err);
        return;
      }
      setActivityAnswerDTOList(data);
      setActivityAnswerDTOListLoading(false);
    };
    if (checkViewPermissionGranted("catchxam_application")) {
      retrieveAndSetCatchxamApplication();
      retrieveAndSetCatchxamApplicationProgress();
      retrieveAndSetActivityAnswerList();
    } else {
      navigate("/permission-error");
    }
  }, [window.location]);

  useEffect(() => {
    if (!activity) return;
    setCurrentActivityInitialTimestamp(new Date().getTime());
  }, [activity]);

  useEffect(() => {
    const retrieveAndSetActivityWithData = async () => {
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: true,
      }));
      const activityDTOList = catchxamApplication.catchxamDTO.activityDTOList;
      if (activityDTOList.length > 0) {
        const currentActivity = activityDTOList[activityNumber];
        const { data, err } = await retrieveActivityWithDataById(
          currentActivity.id
        );
        if (err) {
          console.log(err);
        } else {
          setActivity(data);
        }
      }
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: false,
      }));
    };
    if (activityNumber === 0) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
    }
    if (
      catchxamApplication &&
      activityNumber === catchxamApplication.catchxamDTO.activityDTOList.length
    ) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
      setShowCompleteModal(true);
      setActivity(null);
    } else if (
      catchxamApplication &&
      activityNumber !== null &&
      activityNumber >= 0
    ) {
      setActivity(null);
      retrieveAndSetActivityWithData();
    } else {
      setActivity(null);
    }
  }, [catchxamApplication, activityNumber]);

  useEffect(() => {
    if (!catchxamApplication) return;
    retrieveAndSetPlanWeekList();
  }, [catchxamApplication]);

  useEffect(() => {
    if (planWeekList.length === 0) return;
    retrieveAndSetPlanWeekDocuments();
  }, [planWeekList]);

  const retrieveAndSetPlanWeekList = async () => {
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      planWeek: true,
    }));
    const beginAt = moment(new Date().getTime())
      .startOf("isoWeek")
      .toDate()
      .getTime();
    const endAt = moment(new Date().getTime())
      .endOf("isoWeek")
      .toDate()
      .getTime();
    const queryParams = {
      brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
      campusId: userProfileSeason.institutionDTO.campusDTO.id,
      institutionId: userProfileSeason.institutionDTO.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      coterieType: catchxamApplication.catchxamDTO.coterieType,
      beginAt,
      endAt,
    };
    const { data, err } = await queryPlanWeekListByParams(queryParams);
    if (err) {
      console.log(err);
      return;
    }
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      planWeek: false,
    }));
    setPlanWeekList(data);
  };

  const retrieveAndSetPlanWeekDocuments = async () => {
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      documents: true,
    }));
    const planWeekMap = planWeekList.map((planWeek) => {
      const planWeekMap = {};
      const { planWeekDocumentDTOList } = planWeek;
      for (const planWeekDocument of planWeekDocumentDTOList) {
        if (planWeekMap[planWeek.id]) {
          planWeekMap[planWeek.id].push({
            documentId: planWeekDocument.documentId,
          });
        } else {
          planWeekMap[planWeek.id] = [
            { documentId: planWeekDocument.documentId },
          ];
        }
      }
      return planWeekMap;
    });
    if (Object.keys(planWeekMap).length === 0) {
      setPlanWeekLoading((prevPlanWeekLoading) => ({
        ...prevPlanWeekLoading,
        documents: false,
      }));
      return;
    }
    const planWeekPromises = planWeekMap.map(async (planWeek) => {
      if (Object.keys(planWeek).length === 0) return;
      const planWeekId = Object.keys(planWeek)[0];
      const foundPlanWeek = planWeekList.find(
        (planWeek) => planWeek.id === parseFloat(planWeekId)
      );
      const { data, err } = await queryMainFileByIdList({
        idList: planWeek[planWeekId].map((pw) => pw.documentId),
      });
      if (err) {
        console.log(err);
      } else {
        setPlanWeekMap((prevDocumentMap) => ({
          ...prevDocumentMap,
          [planWeekId]: {
            ...foundPlanWeek,
            documentList: data,
          },
        }));
      }
    });
    await Promise.all(planWeekPromises);
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      documents: false,
    }));
  };

  const retrieveCurrentActivityTemplate = () => {
    return activity.activityTemplateDTOList.find(
      (activityTemplateDTO) => activityTemplateDTO.type === "MCSA"
    );
  };

  const retrieveCurrentActivityAnswer = () => {
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    const foundActivityAnswerDTO = activityAnswerDTOList[foundIndex];
    if (foundIndex > -1 && foundActivityAnswerDTO.data) {
      const currentAnswer = foundActivityAnswerDTO;
      return {
        index: foundIndex,
        answer: {
          ...currentAnswer,
          data: JSON.parse(currentAnswer.data),
        },
      };
    } else {
      const currentActivityTemplate = retrieveCurrentActivityTemplate();
      return {
        index: foundIndex,
        answer: {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          activityDTO: {
            id: activity.id,
          },
          catchxamApplicationDTO: {
            id: catchxamApplicationId,
          },
          data: currentActivityTemplate
            ? [
                constructActivityAnswerMap(
                  currentActivityTemplate,
                  JSON.parse(activity.data)
                ),
              ]
            : [],
        },
      };
    }
  };

  const retrieveCurrentActivityAnswerTemplate = () => {
    const { answer } = retrieveCurrentActivityAnswer();
    const { data } = answer;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    return { answerTemplate, answer };
  };

  const checkCanAnswerQuestion = () => {
    if (activity) {
      const activityData = JSON.parse(activity.data);
      let durationMap = null;

      if (activityData.durationMap) {
        durationMap = JSON.parse(activityData.durationMap);
      }
      if (
        retrieveClockTimeLeft(
          "CATCHXAM",
          null,
          catchxamApplication?.durationType,
          catchxamApplication?.durationInMinutes,
          catchxamApplicationProgressDTO?.activityProgressDTOSet,
          activity
        ) < 0 ||
        (durationMap
          ? retrieveClockTimeLeft(
              "ACTIVITY",
              durationMap.duration,
              catchxamApplication?.durationType,
              catchxamApplication?.durationInMinutes,
              catchxamApplicationProgressDTO?.activityProgressDTOSet,
              activity
            ) < 0
          : false)
      ) {
        return false;
      } else {
        return true;
      }
    }
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate.isEmpty) {
      return false;
    }
    return false;
  };

  const createOrPatchActivityProgress = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: true,
    }));
    const foundActivityProgressDTO =
      catchxamApplicationProgressDTO.activityProgressDTOSet.find(
        (activityProgressDTO) =>
          parseFloat(activityProgressDTO.activityDTO.id) ===
          parseFloat(activity.id)
      );
    if (foundActivityProgressDTO) {
      await patchAndSetActivityProgress(foundActivityProgressDTO.id);
    } else {
      await createAndSetActivityProgress();
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: false,
    }));
  };

  const createAndSetActivityProgress = async () => {
    const { data, err } =
      await createActivityProgressByActivityIdCatchxamApplicationId({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
        activity: {
          id: activity.id,
        },
        catchxamApplication: {
          id: catchxamApplication.id,
        },
        seasonId: userProfileSeason.id,
        gradeId: userProfileGrade.id,
        branchId: userProfileBranch.id,
      });
    if (err) {
      console.log(err);
    } else {
      catchxamApplicationProgressDTO.activityProgressDTOSet.push({
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        catchxamApplicationDTO: data.catchxamApplicationDTO,
      });
      setCatchxamApplicationProgressDTO(
        JSON.parse(JSON.stringify(catchxamApplicationProgressDTO))
      );
    }
  };

  const patchAndSetActivityProgress = async (activityProgressId) => {
    const { data, err } = await patchActivityProgress({
      id: activityProgressId,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
      activityId: activity.id,
      catchxamApplicationId: catchxamApplication.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    });
    if (err) {
      console.log(err);
    } else {
      const foundIndex =
        catchxamApplicationProgressDTO.activityProgressDTOSet.findIndex(
          (activityProgressDTO) =>
            parseFloat(activityProgressDTO.id) ===
            parseFloat(activityProgressId)
        );
      catchxamApplicationProgressDTO.activityProgressDTOSet[foundIndex] = {
        ...catchxamApplicationProgressDTO.activityProgressDTOSet[foundIndex],
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        catchxamApplicationDTO: data.catchxamApplicationDTO,
      };
      setCatchxamApplicationProgressDTO(
        JSON.parse(JSON.stringify(catchxamApplicationProgressDTO))
      );
    }
  };

  const checkIsQuicktivityContent = () => {
    if (!activity) return;
    const data = JSON.parse(activity.data);
    if (data.durationMap) {
      const durationMap = JSON.parse(data.durationMap);
      if (durationMap.durationType === "EACH") {
        return true;
      }
    }
  };

  const changeActivityAnswer = (answer) => {
    const copyAnswer = JSON.parse(JSON.stringify(answer));
    copyAnswer.data = JSON.stringify(copyAnswer.data);
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    if (foundIndex === -1) {
      activityAnswerDTOList.push(copyAnswer);
    } else {
      activityAnswerDTOList[foundIndex] = copyAnswer;
    }
    setActivityAnswerDTOList(JSON.parse(JSON.stringify(activityAnswerDTOList)));
  };

  const handleCreateOrUpdateActivityAnswerWithData = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: true,
    }));
    const { index, answer } = retrieveCurrentActivityAnswer();
    const baseAnswer = {
      userId: answer.userId,
      userProfileId: answer.userProfileId,
      data: JSON.stringify(answer.data),
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    };
    if (answer.id) {
      const currentAnswer = {
        ...baseAnswer,
        id: answer.id,
        activityId: answer.activityDTO.id,
        catchxamApplicationId: answer.catchxamApplicationDTO.id,
      };
      const { data, err } = await patchActivityAnswerWithData(currentAnswer);
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          ...activityAnswerDTOList[index],
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          catchxamApplicationDTO: data.catchxamApplicationDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    } else {
      const newAnswer = {
        ...baseAnswer,
        activity: {
          id: answer.activityDTO.id,
        },
        catchxamApplication: {
          id: answer.catchxamApplicationDTO.id,
        },
      };
      const { data, err } =
        await createActivityAnswerByActivityIdCatchxamApplicationId(newAnswer);
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          catchxamApplicationDTO: data.catchxamApplicationDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: false,
    }));
  };

  const handleToNextActivity = (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      handleCreateOrUpdateActivityAnswerWithData();
    }
    createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleSetEmptyOrContinue = async () => {
    const { index, answer } = retrieveCurrentActivityAnswer();
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const { data } = answer;
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    if (answerTemplate) {
      if (answerTemplate.isEmpty) {
        answerTemplate.isEmpty = false;
      } else {
        answerTemplate.isEmpty = true;
      }
      const copyAnswer = JSON.parse(JSON.stringify(answer));
      copyAnswer.data = JSON.stringify(copyAnswer.data);
      activityAnswerDTOList[index] = copyAnswer;
      setActivityAnswerDTOList([...activityAnswerDTOList]);
      handleCreateOrUpdateActivityAnswerWithData();
    }
  };

  const handleToNextActivityWithAsync = async (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      await handleCreateOrUpdateActivityAnswerWithData();
    }
    await createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleToNextActivityCheckCanAnswer = (nextActivityNumber) => {
    if (checkCanAnswerQuestion()) {
      if (
        nextActivityNumber ===
        catchxamApplication.catchxamDTO.activityDTOList.length
      ) {
        handleToNextActivityWithAsync(nextActivityNumber);
      } else {
        handleToNextActivity(nextActivityNumber);
      }
    } else {
      setActivityNumber(nextActivityNumber);
    }
  };

  const handleToPreviousActivity = async () => {
    await createOrPatchActivityProgress();
    setActivityNumber(activityNumber - 1);
  };

  const handleGoPreviousScreen = async () => {
    if (pageState === 1) {
      if (activity) {
        await createOrPatchActivityProgress();
        setCurrentActivityInitialTimestamp(new Date().getTime());
      }
    }
    navigate("/homeworks", {
      state: { state: "FORCE_UPDATE" },
    });
  };

  const handleOnTimerEnds = (valid) => {
    if (!valid) return;
    handleToNextActivityCheckCanAnswer(activityNumber + 1);
  };

  const RenderCurrentActivityContent = () => {
    if (!activity) return null;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    if (!currentActivityTemplate) return null;
    const { answer } = retrieveCurrentActivityAnswer();
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === currentActivityTemplate.type
    );
    if (foundIndex === -1) {
      answer.data.push(
        constructActivityAnswerMap(
          currentActivityTemplate,
          JSON.parse(activity.data)
        )
      );
    }
    if (currentActivityTemplate.type === "MCSA") {
      return (
        <MCSAActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    }
    return null;
  };

  const RenderSingleKeyValueText = (key, value) => {
    return (
      <div className="flex flex-row justify-between items-center w-full">
        <p className="mx-1 font-bold">{i18n.t(key)}</p>
        <p className="mx-1">{value}</p>
      </div>
    );
  };

  const RenderWeeklyPlanDocuments = () => {
    return (
      <>
        {planWeekLoading.planWeek ? (
          <BaseLoading size="small" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            {RenderSingleKeyValueText("weekly_plan_documents", null)}
            {planWeekLoading.documents ? (
              <BaseLoading
                size="small"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              Object.keys(planWeekMap).map((planWeekId) => {
                const planWeek = planWeekMap[planWeekId];
                return (
                  <div className="w-full my-2">
                    <div className="">
                      <p>{planWeek.coterieType}</p>
                    </div>
                    {planWeek.documentList.map((document) => {
                      const { path, fileName, fileOriginalName } = document;
                      return (
                        <div className="px-4 my-1 cursor-pointer underline">
                          <a
                            href={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
                            target={"_blank"}
                          >
                            {fileOriginalName}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
          </>
        )}
      </>
    );
  };

  const RenderActivitySetEmpty = () => {
    if (!activity) return;
    if (!retrieveActivityAnswerFromAnswerList(activityAnswerDTOList, activity))
      return;
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    return (
      <div
        className="flex flex-row items-center gap-x-2 cursor-pointer"
        onClick={handleSetEmptyOrContinue}
      >
        <BaseImage
          src={
            answerTemplate?.isEmpty
              ? "/icons/checkbox.png"
              : "/icons/empty-checkbox.png"
          }
          alt="checkbox-empty-checkbox"
          size="small"
          onClick={() => {}}
        />
        <p>{i18n.t("i_want_to_set_empty")}</p>
      </div>
    );
  };

  const RenderActivityEmptyContent = () => {
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate?.isEmpty) {
      return <ActivityEmptyContent />;
    }
  };

  const RenderCompleteModal = () => {
    if (!catchxamApplication) return;
    const stateList = constructActivityAnswerStateList(
      activityAnswerDTOList,
      catchxamApplication.catchxamDTO.activityDTOList
    );
    const notExistsItemList = stateList.filter(
      (item) => item.state === "NOT_EXISTS"
    );
    const emptyItemList = stateList.filter((item) => item.state === "EMPTY");
    return (
      <BaseModal
        isOpen={showCompleteModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setActivityNumber(0);
          setShowCompleteModal(false);
        }}
        customSize={"w-[750px]"}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setActivityNumber(0);
                setShowCompleteModal(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div>
                <p className="font-bold">
                  {i18n.t("are_you_sure_do_you_want_to_complete_text")}
                </p>
              </div>
              <DividerLine />

              {notExistsItemList.length > 0 || emptyItemList.length > 0 ? (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/exclamation-red.png"
                    alt="exclamation"
                    size="xlarge"
                  />
                  <div className="flex flex-col">
                    {notExistsItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_did_not_answer_activities_text_1")}
                          {notExistsItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === notExistsItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}
                          {i18n.t("you_did_not_answer_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                    {emptyItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_set_empty_activities_text_1")}
                          {emptyItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === emptyItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}

                          {i18n.t("you_set_empty_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/checkbox.png"
                    alt="checkbox"
                    size="medium"
                  />
                  <div>
                    <p>{i18n.t("you_have_answered_all_activities")}</p>
                  </div>
                </div>
              )}

              <div className="flex justify-end ">
                <div className="flex flex-row gap-x-2">
                  <SecondaryButton
                    title={i18n.t("continue")}
                    size="small"
                    onClick={() => {
                      if (notExistsItemList.length > 0) {
                        setActivityNumber(notExistsItemList[0].index);
                      } else if (emptyItemList.length > 0) {
                        setActivityNumber(emptyItemList[0].index);
                      } else {
                        setActivityNumber(0);
                      }
                      setShowCompleteModal(false);
                    }}
                  />
                  <PrimaryButton
                    title={i18n.t("complete")}
                    size="small"
                    onClick={() => {
                      navigate("/homeworks", {
                        state: {
                          state: "FORCE_UPDATE",
                        },
                      });
                      setShowCompleteModal(false);
                    }}
                  />
                </div>
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    if (pageState === 0) {
      if (catchxamApplication) {
        return (
          <ContentTransition
            language={language}
            description={catchxamApplication.catchxamDTO.description}
            type={catchxamApplication.catchxamDTO.coterieType}
            level={catchxamApplication.catchxamDTO.level}
            activityCount={
              catchxamApplication.catchxamDTO.activityDTOList.length
            }
            beginAt={catchxamApplication.beginAt}
            endAt={catchxamApplication.endAt}
            durationType={catchxamApplication.durationType}
            durationInMinutes={catchxamApplication.durationInMinutes}
            answeredActivityCount={activityAnswerDTOList.length}
            totalTimeSpent={retrieveTotalTimeSpentInMinutes(
              catchxamApplicationProgressDTO?.activityProgressDTOSet
            )}
            loading={
              catchxamApplication.beginAt > new Date().getTime() ||
              catchxamApplication.endAt < new Date().getTime() ||
              activityAnswerDTOListLoading ||
              catchxamApplicationProgressDTOLoading
            }
            planWeekLoading={planWeekLoading}
            planWeekMap={planWeekMap}
            onClick={() => {
              setActivityNumber(0);
              setPageState(1);
            }}
          />
        );
      } else {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      }
    } else if (pageState === 1) {
      if (catchxamApplication) {
        return (
          <div className="h-full no-scrollbar overflow-y-auto pb-[200px]">
            {activityLoading.answer || activityLoading.progress ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : activityLoading.self ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : activity ? (
              <div className="flex flex-col w-full">
                <div className="my-1">
                  <CatchtivityProgressBar
                    activityNumber={activityNumber + 1}
                    onActivityChange={handleToNextActivityCheckCanAnswer}
                    totalActivityCount={
                      catchxamApplication
                        ? catchxamApplication.catchxamDTO.activityDTOList.length
                        : 0
                    }
                    showClockTime={
                      catchxamApplication?.durationType === "NONE"
                        ? false
                        : true
                    }
                    clockTimeLeft={retrieveClockTimeLeft(
                      "CATCHXAM",
                      null,
                      catchxamApplication?.durationType,
                      catchxamApplication?.durationInMinutes,
                      catchxamApplicationProgressDTO?.activityProgressDTOSet,
                      activity
                    )}
                    startTimer={
                      pageState === 1 &&
                      !showCompleteModal &&
                      !activityLoading.answer &&
                      !activityLoading.progress
                        ? retrieveClockTimeLeft(
                            "CATCHXAM",
                            null,
                            catchxamApplication?.durationType,
                            catchxamApplication?.durationInMinutes,
                            catchxamApplicationProgressDTO?.activityProgressDTOSet,
                            activity
                          ) > 0
                        : false
                    }
                    handleOnTimerEnds={handleOnTimerEnds}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row items-center" />
                  {RenderActivitySetEmpty()}
                </div>
                {RenderActivityEmptyContent()}
                <div className="my-2">{RenderCurrentActivityContent()}</div>
              </div>
            ) : null}
          </div>
        );
      }
    }
    return null;
  };

  const isQuicktivityContent = checkIsQuicktivityContent();

  return (
    <div className="h-screen p-4">
      {RenderCompleteModal()}
      <ScreenCard>
        <DashboardHeader
          handleReturnOnClick={pageState === 1 ? handleGoPreviousScreen : null}
          title={catchxamApplication?.catchxamDTO.name}
          level={
            pageState === 1 ? catchxamApplication?.catchxamDTO.level : null
          }
          durationType={
            pageState === 1 ? catchxamApplication?.durationType : null
          }
          durationInMinutes={
            pageState === 1 ? catchxamApplication?.durationInMinutes : null
          }
        />

        {RenderMainContent()}
        {pageState === 1 && activity ? (
          <CatchtivityFooter
            activityNumber={activityNumber}
            totalActivityCount={
              catchxamApplication
                ? catchxamApplication.catchxamDTO.activityDTOList.length
                : 0
            }
            showTimedProgressBar={isQuicktivityContent}
            activity={activity}
            activityLoading={
              isQuicktivityContent
                ? activityLoading.self || activityLoading.progress
                : activityLoading.self
            }
            activityProgressList={
              catchxamApplicationProgressDTO?.activityProgressDTOSet
            }
            durationType={catchxamApplication?.durationType}
            durationInMinutes={catchxamApplication?.durationInMinutes}
            handleLeftArrowOnClick={handleToPreviousActivity}
            handleRightArrowOnClick={handleToNextActivityCheckCanAnswer}
          />
        ) : null}
      </ScreenCard>
    </div>
  );
};

export default CatchxamApplicationScreen;
