import ShowBodyMediaByContentType from "./ShowBodyMediaByContentType";

const ActivityBodyContent = (props) => {
  const { templateType, bodyMap, contentMap, answerMap } = props;
  let currentQQIndex = 0;

  return (
    <div className="flex flex-col justify-center items-center">
      {Object.keys(bodyMap).map((key, index) => {
        const body = JSON.parse(bodyMap[key]);
        if (templateType === "DROPDOWN") {
          while (body.value.includes("@@")) {
            if (
              answerMap[currentQQIndex].trim() === "" ||
              answerMap[currentQQIndex].trim() === "DEFAULT_OPTION"
            ) {
              body.value = body.value.replace(
                "@@",
                `----(${currentQQIndex + 1})----`
              );
            } else {
              if (contentMap["type"] === "TEXT") {
                body.value = body.value.replace(
                  "@@",
                  `--TEXT--${answerMap[currentQQIndex]}--TEXT--`
                );
              } else if (contentMap["type"] === "IMAGE") {
                body.value = body.value.replace(
                  "@@",
                  `--IMAGE--${answerMap[currentQQIndex]}--IMAGE--`
                );
              } else if (contentMap["type"] === "VIDEO") {
                body.value = body.value.replace(
                  "@@",
                  `--VIDEO--${answerMap[currentQQIndex]}--VIDEO--`
                );
              } else if (contentMap["type"] === "AUDIO") {
                body.value = body.value.replace(
                  "@@",
                  `--AUDIO--${answerMap[currentQQIndex]}--AUDIO--`
                );
              } else {
                body.value = body.value.replace(
                  "@@",
                  answerMap[currentQQIndex]
                );
              }
            }
            currentQQIndex++;
          }
        } else if (templateType === "FILL_IN_THE_BLANKS") {
          while (body.value.includes("@@")) {
            if (answerMap[currentQQIndex].trim() === "") {
              body.value = body.value.replace(
                "@@",
                `----(${currentQQIndex + 1})----`
              );
            } else {
              if (contentMap["type"] === "TEXT") {
                body.value = body.value.replace(
                  "@@",
                  `--TEXT--${answerMap[currentQQIndex]}--TEXT--`
                );
              } else if (contentMap["type"] === "IMAGE") {
                body.value = body.value.replace(
                  "@@",
                  `--IMAGE--${answerMap[currentQQIndex]}--IMAGE--`
                );
              } else if (contentMap["type"] === "VIDEO") {
                body.value = body.value.replace(
                  "@@",
                  `--VIDEO--${answerMap[currentQQIndex]}--VIDEO--`
                );
              } else if (contentMap["type"] === "AUDIO") {
                body.value = body.value.replace(
                  "@@",
                  `--AUDIO--${answerMap[currentQQIndex]}--AUDIO--`
                );
              } else {
                body.value = body.value.replace(
                  "@@",
                  answerMap[currentQQIndex]
                );
              }
            }
            currentQQIndex++;
          }
        }

        return (
          <ShowBodyMediaByContentType
            index={index}
            type={body.type}
            value={body.value}
            size={body.size}
          />
        );
      })}
    </div>
  );
};

export default ActivityBodyContent;
