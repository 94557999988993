import i18n from "../../language/i18n";
import { useNavigate } from "react-router-dom";
import ReportCardView from "../reports/ReportCardView";

const Report = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2 overflow-y-auto no-scrollbar">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("reports")}
          </p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/reports");
          }}
        >
          <p className="text-catchup-blue-700 underline">{i18n.t("see_all")}</p>
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-center">
        <ReportCardView isMinimized={true} />
      </div>
    </div>
  );
};

export default Report;
