import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Earned Points
 */
export const queryEarnedPointListUserInBranchLeaderboardByParams = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.gamification.url}${endpoints.gamification["earned-points"].context}${endpoints.gamification["earned-points"].query.dto["user-in-branch-leaderboard"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryEarnedPointListBranchInSeasonLeaderboardByParams = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.gamification.url}${endpoints.gamification["earned-points"].context}${endpoints.gamification["earned-points"].query.dto["branch-in-season-leaderboard"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryEarnedPointDTOListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.gamification.url}${endpoints.gamification["earned-points"].context}${endpoints.gamification["earned-points"].query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Earned Badges
 */
export const queryEarnedBadgeDTOListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.gamification.url}${endpoints.gamification["earned-badges"].context}${endpoints.gamification["earned-badges"].query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Statuses
 */
export const queryPersonalStatusByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.gamification.url}${endpoints.gamification.statuses.context}${endpoints.gamification.statuses.personal.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
