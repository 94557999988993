import i18n from "../language/i18n";

const retrieveDefaultOrderingDataMap = (orderingMaterialMap) => {
  const dataMap = {};
  Object.keys(orderingMaterialMap)
    .sort(() => 0.5 - Math.random())
    .forEach((key, index) => {
      dataMap[index] = key;
    });

  return dataMap;
};

const retrieveDefaultDropdownMap = (dropdownMaterialMap) => {
  const dataMap = {};
  Object.keys(dropdownMaterialMap).forEach((key) => {
    dataMap[key] = "DEFAULT_OPTION";
  });
  return dataMap;
};

const retrieveDefaultMCSAMap = (MCSAMaterialMap) => {
  const dataMap = {};
  Object.keys(MCSAMaterialMap).forEach((key, index) => {
    dataMap[key] = "ANSWER_KEY";
  });
  return dataMap;
};

const retrieveDefaultMCMAMap = (MCMAMaterialMap) => {
  const dataMap = {};
  Object.keys(MCMAMaterialMap).forEach((key, index) => {
    dataMap[key] = [];
  });
  return dataMap;
};

const retrieveDefaultMatchingMap = (matchingMaterialMap) => {
  const dataMap = {};
  Object.keys(matchingMaterialMap).forEach((key, index) => {
    dataMap[key] = "";
  });
  return dataMap;
};

const retrieveDefaultGroupingMap = (groupingMaterialMap) => {
  const dataMap = {};
  Object.keys(groupingMaterialMap).forEach((key, index) => {
    dataMap[key] = [];
  });
  return dataMap;
};

const retrieveDefaultFillInTheBlanksMap = (fillInTheBlanksMaterialMap) => {
  const dataMap = {};
  Object.keys(fillInTheBlanksMaterialMap).forEach((key) => {
    dataMap[key] = "";
  });
  return dataMap;
};

const retrieveDefaultOpenEndedMap = () => {
  return { ANSWER: "" };
};

const retrieveDefaultTrueFalseMap = () => {
  return { trueList: [], falseList: [] };
};

export const retrieveCurrentDefaultDataMap = (
  activityTemplate,
  activityData
) => {
  const defaultDataMap = {
    activityTemplateId: activityTemplate.id,
    type: activityTemplate.type,
  };
  if (activityTemplate.type === "ORDERING") {
    defaultDataMap.answerMap = retrieveDefaultOrderingDataMap(
      activityData.orderingMaterialMap
    );
  } else if (activityTemplate.type === "DROPDOWN") {
    defaultDataMap.answerMap = retrieveDefaultDropdownMap(
      activityData.dropdownMaterialMap
    );
  } else if (activityTemplate.type === "MCSA") {
    defaultDataMap.answerMap = retrieveDefaultMCSAMap(
      activityData.MCSAMaterialMap
    );
  } else if (activityTemplate.type === "MCMA") {
    defaultDataMap.answerMap = retrieveDefaultMCMAMap(
      activityData.MCMAMaterialMap
    );
  } else if (activityTemplate.type === "MATCHING") {
    defaultDataMap.answerMap = retrieveDefaultMatchingMap(
      activityData.matchingMaterialMap
    );
  } else if (activityTemplate.type === "GROUPING") {
    defaultDataMap.answerMap = retrieveDefaultGroupingMap(
      activityData.groupingMaterialMap
    );
  } else if (activityTemplate.type === "FILL_IN_THE_BLANKS") {
    defaultDataMap.answerMap = retrieveDefaultFillInTheBlanksMap(
      activityData.fillInTheBlanksMaterialMap
    );
  } else if (activityTemplate.type === "OPEN_ENDED") {
    defaultDataMap.answerMap = retrieveDefaultOpenEndedMap();
  } else if (activityTemplate.type === "TRUE_FALSE") {
    defaultDataMap.answerMap = retrieveDefaultTrueFalseMap();
  }
  return defaultDataMap;
};

export const constructActivityAnswerMap = (activityTemplate, activityData) => {
  if (activityTemplate.type === "ORDERING") {
    activityData.orderingMaterialMap = JSON.parse(
      activityData.orderingMaterialMap
    );
  } else if (activityTemplate.type === "DROPDOWN") {
    activityData.dropdownMaterialMap = JSON.parse(
      activityData.dropdownMaterialMap
    );
  } else if (activityTemplate.type === "MCSA") {
    activityData.MCSAMaterialMap = JSON.parse(activityData.MCSAMaterialMap);
  } else if (activityTemplate.type === "MCMA") {
    activityData.MCMAMaterialMap = JSON.parse(activityData.MCMAMaterialMap);
  } else if (activityTemplate.type === "MATCHING") {
    activityData.matchingMaterialMap = JSON.parse(
      activityData.matchingMaterialMap
    );
  } else if (activityTemplate.type === "GROUPING") {
    activityData.groupingMaterialMap = JSON.parse(
      activityData.groupingMaterialMap
    );
  } else if (activityTemplate.type === "FILL_IN_THE_BLANKS") {
    activityData.fillInTheBlanksMaterialMap = JSON.parse(
      activityData.fillInTheBlanksMaterialMap
    );
  } else if (activityTemplate.type === "OPEN_ENDED") {
    activityData.openEndedMaterialMap = {};
  } else if (activityTemplate.type === "TRUE_FALSE") {
    activityData.trueFalseMaterialMap = { trueList: [], falseList: [] };
  }
  return retrieveCurrentDefaultDataMap(activityTemplate, activityData);
};

export const constructInputWithSpecialExpressionList = (inputText) => {
  const inputPartList = [];
  if (!inputText) return [];
  const splittedBold = inputText.split("**");
  let isBold = false;
  for (let i = 0; i < splittedBold.length; i++) {
    let isUnderline = false;
    const splittedUnderline = splittedBold[i].split("__");
    for (let j = 0; j < splittedUnderline.length; j++) {
      let isEquation = false;
      const splittedEquation = splittedUnderline[j].split("`");
      for (let k = 0; k < splittedEquation.length; k++) {
        inputPartList.push({
          value: splittedEquation[k],
          isEquation,
          isUnderline,
          isBold,
        });
        isEquation = !isEquation;
      }
      isUnderline = !isUnderline;
    }
    isBold = !isBold;
  }
  return inputPartList;
};

export const ignoreMathematicalExpression = (inputText) => {
  return inputText
    .replaceAll("{", "")
    .replaceAll("}", "")
    .replaceAll("_", "")
    .replaceAll("^", "")
    .replaceAll("\\frac", "")
    .replaceAll("\\text", "")
    .replaceAll("\\sqrt", "");
};

export const checkIfAnswerIsEmpty = (answer) => {
  const { data } = answer;
  if (data && data.length > 0) {
    const foundAnswer = data[0];
    const { type, answerMap } = foundAnswer;
    if (type === "ORDERING") {
      return false;
    } else if (type === "DROPDOWN") {
      for (const key of Object.keys(answerMap)) {
        if (answerMap[key] !== "DEFAULT_OPTION") {
          return false;
        }
      }
    } else if (type === "MCSA") {
      const key = Object.keys(answerMap)[0];
      if (answerMap[key] !== "ANSWER_KEY") {
        return false;
      }
    } else if (type === "MCMA") {
      const key = Object.keys(answerMap)[0];
      if (answerMap[key].length !== 0) {
        return false;
      }
    } else if (type === "MATCHING") {
      for (const key of Object.keys(answerMap)) {
        if (answerMap[key]) {
          return false;
        }
      }
    } else if (type === "GROUPING") {
      for (const key of Object.keys(answerMap)) {
        if (answerMap[key].length !== 0) {
          return false;
        }
      }
    } else if (type === "FILL_IN_THE_BLANKS") {
      for (const key of Object.keys(answerMap)) {
        if (answerMap[key]) {
          return false;
        }
      }
    } else if (type === "OPEN_ENDED") {
      const key = Object.keys(answerMap)[0];
      if (answerMap[key]) {
        return false;
      }
    } else if (type === "TRUE_FALSE") {
      return (
        answerMap.trueList.length === 0 && answerMap.falseList.length === 0
      );
    }
  }
  return true;
};

export const constructActivityAnswerStateList = (answerList, activityList) => {
  const stateList = [];
  activityList.forEach((activity, index) => {
    stateList.push({
      index,
      id: activity.id,
      state: checkActivityAnswerState(answerList, activity),
    });
  });
  return stateList;
};

export const retrieveActivityAnswerFromAnswerList = (answerList, activity) => {
  // if (!answerList || !activity) return;
  return answerList.find(
    (answer) => parseFloat(answer.activityDTO.id) === parseFloat(activity.id)
  );
};

export const checkActivityAnswerState = (answerList, activity) => {
  const activityAnswer = retrieveActivityAnswerFromAnswerList(
    answerList,
    activity
  );
  if (!activityAnswer) {
    return "NOT_EXISTS";
  }
  const { data } = activityAnswer;
  if (!data) return "NOT_EXISTS";
  const parsedData = JSON.parse(data);
  if (
    parsedData.filter((datum) => !datum.isEmpty).length === parsedData.length
  ) {
    return "ANSWERED";
  } else {
    return "EMPTY";
  }
};

export const findBestFitActivity = (
  activity,
  individualModelList,
  outcomeModelList
) => {
  const { activityTemplateDTOList, data, coterieType, categoryIdList } =
    activity;
  const parsedData = JSON.parse(data);
  const {
    orderingTaxonomyMap,
    dropdownTaxonomyMap,
    MCSATaxonomyMap,
    MCMATaxonomyMap,
    matchingTaxonomyMap,
    groupingTaxonomyMap,
    fillInTheBlanksTaxonomyMap,
    openEndedTaxonomyMap,
    trueFalseTaxonomyMap,
  } = parsedData;
  const taxonomyMap = {};
  if (orderingTaxonomyMap) {
    taxonomyMap["orderingTaxonomyMap"] = JSON.parse(orderingTaxonomyMap);
  }
  if (dropdownTaxonomyMap) {
    taxonomyMap["dropdownTaxonomyMap"] = JSON.parse(dropdownTaxonomyMap);
  }
  if (MCSATaxonomyMap) {
    taxonomyMap["MCSATaxonomyMap"] = JSON.parse(MCSATaxonomyMap);
  }
  if (MCMATaxonomyMap) {
    taxonomyMap["MCMATaxonomyMap"] = JSON.parse(MCMATaxonomyMap);
  }
  if (matchingTaxonomyMap) {
    taxonomyMap["matchingTaxonomyMap"] = JSON.parse(matchingTaxonomyMap);
  }
  if (groupingTaxonomyMap) {
    taxonomyMap["groupingTaxonomyMap"] = JSON.parse(groupingTaxonomyMap);
  }
  if (fillInTheBlanksTaxonomyMap) {
    taxonomyMap["fillInTheBlanksTaxonomyMap"] = JSON.parse(
      fillInTheBlanksTaxonomyMap
    );
  }
  if (trueFalseTaxonomyMap) {
    taxonomyMap["trueFalseTaxonomyMap"] = JSON.parse(trueFalseTaxonomyMap);
  }
  if (openEndedTaxonomyMap) {
    taxonomyMap["openEndedTaxonomyMap"] = JSON.parse(openEndedTaxonomyMap);
  }
  let coterieField = "VERBAL";
  if (
    coterieType === "MATHEMATICS" ||
    coterieType === "BIOLOGY" ||
    coterieType === "PHYSICS" ||
    coterieType === "CHEMISTRY" ||
    coterieType === "SCIENCE"
  ) {
    coterieField = "NUMERIC";
  }
  let foundModel;
  let isOutcomeFound = true;
  if (categoryIdList.length > 0) {
    const categoryId = categoryIdList[0];
    foundModel = outcomeModelList.find(
      (outcomeModel) => outcomeModel.outcomeId === categoryId
    );
    if (!foundModel) isOutcomeFound = false;
  } else {
    isOutcomeFound = false;
  }
  if (!isOutcomeFound) {
    foundModel = individualModelList.find(
      (individualModel) => individualModel.coterieField === coterieField
    );
  }

  let bestScore = Number.MAX_SAFE_INTEGER;
  let bestActivityTemplate;
  let bestActivityTemplateList = [];
  const activityTemplateValueMap = {};
  for (const activityTemplateDTO of activityTemplateDTOList) {
    const { type } = activityTemplateDTO;
    let currentTaxonomyMap;
    if (type === "ORDERING") {
      currentTaxonomyMap = taxonomyMap["orderingTaxonomyMap"];
    } else if (type === "DROPDOWN") {
      currentTaxonomyMap = taxonomyMap["dropdownTaxonomyMap"];
    } else if (type === "MCSA") {
      currentTaxonomyMap = taxonomyMap["MCSATaxonomyMap"];
    } else if (type === "MCMA") {
      currentTaxonomyMap = taxonomyMap["MCMATaxonomyMap"];
    } else if (type === "MATCHING") {
      currentTaxonomyMap = taxonomyMap["matchingTaxonomyMap"];
    } else if (type === "GROUPING") {
      currentTaxonomyMap = taxonomyMap["groupingTaxonomyMap"];
    } else if (type === "FILL_IN_THE_BLANKS") {
      currentTaxonomyMap = taxonomyMap["fillInTheBlanksTaxonomyMap"];
    } else if (type === "OPEN_ENDED") {
      currentTaxonomyMap = taxonomyMap["openEndedTaxonomyMap"];
    } else if (type === "TRUE_FALSE") {
      currentTaxonomyMap = taxonomyMap["trueFalseTaxonomyMap"];
    }

    const {
      type: taxonomyType,
      groupName: taxonomyGroupName,
      name: taxonomyName,
    } = currentTaxonomyMap;
    const taxonomyString =
      taxonomyType.toLowerCase() +
      taxonomyGroupName.toLowerCase().charAt(0).toUpperCase() +
      taxonomyGroupName.toLowerCase().substring(1) +
      taxonomyName.toLowerCase().charAt(0).toUpperCase() +
      taxonomyName.toLowerCase().substring(1);

    const currentTaxonomyScore = foundModel[taxonomyString];
    const splittedTypeList = type.split("_");
    let activityTemplateString = "";
    for (let i = 0; i < splittedTypeList.length; i++) {
      const currentType = splittedTypeList[i].toLowerCase();
      if (i === 0) {
        activityTemplateString += currentType;
      } else {
        activityTemplateString +=
          currentType.charAt(0).toUpperCase() + currentType.substring(1);
      }
    }
    const currentActivityTemplateScore = foundModel[activityTemplateString];
    const currentScore = currentTaxonomyScore * currentActivityTemplateScore;
    activityTemplateValueMap[type] = currentScore;
    if (currentScore < bestScore) {
      bestScore = currentScore;
      bestActivityTemplate = type;
      bestActivityTemplateList = [type];
    } else if (currentScore === bestScore) {
      bestActivityTemplateList.push(type);
    }
  }

  return {
    bestScore,
    bestActivityTemplate,
    bestActivityTemplateList,
    activityTemplateValueMap,
  };
};

export const retrieveContestTypeOptionList = () => {
  return [
    {
      value: "QUIZ",
      text: i18n.t("QUIZ"),
    },
  ];
};

export const retrieveFrequencyTypeOptionList = () => {
  return [
    {
      value: "WEEKLY",
      text: i18n.t("WEEKLY"),
    },
  ];
};

export const retrieveDistintCoterieTypeFromCatchtivityApplicationDTO = (
  catchtivityApplicationDTOList
) => {
  const coterieTypeList = [];
  for (const catchtivityApplicationDTO of catchtivityApplicationDTOList) {
    const { catchtivityDTO } = catchtivityApplicationDTO;
    const { coterieType } = catchtivityDTO;
    if (
      coterieTypeList.findIndex(
        (foundCoterieType) => foundCoterieType === coterieType
      ) === -1
    ) {
      coterieTypeList.push(coterieType);
    }
  }
  return coterieTypeList;
};

export const retrieveClockTimeLeft = (
  type,
  value,
  durationType,
  durationInMinutes,
  activityProgressDTOSet,
  activity
) => {
  if (type === "CATCHTIVITY" || type === "CATCHXAM" || type === "CONTEST") {
    if (durationType === "ALL") {
      return durationInMinutes
        ? durationInMinutes * 60 -
            retrieveTotalTimeSpentInSeconds(activityProgressDTOSet)
        : 0;
    } else if (durationType === "EACH") {
      return durationInMinutes
        ? durationInMinutes * 60 -
            retrieveEachTimeSpentInSeconds(activityProgressDTOSet, activity)
        : 0;
    } else return 0;
  } else if (type === "ACTIVITY") {
    return (
      value - retrieveEachTimeSpentInSeconds(activityProgressDTOSet, activity)
    );
  }
  return 0;
};

export const retrieveEachTimeSpentInSeconds = (
  activityProgressList,
  activity
) => {
  if (!activityProgressList || !activity) return 0;
  const foundActivityProgress = activityProgressList.find(
    (activityProgress) =>
      parseFloat(activityProgress.activityDTO.id) === parseFloat(activity.id)
  );
  if (foundActivityProgress) {
    return foundActivityProgress.timeSpent / 1000;
  }
  return 0;
};

export const retrieveTotalTimeSpentInSeconds = (activityProgressList) => {
  let timeSpent = 0;
  if (!activityProgressList) return 0;
  activityProgressList.forEach((activityProgress) => {
    timeSpent += activityProgress.timeSpent;
  });
  return timeSpent / 1000;
};

export const retrieveTotalTimeSpentInMinutes = (activityProgressList) => {
  return retrieveTotalTimeSpentInSeconds(activityProgressList) / 60;
};

export const parseContentMapFromData = (data) => {
  return JSON.parse(data.contentMap);
};

export const parseBodyMapFromData = (data, type) => {
  if (type === "ORDERING") {
    return JSON.parse(data.orderingBodyMap);
  } else if (type === "DROPDOWN") {
    return JSON.parse(data.dropdownBodyMap);
  } else if (type === "MCSA") {
    return JSON.parse(data.MCSABodyMap);
  } else if (type === "MCMA") {
    return JSON.parse(data.MCMABodyMap);
  } else if (type === "MATCHING") {
    return JSON.parse(data.matchingBodyMap);
  } else if (type === "GROUPING") {
    return JSON.parse(data.groupingBodyMap);
  } else if (type === "FILL_IN_THE_BLANKS") {
    return JSON.parse(data.fillInTheBlanksBodyMap);
  } else if (type === "OPEN_ENDED") {
    return JSON.parse(data.openEndedBodyMap);
  } else if (type === "TRUE_FALSE") {
    return JSON.parse(data.trueFalseBodyMap);
  }
};

export const parseMaterialMapFromData = (data, type) => {
  if (type === "ORDERING") {
    return JSON.parse(data.orderingMaterialMap);
  } else if (type === "DROPDOWN") {
    const dropdownMaterialMap = JSON.parse(data.dropdownMaterialMap);
    Object.keys(dropdownMaterialMap).forEach((materialKey) => {
      dropdownMaterialMap[materialKey] = JSON.parse(
        dropdownMaterialMap[materialKey]
      );
      const answerKey = Object.keys(dropdownMaterialMap[materialKey])[0];
      dropdownMaterialMap[materialKey][answerKey] = JSON.parse(
        dropdownMaterialMap[materialKey][answerKey]
      );
    });
    return dropdownMaterialMap;
  } else if (type === "MCSA") {
    const MCSAMaterialMap = JSON.parse(data.MCSAMaterialMap);
    Object.keys(MCSAMaterialMap).forEach((key) => {
      MCSAMaterialMap[key] = JSON.parse(MCSAMaterialMap[key]);
    });
    return MCSAMaterialMap;
  } else if (type === "MCMA") {
    const MCMAMaterialMap = JSON.parse(data.MCMAMaterialMap);
    Object.keys(MCMAMaterialMap).forEach((key) => {
      MCMAMaterialMap[key] = JSON.parse(MCMAMaterialMap[key]);
    });
    return MCMAMaterialMap;
  } else if (type === "MATCHING") {
    return JSON.parse(data.matchingMaterialMap);
  } else if (type === "GROUPING") {
    const groupingMaterialMap = JSON.parse(data.groupingMaterialMap);
    Object.keys(groupingMaterialMap).forEach((key) => {
      groupingMaterialMap[key] = JSON.parse(groupingMaterialMap[key]);
    });
    return groupingMaterialMap;
  } else if (type === "FILL_IN_THE_BLANKS") {
    return JSON.parse(data.fillInTheBlanksMaterialMap);
  } else if (type === "OPEN_ENDED") {
    return {};
  } else if (type === "TRUE_FALSE") {
    const trueFalseMaterialMap = JSON.parse(data.trueFalseMaterialMap);
    Object.keys(trueFalseMaterialMap).forEach((key) => {
      trueFalseMaterialMap[key] = JSON.parse(trueFalseMaterialMap[key]);
    });
    return trueFalseMaterialMap;
  }
};

export const retrieveColorByScore = (score) => {
  if (score <= 25) {
    return "#F96666";
  } else if (score > 25 && score <= 50) {
    return "#f98d66";
  } else if (score > 50 && score <= 75) {
    return "#cbd357";
  } else {
    return "#ABD357";
  }
};

export const constructActivityItemListWithAnswersForAI = (
  bodyMap,
  materialMap,
  type
) => {
  const itemList = [];
  if (type === "ORDERING") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_order")}: `,
      });
      Object.keys(materialMap).forEach((key) => {
        itemList.push({
          type: "text",
          text: materialMap[key],
        });
      });
    }
  } else if (type === "DROPDOWN") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value.replaceAll("@@", "___________"),
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_blanks")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        itemList.push({
          type: "text",
          text: `${index + 1}. ${Object.keys(materialMap[key])[0]}`,
        });
      });
    }
  } else if (type === "MCSA") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answer")}: `,
      });
      Object.keys(materialMap).forEach((key) => {
        itemList.push({
          type: "text",
          text: key,
        });
      });
    }
  } else if (type === "MCMA") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      const splittedTextList = Object.keys(materialMap)[0].split("§");
      splittedTextList.forEach((item) => {
        const parsedItemList = JSON.parse(item);
        for (const parsedItem of parsedItemList) {
          itemList.push({
            type: "text",
            text: parsedItem,
          });
        }
      });
    }
  } else if (type === "MATCHING") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        itemList.push({
          type: "text",
          text: `${i18n.t("match")} ${index + 1}:`,
        });
        itemList.push({
          type: "text",
          text: `${key}: ${materialMap[key]}`,
        });
      });
    }
  } else if (type === "GROUPING") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        let text = `${i18n.t("group")} ${index + 1}: `;
        itemList.push({
          type: "text",
          text: text,
        });

        const groupName = key;
        let currentText = "";
        materialMap[groupName].forEach((item) => {
          currentText += `${item}, `;
        });
        currentText = currentText.substring(0, currentText.length - 2);
        itemList.push({
          type: "text",
          text: `${groupName}: [${currentText}]`,
        });
      });
    }
  } else if (type === "FILL_IN_THE_BLANKS") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value.replaceAll("@@", "___________"),
        });
      }
    });
    if (Object.keys(materialMap).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_blanks")}: `,
      });
      Object.keys(materialMap).forEach((key, index) => {
        JSON.parse(materialMap[key]).forEach((item) => {
          itemList.push({
            type: "text",
            text: `${index + 1}. ${item}`,
          });
        });
      });
    }
  } else if (type === "OPEN_ENDED") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
  } else if (type === "TRUE_FALSE") {
    Object.keys(bodyMap).forEach((key) => {
      const bodyItem = JSON.parse(bodyMap[key]);
      if (bodyItem.type === "IMAGE") {
        itemList.push({
          type: "image_url",
          imageUrl: bodyItem.value,
        });
      } else {
        itemList.push({
          type: "text",
          text: bodyItem.value,
        });
      }
    });
    if (materialMap.trueList && Object.keys(materialMap.trueList).length > 0) {
      itemList.push({
        type: "text",
        text: `${i18n.t("correct_answers")}: `,
      });
      materialMap.trueList.forEach((item) => {
        itemList.push({
          type: "text",
          text: item,
        });
      });
    }
    if (
      materialMap.falseList &&
      Object.keys(materialMap.falseList).length > 0
    ) {
      itemList.push({
        type: "text",
        text: `${i18n.t("incorrect_answers")}: `,
      });
      materialMap.falseList.forEach((item) => {
        itemList.push({
          type: "text",
          text: item,
        });
      });
    }
  }

  return itemList;
};
