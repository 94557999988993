import i18n from "../../language/i18n";
import BaseImage from "../images/BaseImage";

const Status = (props) => {
  const {
    userId,
    userProfileId,
    contentList,
    etudeList,
    allTimeRankingList,
    thisWeekRankingList,
    remainingPoints,
  } = props;

  const foundThisWeekRanking = thisWeekRankingList.find(
    (ranking) =>
      ranking.userId === userId && ranking.userProfileId === userProfileId
  );

  const foundAllTimeRanking = allTimeRankingList.find(
    (ranking) =>
      ranking.userId === userId && ranking.userProfileId === userProfileId
  );

  const completedContentList = contentList.filter(
    (content) => content.completionStatus === "COMPLETED"
  );
  const onGoingContentList = contentList.filter(
    (content) => content.completionStatus === "ON_GOING"
  );
  const timeUpContentList = contentList.filter(
    (content) => content.completionStatus === "TIME_UP"
  );
  const notStartedContentList = contentList.filter(
    (content) => content.completionStatus === "NOT_STARTED"
  );

  const constructThisWeekRankingText = () => {
    if (foundThisWeekRanking) {
      const { rank } = foundThisWeekRanking;
      return (
        <span>
          {i18n.t("your_ranking_in_contest_this_week_is_text_1")}
          <span className="font-semibold">{rank}</span>
          {i18n.t("your_ranking_in_contest_this_week_is_text_2")}
        </span>
      );
    } else {
      return i18n.t("you_have_not_attended_this_weeks_contest");
    }
  };

  const constructThisWeekTotalPointsText = () => {
    if (!foundThisWeekRanking) return;
    const { totalPoints } = foundThisWeekRanking;
    return (
      <span>
        {i18n.t("your_total_points_in_contest_this_week_is_text_1")}
        <span className="font-semibold">{totalPoints}</span>
        {i18n.t("your_total_points_in_contest_this_week_is_text_2")}
      </span>
    );
  };

  const constructAllTimeRankingText = () => {
    if (!foundAllTimeRanking) return;
    const { rank } = foundAllTimeRanking;
    return (
      <span>
        {i18n.t("your_ranking_in_contest_all_time_is_text_1")}
        <span className="font-semibold">{rank}</span>
        {i18n.t("your_ranking_in_contest_all_time_is_text_2")}
      </span>
    );
  };

  const constructAllTimeTotalPointsText = () => {
    if (!foundAllTimeRanking) return;
    const { totalPoints } = foundAllTimeRanking;
    return (
      <span>
        {i18n.t("your_total_points_in_contest_all_time_is_text_1")}
        <span className="font-semibold">{totalPoints}</span>
        {i18n.t("your_total_points_in_contest_all_time_is_text_2")}
      </span>
    );
  };

  const constructSpentPointsText = () => {
    if (!foundAllTimeRanking) return;
    const { totalPoints } = foundAllTimeRanking;
    const spentPoints = totalPoints - remainingPoints;
    return (
      <span>
        {i18n.t("you_have_spent_points_so_far_text_1")}
        <span className="font-semibold">{spentPoints}</span>
        {i18n.t("you_have_spent_points_so_far_text_2")}
      </span>
    );
  };

  const constructOverallContentStatusText = () => {
    if (contentList.length === 0) return;
    return (
      <span>
        {i18n.t("overall_content_status_text_1")}
        <span className="font-semibold">{contentList.length}</span>
        {i18n.t("overall_content_status_text_2")}
        <span className="font-semibold">{completedContentList.length}</span>
        {i18n.t("overall_content_status_text_3")}
        <span className="font-semibold">
          {onGoingContentList.length + timeUpContentList.length}
        </span>
        {i18n.t("overall_content_status_text_4")}
        <span className="font-semibold">{notStartedContentList.length}</span>
        {i18n.t("overall_content_status_text_5")}
      </span>
    );
  };

  const constructEtudeStatusText = () => {
    if (etudeList.length === 0) return;
    return (
      <span>
        {i18n.t("overall_etude_status_text_1")}
        <span className="font-semibold">{etudeList.length}</span>
        {i18n.t("overall_etude_status_text_2")}
      </span>
    );
  };

  const RenderSingleItem = (value) => {
    return (
      <div className="flex flex-row my-1 gap-x-1">
        <div>
          <BaseImage
            src="/icons/status-item.png"
            alt="status-item"
            size="small"
          />
        </div>
        <div className="flex-1">
          <p>{value}</p>
        </div>
      </div>
    );
  };

  const overallContentStatusText = constructOverallContentStatusText();
  const etudeStatusText = constructEtudeStatusText();
  const thisWeekRankingText = constructThisWeekRankingText();
  const thisWeekTotalPointsText = constructThisWeekTotalPointsText();
  const allTimeRankingText = constructAllTimeRankingText();
  const allTimeTotalPointsText = constructAllTimeTotalPointsText();
  const spentPointsText = constructSpentPointsText();

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("my_status")}
          </p>
        </div>
      </div>
      <div className="flex-1 flex flex-col min-h-[200px] mt-1 overflow-y-auto no-scrollbar">
        {overallContentStatusText
          ? RenderSingleItem(overallContentStatusText)
          : null}
        {etudeStatusText ? RenderSingleItem(etudeStatusText) : null}
        {RenderSingleItem(thisWeekRankingText)}
        {thisWeekTotalPointsText
          ? RenderSingleItem(thisWeekTotalPointsText)
          : null}
        {allTimeRankingText ? RenderSingleItem(allTimeRankingText) : null}
        {allTimeTotalPointsText
          ? RenderSingleItem(allTimeTotalPointsText)
          : null}
        {spentPointsText ? RenderSingleItem(spentPointsText) : null}
      </div>
    </div>
  );
};

export default Status;
