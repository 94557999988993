import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Catchtivity Application
 */
export const queryCatchtivityApplicationListBySeasonIdAndGradeBranchMapList =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.query.dto["by-seasonId-gradeBranchMapList"].context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const retrieveCatchtivityApplicationById = async (
  catchtiviyApplicationId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.dto.context}/${catchtiviyApplicationId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCatchtivityApplicationProgress = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.applications.context}${endpoints.catchtivities.applications.progress.dto["by-catchtivityApplicationId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchxam Application
 */
export const queryCatchxamApplicationListBySeasonIdAndGradeBranchMapList =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.query.dto["by-seasonId-gradeBranchMapList"].context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const retrieveCatchxamApplicationById = async (
  catchxamApplicationId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.dto.context}/${catchxamApplicationId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCatchxamApplicationProgress = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.progress.dto["by-catchxamApplicationId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrievePerformanceCatchxamApplication = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.catchxams.applications.context}${endpoints.catchtivities.catchxams.applications.performance.learner.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity
 */
export const retrieveActivityWithDataById = async (id) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.with.data.dto.context}/${id}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveActivityWithDataByIdAndToken = async (id, token) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.context}${endpoints.catchtivities.activities.with.data.dto.context}/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Answer
 */
export const createActivityAnswerByActivityIdCatchtivityApplicationId = async (
  newActivityAnswer
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers["by-activityId-catchtivityApplicationId"].context}`,
      newActivityAnswer
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityAnswerByActivityIdCatchxamApplicationId = async (
  newActivityAnswer
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers["by-activityId-catchxamApplicationId"].context}`,
      newActivityAnswer
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityAnswerByActivityIdStandardExamApplicationId = async (
  newActivityAnswer
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers["by-activityId-standardExamApplicationId"].context}`,
      newActivityAnswer
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityAnswerByActivityIdEtudeId = async (
  newActivityAnswer
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers["by-activityId-etudeId"].context}`,
      newActivityAnswer
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityAnswerByActivityIdContestId = async (
  newActivityAnswer
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers["by-activityId-contestId"].context}`,
      newActivityAnswer
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchActivityAnswerWithData = async (patchActivityAnswer) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.with.data.context}/`,
      patchActivityAnswer
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityAnswerListByCatchtivityApplicationId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-catchtivityApplicationId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityAnswerListByCatchxamApplicationId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-catchxamApplicationId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityAnswerListByStandardExamApplicationId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-standardExamApplicationId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityAnswerListByActivityIdAndCatchtivityApplicationId =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-activityId-catchtivityApplicationId"].context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const queryActivityAnswerListByActivityIdAndCatchxamApplicationId =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-activityId-catchxamApplicationId"].context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const queryActivityAnswerListByActivityIdAndStandardExamApplicationId =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-activityId-standardExamApplicationId"].context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const queryActivityAnswerListByEtudeId = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-etudeId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityAnswerListByContestId = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.answers.context}${endpoints.catchtivities.activities.answers.query.dto["by-contestId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Progress
 */
export const createActivityProgressByActivityIdCatchtivityApplicationId =
  async (newActivityProgress) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.progress.context}${endpoints.catchtivities.activities.progress["by-activityId-catchtivityApplicationId"].context}`,
        newActivityProgress
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const createActivityProgressByActivityIdCatchxamApplicationId = async (
  newActivityProgress
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.progress.context}${endpoints.catchtivities.activities.progress["by-activityId-catchxamApplicationId"].context}`,
      newActivityProgress
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityProgressByActivityIdStandardExamApplicationId =
  async (newActivityProgress) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.progress.context}${endpoints.catchtivities.activities.progress["by-activityId-standardExamApplicationId"].context}`,
        newActivityProgress
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const createActivityProgressByActivityIdContestId = async (
  newActivityProgress
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.progress.context}${endpoints.catchtivities.activities.progress["by-activityId-contestId"].context}`,
      newActivityProgress
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchActivityProgress = async (patchActivityProgress) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.progress.context}/`,
      patchActivityProgress
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Reviews
 */
export const createActivityReviewByActivityIdCatchtivityApplicationId = async (
  createParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.reviews.context}${endpoints.catchtivities.activities.reviews["by-activityId-catchtivityApplicationId"].context}`,
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityReviewByActivityIdCatchxamApplicationId = async (
  createParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.reviews.context}${endpoints.catchtivities.activities.reviews["by-activityId-catchxamApplicationId"].context}`,
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createActivityReviewByActivityIdStandardExamApplicationId = async (
  createParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.reviews.context}${endpoints.catchtivities.activities.reviews["by-activityId-standardExamApplicationId"].context}`,
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityReviewListByUserIdAndUserProfileId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.reviews.context}${endpoints.catchtivities.activities.reviews.query.dto["by-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchActivityReview = async (patchParams) => {
  try {
    const response = await axios.patch(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.reviews.context}/`,
      patchParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchtivity
 */
export const retrieveCatchtivityWithActivityDataById = async (
  catchtivityId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities.context
      }${endpoints.catchtivities.dto["with-activity-data"].id.context.replace(
        "{id}",
        catchtivityId
      )}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCatchtivityWithActivityDataByIdAndToken = async (
  catchtivityId,
  token
) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities.context
      }${endpoints.catchtivities.dto["with-activity-data"].id.context.replace(
        "{id}",
        catchtivityId
      )}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Catchxam
 */
export const retrieveCatchxamWithActivityDataById = async (catchxamId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities.catchxams.context
      }${endpoints.catchtivities.catchxams.dto[
        "with-activity-data"
      ].id.context.replace("{id}", catchxamId)}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCatchxamWithActivityDataByIdAndToken = async (
  catchxamId,
  token
) => {
  const axiosInstance = axios.create();
  try {
    const response = await axiosInstance.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities.catchxams.context
      }${endpoints.catchtivities.catchxams.dto[
        "with-activity-data"
      ].id.context.replace("{id}", catchxamId)}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Standard Exam
 */
export const retrieveStandardExamWithActivityDataByIdAndToken = async (
  standardExamId,
  token
) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities["standard-exams"].context
      }${endpoints.catchtivities["standard-exams"].dto[
        "with-activity-data"
      ].id.context.replace("{id}", standardExamId)}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveStandardExamWithActivityDataById = async (
  standardExamId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${
        endpoints.catchtivities["standard-exams"].context
      }${endpoints.catchtivities["standard-exams"].dto[
        "with-activity-data"
      ].id.context.replace("{id}", standardExamId)}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Standard Exam Application
 */
export const queryStandardExamApplicationListBySeasonIdAndGradeBranchMapList =
  async (queryParams) => {
    try {
      const response = await axios.post(
        `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications.query.dto["by-seasonId-gradeBranchMapList"].context}`,
        queryParams
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const retrieveStandardExamApplicationById = async (
  standardExamApplicationId
) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications.dto.context}/${standardExamApplicationId}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveStandardExamApplicationProgress = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["standard-exams"].applications.context}${endpoints.catchtivities["standard-exams"].applications.progress.dto["by-standardExamApplicationId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Etude
 */
export const queryEtudeDTOListByUserIdAndUserProfileId = async (
  queryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes.query.dto["by-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveEtudeById = async (etudeId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes.dto.id.context}`.replace(
        "{id}",
        etudeId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const evaluateEtudeById = async (etudeId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.etudes.context}${endpoints.catchtivities.etudes.id.evaluate.context}`.replace(
        "{id}",
        etudeId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Template Feedback
 */
export const createActivityTemplateFeedbackByToken = async (
  activityTemplateFeedback,
  token
) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-template-feedbacks"].context}${endpoints.catchtivities["activity-template-feedbacks"]["by-token"].context}`,
      activityTemplateFeedback,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryActivityTemplateFeedbackListByToken = async (
  queryParams,
  token
) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities["activity-template-feedbacks"].context}${endpoints.catchtivities["activity-template-feedbacks"].query.dto["by-activityId"].context}`,
      queryParams,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Contest
 */
export const retrieveContestById = async (contestId) => {
  try {
    const response = await axios.get(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.contests.context}${endpoints.catchtivities.contests.dto.id.context}`.replace(
        "{id}",
        contestId
      )
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createContestByActivityBundleId = async (createParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.contests.context}${endpoints.catchtivities.contests["by-activityBundleId"].context}`,
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createContestByCoterieType = async (createParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.contests.context}${endpoints.catchtivities.contests["by-coterieType"].context}`,
      createParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryContestListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.contests.context}${endpoints.catchtivities.contests.query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveContestProgress = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.contests.context}${endpoints.catchtivities.contests.progress.dto["by-contestId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const evaluateContestByParams = async (evaluateParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.contests.context}${endpoints.catchtivities.contests.evaluate.context}`,
      evaluateParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * AI
 */
export const hintActivityWithAI = async (hintWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-hint"].context}${endpoints.catchtivities.ai["activity-hint"]["from-gpt4"].context}`,
      hintWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const solveActivityWithAI = async (solveWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-solver"].context}${endpoints.catchtivities.ai["activity-solver"]["from-gpt4"].context}`,
      solveWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const solveActivityWithAIOnlyImage = async (solveWithAI) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.ai["activity-solver"].context}${endpoints.catchtivities.ai["activity-solver"]["from-gpt4"]["only-image"].context}`,
      solveWithAI
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Activity Evaluations
 */
export const checkWrongActivityByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.catchtivity.url}${endpoints.catchtivities.activities.evaluations.context}${endpoints.catchtivities.activities.evaluations.check["wrong-activities"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
