import { useEffect } from "react";
import FullCard from "../../components/cards/FullCard";
import HomeworkApplicationListView from "../../components/views/HomeworkApplicationListView";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import {
  queryCatchtivityApplicationListBySeasonIdAndGradeBranchMapList,
  queryCatchxamApplicationListBySeasonIdAndGradeBranchMapList,
  queryStandardExamApplicationListBySeasonIdAndGradeBranchMapList,
} from "../../requests/CatchtivityRequests";
import { useLocation } from "react-router-dom";
import DashboardHeader from "../../components/headers/DashboardHeader";
import i18n from "../../language/i18n";

const HomeworkScreen = () => {
  const location = useLocation();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileSeason,
    activeContentList,
    setCatchtivityApplicationDTOList,
    setCatchtivityApplicationListLoading,
    setCatchxamApplicationDTOList,
    setCatchxamApplicationListLoading,
    setStandardExamApplicationDTOList,
    setStandardExamApplicationListLoading,
  } = useAuth();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetCatchtivityApplicationList = async () => {
      setCatchtivityApplicationListLoading(true);
      const gradeBranchMapList = userProfile.branchDTOList.map((branchDTO) => ({
        gradeId: branchDTO.gradeDTO.id,
        branchIdList: userProfile.branchDTOList
          .filter(
            (innerBranchDTO) =>
              innerBranchDTO.gradeDTO.id === branchDTO.gradeDTO.id
          )
          .map((innerBranchDTO) => innerBranchDTO.id),
      }));
      const { data, err } =
        await queryCatchtivityApplicationListBySeasonIdAndGradeBranchMapList({
          userId: userInformation.id,
          userProfileId: userProfile.id,
          seasonId: userProfileSeason.id,
          gradeBranchMapList,
          deleted: false,
        });
      if (err) {
        console.log(err);
      } else {
        setCatchtivityApplicationDTOList(data.sort((a, b) => b.id - a.id));
      }
      setCatchtivityApplicationListLoading(false);
    };
    const retrieveAndSetCatchxamApplicationList = async () => {
      setCatchxamApplicationListLoading(true);
      const gradeBranchMapList = userProfile.branchDTOList.map((branchDTO) => ({
        gradeId: branchDTO.gradeDTO.id,
        branchIdList: userProfile.branchDTOList
          .filter(
            (innerBranchDTO) =>
              innerBranchDTO.gradeDTO.id === branchDTO.gradeDTO.id
          )
          .map((innerBranchDTO) => innerBranchDTO.id),
      }));
      const { data, err } =
        await queryCatchxamApplicationListBySeasonIdAndGradeBranchMapList({
          userId: userInformation.id,
          userProfileId: userProfile.id,
          seasonId: userProfileSeason.id,
          gradeBranchMapList,
        });
      if (err) {
        console.log(err);
      } else {
        setCatchxamApplicationDTOList(data.sort((a, b) => b.id - a.id));
      }
      setCatchxamApplicationListLoading(false);
    };
    const retrieveAndSetStandardExamApplicationList = async () => {
      setStandardExamApplicationListLoading(true);
      const gradeBranchMapList = userProfile.branchDTOList.map((branchDTO) => ({
        gradeId: branchDTO.gradeDTO.id,
        branchIdList: userProfile.branchDTOList
          .filter(
            (innerBranchDTO) =>
              innerBranchDTO.gradeDTO.id === branchDTO.gradeDTO.id
          )
          .map((innerBranchDTO) => innerBranchDTO.id),
      }));
      const { data, err } =
        await queryStandardExamApplicationListBySeasonIdAndGradeBranchMapList({
          userId: userInformation.id,
          userProfileId: userProfile.id,
          seasonId: userProfileSeason.id,
          gradeBranchMapList,
        });
      if (err) {
        console.log(err);
      } else {
        setStandardExamApplicationDTOList(data.sort((a, b) => b.id - a.id));
      }
      setStandardExamApplicationListLoading(false);
    };
    if (location && location.state) {
      if (location.state.state === "FORCE_UPDATE") {
        retrieveAndSetCatchtivityApplicationList();
        retrieveAndSetCatchxamApplicationList();
        retrieveAndSetStandardExamApplicationList();
      }
    }
  }, [location]);

  const RenderMainContent = () => {
    return (
      <FullCard>
        <DashboardHeader
          title={
            activeContentList.length === 0
              ? i18n.t("no_active_catchtivity_applications")
              : `${i18n.t("total_active_catchtivity_applications")} (${
                  activeContentList.length
                })`
          }
        />
        <HomeworkApplicationListView />
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default HomeworkScreen;
