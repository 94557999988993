import BaseImage from "../images/BaseImage";

const PersonalLabel = (props) => {
  const { title, icon, font } = props;
  return (
    <div className="px-3 py-1 gap-x-3 border border-personal-label-border bg-personal-label text-personal-label-text rounded-catchup-3xlarge">
      <div className="flex flex-row items-center gap-x-2">
        {icon ? (
          icon
        ) : (
          <BaseImage
            src="/icons/personal-label.png"
            alt="label"
            size="xsmall"
          />
        )}
        <p className={font ? font : "text-sm"}>{title}</p>
      </div>
    </div>
  );
};

export default PersonalLabel;
