import i18n from "../../language/i18n";
import { retrieveClockTimeLeft } from "../../utilization/CatchtivityUtilization";
import PrimaryButton from "../buttons/PrimaryButton";
import TimedProgressBar from "../progress-bar/TimedProgressBar";

const CatchtivityFooter = (props) => {
  const {
    activityNumber,
    totalActivityCount,
    showTimedProgressBar,
    activity,
    activityLoading,
    activityProgressList,
    durationType,
    durationInMinutes,
    handleLeftArrowOnClick,
    handleRightArrowOnClick,
  } = props;

  let data;
  let durationMap;
  if (showTimedProgressBar && activity) {
    data = JSON.parse(activity.data);
    durationMap = JSON.parse(data.durationMap);
  }

  return (
    <div className="flex flex-row items-center justify-between gap-x-4">
      <div className={`${activityLoading ? "opacity-40" : "opacity-100"}`}>
        {handleLeftArrowOnClick ? (
          <PrimaryButton
            title={i18n.t("previous")}
            size="medium"
            disabled={activityNumber === 0 || activityLoading}
            iconPosition="left"
            onClick={() => {
              if (activityLoading) return;
              handleLeftArrowOnClick();
            }}
          />
        ) : null}
      </div>
      <div className="flex-1">
        {activityLoading ? null : showTimedProgressBar ? (
          <TimedProgressBar
            showRemainingTime={true}
            startTimer={
              retrieveClockTimeLeft(
                "ACTIVITY",
                durationMap.duration,
                durationType,
                durationInMinutes,
                activityProgressList,
                activity
              ) > 0
            }
            title={i18n.t("quicktivity")}
            totalTimeInSeconds={durationMap.duration}
            remainingTimeInSeconds={retrieveClockTimeLeft(
              "ACTIVITY",
              durationMap.duration,
              durationType,
              durationInMinutes,
              activityProgressList,
              activity
            )}
            handleOnTimerEnds={() => {
              // if (activityLoading) return;
              // handleRightArrowOnClick(activityNumber + 1);
            }}
          />
        ) : null}
      </div>
      <div className={`${activityLoading ? "opacity-40" : "opacity-100"}`}>
        {handleRightArrowOnClick ? (
          <PrimaryButton
            title={
              activityNumber + 1 === totalActivityCount
                ? i18n.t("complete")
                : i18n.t("next")
            }
            size="medium"
            disabled={activityLoading}
            onClick={() => {
              if (activityLoading) return;
              handleRightArrowOnClick(activityNumber + 1);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CatchtivityFooter;
