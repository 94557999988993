import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import { useAuth } from "../../context/UserContextProvider";
import BaseImage from "../images/BaseImage";
import { constructWeekName } from "../../utilization/DateUtilization";
import ApproveButton from "../buttons/ApproveButton";
import PrimaryButton from "../buttons/PrimaryButton";
import DeleteButton from "../buttons/DeleteButton";
import BaseLoading from "../loading/BaseLoading";

const Homework = (props) => {
  const navigate = useNavigate();
  const { contentList, language, loading } = props;

  const RenderSingleHomeworkCard = (activeContent) => {
    const {
      id,
      type,
      beginAt,
      endAt,
      catchtivityDTO,
      catchxamDTO,
      standardExamDTO,
      completionStatus,
    } = activeContent;
    const beginDate = new Date(beginAt);
    const endDate = new Date(endAt);
    let coterieType;
    let activityDTOList = [];
    if (type === "CATCHTIVITY") {
      coterieType = catchtivityDTO.coterieType;
      activityDTOList = catchtivityDTO.activityDTOList;
    } else if (type === "CATCHAM") {
      coterieType = catchxamDTO.coterieType;
      activityDTOList = catchxamDTO.activityDTOList;
    } else if (type === "STANDARD_EXAM") {
      coterieType = standardExamDTO.standardExamType;
    }
    return (
      <div className="h-full rounded-2xl bg-catchup-blue-50">
        <div className="h-full p-3 flex flex-col justify-between">
          <div>
            <div className="flex flex-row items-center gap-x-2">
              <div>
                <BaseImage
                  src={`${
                    type === "CATCHTIVITY"
                      ? "/icons/catchtivity.png"
                      : type === "CATCHXAM"
                      ? "/icons/catchxam.png"
                      : type === "STANDARD_EXAM"
                      ? "/icons/standard-exam.png"
                      : null
                  }`}
                  alt="type"
                  size="medium"
                />
              </div>
              <div>
                <p className="text-catchup-gray-500 font-semibold">
                  {i18n.t(`${type.toLowerCase()}_abbrevation`)}
                </p>
                <p className="text-catchup-gray-400">
                  {constructWeekName(beginDate, endDate)}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-2 text-catchup-gray-300 mt-2">
              <div>
                <p>{i18n.t(coterieType)}</p>
              </div>
              <img
                className="w-1 h-1"
                src="/icons/small-dot-gray.png"
                alt="small-dot-gray"
              />
              <div>
                <p>
                  {activityDTOList.length} {i18n.t("activity")}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-x-2 mt-2">
              <p className="text-catchup-gray-800 font-semibold">{`${i18n.t(
                "deadline_date"
              )}:`}</p>
              <p className="text-catchup-gray-800 font-semibold">
                {endDate.toLocaleString(language)}
              </p>
            </div>
          </div>

          {completionStatus === "COMPLETED" ? (
            <div className="flex flex-col mt-2">
              <ApproveButton
                title={i18n.t(completionStatus)}
                size="unlimited"
                textOnly={true}
                onClick={() => {}}
              />
            </div>
          ) : completionStatus === "NOT_STARTED" ||
            completionStatus === "ON_GOING" ? (
            <div className="flex flex-col mt-2">
              <PrimaryButton
                title={
                  completionStatus === "NOT_STARTED"
                    ? i18n.t("START")
                    : i18n.t("CONTINUE")
                }
                size="unlimited"
                onClick={() => {
                  navigate(`/catchtivities/applications/${id}`);
                }}
              />
            </div>
          ) : completionStatus === "TIME_UP" ? (
            <div className="flex flex-col mt-2">
              <DeleteButton
                title={i18n.t(completionStatus)}
                textOnly={true}
                size="unlimited"
                onClick={() => {}}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2">
      <div className="flex flex-row justify-between items-center mb-2">
        <div>
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("upcoming_homeworks")}
          </p>
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/homeworks")}>
          <p className="text-catchup-blue-700 underline">{i18n.t("see_all")}</p>
        </div>
      </div>

      {loading ? (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      ) : (
        <div className="flex-1 flex flex-col min-h-[200px] overflow-y-auto no-scrollbar">
          <div className="h-full flex flex-row gap-x-2">
            {contentList.map((content, index) => (
              <div className="h-full w-1/3" key={index}>
                {RenderSingleHomeworkCard(content)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Homework;
