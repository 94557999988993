import { useCallback, useEffect, useState } from "react";
import i18n from "../../language/i18n";
import InputGroup from "../groups/InputGroup";
import { queryUserProfileDTOListBySeasonIdAndSearchName } from "../../requests/ManagementRequests";
import debounce from "lodash.debounce";
import BaseImage from "../images/BaseImage";
import { useNavigate } from "react-router-dom";

const SearchFriends = (props) => {
  const navigate = useNavigate();
  const { seasonId } = props;
  const [searchName, setSearchName] = useState(null);
  const [searchedUserProfileDTOList, setSearchedUserProfileDTOList] = useState(
    []
  );

  useEffect(() => {
    if (!seasonId) return;
    if (searchName?.length >= 3) {
      debouncedRetrieveAndSetSearchedUserProfileDTOList(searchName);
    } else {
      setSearchedUserProfileDTOList([]);
    }
  }, [seasonId, searchName]);

  const retrieveAndSetSearchedUserProfileDTOList = async (searchName) => {
    const { data, err } = await queryUserProfileDTOListBySeasonIdAndSearchName({
      seasonId,
      searchName,
    });
    if (err) {
      console.log(err);
      return;
    }
    setSearchedUserProfileDTOList(data);
  };

  const debouncedRetrieveAndSetSearchedUserProfileDTOList = useCallback(
    debounce(function (searchName) {
      retrieveAndSetSearchedUserProfileDTOList(searchName);
    }, 1000),
    []
  );

  const handleOnChange = (key, value) => {
    if (key === "searchName") {
      setSearchName(value);
    }
  };

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("find_friends")}
          </p>
        </div>
      </div>
      <div className="flex-1 min-h-[300px] flex flex-col">
        <div className="mx-2">
          <InputGroup
            type="text"
            title={i18n.t("name")}
            value={searchName}
            onChange={(event) => {
              handleOnChange("searchName", event.target.value);
            }}
          />
        </div>
        <div className="h-full flex flex-col gap-y-2 mt-2 overflow-y-auto no-scrollbar">
          {searchedUserProfileDTOList.map((searchedUserProfileDTO, index) => {
            const { id, userDTO, name, branchDTOList } = searchedUserProfileDTO;
            const { firstName, lastName } = userDTO;
            const branchDTO = branchDTOList[0];
            return (
              <div
                key={index}
                className="flex flex-row items-center cursor-pointer gap-x-2"
                onClick={() => {
                  navigate(`/view/profiles/${id}/branches/${branchDTO.id}`);
                }}
              >
                <div>
                  <BaseImage
                    src="/icons/user-profile.png"
                    alt="arrow"
                    size="small"
                  />
                </div>
                <div>
                  <p>
                    {firstName} {lastName} ({name})
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchFriends;
