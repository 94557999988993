import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import MascotWithCrown from "../mascots/MascotWithCrown";
import GradeLabel from "../labels/GradeLabel";
import BaseBadge from "../badge/BaseBadge";

const Profile = (props) => {
  const navigate = useNavigate();
  const { ranking, firstName, lastName, branchName, badgeDTOList } = props;

  const filterBadgeDTOList = () => {
    return badgeDTOList.slice(0, 3);
  };

  const calculateRestOfTheBadgeCount = () => {
    if (badgeDTOList.length > 3) {
      return badgeDTOList.length - 3;
    }
    return 0;
  };

  const restOfTheBadgeCount = calculateRestOfTheBadgeCount();
  const filteredBadgeDTOList = filterBadgeDTOList();

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("my_profile")}
          </p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/profile");
          }}
        >
          <p className="text-catchup-blue-700 underline">
            {i18n.t("to_my_profile")}
          </p>
        </div>
      </div>
      <div className="h-full flex flex-row items-center justify-center">
        <div className="w-1/3">
          <div className="p-2">
            <MascotWithCrown
              rank={ranking?.rank}
              showRank={ranking !== undefined}
            />
          </div>
        </div>
        <div className="w-2/3">
          <div className="flex flex-row">
            <div className="">
              <p className="text-2xl font-semibold">
                {firstName} {lastName}
              </p>
            </div>
            <div className="">
              <GradeLabel title={branchName} />
            </div>
          </div>

          <div className="my-1">
            <div className="relative flex flex-row items-center">
              {filteredBadgeDTOList.length > 0 ? (
                <div className="z-0">
                  <BaseBadge
                    earnedBadgeDTO={filteredBadgeDTOList[0]}
                    size="4xlarge"
                  />
                </div>
              ) : null}
              {filteredBadgeDTOList.length > 1 ? (
                <div className="z-10">
                  <BaseBadge
                    earnedBadgeDTO={filteredBadgeDTOList[1]}
                    size="4xlarge"
                  />
                </div>
              ) : null}
              {filteredBadgeDTOList.length > 2 ? (
                <div className="z-20">
                  <BaseBadge
                    earnedBadgeDTO={filteredBadgeDTOList[2]}
                    size="4xlarge"
                  />
                </div>
              ) : null}
              {restOfTheBadgeCount > 0 ? (
                <div className="">
                  <p className="text-catchup-gray-400 text-md">
                    +{restOfTheBadgeCount} {i18n.t("badge")}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
