import { useLocation, useNavigate } from "react-router-dom";
import BaseLoading from "../../components/loading/BaseLoading";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import {
  retrieveK12UserByAuthorizationCode,
  retrieveTokenInformationWithExternalResource,
  retrieveUserInformationFromToken,
  retrieveUserProfileListFromToken,
} from "../../requests/ManagementRequests";
import { NotificationManager } from "react-notifications";
import i18n from "../../language/i18n";
import { useApp } from "../../context/AppContextProvider";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import { parseJwt } from "../../utilization/TokenUtilization";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const LoginWithK12Screen = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setBoarding, isFullScreen, setIsFullScreen } = useApp();
  const { handleStateLogin, handleStateChooseProfile } = useAuth();
  const searchParams = new URLSearchParams(search);
  const [authorizationCode, setAuthorizationCode] = useState(null);
  const [k12UserInformation, setK12UserInformation] = useState(null);
  const [loginState, setLoginState] = useState("SEARCH");
  const K12_BASE_URL = process.env.REACT_APP_K12_BASE_URL;
  const K12_CLIENT_ID = process.env.REACT_APP_K12_CLIENT_ID;
  const K12_REDIRECT_URI = `${connection.app.url}/login/with-k12`;
  const K12_SCOPE = process.env.REACT_APP_K12_SCOPE;
  const K12_AUTH_URI =
    `${K12_BASE_URL}/GWCore.Web/connect/authorize?response_type=code&client_id=${K12_CLIENT_ID}&redirect_uri=${K12_REDIRECT_URI}&scope=${K12_SCOPE}`.replace(
      " ",
      "%20"
    );

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (searchParams.size === 0) {
      window.location.href = K12_AUTH_URI;
    } else {
      const currentAuthorizationCode = searchParams.get("code");
      if (!currentAuthorizationCode) return;
      setAuthorizationCode(currentAuthorizationCode);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetK12AccessToken = async () => {
      const { data, err } = await retrieveK12UserByAuthorizationCode({
        code: authorizationCode,
        redirectUri: K12_REDIRECT_URI,
      });
      if (err) {
        console.log(err);
        return;
      }
      setK12UserInformation(data);
    };
    if (!authorizationCode) return;
    setLoginState("ACCESS_TOKEN");
    retrieveAndSetK12AccessToken();
  }, [authorizationCode]);

  useEffect(() => {
    const retrieveAndSetUserInformation = async () => {
      const { id } = k12UserInformation;
      const { data, err } = await retrieveTokenInformationWithExternalResource({
        integrationId: id,
        externalResourceType: "K12",
      });
      if (err) {
        console.log(err);
        NotificationManager.error(i18n.t("invalid_user_credentials_text"));
        navigate("/login");
      } else {
        const { accessToken, refreshToken } = data;
        const { data: userData, err: userErr } =
          await retrieveUserInformationFromToken(accessToken);
        if (userErr) {
          console.log(userErr);
          return;
        }
        if (userData.enabled) {
          const { data: userProfileSetData } =
            await retrieveUserProfileListFromToken(accessToken);
          handleStateLogin(
            accessToken,
            refreshToken,
            userData,
            userProfileSetData
          );
          const tokenJSON = parseJwt(accessToken);
          const { profileId } = tokenJSON;
          const foundUserProfile = userProfileSetData.find(
            (userProfile) =>
              parseFloat(userProfile.id) === parseFloat(profileId)
          );
          if (foundUserProfile) {
            const { branchDTOList } = foundUserProfile;
            const branchDTO = branchDTOList[0];
            handleStateChooseProfile(
              foundUserProfile,
              branchDTO.gradeDTO.seasonDTO,
              branchDTO.gradeDTO,
              branchDTO
            );
            localStorage.setItem("appState", "CHANGE_PROFILE");
            setBoarding("DASHBOARD");
          } else {
            localStorage.setItem("appState", "LOGIN");
            setBoarding("PROFILE_SELECTION_REQUIRED");
          }
          navigate("/");
          NotificationManager.success(i18n.t("login_successful_text"));
        } else {
          navigate("/");
          setBoarding("ACCOUNT_ACTIVATION_REQUIRED");
        }
      }
    };
    if (!k12UserInformation) return;
    setLoginState("SEARCH_DETAILED_USER_INFORMATION");
    retrieveAndSetUserInformation();
  }, [k12UserInformation]);

  return (
    <div className="h-full flex flex-col items-center justify-center text-center">
      <div className="my-2">
        <BaseLoading size="large" color="#55777f" secondaryColor="55777f" />
      </div>
      <div className="my-2">
        <p className="font-semibold">{i18n.t("please_wait")}</p>
        <p className="italic">{i18n.t(loginState)}</p>
      </div>
    </div>
  );
};

export default LoginWithK12Screen;
