import { useDrag } from "react-dnd";

const DraggableItem = (props) => {
  const { index, item, type, component, moveCardHandler } = props;

  const [{ isDragging }, drag] = useDrag({
    type,
    item,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        moveCardHandler();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      key={index}
      className={`${
        isDragging ? "w-[0px] opacity-0" : "opacity-100"
      } transition-all duration-500`}
    >
      <div ref={drag} className="" style={{ opacity }}>
        {component}
      </div>
    </div>
  );
};

export default DraggableItem;
