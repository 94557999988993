import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Plan
 */
export const queryPlanListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.context}${endpoints.plans.query.dto.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCustomPlanByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.context}${endpoints.plans.query.dto["by-params"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryPlanWeekListByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.plan.url}${endpoints.plans.week.context}${endpoints.plans.week.query.context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
