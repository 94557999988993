import i18n from "../language/i18n";

export const retrieveGenderOptionList = () => {
  return [
    {
      value: "MALE",
      text: i18n.t("MALE"),
    },
    {
      value: "FEMALE",
      text: i18n.t("FEMALE"),
    },
    {
      value: "NOT_GIVEN",
      text: i18n.t("NOT_GIVEN"),
    },
  ];
};

export const retrieveCoterieTypeOptionList = () => {
  return [
    {
      text: i18n.t("TURKISH"),
      value: "TURKISH",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("HISTORY"),
      value: "HISTORY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("MATHEMATICS"),
      value: "MATHEMATICS",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("BIOLOGY"),
      value: "BIOLOGY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("LITERATURE"),
      value: "LITERATURE",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("GEOGRAPHY"),
      value: "GEOGRAPHY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("ENGLISH"),
      value: "ENGLISH",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("PHYSICS"),
      value: "PHYSICS",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("CHEMISTRY"),
      value: "CHEMISTRY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("PHILOSOPHY"),
      value: "PHILOSOPHY",
      includes: ["COLLEGE", "HIGH_SCHOOL", "COURSE"],
    },
    {
      text: i18n.t("PHYSICAL_EDUCATION"),
      value: "PHYSICAL_EDUCATION",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("SOCIAL_STUDIES"),
      value: "SOCIAL_STUDIES",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "COURSE",
      ],
    },
    {
      text: i18n.t("SCIENCE"),
      value: "SCIENCE",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "COURSE",
      ],
    },
    {
      text: i18n.t("LIFE_STUDIES"),
      value: "LIFE_STUDIES",
      includes: ["WEST_PRIMARY", "EAST_PRIMARY", "COLLEGE", "COURSE"],
    },
    {
      text: i18n.t("CULTURE_AND_RELIGION_KNOWLEDGE"),
      value: "CULTURE_AND_RELIGION_KNOWLEDGE",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
    {
      text: i18n.t("TRAFFIC_SAFETY"),
      value: "TRAFFIC_SAFETY",
      includes: ["WEST_PRIMARY", "EAST_PRIMARY", "COLLEGE", "COURSE"],
    },
    {
      text: i18n.t("GENERAL_CULTURE"),
      value: "GENERAL_CULTURE",
      includes: [
        "WEST_PRIMARY",
        "EAST_PRIMARY",
        "EAST_SECONDARY",
        "COLLEGE",
        "HIGH_SCHOOL",
        "COURSE",
      ],
    },
  ];
};

export const filterCoterieTypeOptionList = () => {
  return retrieveCoterieTypeOptionList().sort((a, b) =>
    a.text.localeCompare(b.text)
  );
};
