import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BaseImage from "../../components/images/BaseImage";
import i18n from "../../language/i18n";
import FullCard from "../../components/cards/FullCard";
import {
  createOrUpdateStorageFileFromCatchtivityApplicationFromMobile,
  createOrUpdateStorageFileFromCatchxamApplicationFromMobile,
  createOrUpdateStorageFileFromEtudeFromMobile,
} from "../../requests/StorageRequests";
import { retrieveDocumentTypeFromAcceptedFormat } from "../../utilization/StorageUtilization";
import BaseLoading from "../../components/loading/BaseLoading";
import { useApp } from "../../context/AppContextProvider";

const FileToAnyApplicationScreen = (props) => {
  const navigate = useNavigate();
  const { base64Data } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadingStatus, setUploadingStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userProfileId, setUserProfileId] = useState(null);
  const [type, setType] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const [activityId, setActivityId] = useState(null);
  const [activityTemplateId, setActivityTemplateId] = useState(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!base64Data) return;
    const base64 = JSON.parse(decodeURI(atob(base64Data)));
    const {
      userId,
      userProfileId,
      type,
      applicationId,
      activityId,
      activityTemplateId,
    } = base64;
    setUserId(userId);
    setUserProfileId(userProfileId);
    setType(type);
    setApplicationId(applicationId);
    setActivityId(activityId);
    setActivityTemplateId(activityTemplateId);
  }, [base64Data]);

  useEffect(() => {
    if (!uploadFile) return;
    handleUploadLearnerFile();
  }, [uploadFile]);

  useEffect(() => {
    if (uploadingStatus === "SUCCESS") {
      navigate("/upload/file-to-application/success");
    }
  }, [uploadingStatus]);

  const handleUploadLearnerFile = async () => {
    setUploadingStatus("UPLOADING");
    const formData = new FormData();
    formData.append("file", uploadFile);
    const documentType = retrieveDocumentTypeFromAcceptedFormat(
      uploadFile.type
    );
    formData.append(
      "path",
      `/${userId}/${userProfileId}/${documentType.toLowerCase()}s`
    );

    formData.append("documentType", documentType);
    formData.append("userId", userId);
    formData.append("userProfileId", userProfileId);
    formData.append("activityId", activityId);
    formData.append("activityTemplateId", activityTemplateId);
    let curData;
    let curErr;
    if (type === "CATCHTIVITY_APPLICATION") {
      formData.append("catchtivityApplicationId", applicationId);
      const { data, err } =
        await createOrUpdateStorageFileFromCatchtivityApplicationFromMobile(
          formData
        );
      curData = data;
      curErr = err;
    } else if (type === "CATCHXAM_APPLICATION") {
      formData.append("catchxamApplicationId", applicationId);
      const { data, err } =
        await createOrUpdateStorageFileFromCatchxamApplicationFromMobile(
          formData
        );
      curData = data;
      curErr = err;
    } else if (type === "ETUDE_APPLICATION") {
      formData.append("etudeId", applicationId);
      const { data, err } = await createOrUpdateStorageFileFromEtudeFromMobile(
        formData
      );
      curData = data;
      curErr = err;
    }

    if (curErr) {
      setUploadingStatus("FAILED");
      console.log(curErr);
      return;
    }
    setUploadingStatus("SUCCESS");
  };

  return (
    <div className="relative h-full flex p-4">
      <div className="w-full">
        <FullCard>
          <div className="h-full flex flex-row justify-center items-center gap-x-4">
            <div
              className="flex flex-row items-center justify-center cursor-pointer gap-x-2"
              onClick={() => {
                setUploadFile(null);
                inputFileRef.current.click();
              }}
            >
              {uploadingStatus === "UPLOADING" ? (
                <BaseLoading
                  size="large"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              ) : (
                <>
                  <BaseImage
                    src="/icons/upload-device.png"
                    alt="upload"
                    size="small"
                  />
                  <p className="text-base underline italic">
                    {i18n.t("i_want_to_upload_from_my_phone")}
                  </p>
                  <input
                    className="hidden"
                    type="file"
                    id="file"
                    ref={inputFileRef}
                    accept="image/jpeg, image/png, image/jpg, application/pdf"
                    multiple={false}
                    onChange={(event) => {
                      setUploadFile(event.target.files[0]);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </FullCard>
      </div>
    </div>
  );
};

export default FileToAnyApplicationScreen;
