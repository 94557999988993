import { useEffect, useRef, useState } from "react";
import FullCard from "../cards/FullCard";
import BaseImage from "../images/BaseImage";
import MascotWithCrown from "../mascots/MascotWithCrown";
import i18n from "../../language/i18n";

const ProfileWithBanner = (props) => {
  const {
    useAbsolute,
    userId,
    userProfileId,
    language,
    firstName,
    lastName,
    dateOfBirth,
    aboutMe,
    thisWeekRankingList,
    branchName,
    gradeName,
    seasonName,
    institutionName,
  } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [aboutMeHeight, setAboutMeHeight] = useState(0);
  const aboutMeRef = useRef(null);

  useEffect(() => {
    if (!useAbsolute) return;
    if (!imageLoaded) return;
    if (!aboutMeRef) return;
    if (!aboutMeRef.current) return;
    const { offsetHeight } = aboutMeRef.current;
    setAboutMeHeight(offsetHeight);
  }, [aboutMeRef, imageLoaded]);

  const retrieveCurrentRanking = () => {
    return thisWeekRankingList.find(
      (ranking) =>
        ranking.userId === userId && ranking.userProfileId === userProfileId
    );
  };

  const retrieveBirthday = () => {
    const currentDate = new Date(dateOfBirth);
    const formatter = new Intl.DateTimeFormat(language, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(currentDate);
  };

  const calculateMarginTop = () => {
    if (!imageLoaded) return 0;
    if (useAbsolute) {
      return -94 + aboutMeHeight;
    } else {
      return 10;
    }
  };

  const handleOnLoad = () => {
    setImageLoaded(true);
  };
  const currentRanking = retrieveCurrentRanking();
  const currentBirthday = retrieveBirthday();

  const marginTop = calculateMarginTop();

  return (
    <FullCard>
      <div className="w-full h-[300px] relative">
        <img
          src="/banners/banner.png"
          alt="banner"
          className="w-full h-full object-cover rounded-3xl"
          onLoad={handleOnLoad}
        />
        <div className="absolute top-5 right-5 bg-catchup-blue-500 rounded-full p-1">
          <BaseImage
            src="icons/edit-white.png"
            alt="edit"
            size="medium"
            onClick={() => {}}
          />
        </div>
      </div>
      <div className="flex flex-row px-5 gap-x-10" style={{ marginTop }}>
        <div className="w-[200px]">
          <MascotWithCrown
            rank={currentRanking?.rank}
            showRank={currentRanking !== undefined}
          />
        </div>
        <div
          className={`${
            useAbsolute ? "justify-end" : "justify-center"
          } flex-1 flex flex-col gap-y-1`}
        >
          <div className="flex flex-row gap-x-2 items-center">
            <div>
              <p className="font-semibold text-2xl text-catchup-gray-600">
                {firstName} {lastName}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-x-2 items-center">
            <div>
              <BaseImage
                src="icons/birthday-blue.png"
                size="small"
                alt="birthday"
              />
            </div>
            <div>
              <p className="text-lg text-catchup-gray-600">{currentBirthday}</p>
            </div>
          </div>
          <div className="w-full" ref={aboutMeRef}>
            <p className="text-lg text-catchup-gray-600">
              {aboutMe ? aboutMe : i18n.t("about_me")}
            </p>
          </div>

          <div className="flex flex-row flex-wrap gap-x-2">
            <div className="flex flex-row items-center gap-x-1">
              <BaseImage
                src="icons/institution.png"
                alt="institution"
                size="small"
              />
              <div>
                <p className="text-catchup-gray-400 font-semibold">
                  {institutionName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <BaseImage src="icons/season.png" alt="season" size="small" />
              <div>
                <p className="text-catchup-gray-400 font-semibold">
                  {seasonName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <BaseImage src="icons/grade.png" alt="grade" size="small" />
              <div>
                <p className="text-catchup-gray-400 font-semibold">
                  {gradeName}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <BaseImage src="icons/branch.png" alt="branch" size="small" />
              <div>
                <p className="text-catchup-gray-400 font-semibold">
                  {branchName}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullCard>
  );
};

export default ProfileWithBanner;
