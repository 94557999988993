import { InlineMath } from "react-katex";
import MediaDropdown from "../../dropdown/MediaDropdown";
import InputGroup from "../../groups/InputGroup";
import ShowMaterialMediaByContentType from "./ShowMaterialMediaByContentType";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";
import i18n from "../../../language/i18n";
import { useEffect } from "react";
import { useState } from "react";
import BaseImage from "../../images/BaseImage";
import { shuffleArray } from "../../../utilization/AppUtilization";
import DividerLine from "../../divider/DividerLine";

const DropdownActivityMaterialContent = (props) => {
  const {
    uniqueValue,
    answer,
    materialMap,
    contentMap,
    checkCanAnswerQuestion,
    onChange,
    isPreview,
    showCorrectAnswer,
  } = props;
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!showCorrectAnswer) return;
    const foundAnswer = answer.data.find(
      (answerData) => answerData.type === "DROPDOWN"
    );
    if (foundAnswer.answerMap.length === 0) return;
    foundAnswer.answerMap = Object.keys(materialMap).map(
      (materialMapKey, index) => Object.keys(materialMap[materialMapKey])[0]
    );
    onChange(answer, 0, Object.keys(materialMap[0])[0]);
    setUpdated(true);
  }, [showCorrectAnswer]);

  useEffect(() => {
    if (!updated) return;
    setUpdated(false);
  }, [updated]);

  const retrieveAnswerMap = () => {
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "DROPDOWN"
    );
    return answer.data[foundIndex].answerMap;
  };

  const checkAnswerState = (correctAnswer, learnerAnswer) => {
    if (!isPreview) return "HIDDEN";
    if (correctAnswer === learnerAnswer) {
      return "CORRECT";
    }
    return "INCORRECT";
  };

  const answerMap = retrieveAnswerMap();

  return (
    <div className="flex flex-row flex-wrap items-center">
      <div className="hidden md:block">
        <span className="font-semibold text-xl opacity-60">
          {i18n.t("please_select_dropdown_text")}
        </span>
      </div>
      <div className="hidden md:contents">
        <DividerLine />
      </div>
      <div className="w-full flex flex-row flex-wrap">
        {Object.keys(answerMap).map((materialKey, index) => {
          const answerKey = Object.keys(materialMap[materialKey])[0];
          const learnerAnswerState = checkAnswerState(
            answerKey,
            answerMap[materialKey]
          );
          return (
            <div key={index} className="w-full md:w-1/2">
              <div className="mx-2">
                <div className="w-full flex flex-row my-2 gap-x-2">
                  <div className="my-auto">
                    <p className="text-xl">{parseFloat(materialKey) + 1}.</p>
                  </div>
                  <div className="w-full relative">
                    <div className="flex-1">
                      {checkCanAnswerQuestion() ? (
                        contentMap.type === "TEXT" ? (
                          <div className="flex-1">
                            <InputGroup
                              type="select"
                              title={null}
                              placeholder={null}
                              value={answerMap[materialKey]}
                              optionList={shuffleArray(
                                materialMap[materialKey][answerKey]
                              ).map((materialOption) => ({
                                text: (
                                  <span className="text-xl whitespace-pre-wrap">
                                    {constructInputWithSpecialExpressionList(
                                      materialOption
                                    ).map((inputPart, index) => (
                                      <span
                                        key={index}
                                        className={`${
                                          inputPart.isBold ? "font-bold" : ""
                                        } ${
                                          inputPart.isUnderline
                                            ? "underline"
                                            : ""
                                        }`}
                                      >
                                        {inputPart.isEquation ? (
                                          <span className="text-2xl">
                                            <InlineMath
                                              math={inputPart.value}
                                            />
                                          </span>
                                        ) : (
                                          inputPart.value
                                        )}
                                      </span>
                                    ))}
                                  </span>
                                ),
                                value: materialOption,
                              }))}
                              onChange={(e) => {
                                onChange(answer, materialKey, e.target.value);
                              }}
                            />
                          </div>
                        ) : (
                          <MediaDropdown
                            id={materialKey}
                            answer={
                              answerMap[materialKey] === "DEFAULT_OPTION" ? (
                                <div className="w-catchup-activity-box-item border h-catchup-activity-box-item rounded-catchup-xlarge border-dashed border-catchup-blue">
                                  <div className="h-full flex flex-col items-center justify-center px-4 py-2">
                                    <span className="italic">
                                      {i18n.t("please_select")}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <ShowMaterialMediaByContentType
                                  key={`${uniqueValue}-${index}`}
                                  contentType={contentMap.type}
                                  src={answerMap[materialKey]}
                                  canFullScreen={false}
                                />
                              )
                            }
                            optionList={materialMap[materialKey][answerKey].map(
                              (materialOption, index) => ({
                                id: index,
                                media: (
                                  <div key={index}>
                                    <ShowMaterialMediaByContentType
                                      key={`${uniqueValue}-${index}`}
                                      contentType={contentMap.type}
                                      src={materialOption}
                                      canFullScreen={false}
                                    />
                                  </div>
                                ),
                                onClick: (e) => {
                                  onChange(
                                    answer,
                                    materialKey,
                                    e.target.currentSrc
                                  );
                                },
                              })
                            )}
                          />
                        )
                      ) : (
                        <p className="text-xl whitespace-pre-wrap">
                          {constructInputWithSpecialExpressionList(
                            answerMap[materialKey]
                          ).map((inputPart, index) => (
                            <span
                              key={index}
                              className={`${
                                inputPart.isBold ? "font-bold" : ""
                              } ${inputPart.isUnderline ? "underline" : ""}`}
                            >
                              {inputPart.isEquation ? (
                                <span className="text-2xl">
                                  <InlineMath math={inputPart.value} />
                                </span>
                              ) : (
                                inputPart.value
                              )}
                            </span>
                          ))}
                        </p>
                      )}
                    </div>
                    {learnerAnswerState === "CORRECT" ? (
                      <div className="absolute top-[0px] right-4 bg-catchup-white">
                        <BaseImage
                          src="/icons/checkbox.png"
                          alt="chekbbox"
                          size="small"
                        />
                      </div>
                    ) : learnerAnswerState === "INCORRECT" ? (
                      <div className="absolute top-[0px] right-4 bg-catchup-white">
                        <BaseImage
                          src="/icons/cross-red.png"
                          alt="cross-red"
                          size="small"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropdownActivityMaterialContent;
