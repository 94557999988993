import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import useScreenSize from "../../hooks/useScreenSize";
import { retrieveUserProfileDTOById } from "../../requests/ManagementRequests";
import { useAuth } from "../../context/UserContextProvider";
import ProfileWithBanner from "../../components/dashboards/ProfileWithBanner";
import Mascot from "../../components/dashboards/Mascot";
import Badge from "../../components/dashboards/Badge";
import { queryEarnedBadgeDTOListByParams } from "../../requests/GamificationRequests";

const ViewUserProfileScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const { viewUserProfileId, viewUserProfileBranchId } = useParams();
  const { language } = useAuth();
  const { screenSize } = useScreenSize();
  const [view, setView] = useState("TABLET");
  const [viewUserProfile, setViewUserProfile] = useState(null);
  const [viewEarnedBadgeList, setViewEarnedBadgeList] = useState([]);
  const BASE_PADDING = 8;

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!screenSize) return;
    if (screenSize.width <= 1280) {
      setView("TABLET");
    } else {
      setView("PC");
    }
  }, [screenSize]);

  useEffect(() => {
    const retrieveAndSetViewUserProfileDTOById = async () => {
      const { data, err } = await retrieveUserProfileDTOById(viewUserProfileId);
      if (err) {
        console.log(err);
        return err;
      }
      setViewUserProfile(data);
    };
    if (!viewUserProfileId) return;
    retrieveAndSetViewUserProfileDTOById();
  }, [viewUserProfileId]);

  useEffect(() => {
    const retrieveAndSetEarnedBadgeList = async (userId, userProfileId) => {
      const { data, err } = await queryEarnedBadgeDTOListByParams({
        userId,
        userProfileId,
      });
      if (err) {
        console.log(err);
        return;
      }
      setViewEarnedBadgeList(data);
    };
    if (!viewUserProfile) return;
    const { userDTO: viewUserInformation } = viewUserProfile;
    retrieveAndSetEarnedBadgeList(viewUserInformation.id, viewUserProfile.id);
  }, [viewUserProfile]);

  if (!viewUserProfile) return;
  const { userDTO: viewUserInformation, branchDTOList: viewBranchDTOList } =
    viewUserProfile;
  const foundViewUserProfileBranch = viewBranchDTOList.find(
    (viewBranchDTO) => viewBranchDTO.id === parseFloat(viewUserProfileBranchId)
  );
  if (!foundViewUserProfileBranch) return;
  const { gradeDTO: viewUserProfileGrade } = foundViewUserProfileBranch;
  const { seasonDTO: viewUserProfileSeason } = viewUserProfileGrade;

  const RenderTabletContent = () => {
    return (
      <div className="flex flex-row flex-wrap" style={{ rowGap: BASE_PADDING }}>
        <div className="w-full">
          <ProfileWithBanner
            useAbsolute={false}
            canUpdateBanner={false}
            userId={viewUserInformation.id}
            userProfileId={viewUserProfileSeason.id}
            language={language}
            firstName={viewUserInformation.firstName}
            lastName={viewUserInformation.lastName}
            aboutMe={viewUserInformation.aboutMe}
            dateOfBirth={viewUserInformation.dateOfBirth}
            thisWeekRankingList={[]}
            branchName={foundViewUserProfileBranch.name}
            gradeName={viewUserProfileGrade.name}
            seasonName={viewUserProfileSeason.name}
            institutionName={viewUserProfileSeason.institutionDTO.name}
          />
        </div>
        <div className="w-full flex flex-row">
          <div className="w-full" style={{ paddingRight: BASE_PADDING / 2 }}>
            <Badge earnedBadgeDTOList={viewEarnedBadgeList} />
          </div>
        </div>
        <div className="w-full flex flex-row">
          <div className="w-full" style={{ paddingLeft: BASE_PADDING / 2 }}>
            <Mascot />
          </div>
        </div>
      </div>
    );
  };

  const RenderPCContent = () => {
    return (
      <div className="flex flex-row flex-wrap" style={{ rowGap: BASE_PADDING }}>
        <div className="w-full">
          <ProfileWithBanner
            useAbsolute={false}
            canUpdateBanner={false}
            userId={viewUserInformation.id}
            userProfileId={viewUserProfileSeason.id}
            language={language}
            firstName={viewUserInformation.firstName}
            lastName={viewUserInformation.lastName}
            aboutMe={viewUserInformation.aboutMe}
            dateOfBirth={viewUserInformation.dateOfBirth}
            thisWeekRankingList={[]}
            branchName={foundViewUserProfileBranch.name}
            gradeName={viewUserProfileGrade.name}
            seasonName={viewUserProfileSeason.name}
            institutionName={viewUserProfileSeason.institutionDTO.name}
          />
        </div>
        <div className="w-full" style={{ paddingRight: BASE_PADDING / 2 }}>
          <div
            className="w-full flex flex-row"
            style={{ paddingBottom: BASE_PADDING / 2 }}
          >
            <div className="w-[70%]" style={{ paddingRight: BASE_PADDING / 2 }}>
              <Badge earnedBadgeDTOList={viewEarnedBadgeList} />
            </div>
            <div
              className="w-[30%] min-h-[300px]"
              style={{ paddingLeft: BASE_PADDING / 2 }}
            >
              <Mascot />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    if (view === "TABLET") {
      return RenderTabletContent();
    } else if (view === "PC") {
      return RenderPCContent();
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ViewUserProfileScreen;
