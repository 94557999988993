import { useEffect, useState } from "react";
import i18n from "../../language/i18n";
import { retrieveCoterieTypeOptionList } from "../../utilization/ManagementUtilization";
import { useLocation, useNavigate } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import {
  createContestByActivityBundleId,
  createContestByCoterieType,
  queryContestListByParams,
} from "../../requests/CatchtivityRequests";
import { useAuth } from "../../context/UserContextProvider";
import {
  calculateEndDateOfWeek,
  calculateStartDateOfWeek,
} from "../../utilization/DateUtilization";
import { retrieveDistintCoterieTypeFromCatchtivityApplicationDTO } from "../../utilization/CatchtivityUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useApp } from "../../context/AppContextProvider";
import InputGroup from "../../components/groups/InputGroup";
import DividerLine from "../../components/divider/DividerLine";
import DashboardHeader from "../../components/headers/DashboardHeader";
import CoterieLabel from "../../components/labels/CoterieLabel";
import BaseImage from "../../components/images/BaseImage";
import BaseLoading from "../../components/loading/BaseLoading";
import Leaderboard from "../../components/dashboards/Leaderboard";

const CheckContestScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    catchtivityApplicationDTOList,
    contestList,
    setContestList,
    contestListLoading,
    setContestListLoading,
    thisWeekRankingList,
    thisWeekRankingListLoading,
    allTimeRankingList,
    allTimeRankingListLoading,
  } = useAuth();
  const [loading, setLoading] = useState({
    contestList: false,
  });
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("GENERAL_CULTURE");
  const [selectedFrequencyType, setSelectedFrequencyType] = useState("WEEKLY");
  const [userInBranchLeaderboard, setUserInBranchLeaderboard] = useState([]);
  const [branchInSeasonLeaderboard, setBranchInSeasonLeaderboard] = useState(
    []
  );
  const GENERAL_CULTURE_BUNDLE_ID = 16; // 16 for production | 20 for development

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (location && location.state) {
      if (location.state.state === "FORCE_UPDATE") {
        retrieveAndSetContestList(distinctCoterieTypeOptionList);
      }
    }
  }, [location]);

  const retrieveAndSetContestList = async (coterieTypeList) => {
    setContestListLoading(true);
    const { data, err } = await queryContestListByParams({
      coterieTypeList,
      level: userProfileGrade.level,
      type: "QUIZ",
      frequencyType: "WEEKLY",
      userId: userInformation.id,
      userProfileId: userProfile.id,
      beginAt: calculateStartDateOfWeek(new Date()).getTime(),
      endAt: calculateEndDateOfWeek(new Date()).getTime(),
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    });
    if (err) {
      console.log(err);
      return;
    }
    setContestListLoading(false);
    setContestList(data);
  };

  const filterContestList = () => {
    return contestList.filter(
      (contest) => contest.coterieType === selectedCoterieType
    );
  };

  const filterCatchtivityApplicationDTO = () => {
    return catchtivityApplicationDTOList.filter(
      (catchtivityApplicationDTO) =>
        catchtivityApplicationDTO.catchtivityDTO.coterieType ===
        selectedCoterieType
    );
  };

  const filterCoterieTypeOptionList = (distinctCoterieTypeOptionList) => {
    return retrieveCoterieTypeOptionList().filter(
      (item) =>
        item.value === "GENERAL_CULTURE" ||
        distinctCoterieTypeOptionList.includes(item.value)
    );
  };

  const constructRankingListByGrade = (rankingList) => {
    const rankingListByGrade = [];
    rankingList.forEach((ranking) => {
      const { branchName, gradeName, totalPoints } = ranking;
      const foundRankingByGrade = rankingListByGrade.find(
        (ranking) => ranking.branchName === branchName
      );
      if (foundRankingByGrade) {
        foundRankingByGrade.totalPoints += totalPoints;
      } else {
        rankingListByGrade.push({
          firstName: gradeName,
          lastName: branchName,
          branchName,
          gradeName,
          totalPoints,
        });
      }
    });
    rankingListByGrade.sort((a, b) => b.totalPoints - a.totalPoints);
    if (rankingListByGrade.length === 0) return rankingListByGrade;
    let currentRank = 1;
    let previousPoints = rankingListByGrade[0].totalPoints;
    return rankingListByGrade.map((item, index) => {
      if (index > 0 && item.totalPoints < previousPoints) {
        currentRank = index + 1;
      }

      previousPoints = item.totalPoints;

      return {
        ...item,
        rank: currentRank,
      };
    });
  };

  const handleOnChange = (key, value) => {
    if (key === "selectedCoterieType") {
      setSelectedCoterieType(value);
    } else if (key === "frequencyType") {
      setSelectedFrequencyType(value);
    }
  };

  const handleCreateContentOnClick = async () => {
    const createParams = {
      coterieType: selectedCoterieType,
      level: userProfileGrade.level,
      type: "QUIZ",
      frequencyType: selectedFrequencyType,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      beginAt: calculateStartDateOfWeek(new Date()).getTime(),
      endAt: calculateEndDateOfWeek(new Date()).getTime(),
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    };
    let data;
    let err;
    if (selectedCoterieType === "GENERAL_CULTURE") {
      createParams.activityBundleId = GENERAL_CULTURE_BUNDLE_ID;
      const {
        data: contestFromActivityBundleData,
        err: contestFromActivityBundleErr,
      } = await createContestByActivityBundleId(createParams);
      data = contestFromActivityBundleData;
      err = contestFromActivityBundleErr;
    } else {
      const combinedCategoryIdList = [];
      for (const catchtivityApplicationDTO of filteredCatchtivityApplicationDTOList) {
        const { catchtivityDTO } = catchtivityApplicationDTO;
        const { activityDTOList } = catchtivityDTO;
        for (const activityDTO of activityDTOList) {
          const { categoryIdList } = activityDTO;
          for (const categoryId of categoryIdList) {
            if (
              combinedCategoryIdList.findIndex(
                (combinedCategory) => combinedCategory === categoryId
              ) === -1
            ) {
              combinedCategoryIdList.push(categoryId);
            }
          }
        }
      }
      createParams.brandId =
        userProfileSeason.institutionDTO.campusDTO.brandDTO.id;
      createParams.campusId = userProfileSeason.institutionDTO.campusDTO.id;
      createParams.institutionId = userProfileSeason.institutionDTO.id;
      createParams.categoryIdList = combinedCategoryIdList;
      const {
        data: contestFromCoterieTypeData,
        err: contestFromCoterieTypeErr,
      } = await createContestByCoterieType(createParams);
      data = contestFromCoterieTypeData;
      err = contestFromCoterieTypeErr;
    }

    if (err) {
      console.log(err);
      return;
    }
    await retrieveAndSetContestList(distinctCoterieTypeOptionList);
    handleToContestOnClick(data);
  };

  const handleToContestOnClick = (itemId) => {
    navigate(`/contests/${itemId}`);
  };

  const RenderStartContestContent = () => {
    return (
      <div className="flex flex-row item-center justify-center">
        <PrimaryButton
          title={i18n.t("start")}
          size="small"
          onClick={handleCreateContentOnClick}
        />
      </div>
    );
  };

  const RenderNotEvaluatedContestContent = (contest) => {
    return (
      <div className="flex flex-row item-center justify-center">
        <PrimaryButton
          title={i18n.t("continue")}
          size="small"
          onClick={() => {
            handleToContestOnClick(contest.id);
          }}
        />
      </div>
    );
  };

  const RenderEvaluatedContestContent = (contest) => {
    return null;
  };

  const RenderLeaderboardContent = () => {
    return (
      <div className="flex flex-row flex-wrap gap-x-2">
        <div className="flex-1 h-[700px]">
          <Leaderboard
            detailed={true}
            useMascot={true}
            userId={userInformation.id}
            userProfileId={userProfile.id}
            userProfileBranchId={userProfileBranch.id}
            thisWeekRankingList={thisWeekRankingList}
            allTimeRankingList={allTimeRankingList}
          />
        </div>
        <div className="flex-1 h-[700px]">
          <Leaderboard
            detailed={false}
            useMascot={false}
            userId={userInformation.id}
            userProfileId={userProfile.id}
            userProfileBranchId={userProfileBranch.id}
            thisWeekRankingList={thisWeekRankingListByGrade}
            allTimeRankingList={allTimeRankingListByGrade}
          />
        </div>
      </div>
    );
  };

  const RenderContestContent = () => {
    if (filteredContestList.length === 0) return RenderStartContestContent();
    const contest = filteredContestList[0];
    const { isEvaluated } = contest;
    if (isEvaluated) {
      return RenderEvaluatedContestContent(contest);
    } else {
      return RenderNotEvaluatedContestContent(contest);
    }
  };

  const RenderMissingContestContent = () => {
    const filteredMissingCoterieTypeOptionList =
      distinctCoterieTypeOptionList.filter((coterieTypeOption) => {
        const foundIndex = contestList.findIndex(
          (contest) => contest.coterieType === coterieTypeOption
        );
        if (foundIndex === -1) return true;
        return !contestList[foundIndex].isEvaluated;
      });
    if (filteredMissingCoterieTypeOptionList.length === 0) return null;
    return (
      <div className="w-full">
        <div className="flex flex-row items-center justify-between gap-x-2 rounded-2xl">
          <div className="min-w-[250px] flex flex-row items-center gap-x-2">
            <BaseImage
              src="/icons/exclamation-red.png"
              alt="exclamation"
              size="medium"
            />
            <div>
              <p className="text-lg">
                {i18n.t("contests_you_should_do_this_week")}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center flex-wrap gap-x-1 gap-y-1">
            {filteredMissingCoterieTypeOptionList.map((coterieTypeOption) => (
              <CoterieLabel title={i18n.t(coterieTypeOption)} />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-2">
          <div className="flex-1 flex flex-row items-center gap-x-2 mb-2">
            <div className="w-full lg:w-1/2 xl:w-1/3">
              <InputGroup
                type="select"
                title={i18n.t("coterie_type")}
                placeholder={i18n.t("coterie_type")}
                value={selectedCoterieType}
                optionList={filteredCoterieTypeOptionList}
                onChange={(event) => {
                  handleOnChange("selectedCoterieType", event.target.value);
                }}
              />
            </div>
            {/* <div className="w-full lg:w-1/2 xl:w-1/3">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("frequency_type")}
                    placeholder={i18n.t("frequency_type")}
                    value={selectedFrequencyType}
                    optionList={filteredFrequencyTypeOptionList}
                    onChange={(event) => {
                      handleOnChange("frequencyType", event.target.value);
                    }}
                  />
                </div>
              </div> */}
          </div>
          {RenderContestContent()}
        </div>

        <DividerLine />
      </div>
    );
  };

  const distinctCoterieTypeOptionList =
    retrieveDistintCoterieTypeFromCatchtivityApplicationDTO(
      catchtivityApplicationDTOList
    );
  const filteredCoterieTypeOptionList = filterCoterieTypeOptionList(
    distinctCoterieTypeOptionList
  );
  if (
    distinctCoterieTypeOptionList.findIndex(
      (coterieType) => coterieType === "GENERAL_CULTURE"
    ) === -1
  ) {
    distinctCoterieTypeOptionList.push("GENERAL_CULTURE");
  }
  const filteredContestList = filterContestList();
  const thisWeekRankingListByGrade =
    constructRankingListByGrade(thisWeekRankingList);
  const allTimeRankingListByGrade =
    constructRankingListByGrade(allTimeRankingList);
  const filteredCatchtivityApplicationDTOList =
    filterCatchtivityApplicationDTO();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">
        <FullCard>
          <DashboardHeader title={i18n.t("contest")} />
          {RenderMissingContestContent()}

          {selectedCoterieType !== "DEFAULT_OPTION" &&
          selectedFrequencyType !== "DEFAULT_OPTION" ? (
            contestListLoading ||
            thisWeekRankingListLoading ||
            allTimeRankingListLoading ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              RenderLeaderboardContent()
            )
          ) : null}
        </FullCard>
      </div>
    </div>
  );
};

export default CheckContestScreen;
