import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import { useEffect, useRef, useState } from "react";
import {
  createActivityAnswerByActivityIdStandardExamApplicationId,
  createActivityProgressByActivityIdStandardExamApplicationId,
  patchActivityAnswerWithData,
  patchActivityProgress,
  queryActivityAnswerListByStandardExamApplicationId,
  retrieveActivityWithDataById,
  retrieveStandardExamApplicationById,
  retrieveStandardExamApplicationProgress,
} from "../../requests/CatchtivityRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import ActivityEmptyContent from "../../components/activities/empty-content/ActivityEmptyContent";
import BaseModal from "../../components/modal/BaseModal";
import i18n from "../../language/i18n";
import {
  checkActivityAnswerState,
  checkIfAnswerIsEmpty,
  constructActivityAnswerMap,
  constructActivityAnswerStateList,
  retrieveActivityAnswerFromAnswerList,
  retrieveTotalTimeSpentInMinutes,
} from "../../utilization/CatchtivityUtilization";
import FullCard from "../../components/cards/FullCard";
import BaseImage from "../../components/images/BaseImage";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import CatchtivityFooter from "../../components/footers/CatchtivityFooter";
import useScreenSize from "../../hooks/useScreenSize";
import ContentTransition from "../../components/transitions/ContentTransition";
import DashboardHeader from "../../components/headers/DashboardHeader";
import CatchtivityProgressBar from "../../components/progress-bar/CatchtivityProgressBar";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";
import ScreenCard from "../../components/cards/ScreenCard";

const StandardExamApplicationScreen = () => {
  const { standardExamApplicationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [activityNumber, setActivityNumber] = useState(null);
  const [standardExamApplicationDTO, setStandardExamApplicationDTO] =
    useState(null);
  const [activity, setActivity] = useState(null);
  const [activityAnswerDTOList, setActivityAnswerDTOList] = useState([]);
  const [activityAnswerDTOListLoading, setActivityAnswerDTOListLoading] =
    useState(true);
  const [
    standardExamApplicationProgressDTO,
    setStandardExamApplicationProgressDTO,
  ] = useState(null);
  const [
    standardExamApplicationProgressDTOLoading,
    setStandardExamApplicationProgressDTOLoading,
  ] = useState(true);
  const [currentActivityInitialTimestamp, setCurrentActivityInitialTimestamp] =
    useState(new Date().getTime());
  const [activityLoading, setActivityLoading] = useState({
    self: false,
    answer: false,
    progress: false,
  });
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [selectedStandardExamCoterieType, setSelectedStandardExamCoterieType] =
    useState(null);
  const [standardExamBlockClientHeight, setStandardExamBlockClientHeight] =
    useState(null);
  const selectStandardExamBlockRef = useRef(null);
  const { screenSize } = useScreenSize();

  useEffect(() => {
    if (pageState === 0) {
      if (isFullScreen === true) {
        setIsFullScreen(false);
      }
    } else {
      if (isFullScreen === false) {
        setIsFullScreen(true);
      }
    }
  }, [pageState]);

  useEffect(() => {
    if (selectStandardExamBlockRef.current) {
      setStandardExamBlockClientHeight(
        selectStandardExamBlockRef.current.clientHeight
      );
    }
  }, [selectStandardExamBlockRef.current, screenSize]);

  useEffect(() => {
    const retrieveAndSetStandardExamApplication = async () => {
      const { data, err } = await retrieveStandardExamApplicationById(
        standardExamApplicationId
      );
      if (err) {
        console.log(err);
        return;
      }
      setStandardExamApplicationDTO(data);
    };
    const retrieveAndSetStandardExamApplicationProgress = async () => {
      setStandardExamApplicationProgressDTOLoading(true);
      const { data, err } = await retrieveStandardExamApplicationProgress({
        id: standardExamApplicationId,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setStandardExamApplicationProgressDTO(data);
      setStandardExamApplicationProgressDTOLoading(false);
    };
    const retrieveAndSetActivityAnswerList = async () => {
      setActivityAnswerDTOListLoading(true);
      const { data, err } =
        await queryActivityAnswerListByStandardExamApplicationId({
          standardExamApplicationId,
          userId: userInformation.id,
          userProfileId: userProfile.id,
        });
      if (err) {
        console.log(err);
        return;
      }
      setActivityAnswerDTOList(data);
      setActivityAnswerDTOListLoading(false);
    };
    if (checkViewPermissionGranted("standard_exam_application")) {
      retrieveAndSetStandardExamApplication();
      retrieveAndSetStandardExamApplicationProgress();
      retrieveAndSetActivityAnswerList();
    } else {
      navigate("/permission-error");
    }
  }, [window.location]);

  useEffect(() => {
    if (!activity) return;
    setCurrentActivityInitialTimestamp(new Date().getTime());
  }, [activity]);

  useEffect(() => {
    const retrieveAndSetActivityWithData = async () => {
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: true,
      }));
      const activityDTOList = retrieveStandardExamActivityList();
      if (activityDTOList.length > 0) {
        const currentActivity = activityDTOList[activityNumber];
        const { data, err } = await retrieveActivityWithDataById(
          currentActivity.id
        );
        if (err) {
          console.log(err);
        } else {
          setActivity(data);
        }
        setActivityLoading((prevActivityLoading) => ({
          ...prevActivityLoading,
          self: false,
        }));
      }
    };
    if (!standardExamApplicationDTO) return;
    const activityList = retrieveStandardExamActivityList();
    const { standardExamDTO } = standardExamApplicationDTO;
    const { standardExamActivityDTOList } = standardExamDTO;
    const foundStandardExamActivityDTO = standardExamActivityDTOList.find(
      (standardExamActivityDTO) =>
        standardExamActivityDTO.standardExamCoterieType ===
        selectedStandardExamCoterieType
    );
    if (!foundStandardExamActivityDTO) return;
    if (activityNumber === 0) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
    }
    if (activityList.length > 0 && activityNumber === activityList.length) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
      if (
        foundStandardExamActivityDTO.index ===
        standardExamActivityDTOList.length - 1
      ) {
        setShowCompleteModal(true);
        setActivity(null);
      } else {
        const nextIndex = foundStandardExamActivityDTO.index + 1;
        const foundStandardExamActivityDTOByIndex =
          standardExamActivityDTOList.find(
            (standardExamActivityDTO) =>
              standardExamActivityDTO.index === nextIndex
          );
        setActivityNumber(0);
        setSelectedStandardExamCoterieType(
          foundStandardExamActivityDTOByIndex.standardExamCoterieType
        );
      }
    } else if (activityNumber !== null && activityNumber >= 0) {
      retrieveAndSetActivityWithData();
    } else {
      setActivity(null);
    }
  }, [
    standardExamApplicationDTO,
    selectedStandardExamCoterieType,
    activityNumber,
  ]);

  const retrieveCurrentActivityTemplate = () => {
    return activity.activityTemplateDTOList.find(
      (activityTemplateDTO) => activityTemplateDTO.type === "MCSA"
    );
  };

  const retrieveCurrentActivityAnswer = () => {
    if (!activity) return { answer: { data: [] }, index: null };
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    const foundActivityAnswerDTO = activityAnswerDTOList[foundIndex];
    if (foundIndex > -1 && foundActivityAnswerDTO.data) {
      const currentAnswer = foundActivityAnswerDTO;
      return {
        index: foundIndex,
        answer: {
          ...currentAnswer,
          data: JSON.parse(currentAnswer.data),
        },
      };
    } else {
      const currentActivityTemplate = retrieveCurrentActivityTemplate();
      return {
        index: foundIndex,
        answer: {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          activityDTO: {
            id: activity.id,
          },
          standardExamApplicationDTO: {
            id: standardExamApplicationId,
          },
          data: currentActivityTemplate
            ? [
                constructActivityAnswerMap(
                  currentActivityTemplate,
                  JSON.parse(activity.data)
                ),
              ]
            : [],
        },
      };
    }
  };

  const retrieveCurrentActivityAnswerTemplate = () => {
    const { answer } = retrieveCurrentActivityAnswer();
    const { data } = answer;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    return { answerTemplate, answer };
  };

  const retrieveStandardExamActivityList = () => {
    if (!standardExamApplicationDTO) return [];
    const { standardExamDTO } = standardExamApplicationDTO;
    const { standardExamActivityDTOList } = standardExamDTO;
    const foundStandardExamActivityDTO = standardExamActivityDTOList.find(
      (standardExamActivityDTO) =>
        standardExamActivityDTO.standardExamCoterieType ===
        selectedStandardExamCoterieType
    );
    if (!foundStandardExamActivityDTO) return [];
    const { standardExamActivityItemDTOList } = foundStandardExamActivityDTO;
    return standardExamActivityItemDTOList.map((item) => item.activityDTO);
  };

  const retrieveAllStandardExamActivityList = () => {
    const activityList = [];
    if (!standardExamApplicationDTO) return [];
    const { standardExamDTO } = standardExamApplicationDTO;
    const { standardExamActivityDTOList } = standardExamDTO;

    for (const standardExamActivityDTO of standardExamActivityDTOList) {
      const { standardExamActivityItemDTOList } = standardExamActivityDTO;
      activityList.push(...standardExamActivityItemDTOList);
    }
    return activityList;
  };

  const checkCanAnswerQuestion = () => {
    if (activity) {
      return true;
    }
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate.isEmpty) {
      return false;
    }
    return false;
  };

  const createOrPatchActivityProgress = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: true,
    }));
    const foundActivityProgressDTO =
      standardExamApplicationProgressDTO.activityProgressDTOSet.find(
        (activityProgressDTO) =>
          parseFloat(activityProgressDTO.activityDTO.id) ===
          parseFloat(activity.id)
      );
    if (foundActivityProgressDTO) {
      await patchAndSetActivityProgress(foundActivityProgressDTO.id);
    } else {
      await createAndSetActivityProgress();
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: false,
    }));
  };

  const createAndSetActivityProgress = async () => {
    const { data, err } =
      await createActivityProgressByActivityIdStandardExamApplicationId({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
        activity: {
          id: activity.id,
        },
        standardExamApplication: {
          id: standardExamApplicationDTO.id,
        },
        seasonId: userProfileSeason.id,
        gradeId: userProfileGrade.id,
        branchId: userProfileBranch.id,
      });
    if (err) {
      console.log(err);
    } else {
      standardExamApplicationProgressDTO.activityProgressDTOSet.push({
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        standardExamApplicationDTO: data.standardExamApplicationDTO,
      });
      setStandardExamApplicationProgressDTO(
        JSON.parse(JSON.stringify(standardExamApplicationProgressDTO))
      );
    }
  };

  const patchAndSetActivityProgress = async (activityProgressId) => {
    const { data, err } = await patchActivityProgress({
      id: activityProgressId,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
      activityId: activity.id,
      standardExamApplicationId: standardExamApplicationDTO.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    });
    if (err) {
      console.log(err);
    } else {
      const foundIndex =
        standardExamApplicationProgressDTO.activityProgressDTOSet.findIndex(
          (activityProgressDTO) =>
            parseFloat(activityProgressDTO.id) ===
            parseFloat(activityProgressId)
        );
      standardExamApplicationProgressDTO.activityProgressDTOSet[foundIndex] = {
        ...standardExamApplicationProgressDTO.activityProgressDTOSet[
          foundIndex
        ],
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        standardExamApplicationDTO: data.standardExamApplicationDTO,
      };
      setStandardExamApplicationProgressDTO(
        JSON.parse(JSON.stringify(standardExamApplicationProgressDTO))
      );
    }
  };

  const changeActivityAnswer = (answer) => {
    const copyAnswer = JSON.parse(JSON.stringify(answer));
    copyAnswer.data = JSON.stringify(copyAnswer.data);
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    if (foundIndex === -1) {
      activityAnswerDTOList.push(copyAnswer);
    } else {
      activityAnswerDTOList[foundIndex] = copyAnswer;
    }
    setActivityAnswerDTOList(JSON.parse(JSON.stringify(activityAnswerDTOList)));
  };

  const handleCreateOrUpdateActivityAnswerWithData = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: true,
    }));
    const { index, answer } = retrieveCurrentActivityAnswer();
    const baseAnswer = {
      userId: answer.userId,
      userProfileId: answer.userProfileId,
      data: JSON.stringify(answer.data),
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    };
    if (answer.id) {
      const currentAnswer = {
        ...baseAnswer,
        id: answer.id,
        activityId: answer.activityDTO.id,
        standardExamApplicationId: answer.standardExamApplicationDTO.id,
      };
      const { data, err } = await patchActivityAnswerWithData(currentAnswer);
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          ...activityAnswerDTOList[index],
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          standardExamApplicationDTO: data.standardExamApplicationDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    } else {
      const newAnswer = {
        ...baseAnswer,
        activity: {
          id: answer.activityDTO.id,
        },
        standardExamApplication: {
          id: answer.standardExamApplicationDTO.id,
        },
      };
      const { data, err } =
        await createActivityAnswerByActivityIdStandardExamApplicationId(
          newAnswer
        );
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          standardExamApplicationDTO: data.standardExamApplicationDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: false,
    }));
  };

  const handleToNextActivity = async (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      handleCreateOrUpdateActivityAnswerWithData();
    }
    createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleSetEmptyOrContinue = async () => {
    const { index, answer } = retrieveCurrentActivityAnswer();
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const { data } = answer;
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    if (answerTemplate) {
      if (answerTemplate.isEmpty) {
        answerTemplate.isEmpty = false;
      } else {
        answerTemplate.isEmpty = true;
      }
      const copyAnswer = JSON.parse(JSON.stringify(answer));
      copyAnswer.data = JSON.stringify(copyAnswer.data);
      activityAnswerDTOList[index] = copyAnswer;
      setActivityAnswerDTOList([...activityAnswerDTOList]);
      handleCreateOrUpdateActivityAnswerWithData();
    }
  };

  const handleToNextActivityWithAsync = async (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      await handleCreateOrUpdateActivityAnswerWithData();
    }
    await createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleToNextActivityCheckCanAnswer = (nextActivityNumber) => {
    const activityList = retrieveStandardExamActivityList();
    if (checkCanAnswerQuestion()) {
      if (nextActivityNumber === activityList.length) {
        handleToNextActivityWithAsync(nextActivityNumber);
      } else {
        handleToNextActivity(nextActivityNumber);
      }
    } else {
      setActivityNumber(nextActivityNumber);
    }
  };

  const handleToPreviousActivity = async () => {
    await createOrPatchActivityProgress();
    setActivityNumber(activityNumber - 1);
  };

  const handleGoPreviousScreen = async () => {
    if (pageState === 1) {
      if (activity) {
        await createOrPatchActivityProgress();
        setCurrentActivityInitialTimestamp(new Date().getTime());
      }
    }
    navigate("/homeworks", {
      state: { state: "FORCE_UPDATE" },
    });
  };

  const RenderCurrentActivityContent = () => {
    if (activity) {
      const currentActivityTemplate = retrieveCurrentActivityTemplate();
      if (currentActivityTemplate) {
        const { answer } = retrieveCurrentActivityAnswer();
        const foundIndex = answer.data.findIndex(
          (answerData) => answerData.type === currentActivityTemplate.type
        );
        if (foundIndex === -1) {
          answer.data.push(
            constructActivityAnswerMap(
              currentActivityTemplate,
              JSON.parse(activity.data)
            )
          );
        }
        if (currentActivityTemplate.type === "MCSA") {
          return (
            <MCSAActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        }
      }
    }
    return null;
  };

  const RenderSingleKeyValueText = (key, value) => {
    return (
      <div className="flex flex-row justify-between items-center w-full">
        <p className="mx-1 font-bold">{i18n.t(key)}</p>
        <p className="mx-1">{value}</p>
      </div>
    );
  };

  const RenderActivitySetEmpty = () => {
    if (!activity) return;
    if (!retrieveActivityAnswerFromAnswerList(activityAnswerDTOList, activity))
      return;
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    return (
      <div
        className="flex flex-row items-center gap-x-2 cursor-pointer"
        onClick={handleSetEmptyOrContinue}
      >
        <BaseImage
          src={
            answerTemplate?.isEmpty
              ? "/icons/checkbox.png"
              : "/icons/empty-checkbox.png"
          }
          alt="checkbox-empty-checkbox"
          size="small"
          onClick={() => {}}
        />
        <p>{i18n.t("i_want_to_set_empty")}</p>
      </div>
    );
  };

  const RenderActivityEmptyContent = () => {
    if (!activity) return null;
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate?.isEmpty) {
      return <ActivityEmptyContent />;
    }
  };

  const RenderCompleteModal = () => {
    if (!standardExamApplicationDTO) return;
    if (!showCompleteModal) return;

    const { standardExamDTO } = standardExamApplicationDTO;
    const { standardExamActivityDTOList } = standardExamDTO;
    const stateListByCoterieType = {};
    standardExamActivityDTOList.forEach((standardExamActivityDTO) => {
      const { standardExamActivityItemDTOList } = standardExamActivityDTO;
      const activityList = standardExamActivityItemDTOList.map(
        (item) => item.activityDTO
      );
      const stateList = constructActivityAnswerStateList(
        activityAnswerDTOList,
        activityList
      );
      stateListByCoterieType[standardExamActivityDTO.standardExamCoterieType] =
        stateList;
    });
    return (
      <BaseModal
        isOpen={showCompleteModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setActivityNumber(0);
          setShowCompleteModal(false);
        }}
        customSize={"w-[750px]"}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setActivityNumber(0);
                setShowCompleteModal(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div>
                <p className="font-bold">
                  {i18n.t("are_you_sure_do_you_want_to_complete_text")}
                </p>
              </div>
              <DividerLine />

              {Object.keys(stateListByCoterieType).map((item) => {
                const stateList = stateListByCoterieType[item];
                const notExistsItemList = stateList.filter(
                  (item) => item.state === "NOT_EXISTS"
                );
                const emptyItemList = stateList.filter(
                  (item) => item.state === "EMPTY"
                );
                return (
                  <div className="my-2">
                    <p className="font-bold text-base">{i18n.t(item)}</p>
                    {notExistsItemList.length > 0 ||
                    emptyItemList.length > 0 ? (
                      <div className="flex flex-row items-center gap-x-2">
                        <BaseImage
                          src="/icons/exclamation-red.png"
                          alt="exclamation"
                          size="large"
                        />
                        <div className="flex-1 flex flex-col">
                          {notExistsItemList.length > 0 ? (
                            <div>
                              <p className="text-base">
                                {i18n.t("you_did_not_answer_activities_text_1")}
                                {notExistsItemList.map((item, index) => (
                                  <>
                                    <span className="font-bold">
                                      {item.index + 1}
                                    </span>
                                    {index ===
                                    notExistsItemList.length - 1 ? null : (
                                      <span className="font-bold">, </span>
                                    )}
                                  </>
                                ))}
                                {i18n.t("you_did_not_answer_activities_text_2")}
                              </p>
                            </div>
                          ) : null}
                          {emptyItemList.length > 0 ? (
                            <div>
                              <p>
                                {i18n.t("you_set_empty_activities_text_1")}
                                {emptyItemList.map((item, index) => (
                                  <>
                                    <span className="font-bold">
                                      {item.index + 1}
                                    </span>
                                    {index ===
                                    emptyItemList.length - 1 ? null : (
                                      <span className="font-bold">, </span>
                                    )}
                                  </>
                                ))}

                                {i18n.t("you_set_empty_activities_text_2")}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-x-2">
                        <BaseImage
                          src="/icons/checkbox.png"
                          alt="checkbox"
                          size="medium"
                        />
                        <div>
                          <p>{i18n.t("you_have_answered_all_activities")}</p>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

              <div className="flex justify-end ">
                <div className="flex flex-row gap-x-2">
                  <SecondaryButton
                    title={i18n.t("continue")}
                    size="small"
                    onClick={() => {
                      setActivityNumber(0);
                      setShowCompleteModal(false);
                    }}
                  />
                  <PrimaryButton
                    title={i18n.t("complete")}
                    size="small"
                    onClick={() => {
                      navigate("/homeworks", {
                        state: {
                          state: "FORCE_UPDATE",
                        },
                      });
                      setShowCompleteModal(false);
                    }}
                  />
                </div>
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderSelectStandardExamCoterieType = () => {
    if (!standardExamApplicationDTO) return;
    const { standardExamDTO } = standardExamApplicationDTO;
    const { standardExamActivityDTOList } = standardExamDTO;
    const activityList = retrieveStandardExamActivityList();

    const sortedStandardExamActivityDTOList = standardExamActivityDTOList.sort(
      (a, b) => a.index - b.index
    );
    let innerBlockCount = 0;
    let blockMaxActivityCount = 0;
    if (standardExamBlockClientHeight) {
      blockMaxActivityCount = parseInt(standardExamBlockClientHeight / 25);
      innerBlockCount = activityList.length / blockMaxActivityCount;
      if (activityList.length % blockMaxActivityCount !== 0) {
        innerBlockCount++;
      }
    }

    return (
      <div className="h-full flex flex-col">
        <div className="h-full flex flex-row">
          <div className="min-w-[60px] border-r-catchup-gray-50 border-r">
            {sortedStandardExamActivityDTOList.map(
              (standardExamActivityDTO, index) => {
                const { standardExamActivityItemDTOList } =
                  standardExamActivityDTO;
                let count = 0;

                standardExamActivityItemDTOList.forEach(
                  (standardExamActivityItemDTO) => {
                    const activityAnswerState = checkActivityAnswerState(
                      activityAnswerDTOList,
                      standardExamActivityItemDTO.activityDTO
                    );
                    const foundIndex = activityAnswerDTOList.findIndex(
                      (activityAnswerDTO) =>
                        parseFloat(activityAnswerDTO.activityDTO.id) ===
                        parseFloat(standardExamActivityItemDTO.activityDTO.id)
                    );
                    if (foundIndex > -1 && activityAnswerState === "ANSWERED") {
                      count++;
                    }
                  }
                );
                return (
                  <div
                    key={index}
                    className="my-2 cursor-pointer flex flex-col"
                    onClick={() => {
                      setSelectedStandardExamCoterieType(
                        standardExamActivityDTO.standardExamCoterieType
                      );
                      handleToNextActivityCheckCanAnswer(0);
                    }}
                  >
                    <p
                      className={`${
                        selectedStandardExamCoterieType ===
                        standardExamActivityDTO.standardExamCoterieType
                          ? "text-catchup-blue-700 font-bold"
                          : "text-catchup-gray-300"
                      } px-1 transition-all duration-300`}
                    >
                      {i18n.t(
                        `${standardExamActivityDTO.standardExamCoterieType}_ABBREVATION`
                      )}
                    </p>
                    <p
                      className={`text-md m-auto ${
                        count === standardExamActivityItemDTOList.length
                          ? "font-bold"
                          : ""
                      }`}
                    >{`${count}/${standardExamActivityItemDTOList.length}`}</p>
                  </div>
                );
              }
            )}
          </div>
          {selectedStandardExamCoterieType
            ? Array(parseInt(innerBlockCount))
                .fill(null)
                .map((_, i) => i)
                .map((_, innerBlockItemIndex) => {
                  return (
                    <div className="min-w-[40px] border-r-catchup-gray-50 border-r">
                      {activityList
                        .slice(
                          innerBlockItemIndex * blockMaxActivityCount,
                          (innerBlockItemIndex + 1) * blockMaxActivityCount
                        )
                        .map((item, index) => {
                          const activityAnswerState = checkActivityAnswerState(
                            activityAnswerDTOList,
                            item
                          );
                          const convertedIndex =
                            innerBlockItemIndex * blockMaxActivityCount + index;
                          return (
                            <div
                              className="my-1 cursor-pointer"
                              onClick={() => {
                                handleToNextActivityCheckCanAnswer(
                                  convertedIndex
                                );
                              }}
                            >
                              <p
                                className={`${
                                  activityNumber === convertedIndex
                                    ? "font-bold"
                                    : activityAnswerState === "ANSWERED"
                                    ? ""
                                    : "text-catchup-gray-300"
                                } ${
                                  activityAnswerState === "NOT_EXISTS"
                                    ? ""
                                    : activityAnswerState === "EMPTY"
                                    ? "text-catchup-orange"
                                    : activityAnswerState === "ANSWERED"
                                    ? "text-catchup-blue-700"
                                    : "text-catchup-blue-700"
                                } px-4`}
                              >
                                {convertedIndex + 1}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  );
                })
            : null}
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    if (pageState === 0) {
      if (standardExamApplicationDTO) {
        return (
          <ContentTransition
            language={language}
            description={standardExamApplicationDTO.standardExamDTO.description}
            type={standardExamApplicationDTO.standardExamDTO.standardExamType}
            level={standardExamApplicationDTO.standardExamDTO.level}
            activityCount={retrieveAllStandardExamActivityList().length}
            beginAt={standardExamApplicationDTO.beginAt}
            endAt={standardExamApplicationDTO.endAt}
            durationType={standardExamApplicationDTO.durationType}
            durationInMinutes={standardExamApplicationDTO.durationInMinutes}
            answeredActivityCount={activityAnswerDTOList.length}
            totalTimeSpent={retrieveTotalTimeSpentInMinutes(
              standardExamApplicationProgressDTO?.activityProgressDTOSet
            )}
            loading={
              standardExamApplicationDTO.beginAt > new Date().getTime() ||
              standardExamApplicationDTO.endAt < new Date().getTime() ||
              activityAnswerDTOListLoading ||
              standardExamApplicationProgressDTOLoading
            }
            onClick={() => {
              setActivityNumber(0);
              setPageState(1);
            }}
          />
        );
      } else {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      }
    } else if (pageState === 1) {
      if (standardExamApplicationDTO) {
        return (
          <div className="h-full no-scrollbar overflow-y-auto pb-[200px]">
            {activityLoading.answer || activityLoading.progress ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              <div
                className="h-full flex flex-row"
                ref={selectStandardExamBlockRef}
              >
                <div className="mr-2">
                  {RenderSelectStandardExamCoterieType()}
                </div>
                {activityLoading.self ? (
                  <BaseLoading
                    size="large"
                    color="#57C2D3"
                    secondaryColor="#57C2D3"
                  />
                ) : activity ? (
                  <div className="flex flex-col w-full">
                    <div className="my-1">
                      <CatchtivityProgressBar
                        activityNumber={activityNumber + 1}
                        onActivityChange={handleToNextActivityCheckCanAnswer}
                        totalActivityCount={
                          retrieveStandardExamActivityList().length
                        }
                        showClockTime={false}
                      />
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center" />
                      {RenderActivitySetEmpty()}
                    </div>
                    {RenderActivityEmptyContent()}
                    <div className="my-2">{RenderCurrentActivityContent()}</div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className="h-screen p-4">
      {RenderCompleteModal()}
      <ScreenCard>
        <DashboardHeader
          handleReturnOnClick={pageState === 1 ? handleGoPreviousScreen : null}
          title={standardExamApplicationDTO?.standardExamDTO.name}
          level={
            pageState === 1
              ? standardExamApplicationDTO?.standardExamDTO.level
              : null
          }
          durationType={
            pageState === 1 ? standardExamApplicationDTO?.durationType : null
          }
          durationInMinutes={
            pageState === 1
              ? standardExamApplicationDTO?.durationInMinutes
              : null
          }
        />
        {RenderMainContent()}
        {activity && pageState === 1 ? (
          <CatchtivityFooter
            currentActivityIndex={activityNumber}
            totalActivityCount={retrieveStandardExamActivityList().length}
            handleLeftArrowOnClick={handleToPreviousActivity}
            handleActivityChangeOnClick={handleToNextActivityCheckCanAnswer}
            handleRightArrowOnClick={handleToNextActivityCheckCanAnswer}
            activityLoading={activityLoading.self}
            activityAnswerList={[]}
            activityList={[]}
          />
        ) : null}
      </ScreenCard>
    </div>
  );
};

export default StandardExamApplicationScreen;
