import { useAudioRecorder } from "react-audio-voice-recorder";
import InputGroup from "../../groups/InputGroup";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import {
  createOrUpdateStorageFileFromCatchtivityApplication,
  createOrUpdateStorageFileFromCatchxamApplication,
  createOrUpdateStorageFileFromEtude,
} from "../../../requests/StorageRequests";
import connection_production from "../../../config/connection.json";
import connection_development from "../../../config/connection_local.json";
import endpoints from "../../../config/endpoints.json";
import i18n from "../../../language/i18n";
import BaseImage from "../../images/BaseImage";
import {
  convertDataURLtoFile,
  retrieveDocumentTypeFromAcceptedFormat,
  retrieveDocumentTypeFromExtension,
} from "../../../utilization/StorageUtilization";
import PDF from "../../pdf/PDF";
import FullCard from "../../cards/FullCard";
import BaseModal from "../../modal/BaseModal";
import QRCode from "react-qr-code";
import BaseTitle from "../../titles/BaseTitle";
import { NotificationManager } from "react-notifications";
import Konva from "../../konva/Konva";
import useScreenSize from "../../../hooks/useScreenSize";
import DividerLine from "../../divider/DividerLine";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const OpenEndedActivityMaterialContent = (props) => {
  const {
    answer,
    bodyMap,
    contentMap,
    onChange,
    userId,
    userProfileId,
    catchtivityApplicationId,
    catchxamApplicationId,
    etudeId,
    activityId,
    activityTemplateId,
    storageStompClient,
  } = props;
  const { containerSize } = useScreenSize();
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadingStatus, setUploadingStatus] = useState(null);
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
  } = useAudioRecorder();
  const [showUploadFromPhoneModal, setShowUploadFromPhoneModal] =
    useState(false);
  const inputFileRef = useRef(null);
  const [subscribedId, setSubscribedId] = useState(null);
  const [showKonvaModal, setShowKonvaModal] = useState(false);

  useEffect(() => {
    if (recordingBlob) {
      let id;
      if (catchtivityApplicationId) {
        id = `catchtivity-${catchtivityApplicationId}`;
      } else if (catchxamApplicationId) {
        id = `catchxam-${catchxamApplicationId}`;
      } else if (etudeId) {
        id = `etude-${etudeId}`;
      }
      const url = URL.createObjectURL(recordingBlob);
      const blob = new File(
        [recordingBlob],
        `${id}-${activityId}-${activityTemplateId}.mp3`,
        { type: "audio/mp3" }
      );
      setUploadFile(blob);
    }
  }, [recordingBlob]);

  useEffect(() => {
    if (!uploadFile) return;
    handleUploadLearnerFile();
  }, [uploadFile]);

  useEffect(() => {
    if (catchtivityApplicationId && storageStompClient) {
      const subscribed = storageStompClient.subscribe(
        "/topic/storage-files/upload",
        (message) => {
          let { body } = message;
          body = JSON.parse(body);
          const {
            userId: bodyUserId,
            userProfileId: bodyUserProfileId,
            documentType,
            fileName,
          } = body;
          if (bodyUserId === userId && bodyUserProfileId === userProfileId) {
            onChange(
              answer,
              `${connection.base.storage.url}${
                endpoints.storage.learner.context
              }${
                endpoints.storage.learner.preview.file.context
              }/${userId}/${userProfileId}/${documentType.toLowerCase()}s/${fileName}`
            );
            setShowUploadFromPhoneModal(false);
          }
        }
      );
      setSubscribedId(subscribed.id);
    }
    return () => {
      if (!catchtivityApplicationId) return;
      if (!storageStompClient) return;
      storageStompClient.unsubscribe(subscribedId);
      setSubscribedId(null);
    };
  }, [catchtivityApplicationId]);

  const retrieveAnswer = () => {
    if (!answer)
      return {
        answerMap: {
          ANSWER: "",
        },
      };
    return answer.data.find((answerData) => answerData.type === "OPEN_ENDED");
  };

  const retrieveAnswerMap = () => {
    const { answerMap } = retrieveAnswer();
    return answerMap;
  };

  const checkShowContent = () => {
    if (
      userId &&
      userProfileId &&
      (catchtivityApplicationId || catchxamApplicationId || etudeId)
    ) {
      return true;
    }
    return false;
  };

  const constructQRData = () => {
    let type = "";
    if (catchtivityApplicationId) {
      type = "CATCHTIVITY_APPLICATION";
    } else if (catchxamApplicationId) {
      type = "CATCHXAM_APPLICATION";
    } else if (etudeId) {
      type = "ETUDE_APPLICATION";
    }
    const base64Data = {
      type,
      applicationId: catchtivityApplicationId
        ? catchtivityApplicationId
        : catchxamApplicationId
        ? catchxamApplicationId
        : etudeId,
      userId,
      userProfileId,
      activityId,
      activityTemplateId,
    };

    return `${connection.app.url}/upload/file-to-application/${btoa(
      encodeURI(JSON.stringify(base64Data))
    )}`;
  };

  const handleUploadLearnerFile = async () => {
    setUploadingStatus("UPLOADING");
    const formData = new FormData();
    formData.append("file", uploadFile);
    const documentType = retrieveDocumentTypeFromAcceptedFormat(
      uploadFile.type
    );
    formData.append(
      "path",
      `/${userId}/${userProfileId}/${documentType.toLowerCase()}s`
    );

    formData.append("documentType", documentType);
    formData.append("userId", userId);
    formData.append("userProfileId", userProfileId);
    formData.append("activityId", activityId);
    formData.append("activityTemplateId", activityTemplateId);
    let curData;
    let curErr;
    if (catchtivityApplicationId) {
      formData.append("catchtivityApplicationId", catchtivityApplicationId);
      const { data, err } =
        await createOrUpdateStorageFileFromCatchtivityApplication(formData);
      curData = data;
      curErr = err;
    } else if (catchxamApplicationId) {
      formData.append("catchxamApplicationId", catchxamApplicationId);
      const { data, err } =
        await createOrUpdateStorageFileFromCatchxamApplication(formData);
      curData = data;
      curErr = err;
    } else if (etudeId) {
      formData.append("etudeId", etudeId);
      const { data, err } = await createOrUpdateStorageFileFromEtude(formData);
      curData = data;
      curErr = err;
    }

    if (curErr) {
      setUploadingStatus("FAILED");
      console.log(curErr);
      return;
    }
    onChange(
      answer,
      `${connection.base.storage.url}${endpoints.storage.learner.context}${
        endpoints.storage.learner.preview.file.context
      }/${userId}/${userProfileId}/${documentType.toLowerCase()}s/${
        curData.fileName
      }`
    );
    setUploadingStatus("SUCCESS");
  };

  const handleAfterCompleteOnClick = (dataUri) => {
    const file = convertDataURLtoFile(dataUri, `${new Date().getTime()}.jpeg`);
    setUploadFile(file);
    setShowKonvaModal(false);
  };

  const convertBodyMapToKonvaItems = () => {
    const itemList = [];
    Object.keys(bodyMap).forEach((key) => {
      itemList.push(JSON.parse(bodyMap[key]));
    });
    return itemList;
  };

  const RenderStartRecordingContent = () => {
    if (isRecording) {
      return (
        <div
          className={`w-[320px] cursor-pointer transition-all duration-300 ${
            isPaused ? "opacity-100" : "opacity-0"
          }`}
          onClick={() => {
            if (checkShowContent()) {
              togglePauseResume();
            } else {
              NotificationManager.info(
                i18n.t("operations_not_permitted_at_preview")
              );
            }
          }}
        >
          <BaseImage
            src="/icons/ongoing-started-audio-element.png"
            alt="audio-element"
            size="custom"
            className="w-full"
          />
          <p>{i18n.t("continue_recording_text")}</p>
        </div>
      );
    } else {
      return (
        <div
          className={`w-[320px] cursor-pointer transition-all duration-300 ${
            isRecording ? "opacity-0" : "opacity-100"
          }`}
          onClick={() => {
            if (checkShowContent()) {
              startRecording();
            } else {
              NotificationManager.info(
                i18n.t("operations_not_permitted_at_preview")
              );
            }
          }}
        >
          <BaseImage
            src="/icons/not-started-audio-element.png"
            alt="audio-element"
            size="custom"
            className="w-full"
          />
          <p>{i18n.t("start_recording_text")}</p>
        </div>
      );
    }
  };

  const RenderListenAudioTextContent = (answerMapAnswer) => {
    if (!answerMapAnswer) return;
    return (
      <div
        className={`border rounded-catchup-xlarge border-catchup-dark-blue my-2`}
      >
        <p className="text-2xl text-catchup-dark-blue px-4 py-2 text-center">
          {i18n.t("completed_recording_text")}
        </p>
      </div>
    );
  };

  const RenderRecordingTextContent = () => {
    if (isPaused) {
      return (
        <div
          className={`border rounded-catchup-xlarge border-catchup-orange my-2`}
        >
          <p className="text-xl text-catchup-orange px-4 py-2 text-center">
            {i18n.t("paused_recording_text")}
          </p>
        </div>
      );
    } else {
      if (isRecording) {
        return (
          <div
            className={`border rounded-catchup-xlarge border-catchup-red my-2`}
          >
            <p className="text-xl text-catchup-red px-4 py-2 text-center">
              {i18n.t("currently_recording_text")}
            </p>
          </div>
        );
      } else {
        return (
          <div
            className={`border rounded-catchup-xlarge border-catchup-dark-blue my-2`}
          >
            <p className="text-xl text-catchup-dark-blue px-4 py-2 text-center">
              {i18n.t("not_started_recording_text")}
            </p>
          </div>
        );
      }
    }
  };

  const RenderStopRecordingContent = () => {
    if (isRecording) {
      return (
        <div
          className={`w-[320px] cursor-pointer transition-all duration-300 ${
            isPaused ? "opacity-0" : "opacity-100"
          }`}
          onClick={() => {
            if (checkShowContent()) {
              togglePauseResume();
            } else {
              NotificationManager.info(
                i18n.t("operations_not_permitted_at_preview")
              );
            }
          }}
        >
          <BaseImage
            src="/icons/started-audio-element.png"
            alt="audio-element"
            size="custom"
            className="w-full"
          />
          <p>{i18n.t("stop_recording_text")}</p>
        </div>
      );
    }
  };

  const RenderRecordingContent = (answerMap) => {
    return (
      <div>
        {answerMap["ANSWER"] ? (
          <>
            {RenderListenAudioTextContent(answerMap["ANSWER"])}
            {RenderListenAudioContent(answerMap["ANSWER"])}
            <div className="flex flex-col items-center justify-center mt-4">
              <SecondaryButton
                title={i18n.t("restart")}
                size="small"
                onClick={() => {
                  if (checkShowContent()) {
                    onChange(answer, "");
                  } else {
                    NotificationManager.info(
                      i18n.t("operations_not_permitted_at_preview")
                    );
                  }
                }}
              />
            </div>
          </>
        ) : (
          <>
            {RenderRecordingTextContent()}
            <div className="flex flex-row gap-x-2">
              {RenderStartRecordingContent()}
              {RenderStopRecordingContent()}
            </div>
            {isRecording ? (
              <div className="flex flex-col items-center justify-center mt-4">
                <PrimaryButton
                  title={i18n.t("complete")}
                  size="small"
                  onClick={() => {
                    if (checkShowContent()) {
                      stopRecording();
                    } else {
                      NotificationManager.info(
                        i18n.t("operations_not_permitted_at_preview")
                      );
                    }
                  }}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  };

  const RenderUploadStatusTextContent = () => {
    if (uploadingStatus === "UPLOADING") {
      return (
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row items-center justify-center gap-x-2">
            <BaseImage
              src="/icons/three-dots.png"
              alt="three-dots"
              size="medium"
            />
            <p>{i18n.t("please_wait_processing")}</p>
          </div>
        </div>
      );
    } else if (uploadingStatus === "FAILED") {
      return (
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row items-center justift-center gap-x-2">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
            />
            <p>{i18n.t("error_occurred_please_wait")}</p>
          </div>
          <div className="mt-4">
            <PrimaryButton
              title={i18n.t("retry_again")}
              size="small"
              onClick={handleUploadLearnerFile}
            />
          </div>
        </div>
      );
    } else if (uploadingStatus === "SUCCESS") {
      return (
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row items-center justify-center gap-x-2">
            <BaseImage src="/icons/info.png" alt="info" size="medium" />
            <p>{i18n.t("processing_completed")}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  const RenderListenAudioContent = (answerMapAnswer) => {
    if (!answerMapAnswer) return;
    return (
      <div className="flex flex-row items-center justify-center gap-x-2">
        <div className="w-[320px]">
          <BaseImage
            src="/icons/finished-audio-element.png"
            alt="finished-audio-element"
            size="custom"
            className="w-full"
          />
        </div>
        <div className="h-16 w-[320px]">
          {RenderUploadStatusTextContent()}
          {uploadingStatus === "SUCCESS" ? (
            <audio
              className="h-full w-full rounded-catchup-xlarge"
              src={`${answerMapAnswer}`}
              controls
              onClick={() => {}}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const RenderImageContent = (answerMap) => {
    const answerMapAnswer = answerMap["ANSWER"];
    let documentType;
    if (
      answerMapAnswer.startsWith(
        `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.preview.file.context}/${userId}/${userProfileId}`
      )
    ) {
      const extension = answerMapAnswer.split(".").pop();
      documentType = retrieveDocumentTypeFromExtension(extension);
    }
    return (
      <>
        {RenderUploadFileContent(answerMapAnswer)}
        {documentType === "IMAGE" ? (
          <div className="flex flex-col justify-center items-center my-5">
            <BaseImage
              src={answerMapAnswer}
              alt="document"
              size="custom"
              className="w-[80%] rounded-catchup-xlarge"
            />
          </div>
        ) : documentType === "PDF" ? (
          <div className="flex flex-col justify-center items-center my-5">
            <PDF file={answerMapAnswer} />
          </div>
        ) : null}
      </>
    );
  };

  const RenderTextContent = (answerMap) => {
    const answerMapAnswer = answerMap["ANSWER"];
    let documentType = "TEXT";
    if (
      answerMapAnswer.includes(
        `${endpoints.storage.learner.context}${endpoints.storage.learner.preview.file.context}/`
      )
    ) {
      const extension = answerMapAnswer.split(".").pop();
      documentType = retrieveDocumentTypeFromExtension(extension);
    }
    return (
      <>
        {RenderUploadFileContent(answerMapAnswer)}
        {documentType === "IMAGE" ? (
          <div className="flex flex-col justify-center items-center my-5">
            <BaseImage
              src={answerMapAnswer}
              alt="document"
              size="custom"
              className="w-[80%] rounded-catchup-xlarge"
            />
          </div>
        ) : documentType === "PDF" ? (
          <div className="flex flex-col justify-center items-center my-5">
            <PDF file={answerMapAnswer} />
          </div>
        ) : (
          <InputGroup
            type="textarea"
            value={answerMapAnswer}
            useMinHeight={true}
            onChange={(e) => {
              onChange(answer, e.target.value);
            }}
          />
        )}
      </>
    );
  };

  const RenderUploadFileContent = (answerMapAnswer) => {
    let show = false;
    let exists = false;
    if (answerMapAnswer) {
      if (
        answerMapAnswer.startsWith(
          `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.preview.file.context}/${userId}/${userProfileId}`
        )
      ) {
        show = true;
        exists = true;
      }
    } else {
      show = true;
      exists = false;
    }
    if (!show) return;

    return (
      <div className="flex flex-row justify-end items-center gap-x-4 flex-wrap">
        <div
          className="flex flex-row items-center justify-center cursor-pointer gap-x-2"
          onClick={() => {
            if (checkShowContent()) {
              setUploadFile(null);
              inputFileRef.current.click();
            } else {
              NotificationManager.info(
                i18n.t("operations_not_permitted_at_preview")
              );
            }
          }}
        >
          <BaseImage src="/icons/upload-pc.png" alt="upload" size="small" />
          <p className="">
            {exists
              ? i18n.t("i_want_to_reupload_from_my_computer")
              : i18n.t("i_want_to_upload_from_my_computer")}
          </p>
          <input
            className="hidden"
            type="file"
            id="file"
            ref={inputFileRef}
            accept="image/jpeg, image/png, image/jpg, application/pdf"
            multiple={false}
            onChange={(event) => {
              setUploadFile(event.target.files[0]);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
        <div
          className="flex flex-row items-center justify-center cursor-pointer gap-x-2"
          onClick={() => {
            if (checkShowContent()) {
              setShowUploadFromPhoneModal(true);
            } else {
              NotificationManager.info(
                i18n.t("operations_not_permitted_at_preview")
              );
            }
          }}
        >
          <BaseImage src="/icons/upload-device.png" alt="upload" size="small" />
          <p className="">
            {exists
              ? i18n.t("i_want_to_reupload_from_my_phone")
              : i18n.t("i_want_to_upload_from_my_phone")}
          </p>
        </div>
        <div
          className="flex flex-row items-center justify-center cursor-pointer gap-x-2"
          onClick={() => {
            if (checkShowContent()) {
              setShowKonvaModal(true);
            } else {
              NotificationManager.info(
                i18n.t("operations_not_permitted_at_preview")
              );
            }
          }}
        >
          <BaseImage src="/icons/upload-draw.png" alt="upload" size="small" />
          <p className="">
            {exists
              ? i18n.t("i_want_to_reupload_by_drawing")
              : i18n.t("i_want_to_upload_by_drawing")}
          </p>
        </div>
      </div>
    );
  };

  const RenderUploadFromPhoneModal = () => {
    return (
      <BaseModal
        isOpen={showUploadFromPhoneModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowUploadFromPhoneModal(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setShowUploadFromPhoneModal(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div className="flex flex-row items-center justify-center flex-wrap">
                <div className="w-1/2">
                  <div className="my-10">
                    <div className="flex flex-col items-center justify-center">
                      <QRCode
                        size={256}
                        value={constructQRData()}
                        viewBox={`0 0 256 256`}
                        level="L"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <BaseTitle title={i18n.t("instructions")} />
                  <div className="px-4 my-2">
                    <div className="flex flex-col justify-center gap-y-1">
                      <div>
                        <p>1. {i18n.t("open_camera_from_your_mobile_phone")}</p>
                      </div>
                      <div>
                        <p>2. {i18n.t("read_the_qr_code")}</p>
                      </div>
                      <div>
                        <p>3. {i18n.t("navigate_to_web_page")}</p>
                      </div>
                      <div>
                        <p>4. {i18n.t("upload_your_file")}</p>
                      </div>
                      <div>
                        <p>5. {i18n.t("wait_until_the_file_is_uploaded")}</p>
                      </div>
                      <div>
                        <p>6. {i18n.t("close_panel")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderConvaContent = () => {
    return (
      <BaseModal
        isOpen={showKonvaModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowKonvaModal(false);
        }}
      >
        <FullCard>
          <Konva
            initialItemList={convertBodyMapToKonvaItems()}
            initialWidth={containerSize.width}
            handleAfterCompleteOnClick={handleAfterCompleteOnClick}
          />
        </FullCard>
      </BaseModal>
    );
  };

  const answerMap = retrieveAnswerMap();

  return (
    <>
      {RenderConvaContent()}
      {RenderUploadFromPhoneModal()}
      <div className="">
        <div className="hidden md:block">
          <span className="font-semibold text-xl opacity-60">
            {i18n.t("please_select_open_ended_text")}
          </span>
        </div>
        <div className="hidden md:contents">
          <DividerLine />
        </div>
        {contentMap.type === "TEXT" ? (
          RenderTextContent(answerMap)
        ) : contentMap.type === "IMAGE" ? (
          RenderImageContent(answerMap)
        ) : contentMap.type === "AUDIO" ? (
          <div className="flex flex-col items-center justify-center">
            {RenderRecordingContent(answerMap)}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default OpenEndedActivityMaterialContent;
