import { useEffect, useRef, useState } from "react";
import i18n from "../../language/i18n";
import useScreenSize from "../../hooks/useScreenSize";

const Welcome = (props) => {
  const { name } = props;
  const ref = useRef();
  const { screenSize } = useScreenSize();
  const [height, setHeight] = useState(0);
  const [top, setTop] = useState(0);
  const [showLetsStart, setShowLetsStart] = useState(true);

  useEffect(() => {
    if (!ref) return;
    if (!ref.current) return;
    const { offsetHeight } = ref.current;
    setHeight(offsetHeight);
    if (offsetHeight - 66 > 0) {
      setTop((offsetHeight - 66) / 2);
      setShowLetsStart(true);
    } else {
      setTop((offsetHeight - 30) / 2);
      setShowLetsStart(false);
    }
  }, [ref, screenSize]);

  return (
    <div
      ref={ref}
      className="relative w-full h-full shadow-full-card rounded-2xl"
    >
      <div className="absolute right-12 top-0 z-10" style={{ height }}>
        <img
          src="/mascots/mascot-base.png"
          alt="Mascot"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="absolute left-8 w-2/3" style={{ top }}>
        <p className="text-catchup-blue-500 text-2xl">
          {`${i18n.t("hello")} ${name}`},
        </p>
        <p className="text-catchup-white text-3xl">
          {i18n.t("too_much_to_do_this_week_lets_start")}
        </p>
      </div>
      <img
        src="/images/dashboard-top-pattern.png"
        alt="dashboard-top-pattern"
        className="w-full h-full object-fill"
      />
    </div>
  );
};

export default Welcome;
