import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import { useParams } from "react-router-dom";
import { constructActivityAnswerMap } from "../../utilization/CatchtivityUtilization";
import FillInTheBlanksActivityContent from "../../components/activities/FillInTheBlanksActivityContent";
import OpenEndedActivityContent from "../../components/activities/OpenEndedActivityContent";
import GroupingActivityContent from "../../components/activities/GroupingActivityContent";
import MatchingActivityContent from "../../components/activities/MatchingActivityContent";
import MCMAActivityContent from "../../components/activities/MCMAActivityContent";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import DropdownActivityContent from "../../components/activities/DropdownActivityContent";
import OrderingActivityContent from "../../components/activities/OrderingActivityContent";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import { retrieveActivityWithDataByIdAndToken } from "../../requests/CatchtivityRequests";
import TrueFalseActivityContent from "../../components/activities/TrueFalseActivityContent";
import ActivitySolutionContent from "../../components/activities/solution-content/ActivitySolutionContent";
import GiveFeedback from "../../components/give-feedback/GiveFeedback";
import SelectionBox from "../../components/boxes/SelectionBox";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";

const ActivityPreviewByIdScreen = () => {
  const { id, base64Data } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [loading, setLoading] = useState(true);
  const [selectedActivityTemplateType, setSelectedActivityTemplateType] =
    useState(null);
  const [data, setData] = useState(null);
  const [answer, setAnswer] = useState({
    data: [],
  });

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    if (!base64Data) return;
    const { token } = JSON.parse(decodeURI(atob(base64Data)));
    retrieveAndSetActivityDataById(id, token);
  }, [id, base64Data]);

  useEffect(() => {
    const checkAnswerMapExists = (type) => {
      if (Object.keys(data.data).find((dataKey) => dataKey === "answerMap")) {
        const foundAnswer = data["answerMap"].find(
          (answer) => answer.type === type
        );
        if (foundAnswer) {
          return foundAnswer;
        }
      }
      return null;
    };
    const constructAnswerBasedOnData = () => {
      const answer = { data: [] };
      if (
        Object.keys(data.data).find(
          (dataKey) => dataKey === "orderingMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("ORDERING");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "ORDERING" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find(
          (dataKey) => dataKey === "dropdownMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("DROPDOWN");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "DROPDOWN" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find((dataKey) => dataKey === "MCSAMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("MCSA");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MCSA" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find((dataKey) => dataKey === "MCMAMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("MCMA");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MCMA" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find(
          (dataKey) => dataKey === "matchingMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("MATCHING");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MATCHING" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find(
          (dataKey) => dataKey === "groupingMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("GROUPING");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "GROUPING" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find(
          (dataKey) => dataKey === "fillInTheBlanksMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("FILL_IN_THE_BLANKS");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "FILL_IN_THE_BLANKS" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find(
          (dataKey) => dataKey === "openEndedMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("OPEN_ENDED");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "OPEN_ENDED" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (
        Object.keys(data.data).find(
          (dataKey) => dataKey === "trueFalseMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("TRUE_FALSE");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "TRUE_FALSE" },
                JSON.parse(JSON.stringify(data.data))
              )
        );
      }
      if (answer.data.length > 0) {
        setSelectedActivityTemplateType(answer.data[0].type);
      }
      setAnswer(answer);
    };
    if (!data) return;
    constructAnswerBasedOnData();
  }, [data]);

  const retrieveTokenFromBase64Data = () => {
    const { token } = JSON.parse(decodeURI(atob(base64Data)));
    return token;
  };

  const retrieveActivityId = () => {
    if (!data) return;
    return data.id;
  };

  const retrieveActivityTemplateId = () => {
    if (!data) return;
    const foundActivityTemplate = data.activityTemplateDTOList.find(
      (activityTemplateDTO) =>
        activityTemplateDTO.type === selectedActivityTemplateType
    );
    if (!foundActivityTemplate) return;
    return foundActivityTemplate.id;
  };

  const retrieveAndSetActivityDataById = async (id, token) => {
    setLoading(true);
    const { data, err } = await retrieveActivityWithDataByIdAndToken(id, token);
    if (err) {
      console.log(err);
      return;
    }
    setData({ ...data, data: JSON.parse(data.data) });
    setLoading(false);
  };

  const retrieveTaxonomyNameFromActivityType = (type) => {
    let taxonomyMap = {
      name: "",
    };
    if (type === "ORDERING") {
      taxonomyMap = JSON.parse(data.data["orderingTaxonomyMap"]);
    } else if (type === "DROPDOWN") {
      taxonomyMap = JSON.parse(data.data["dropdownTaxonomyMap"]);
    } else if (type === "MCSA") {
      taxonomyMap = JSON.parse(data.data["MCSATaxonomyMap"]);
    } else if (type === "MCMA") {
      taxonomyMap = JSON.parse(data.data["MCMATaxonomyMap"]);
    } else if (type === "MATCHING") {
      taxonomyMap = JSON.parse(data.data["matchingTaxonomyMap"]);
    } else if (type === "GROUPING") {
      taxonomyMap = JSON.parse(data.data["groupingTaxonomyMap"]);
    } else if (type === "FILL_IN_THE_BLANKS") {
      taxonomyMap = JSON.parse(data.data["fillInTheBlanksTaxonomyMap"]);
    } else if (type === "OPEN_ENDED") {
      taxonomyMap = JSON.parse(data.data["openEndedTaxonomyMap"]);
    } else if (type === "TRUE_FALSE") {
      taxonomyMap = JSON.parse(data.data["trueFalseTaxonomyMap"]);
    }
    return taxonomyMap.name;
  };

  const RenderSelectedActivityContent = () => {
    if (selectedActivityTemplateType === "ORDERING") {
      return (
        <OrderingActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "DROPDOWN") {
      return (
        <DropdownActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCSA") {
      return (
        <MCSAActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCMA") {
      return (
        <MCMAActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MATCHING") {
      return (
        <MatchingActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "GROUPING") {
      return (
        <GroupingActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
      return (
        <FillInTheBlanksActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "OPEN_ENDED") {
      return (
        <OpenEndedActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          showMaterialContent={true}
        />
      );
    } else if (selectedActivityTemplateType === "TRUE_FALSE") {
      return (
        <TrueFalseActivityContent
          answer={answer}
          data={data.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    }
  };

  const token = retrieveTokenFromBase64Data();
  const activityId = retrieveActivityId();
  const activityTemplateId = retrieveActivityTemplateId();

  const RenderMainContent = () => {
    return (
      <FullCard>
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            <div className="my-2">
              <p className="font-semibold text-lg">
                {i18n.t("activity_template")}
              </p>
            </div>
            <SelectionBox
              optionList={answer.data.map((answerItem) => ({
                ...answerItem,
                id: answerItem.type,
                text: i18n.t(answerItem.type),
                subText: i18n.t(
                  retrieveTaxonomyNameFromActivityType(answerItem.type)
                ),
              }))}
              selectedId={selectedActivityTemplateType}
              handleSelectOnClick={(itemId) => {
                setSelectedActivityTemplateType(itemId);
              }}
            />
            <DividerLine />
            <div className="flex flex-col my-2 w-full p-5">
              {RenderSelectedActivityContent()}
            </div>
            <ActivitySolutionContent
              activityTemplateType={selectedActivityTemplateType}
              data={data.data}
            />
          </>
        )}
        <div className="mt-auto">
          <GiveFeedback
            activityId={activityId}
            activityTemplateId={activityTemplateId}
            token={token}
          />
        </div>
      </FullCard>
    );
  };

  return (
    <div className="mx-auto container h-full">
      <div className="h-[calc(100vh-15px)] pt-[15px]">
        {RenderMainContent()}
      </div>
    </div>
  );
};

export default ActivityPreviewByIdScreen;
