import Modal from "react-modal";

const BaseModal = (props) => {
  const { isOpen, onAfterOpen, onRequestClose, customSize } = props;

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
          borderRadius: 20,
          background: "",
          border: "",
          maxHeight: "70%",
          overflow: "auto",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999,
        },
      }}
      contentLabel=""
    >
      {customSize ? (
        <div className={`${customSize}`}>{props.children}</div>
      ) : (
        <div className="w-[600px] lg:w-[900px] xl:w-[1200px] 2xl:w-[1500px]">
          {props.children}
        </div>
      )}
    </Modal>
  );
};

export default BaseModal;
