import i18n from "../language/i18n";

export const retrieveMainLeftNavigationOptionList = () => {
  return [
    {
      id: "homeworks",
      name: i18n.t("homeworks"),
      visible: true,
      icon: "/icons/dashboard.png",
      selectedIcon: "/icons/dashboard-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/homeworks",
      subOptionList: null,
    },
    {
      id: "contests",
      name: i18n.t("contest"),
      visible: true,
      icon: "/icons/contest.png",
      selectedIcon: "/icons/contest-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/contests",
      subOptionList: null,
    },
    {
      id: "catchtivities",
      name: i18n.t("catchtivity"),
      icon: "/icons/catchtivity.png",
      selectedIcon: "/icons/catchtivity-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/catchtivities",
      subOptionList: [
        {
          id: "catchtivity-applications",
          parentId: "catchtivities",
          name: i18n.t("applications"),
          roleList: ["LEARNER"],
          authorityScreenName: "catchtivity_application",
          navigate: "/catchtivities/applications",
        },
      ],
    },
    {
      id: "catchxams",
      name: i18n.t("catchxam"),
      icon: "/icons/catchxam.png",
      selectedIcon: "/icons/catchxam-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/catchxams",
      subOptionList: [
        {
          id: "catchxam-applications",
          parentId: "catchxams",
          name: i18n.t("applications"),
          roleList: ["LEARNER"],
          authorityScreenName: "catchtivity_application",
          navigate: "/catchxams/applications",
        },
      ],
    },
    {
      id: "standard-exams",
      name: i18n.t("catchxam"),
      icon: "/icons/standard-exam.png",
      selectedIcon: "/icons/standard-exam-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/standard-exams",
      subOptionList: [
        {
          id: "standard-exam-applications",
          parentId: "standard-exams",
          name: i18n.t("applications"),
          roleList: ["LEARNER"],
          authorityScreenName: "catchtivity_application",
          navigate: "/standard-exams/applications",
        },
      ],
    },
    {
      id: "etudes",
      name: i18n.t("etude"),
      visible: true,
      icon: "/icons/etude.png",
      selectedIcon: "/icons/etude-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/etudes",
      subOptionList: null,
    },
    {
      id: "reports",
      name: i18n.t("reports"),
      visible: true,
      icon: "/icons/report.png",
      selectedIcon: "/icons/report-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/reports",
      subOptionList: null,
    },
    {
      id: "plans",
      name: i18n.t("syllabus"),
      visible: true,
      icon: "/icons/plan.png",
      selectedIcon: "/icons/plan-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/plans",
      subOptionList: null,
    },
    {
      id: "practises",
      name: i18n.t("practise_now"),
      visible: true,
      icon: "/icons/dashboard.png",
      selectedIcon: "/icons/dashboard-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/practise",
      subOptionList: null,
    },
    {
      id: "announcements",
      name: i18n.t("announcements"),
      visible: true,
      icon: "/icons/announcement.png",
      selectedIcon: "/icons/announcement-selected.png",
      roleList: ["LEARNER"],
      authorityScreenName: "dashboard",
      navigate: "/announcements",
      subOptionList: null,
    },
    {
      id: "settings",
      name: i18n.t("settings"),
      icon: "/icons/settings.png",
      roleList: ["LEARNER"],
      navigate: "/",
      subOptionList: null,
    },
  ];
};

export const retrieveLanguageOptionList = () => {
  return [
    { value: "TR", text: i18n.t("turkish") },
    { value: "EN", text: i18n.t("english") },
  ];
};
