import { useEffect, useRef, useState } from "react";
import i18n from "../../language/i18n";
import ProgressBar from "./ProgressBar";
import useScreenSize from "../../hooks/useScreenSize";
import InputGroup from "../groups/InputGroup";
import ClockWithTime from "../clock/ClockWithTime";

const CatchtivityProgressBar = (props) => {
  const {
    activityNumber,
    totalActivityCount,
    onActivityChange,
    showClockTime,
    clockTimeLeft,
    startTimer,
    handleOnTimerEnds,
  } = props;
  const { screenSize } = useScreenSize();
  const progressBarRef = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!progressBarRef) return;
    if (!progressBarRef.current) return;
    setWidth(progressBarRef.current.offsetWidth);
  }, [screenSize, progressBarRef]);

  const constructOptionList = () => {
    const optionList = [];
    for (let i = 1; i <= totalActivityCount; i++) {
      optionList.push({
        text: `${i18n.t("activity")} ${i}`,
        value: i,
      });
    }
    return optionList;
  };

  return (
    <div className="w-full flex flex-row items-center gap-x-4 min-h-[80px]">
      <div className="flex-1" ref={progressBarRef}>
        <div className="flex flex-row items-center justify-between mb-2 relative">
          <div className="">
            <p
              className={`${
                activityNumber === 1
                  ? "text-catchup-blue-700 font-bold"
                  : "text-catchup-gray-300"
              }`}
            >
              {i18n.t("activity")} {1}
            </p>
          </div>
          <div>
            <p
              className={`${
                activityNumber === totalActivityCount
                  ? "text-catchup-blue-700 font-bold"
                  : "text-catchup-gray-300"
              }`}
            >
              {i18n.t("activity")} {totalActivityCount}
            </p>
          </div>
          {activityNumber !== 1 && activityNumber !== totalActivityCount ? (
            <div
              className="absolute"
              style={{
                left: `${
                  (activityNumber * width) / totalActivityCount -
                  (activityNumber === 2
                    ? 0
                    : activityNumber === totalActivityCount - 1
                    ? i18n.t("activity").length * 8
                    : i18n.t("activity").length * 4)
                }px`,
              }}
            >
              <p className="text-catchup-blue-700 font-bold">
                {i18n.t("activity")} {activityNumber}
              </p>
            </div>
          ) : null}
        </div>

        <ProgressBar
          remainingTimeInSeconds={totalActivityCount - activityNumber}
          totalTimeInSeconds={totalActivityCount}
          height="h-4"
          color="bg-catchup-gray-100"
          borderColor="border-catchup-gray-100"
        />
      </div>
      {onActivityChange ? (
        <div className="w-[150px] mt-1">
          <InputGroup
            type="select"
            optionList={constructOptionList()}
            value={activityNumber}
            onChange={(e) => {
              if (e.target.value !== "DEFAULT_OPTION") {
                onActivityChange(parseFloat(e.target.value) - 1);
              }
            }}
          />
        </div>
      ) : null}

      {showClockTime ? (
        <div className="px-2">
          <ClockWithTime
            remainingTotalSeconds={clockTimeLeft}
            startTimer={startTimer}
            handleOnTimerEnds={handleOnTimerEnds}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CatchtivityProgressBar;
