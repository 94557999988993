import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import { Tooltip } from "react-tooltip";
import BaseImage from "../images/BaseImage";
import InputGroup from "../groups/InputGroup";
import { Bar, Doughnut, Radar } from "react-chartjs-2";
import OutcomeDetail from "../outcomes/OutcomeDetail";
import BaseModal from "../../components/modal/BaseModal";
import FullCard from "../cards/FullCard";
import OutcomeGraph from "../outcomes/OutcomeGraph";
import BaseLoading from "../loading/BaseLoading";
import DividerLine from "../divider/DividerLine";

const ReportCardView = (props) => {
  const { isMinimized } = props;
  const navigate = useNavigate();
  const {
    language,
    reportDTOList,
    learnerPerformanceLoading,
    learnerPerformance,
    learnerCatchxamReportListLoading,
    learnerCatchxamReportList,
    individualModelLoading,
    individualModelList,
  } = useAuth();
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [showIndividualModel, setShowIndividualModel] = useState(false);

  useEffect(() => {
    if (!learnerPerformanceLoading) return;
    const reportsCoterieType = localStorage.getItem("reportsCoterieType");
    if (reportsCoterieType) {
      setSelectedCoterieType(reportsCoterieType);
    } else {
      if (filteredCoterieTypeOptionList.length > 0) {
        setSelectedCoterieType(filteredCoterieTypeOptionList[0].value);
      }
    }
  }, [learnerPerformanceLoading]);

  useEffect(() => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      localStorage.removeItem("reportsCoterieType");
    } else {
      localStorage.setItem("reportsCoterieType", selectedCoterieType);
    }
  }, [selectedCoterieType]);

  const retrieveOverallScoreByCoterieType = (key) => {
    let { catchxamApplicationPerformanceDTOList } = learnerPerformance;
    if (selectedCoterieType !== "DEFAULT_OPTION") {
      catchxamApplicationPerformanceDTOList =
        catchxamApplicationPerformanceDTOList.filter(
          (catchxamApplicationPerformanceDTO) =>
            catchxamApplicationPerformanceDTO.coterieType ===
            selectedCoterieType
        );
    }
    let overallScore = 0;
    catchxamApplicationPerformanceDTOList.forEach(
      (item) => (overallScore += item[key])
    );
    return overallScore / catchxamApplicationPerformanceDTOList.length;
  };

  const filterCoterieTypeOptionList = () => {
    const coterieTypeOptionList = [];
    filteredCatchxamApplicationDTOList.forEach((item) => {
      const { catchxamApplicationDTO } = item;
      if (
        !coterieTypeOptionList.find(
          (coterieTypeOption) =>
            coterieTypeOption.value ===
            catchxamApplicationDTO.catchxamDTO.coterieType
        )
      ) {
        coterieTypeOptionList.push({
          value: catchxamApplicationDTO.catchxamDTO.coterieType,
          text: i18n.t(catchxamApplicationDTO.catchxamDTO.coterieType),
        });
      }
    });
    return coterieTypeOptionList;
  };

  const filterCatchxamApplicationDTOListByCoterieType = () => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      return filteredCatchxamApplicationDTOList;
    } else {
      return filteredCatchxamApplicationDTOList.filter(
        (item) =>
          item.catchxamApplicationDTO.catchxamDTO.coterieType ===
          selectedCoterieType
      );
    }
  };

  const filterLearnerCatchxamReportListByCoterieType = () => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      return learnerCatchxamReportList;
    } else {
      return learnerCatchxamReportList.filter(
        (learnerCatchxamReport) =>
          learnerCatchxamReport.catchxamApplicationDTO.catchxamDTO
            .coterieType === selectedCoterieType
      );
    }
  };

  const recursivelyIncludeCategoryActivityAnswerDTO = (
    parentCategoryActivityAnswerDTO,
    categoryActivityAnswerDTO
  ) => {
    const { categoryDTO, subCategoryActivityAnswerDTOList } =
      parentCategoryActivityAnswerDTO;
    if (subCategoryActivityAnswerDTOList.length === 0) return;
    const foundCategoryActivityAnswerDTOById =
      subCategoryActivityAnswerDTOList.find(
        (answerDTO) =>
          answerDTO.categoryDTO.id === categoryActivityAnswerDTO.categoryDTO.id
      );
    if (foundCategoryActivityAnswerDTOById) {
      foundCategoryActivityAnswerDTOById.activityScoreDTOSet.push(
        ...categoryActivityAnswerDTO.activityScoreDTOSet
      );
    } else {
      if (
        categoryDTO?.code === categoryActivityAnswerDTO.categoryDTO.parentCode
      ) {
        subCategoryActivityAnswerDTOList.push(categoryActivityAnswerDTO);
      } else {
        for (const answerDTO of subCategoryActivityAnswerDTOList) {
          recursivelyIncludeCategoryActivityAnswerDTO(
            answerDTO,
            categoryActivityAnswerDTO
          );
        }
      }
    }
  };

  const recursivelyIncludeCategoryActivityAnswerDTOList = (
    parentCategoryActivityAnswerDTO,
    categoryActivityAnswerDTOList
  ) => {
    for (const newAnswerDTO of categoryActivityAnswerDTOList) {
      recursivelyIncludeCategoryActivityAnswerDTO(
        {
          subCategoryActivityAnswerDTOList:
            parentCategoryActivityAnswerDTO.currentCategoryActivityAnswerDTOList,
        },
        newAnswerDTO
      );
      const { subCategoryActivityAnswerDTOList } = newAnswerDTO;
      // if (subCategoryActivityAnswerDTOList.length === 0) return;
      recursivelyIncludeCategoryActivityAnswerDTOList(
        parentCategoryActivityAnswerDTO,
        subCategoryActivityAnswerDTOList
      );
    }
  };

  const constructCombinedCatchxamReportMap = () => {
    const combinedCatchxamReportMap = {};
    const copyFilteredLearnerCatchxamReportListByCoterieType = JSON.parse(
      JSON.stringify(filteredLearnerCatchxamReportListByCoterieType)
    );
    for (const learnerCatchxamReport of copyFilteredLearnerCatchxamReportListByCoterieType) {
      const { catchxamApplicationDTO, categoryActivityAnswerDTOList } =
        learnerCatchxamReport;
      const { catchxamDTO } = catchxamApplicationDTO;
      const { coterieType } = catchxamDTO;
      const currentCategoryActivityAnswerDTOList =
        combinedCatchxamReportMap[coterieType];
      if (currentCategoryActivityAnswerDTOList) {
        recursivelyIncludeCategoryActivityAnswerDTOList(
          { currentCategoryActivityAnswerDTOList },
          categoryActivityAnswerDTOList
        );
      } else {
        combinedCatchxamReportMap[coterieType] = categoryActivityAnswerDTOList;
      }
    }
    return combinedCatchxamReportMap;
  };

  const filterLearnerCatchxamApplicationPerformanceDTOListByCoterieType =
    () => {
      if (selectedCoterieType === "DEFAULT_OPTION") {
        return filteredCatchxamApplicationDTOList;
      } else {
        return filteredCatchxamApplicationDTOList.filter(
          (catchxamApplicationPerformanceDTO) =>
            catchxamApplicationPerformanceDTO.coterieType ===
            selectedCoterieType
        );
      }
    };

  const filterCatchxamApplicationDTOList = () => {
    if (!learnerPerformance) return [];
    const { catchxamApplicationPerformanceDTOList } = learnerPerformance;
    const filteredCatchxamApplicationPerformanceDTOList =
      catchxamApplicationPerformanceDTOList.filter(
        (item) => item.catchxamApplicationDTO.isEvaluated
      );
    return filteredCatchxamApplicationPerformanceDTOList;
  };

  const constructBarLabels = () => {
    return filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType.map(
      (catchxamApplicationPerformance) => {
        const { catchxamApplicationDTO } = catchxamApplicationPerformance;
        const { id } = catchxamApplicationDTO;
        const foundCatchxamApplication =
          filteredCatchxamApplicationDTOListByCoterieType.find(
            (item) => item.catchxamApplicationDTO.id === id
          );
        if (foundCatchxamApplication) {
          return foundCatchxamApplication.catchxamApplicationDTO.catchxamDTO
            .name;
        }
      }
    );
  };

  const constructBarData = () => {
    const barData = [];
    const learnerScoreList = [];
    const branchScoreList = [];
    const gradeScoreList = [];
    let currentLearnerCatchxamApplicationPerformanceDTOListByCoterieType;
    if (isMinimized) {
      currentLearnerCatchxamApplicationPerformanceDTOListByCoterieType =
        filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType.slice(
          0,
          5
        );
    } else {
      currentLearnerCatchxamApplicationPerformanceDTOListByCoterieType =
        filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType;
    }
    currentLearnerCatchxamApplicationPerformanceDTOListByCoterieType.forEach(
      (catchxamApplicationPerformance) => {
        const {
          catchxamApplicationDTO,
          learnerScore,
          branchScore,
          gradeScore,
          // seasonScore,
        } = catchxamApplicationPerformance;
        const { id } = catchxamApplicationDTO;
        learnerScoreList.push({
          score: learnerScore * 100,
          stack: `${id}-learner`,
        });
        branchScoreList.push({
          score: branchScore * 100,
          stack: `${id}-branch`,
        });
        gradeScoreList.push({
          score: gradeScore * 100,
          stack: `${id}-grade`,
        });
        // seasonScoreList.push({
        //   score: seasonScore * 100,
        //   stack: `${id}-season`,
        // });
      }
    );
    barData.push({
      label: i18n.t("learner_score"),
      data: learnerScoreList.map((learnerScore) => learnerScore.score),
      backgroundColor: "rgba(0, 44, 58, 1.0)",
      stack: `0`,
    });
    barData.push({
      label: i18n.t("branch_score"),
      data: branchScoreList.map((branchScore) => branchScore.score),
      backgroundColor: "rgba(9, 101, 116,1.0)",
      stack: `1`,
    });
    barData.push({
      label: i18n.t("grade_score"),
      data: gradeScoreList.map((gradeScore) => gradeScore.score),
      backgroundColor: "rgba(84, 187, 203, 1.0)",
      stack: `2`,
    });
    // barData.push({
    //   label: i18n.t("season_score"),
    //   data: seasonScoreList.map((seasonScore) => seasonScore.score),
    //   backgroundColor: "rgba(255, 206, 86, 0.6)",
    //   stack: `3`,
    // });
    return barData;
  };

  const constructRadarTaxonomyLabels = () => {
    return [
      i18n.t("REMEMBER"),
      i18n.t("UNDERSTAND"),
      i18n.t("APPLY"),
      i18n.t("ANALYZE"),
      i18n.t("EVALUATE"),
      i18n.t("CREATE"),
    ];
  };

  const constructRadarActivityTemplateLabels = () => {
    return [
      i18n.t("ORDERING"),
      i18n.t("DROPDOWN"),
      i18n.t("MCSA"),
      i18n.t("MCMA"),
      i18n.t("MATCHING"),
      i18n.t("GROUPING"),
      i18n.t("FILL_IN_THE_BLANKS"),
      i18n.t("OPEN_ENDED"),
      i18n.t("TRUE_FALSE"),
    ];
  };

  const constructRadarTaxonomyData = (coterieField) => {
    const individualModel = individualModelList.find(
      (item) => item.coterieField === coterieField
    );
    if (!individualModel) return;
    return {
      labels: constructRadarTaxonomyLabels(),
      datasets: [
        {
          label: i18n.t(`${coterieField.toLowerCase()}_taxonomy_tendency`),
          data: [
            individualModel.bloomBloomRemember,
            individualModel.bloomBloomUnderstand,
            individualModel.bloomBloomApply,
            individualModel.bloomBloomAnalyze,
            individualModel.bloomBloomEvaluate,
            individualModel.bloomBloomCreate,
          ],
          backgroundColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 0.3)"
              : "rgba(0, 44, 58, 0.3)",
          borderColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 1)"
              : "rgba(0, 44, 58, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const constructRadarActivityTemplateData = (coterieField) => {
    const individualModel = individualModelList.find(
      (item) => item.coterieField === coterieField
    );
    if (!individualModel) return;
    return {
      labels: constructRadarActivityTemplateLabels(),
      datasets: [
        {
          label: i18n.t(
            `${coterieField.toLowerCase()}_activity_template_tendency`
          ),
          data: [
            individualModel.ordering,
            individualModel.dropdown,
            individualModel.mcsa,
            individualModel.mcma,
            individualModel.matching,
            individualModel.grouping,
            individualModel.fillInTheBlanks,
            individualModel.openEnded,
            individualModel.trueFalse,
          ],
          backgroundColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 0.3)"
              : "rgba(0, 44, 58, 0.3)",
          borderColor:
            coterieField === "NUMERIC"
              ? "rgba(17, 72, 89, 1)"
              : "rgba(0, 44, 58, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const constructRadarOptions = () => {
    return {
      scales: {
        r: {
          angleLines: {
            display: true,
          },
          pointLabels: {
            display: true, // Hides the labels around the radar chart
          },
          ticks: {
            display: false, // Hides the labels in the middel (numbers)
          },
          suggestedMin: 0,
          suggestedMax: 1,
        },
      },
    };
  };

  const handleOnChange = (key, value) => {
    if (key === "selectedCoterieType") {
      setSelectedCoterieType(value);
    }
  };

  const RenderCatchxamApplicationDTOList = () => {
    if (filteredCatchxamApplicationDTOListByCoterieType.length === 0) return;
    return (
      <>
        <div className="my-2">
          <p className="font-bold text-lg">{i18n.t("catchxam_applications")}</p>
        </div>
        <table className="bg-catchup-white">
          <thead className="border-b-2">
            <tr>
              <th className="min-w-catchup-table-gap"></th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("name"))}</th>

              <th>{RenderSingleTableHeaderTextItem(i18n.t("coterie_type"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("begin_at"))}</th>
              <th>{RenderSingleTableHeaderTextItem(i18n.t("end_at"))}</th>
              <th>
                {RenderSingleTableHeaderTextItem(i18n.t("completion_status"))}
              </th>
              <th>
                {RenderSingleTableHeaderTextItem(i18n.t("success_percentage"))}
              </th>
              <th>
                {RenderSingleTableHeaderTextItem(
                  i18n.t("to_report_detail_screen")
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCatchxamApplicationDTOListByCoterieType.map(
              (item, index) =>
                RenderCatchxamApplicationDTOItem(
                  item.catchxamApplicationDTO,
                  index
                )
            )}
          </tbody>
        </table>
      </>
    );
  };

  const RenderCatchxamApplicationDTOItem = (catchxamApplicationDTO, index) => {
    const { id, catchxamDTO, beginAt, endAt, completionStatus } =
      catchxamApplicationDTO;
    const { name, description, coterieType } = catchxamDTO;
    const foundReport = reportDTOList.find(
      (reportDTO) =>
        reportDTO.catchxamApplicationId === catchxamApplicationDTO.id
    );
    const foundApplicationPerformance =
      filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType.find(
        (catchxamApplicationPerformance) =>
          catchxamApplicationPerformance.catchxamApplicationDTO.id === id
      );
    if (!foundApplicationPerformance) return;
    return (
      <tr key={index} className="text-center">
        <td>{RenderSingleTableTextItem(index + 1, true)}</td>
        <td>
          <Tooltip id="info-description" />
          <div className="flex flex-row items-center gap-x-2">
            <BaseImage
              src="/icons/info.png"
              alt="info"
              size="small"
              dataTooltipId="info-description"
              dataTooltipContent={description}
              dataTooltipPlace="top"
              dataTooltipVariant="info"
            />
            {RenderSingleTableTextItem(name)}
          </div>
        </td>

        <td>{RenderSingleTableTextItem(i18n.t(coterieType))}</td>
        <td>
          {RenderSingleTableTextItem(
            new Date(beginAt).toLocaleString(language)
          )}
        </td>
        <td>
          {RenderSingleTableTextItem(new Date(endAt).toLocaleString(language))}
        </td>
        <td>{RenderSingleTableTextItem(i18n.t(completionStatus))}</td>
        <td>
          {RenderSingleTableTextItem(
            `${(foundApplicationPerformance.learnerScore * 100).toFixed(2)} %`,
            true
          )}
        </td>
        <td>
          <div className="flex flex-col items-center justify-center">
            {foundReport ? (
              <BaseImage
                src="/icons/report.png"
                alt="report"
                size="medium"
                onClick={() => {
                  navigate(`/reports/catchxams/${foundReport.id}`);
                }}
              />
            ) : null}
          </div>
        </td>
      </tr>
    );
  };

  const RenderSingleTableHeaderTextItem = (text, isBold) => {
    return (
      <div className={`my-2 ${isBold ? "font-bold" : ""}`}>
        <p>{text}</p>
      </div>
    );
  };

  const RenderSingleTableTextItem = (text, isBold) => {
    return (
      <div className={`my-2 ${isBold ? "font-bold" : ""}`}>
        <p>{text}</p>
      </div>
    );
  };

  const RenderCatchxamOutcomeList = () => {
    return learnerCatchxamReportListLoading ? (
      <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
    ) : (
      <>
        <div className="my-2">
          <p className="font-bold text-lg">{i18n.t("catchxam_outcomes")}</p>
        </div>
        {Object.keys(combinedCatchxamReportMap).map((key) => (
          <div className="flex flex-1 flex-col">
            <div className="my-2">
              <p className="font-bold">{i18n.t(key)}</p>
            </div>

            <div className="">
              <OutcomeGraph
                language={language}
                catchxamReportList={combinedCatchxamReportMap[key]}
              />
            </div>

            <div className="w-full flex flex-col my-5 text-sm">
              {combinedCatchxamReportMap[key].map(
                (categoryActivityAnswerDTO) => (
                  <OutcomeDetail
                    categoryActivityAnswerDTO={categoryActivityAnswerDTO}
                  />
                )
              )}
            </div>
            <DividerLine />
          </div>
        ))}
      </>
    );
  };

  const RenderSingleDoughnotHeader = (title, background) => {
    return (
      <div className="flex flex-row items-center gap-x-2">
        <div className="w-5 h-5 rounded-full" style={{ background }} />
        <p className="text-md cursor-default line">{title}</p>
      </div>
    );
  };

  const RenderMinimizedGraphicalData = () => {
    if (!learnerPerformance) return;
    const overallLearnerScoreByCoterieType =
      retrieveOverallScoreByCoterieType("learnerScore");
    const overallBranchScoreByCoterieType =
      retrieveOverallScoreByCoterieType("branchScore");
    const overallGradeScoreByCoterieType =
      retrieveOverallScoreByCoterieType("gradeScore");
    return (
      <div className="w-full">
        <InputGroup
          type="select"
          value={selectedCoterieType}
          optionList={filteredCoterieTypeOptionList}
          onChange={(event) => {
            handleOnChange("selectedCoterieType", event.target.value);
          }}
        />
        <div className="">
          <div className="w-full">
            <div className="">
              <div className="my-2">
                <p className="font-bold text-lg">
                  {i18n.t("overall_catchxam_scores")}
                </p>
              </div>
              <Bar
                options={{
                  responsive: true,
                  interaction: {
                    mode: "index",
                    intersect: false,
                  },
                  maxBarThickness: 40,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                      min: 0,
                      max: 100,
                    },
                  },
                }}
                data={{
                  labels: constructBarLabels(),
                  datasets: constructBarData(),
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="">
              <div className="my-2">
                <p className="font-bold text-lg">{i18n.t("overall_scores")}</p>
              </div>
              <div>
                <div className="flex flex-row items-center justify-between flex-wrap gap-x-4 gap-y-2">
                  {RenderSingleDoughnotHeader(
                    i18n.t("overall_learner_scores"),
                    "rgba(0, 44, 58, 1.0)"
                  )}
                  {RenderSingleDoughnotHeader(
                    i18n.t("overall_branch_scores"),
                    "rgba(9, 101, 116, 1.0)"
                  )}
                  {RenderSingleDoughnotHeader(
                    i18n.t("overall_grade_scores"),
                    "rgba(84, 187, 203, 1.0)"
                  )}
                </div>
              </div>
              <div className="w-1/2 m-auto">
                <Doughnut
                  data={{
                    labels: [],
                    datasets: [
                      {
                        label: i18n.t("score"),
                        data: [
                          overallLearnerScoreByCoterieType * 100,
                          100 - overallLearnerScoreByCoterieType * 100,
                        ],
                        backgroundColor: [
                          "rgba(0, 44, 58, 0.6)",
                          "rgba(255, 255, 255, 0.0)",
                        ],
                        borderColor: [
                          "rgba(0, 44, 58, 1)",
                          "rgba(255, 255, 255, 0.0)",
                        ],
                        borderWidth: 1,
                      },
                      {
                        label: i18n.t("score"),
                        data: [
                          overallBranchScoreByCoterieType * 100,
                          100 - overallBranchScoreByCoterieType * 100,
                        ],
                        backgroundColor: [
                          "rgba(9, 101, 116,0.6)",
                          "rgba(255, 255, 255, 0.0)",
                        ],
                        borderColor: [
                          "rgba(9, 101, 116, 1)",
                          "rgba(255, 255, 255, 0.0)",
                        ],
                        borderWidth: 1,
                      },
                      {
                        label: i18n.t("score"),
                        data: [
                          overallGradeScoreByCoterieType * 100,
                          100 - overallGradeScoreByCoterieType * 100,
                        ],
                        backgroundColor: [
                          "rgba(84, 187, 203, 0.6)",
                          "rgba(255, 255, 255, 0.0)",
                        ],
                        borderColor: [
                          "rgba(84, 187, 203, 1.0)",
                          "rgba(255, 255, 255, 0.0)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderGraphicalData = () => {
    if (!learnerPerformance) return;
    const overallLearnerScoreByCoterieType =
      retrieveOverallScoreByCoterieType("learnerScore");
    const overallBranchScoreByCoterieType =
      retrieveOverallScoreByCoterieType("branchScore");
    const overallGradeScoreByCoterieType =
      retrieveOverallScoreByCoterieType("gradeScore");
    return (
      <div className="flex flex-1 flex-row item-center flex-wrap">
        <div className="w-full lg:w-1/4">
          <div className="mr-4">
            <div className="my-2">
              <p className="font-bold text-lg">{i18n.t("overall_scores")}</p>
            </div>
            <div>
              <div className="flex flex-row items-center justify-between flex-wrap gap-x-4 gap-y-2">
                {RenderSingleDoughnotHeader(
                  i18n.t("overall_learner_scores"),
                  "rgba(0, 44, 58, 1.0)"
                )}
                {RenderSingleDoughnotHeader(
                  i18n.t("overall_branch_scores"),
                  "rgba(9, 101, 116, 1.0)"
                )}
                {RenderSingleDoughnotHeader(
                  i18n.t("overall_grade_scores"),
                  "rgba(84, 187, 203, 1.0)"
                )}
              </div>
            </div>
            <div className="max-w-[350px] m-auto">
              <Doughnut
                data={{
                  labels: [],
                  datasets: [
                    {
                      label: i18n.t("score"),
                      data: [
                        overallLearnerScoreByCoterieType * 100,
                        100 - overallLearnerScoreByCoterieType * 100,
                      ],
                      backgroundColor: [
                        "rgba(0, 44, 58, 0.6)",
                        "rgba(255, 255, 255, 0.0)",
                      ],
                      borderColor: [
                        "rgba(0, 44, 58, 1)",
                        "rgba(255, 255, 255, 0.0)",
                      ],
                      borderWidth: 1,
                    },
                    {
                      label: i18n.t("score"),
                      data: [
                        overallBranchScoreByCoterieType * 100,
                        100 - overallBranchScoreByCoterieType * 100,
                      ],
                      backgroundColor: [
                        "rgba(9, 101, 116,0.6)",
                        "rgba(255, 255, 255, 0.0)",
                      ],
                      borderColor: [
                        "rgba(9, 101, 116, 1)",
                        "rgba(255, 255, 255, 0.0)",
                      ],
                      borderWidth: 1,
                    },
                    {
                      label: i18n.t("score"),
                      data: [
                        overallGradeScoreByCoterieType * 100,
                        100 - overallGradeScoreByCoterieType * 100,
                      ],
                      backgroundColor: [
                        "rgba(84, 187, 203, 0.6)",
                        "rgba(255, 255, 255, 0.0)",
                      ],
                      borderColor: [
                        "rgba(84, 187, 203, 1.0)",
                        "rgba(255, 255, 255, 0.0)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-3/4">
          <div className="ml-4">
            <div className="my-2">
              <p className="font-bold text-lg">
                {i18n.t("overall_catchxam_scores")}
              </p>
            </div>
            <Bar
              options={{
                responsive: true,
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                maxBarThickness: 40,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    min: 0,
                    max: 100,
                  },
                },
              }}
              data={{
                labels: constructBarLabels(),
                datasets: constructBarData(),
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderIndividualModelModal = () => {
    return (
      <BaseModal
        isOpen={showIndividualModel}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowIndividualModel(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setShowIndividualModel(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              {individualModelLoading ? (
                <BaseLoading
                  size="large"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              ) : (
                <>
                  <div className="w-full flex flex-row justify-center items-center flex-wrap">
                    <div className="w-full flex flex-row justify-center items-center">
                      <div className="-rotate-90">
                        <span className="text-xl text-catchup-darker-blue">
                          {i18n.t("taxonomy")}
                        </span>
                      </div>
                      <div className="flex-1 flex">
                        <div className="w-full lg:w-1/2">
                          <Radar
                            data={constructRadarTaxonomyData("VERBAL")}
                            options={constructRadarOptions()}
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Radar
                            data={constructRadarTaxonomyData("NUMERIC")}
                            options={constructRadarOptions()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <DividerLine />
                  <div className="w-full flex flex-row justify-center items-center flex-wrap">
                    <div className="w-full flex flex-row justify-center items-center">
                      <div className="-rotate-90">
                        <span className="text-xl text-catchup-darker-blue">
                          {i18n.t("template")}
                        </span>
                      </div>
                      <div className="flex-1 flex">
                        <div className="w-full lg:w-1/2">
                          <Radar
                            data={constructRadarActivityTemplateData("VERBAL")}
                            options={constructRadarOptions()}
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <Radar
                            data={constructRadarActivityTemplateData("NUMERIC")}
                            options={constructRadarOptions()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const filteredCatchxamApplicationDTOList = filterCatchxamApplicationDTOList();
  const filteredCatchxamApplicationDTOListByCoterieType =
    filterCatchxamApplicationDTOListByCoterieType();
  const filteredLearnerCatchxamApplicationPerformanceDTOListByCoterieType =
    filterLearnerCatchxamApplicationPerformanceDTOListByCoterieType();
  const filteredCoterieTypeOptionList = filterCoterieTypeOptionList();
  const filteredLearnerCatchxamReportListByCoterieType =
    filterLearnerCatchxamReportListByCoterieType();
  const combinedCatchxamReportMap = constructCombinedCatchxamReportMap();

  if (isMinimized) {
    return RenderMinimizedGraphicalData();
  } else {
    return (
      <>
        {RenderIndividualModelModal()}
        <div className="h-full flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("coterie_type")}
                  placeholder={i18n.t("coterie_type")}
                  value={selectedCoterieType}
                  optionList={filteredCoterieTypeOptionList}
                  onChange={(event) => {
                    handleOnChange("selectedCoterieType", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <p
                  className="underline cursor-pointer"
                  onClick={() => {
                    setShowIndividualModel(true);
                  }}
                >
                  {i18n.t("show_my_individual_model")}
                </p>
              </div>
            </div>
          </div>
          {learnerPerformanceLoading || learnerCatchxamReportListLoading ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : (
            <div className="flex flex-1 flex-col">
              {RenderGraphicalData()}

              {filteredCatchxamApplicationDTOListByCoterieType.length > 0 ? (
                <>
                  <DividerLine />
                  {RenderCatchxamApplicationDTOList()}
                </>
              ) : null}

              {Object.keys(combinedCatchxamReportMap).length > 0 ? (
                <>
                  <DividerLine />
                  {RenderCatchxamOutcomeList()}
                </>
              ) : null}
            </div>
          )}
        </div>
      </>
    );
  }
};

export default ReportCardView;
