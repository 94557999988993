import { useState } from "react";
import BaseImage from "../images/BaseImage";
import BaseLoading from "../loading/BaseLoading";

const DeleteButton = (props) => {
  const { title, size, onClick, disabled, textOnly, iconOnly } = props;
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const internalOnClick = async (e) => {
    e.stopPropagation();
    if (loading) return;
    if (disabled) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  let currentWidthClassName;
  let currentHeightClassName;
  let currentLoadingSize;
  if (size === "small") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-8";
    currentLoadingSize = 14;
  } else if (size === "medium") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-10";
    currentLoadingSize = 16;
  } else if (size === "large") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-12";
    currentLoadingSize = 18;
  }

  return (
    <button
      className={`border border-catchup-red bg-catchup-white text-catchup-red rounded-catchup-button ${
        loading
          ? ""
          : disabled
          ? "opacity-50"
          : "hover:bg-catchup-red hover:text-catchup-white hover:border-catchup-red active:bg-catchup-red active:hover:border-catchup-red active:text-catchup-white active:opacity-80"
      } transition duration-300 ${currentWidthClassName} ${currentHeightClassName}`}
      onClick={internalOnClick}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {loading ? (
        <BaseLoading
          height={currentLoadingSize}
          width={currentLoadingSize}
          color="#55777f"
          secondaryColor="55777f"
          hideText={true}
        />
      ) : (
        <div className="flex flex-row justify-center items-center gap-x-2">
          {iconOnly ? null : <p className="">{title}</p>}
          {textOnly ? null : (
            <BaseImage
              src={`${
                isHovered ? "/icons/remove-white.png" : "/icons/remove-red.png"
              }`}
              alt="remove-white"
              size="xsmall"
            />
          )}
        </div>
      )}
    </button>
  );
};

export default DeleteButton;
