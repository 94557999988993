import { useRef } from "react";
import { useDrop } from "react-dnd";

const DroppableItem = (props) => {
  const ref = useRef(null);
  const { key, item, type, component, dropRef, target, setTarget } = props;

  const [, drop] = useDrop({
    accept: type,
    hover() {
      if (item.index !== null && target !== item.index) {
        setTarget(item.index);
      }
    },
  });

  dropRef(drop(ref));

  return (
    <div
      key={key}
      className={`w-full transition-all duration-500 h-full`}
      ref={ref}
    >
      {component}
    </div>
  );
};

export default DroppableItem;
