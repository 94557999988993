import { useAuth } from "../../context/UserContextProvider";
import EtudeApplicationCard from "../cards/EtudeApplicationCard";
import BaseLoading from "../loading/BaseLoading";

const EtudeApplicationListView = () => {
  const { activeEtudeList, etudeListLoading, language } = useAuth();

  return (
    <div className="h-full flex-1 flex flex-col justify-center">
      <div className="flex-1 flex flex-col">
        {etudeListLoading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <div className="flex-1 flex flex-row overflow-auto">
            {activeEtudeList.map((etude) => (
              <div
                className="w-full lg:w-1/2 xl:w-1/3 2xl:w-1/4"
                key={etude.id}
              >
                <EtudeApplicationCard
                  etudeApplicationDTO={etude}
                  language={language}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EtudeApplicationListView;
