import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * User
 */
export const retrieveTokenInformation = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.authenticate.context}`,
      params
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const retrieveTokenInformationWithExternalResource = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["authenticate-with-profile"]["with-external-resource"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveUserInformationFromToken = async (accessToken) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.get(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.userFromToken.context}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const retrieveAccessTokenFromRefreshToken = async (refreshToken) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.refreshToken.context}`,
      {
        refreshToken,
      }
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const retrieveAccessTokenFromRefreshTokenWithExpiration = async (
  refreshToken,
  userProfileId
) => {
  try {
    const response = await axios
      .create()
      .post(
        `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.refreshTokenFromExpiration.context}`,
        {
          refreshToken,
          userProfileId,
        }
      );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveProfileTokenInformation = async (
  profileId,
  refreshToken
) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["authenticate-with-profile"].context}/${profileId}`,
      {
        refreshToken,
      }
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const createBasicUser = async (user) => {
  try {
    const response = await axios.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.basic.context}`,
      user
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

export const patchUser = async (params) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.users.context}/`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const patchUserOnlyRequiredAction = async (params) => {
  try {
    const response = await axios.patch(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users["only-requiredAction"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const sendForgotPasswordEmail = async (userDTO) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.users.context}${endpoints.management.users.send.email["forgot-password"].context}`,
      userDTO
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * User Profile
 */
export const retrieveUserProfileListFromToken = async (accessToken) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.get(
      `${connection.base.management.url}${endpoints.management.users.profiles.context}${endpoints.management.users.profiles.dto.context}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return { data: response.data };
  } catch (err) {
    return { err };
  }
};

/**
 * User Email
 */
export const patchAndForgotUserPassword = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.patch(
      `${connection.base.management.url}${endpoints.management.generated.code.context}${endpoints.management.generated.code["forgot-password"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Integrations
 */
export const retrieveK12UserByAuthorizationCode = async (params) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.management.url}${endpoints.management.integrations.context}${endpoints.management.integrations.k12.users["by-authorization-code"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
