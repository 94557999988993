import {
  parseBodyMapFromData,
  parseContentMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import VerticalDividerLine from "../divider/VerticalDividerLine";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import DropdownActivityMaterialContent from "./material-content/DropdownActivityMaterialContent";

const DropdownActivityContent = (props) => {
  const {
    answer,
    data,
    canAnswerQuestion,
    changeAnswer,
    isPreview,
    showCorrectAnswer,
  } = props;
  const contentMap = parseContentMapFromData(data);
  const dropdownBodyMap = parseBodyMapFromData(data, "DROPDOWN");
  const dropdownMaterialMap = parseMaterialMapFromData(data, "DROPDOWN");

  const retrieveCurrentAnswerMap = () => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "DROPDOWN"
    );
    return answer.data[foundIndex].answerMap;
  };

  const handleDropdownAnswerOnChange = (answer, key, value) => {
    const answerMap = retrieveCurrentAnswerMap();
    answerMap[key] = value;
    changeAnswer(answer);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full md:w-[60%]">
        <ActivityBodyContent
          bodyMap={dropdownBodyMap}
          answerMap={retrieveCurrentAnswerMap()}
          contentMap={contentMap}
          templateType={"DROPDOWN"}
        />
      </div>
      <div className="contents md:hidden">
        <DividerLine />
      </div>
      <div className="hidden md:contents">
        <VerticalDividerLine />
      </div>
      <div className="w-full md:flex-1">
        <DropdownActivityMaterialContent
          uniqueValue={JSON.stringify(data.contentMap)}
          answer={answer}
          materialMap={dropdownMaterialMap}
          contentMap={contentMap}
          checkCanAnswerQuestion={canAnswerQuestion}
          onChange={handleDropdownAnswerOnChange}
          isPreview={isPreview}
          showCorrectAnswer={showCorrectAnswer}
        />
      </div>
    </div>
  );
};

export default DropdownActivityContent;
