import { InlineMath } from "react-katex";
import InputGroup from "../../groups/InputGroup";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";
import i18n from "../../../language/i18n";
import { useState } from "react";
import { useEffect } from "react";
import { shuffleArray } from "../../../utilization/AppUtilization";
import DraggableItem from "../../draggable/DraggableItem";
import DroppableItem from "../../draggable/DroppableItem";
import { useDrop } from "react-dnd";
import ShowMaterialMediaByContentType from "./ShowMaterialMediaByContentType";
import BaseImage from "../../images/BaseImage";
import DividerLine from "../../divider/DividerLine";

const FillInTheBlanksActivityMaterialContent = (props) => {
  const {
    uniqueValue,
    answer,
    optionList,
    materialMap,
    contentMap,
    checkCanAnswerQuestion,
    onChange,
    isPreview,
    showCorrectAnswer,
  } = props;
  const [shuffleOptionList, setShuffleOptionList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pasteOptionIndex, setPasteOptionIndex] = useState(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "FILL_IN_THE_BLANKS",
    drop: () => {},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    setShuffleOptionList(shuffleArray(optionList));
  }, []);

  useEffect(() => {
    if (!showCorrectAnswer) return;
    const foundAnswer = answer.data.find(
      (answerData) => answerData.type === "FILL_IN_THE_BLANKS"
    );
    if (foundAnswer.answerMap.length === 0) return;
    foundAnswer.answerMap = Object.keys(materialMap).map(
      (materialMapKey, index) => JSON.parse(materialMap[materialMapKey])[0]
    );

    onChange(answer, 0, JSON.parse(materialMap[0])[0]);
  }, [showCorrectAnswer]);

  const retrieveAnswerMap = () => {
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "FILL_IN_THE_BLANKS"
    );
    return answer.data[foundIndex].answerMap;
  };

  const checkAnswerState = (correctAnswerList, learnerAnswer) => {
    if (!isPreview) return "HIDDEN";
    const foundIndex = correctAnswerList.findIndex(
      (correctAnswer) => correctAnswer === learnerAnswer
    );
    if (foundIndex !== -1) {
      return "CORRECT";
    }
    return "INCORRECT";
  };

  const checkAnswerProvided = (answerMap, option) => {
    return (
      Object.keys(answerMap).findIndex((key) => answerMap[key] === option) !==
      -1
    );
  };

  const answerMap = retrieveAnswerMap();

  return (
    <div className="flex flex-row flex-wrap items-center" onMouseUp={() => {}}>
      <div className="hidden md:block">
        <span className="font-semibold text-xl opacity-60">
          {i18n.t("please_select_fill_in_the_blanks_text")}
        </span>
      </div>
      <div className="hidden md:contents">
        <DividerLine />
      </div>

      <div className="w-full flex flex-row flex-wrap gap-x-2 gap-y-2 my-2">
        {shuffleOptionList.map((option, index) =>
          checkAnswerProvided(answerMap, option) ? (
            <div className="opacity-30">
              <ShowMaterialMediaByContentType
                key={`${uniqueValue}-${index}`}
                contentType={contentMap.type}
                src={option}
                canFullScreen={true}
              />
            </div>
          ) : (
            <DraggableItem
              key={index}
              item={{ index: option }}
              type={"FILL_IN_THE_BLANKS"}
              component={
                contentMap.type === "TEXT" ? (
                  <div
                    className="border-catchup-blue border-2 px-2 py-1 rounded-catchup-xlarge cursor-pointer"
                    onMouseDown={() => {
                      setSelectedOption(option);
                      setPasteOptionIndex(null);
                    }}
                  >
                    <p className="italic whitespace-pre-wrap">{option}</p>
                  </div>
                ) : (
                  <div
                    className="border-catchup-blue border-2 px-2 py-1 rounded-catchup-xlarge cursor-pointer"
                    onMouseDown={() => {
                      setSelectedOption(option);
                      setPasteOptionIndex(null);
                    }}
                  >
                    <ShowMaterialMediaByContentType
                      key={`${uniqueValue}-${index}`}
                      contentType={contentMap.type}
                      src={option}
                      canFullScreen={true}
                    />
                  </div>
                )
              }
              moveCardHandler={() => {
                onChange(answer, pasteOptionIndex, selectedOption);
              }}
            />
          )
        )}
      </div>
      <div className="flex flex-row flex-wrap">
        {Object.keys(answerMap).map((materialKey, index) => {
          const learnerAnswerState = checkAnswerState(
            JSON.parse(materialMap[materialKey]),
            answerMap[materialKey]
          );
          return (
            <div key={index} className="w-full md:w-1/2">
              <div className="mx-2">
                <DroppableItem
                  item={{ index }}
                  type={"FILL_IN_THE_BLANKS"}
                  target={pasteOptionIndex}
                  setTarget={setPasteOptionIndex}
                  dropRef={drop}
                  component={
                    <div className="w-full flex flex-row my-2 gap-x-2">
                      <div className="my-auto">
                        <p className="text-xl">
                          {parseFloat(materialKey) + 1}.
                        </p>
                      </div>
                      <div className="flex-1">
                        {checkCanAnswerQuestion() ? (
                          contentMap.type === "TEXT" ? (
                            <div className="relative">
                              <div className="flex-1">
                                <InputGroup
                                  type="textarea"
                                  title={null}
                                  placeholder={null}
                                  value={answerMap[materialKey]}
                                  useMinHeight={false}
                                  onChange={(e) => {
                                    onChange(
                                      answer,
                                      materialKey,
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                              {learnerAnswerState === "CORRECT" ? (
                                <div className="absolute -top-[10px] right-4 bg-catchup-white">
                                  <BaseImage
                                    src="/icons/checkbox.png"
                                    alt="checkbox"
                                    size="small"
                                  />
                                </div>
                              ) : learnerAnswerState === "INCORRECT" ? (
                                <div className="absolute -top-[10px] right-4 bg-catchup-white">
                                  <BaseImage
                                    src="/icons/cross-red.png"
                                    alt="cross-red"
                                    size="small"
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : answerMap[materialKey] === "" ? (
                            <div
                              className={`w-catchup-activity-box-item border h-catchup-activity-box-item rounded-catchup-xlarge border-dashed ${
                                learnerAnswerState === "EMPTY"
                                  ? "border-catchup-blue"
                                  : learnerAnswerState === "CORRECT"
                                  ? "border-catchup-green"
                                  : learnerAnswerState === "INCORRECT"
                                  ? "border-catchup-red"
                                  : ""
                              }`}
                            >
                              <div className="h-full flex flex-col items-center justify-center px-4 py-2">
                                <span className="italic">
                                  {i18n.t("please_drop_here")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="flex-1 cursor-pointer"
                              onClick={() => {
                                onChange(answer, materialKey, "");
                              }}
                            >
                              <ShowMaterialMediaByContentType
                                key={`${uniqueValue}-${index}`}
                                contentType={contentMap.type}
                                src={answerMap[materialKey]}
                                canFullScreen={true}
                              />
                            </div>
                          )
                        ) : (
                          <p key={materialKey} className="text-xl">
                            {constructInputWithSpecialExpressionList(
                              answerMap[materialKey]
                            ).map((inputPart, index) => (
                              <span
                                key={index}
                                className={`${
                                  inputPart.isBold ? "font-bold" : ""
                                } ${inputPart.isUnderline ? "underline" : ""}`}
                              >
                                {inputPart.isEquation ? (
                                  <span className="text-2xl">
                                    <InlineMath math={inputPart.value} />
                                  </span>
                                ) : (
                                  inputPart.value
                                )}
                              </span>
                            ))}
                          </p>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FillInTheBlanksActivityMaterialContent;
