import {
  parseBodyMapFromData,
  parseContentMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import MatchingActivityMaterialContent from "./material-content/MatchingActivityMaterialContent";

const MatchingActivityContent = (props) => {
  const {
    answer,
    data,
    canAnswerQuestion,
    changeAnswer,
    isPreview,
    showCorrectAnswer,
  } = props;
  const contentMap = parseContentMapFromData(data);
  const matchingBodyMap = parseBodyMapFromData(data, "MATCHING");
  const matchingMaterialMap = parseMaterialMapFromData(data, "MATCHING");

  const handleMatchingAnswerOnChange = (answer, key, value) => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "MATCHING"
    );
    const answerMap = answer.data[foundIndex].answerMap;
    answerMap[key] = value;
    changeAnswer(answer);
  };

  return (
    <>
      <ActivityBodyContent
        bodyMap={matchingBodyMap}
        templateType={"MATCHING"}
      />
      <DividerLine />
      <MatchingActivityMaterialContent
        uniqueValue={JSON.stringify(data.contentMap)}
        answer={answer}
        materialMap={matchingMaterialMap}
        contentMap={contentMap}
        checkCanAnswerQuestion={canAnswerQuestion}
        onChange={handleMatchingAnswerOnChange}
        isPreview={isPreview}
        showCorrectAnswer={showCorrectAnswer}
      />
    </>
  );
};

export default MatchingActivityContent;
