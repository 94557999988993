import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingScreen from "../screens/landing/LandingScreen";
import ErrorScreen from "../screens/error/ErrorScreen";
import LoginScreen from "../screens/login/LoginScreen";
import RegisterScreen from "../screens/register/RegisterScreen";
import DashboardScreen from "../screens/dashboard/DashboardScreen";
import NetworkErrorScreen from "../screens/error/NetworkErrorScreen";
import PermissionErrorScreen from "../screens/error/PermissionErrorScreen";
import { useApp } from "../context/AppContextProvider";
import ProfileSelectionScreen from "../screens/profile/ProfileSelectionScreen";
import TokenErrorScreen from "../screens/error/TokenErrorScreen";
import AccountActivationErrorScreen from "../screens/error/AccountActivationErrorScreen";
import CatchtivityApplicationScreen from "../screens/catchtivities/CatchtivityApplicationScreen";
import MainLeftNavigation from "../components/navigations/MainLeftNavigation";
import ActivityPreviewByDataScreen from "../screens/preview/ActivityPreviewByDataScreen";
import ActivityPreviewByLearnerDataScreen from "../screens/preview/ActivityPreviewByLearnerDataScreen";
import ActivityPreviewByIdScreen from "../screens/preview/ActivityPreviewByIdScreen";
import CatchxamApplicationScreen from "../screens/catchxams/CatchxamApplicationScreen";
import CatchxamReportScreen from "../screens/reports/CatchxamReportScreen";
import AnnouncementListScreen from "../screens/announcements/AnnouncementListScreen";
import CatchtivityPreviewByIdScreen from "../screens/preview/CatchtivityPreviewByIdScreen";
import CatchxamPreviewByIdScreen from "../screens/preview/CatchxamPreviewByIdScreen";
import StandardExamApplicationScreen from "../screens/standard-exams/StandardExamApplicationScreen";
import StandardExamPreviewByIdScreen from "../screens/preview/StandardExamPreviewByIdScreen";
import PractiseNowScreen from "../screens/practises/PractiseNowScreen";
import EtudeApplicationScreen from "../screens/etudes/EtudeApplicationScreen";
import FileToAnyApplicationScreen from "../screens/uploads/FileToAnyApplicationScreen";
import FileToAnyApplicationSuccessScreen from "../screens/uploads/FileToAnyApplicationSuccessScreen";
import PractiseCatchtivityByIdScreen from "../screens/practises/PractiseCatchtivityByIdScreen";
import PractiseCatchxamByIdScreen from "../screens/practises/PractiseCatchxamByIdScreen";
import PractiseStandardExamByIdScreen from "../screens/practises/PractiseStandardExamByIdScreen";
import ForgotPasswordScreen from "../screens/forgot-password/ForgotPasswordScreen";
import CheckContestScreen from "../screens/contests/CheckContestScreen";
import ContestScreen from "../screens/contests/ContestScreen";
import { useAuth } from "../context/UserContextProvider";
import EtudeListScreen from "../screens/etudes/EtudeListScreen";
import ReportListScreen from "../screens/reports/ReportListScreen";
import PlanListScreen from "../screens/plans/PlanListScreen";
import BaseLoading from "../components/loading/BaseLoading";
import LoginWithK12Screen from "../screens/login/LoginWithK12Screen";
import PractiseApplicationActivityByIdScreen from "../screens/practises/PractiseApplicationActivityByIdScreen";
import ActivityPreviewByIdActivityTemplateIdScreen from "../screens/preview/ActivityPreviewByIdActivityTemplateIdScreen";
import HomeworkScreen from "../screens/homeworks/HomeworkScreen";
import SolveActivityScreen from "../screens/solve/SolveActivityScreen";
import UserProfileScreen from "../screens/profile/UserProfileScreen";

const retrieveLandingRouterOptionList = () => {
  return [
    {
      path: "/",
      element: <LandingScreen />,
    },
    {
      path: "/login",
      element: <LoginScreen />,
    },
    {
      path: "/login/with-k12",
      element: <LoginWithK12Screen />,
    },
    {
      path: "/register",
      element: <RegisterScreen />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordScreen />,
    },
    {
      path: "/network-error",
      element: <NetworkErrorScreen />,
    },
    {
      path: "/preview/activity/by-data/:base64Data",
      element: <ActivityPreviewByDataScreen />,
    },
    {
      path: "/preview/activity/by-learner-data/:base64Data",
      element: <ActivityPreviewByLearnerDataScreen />,
    },
    {
      path: "/preview/activity/:id/:base64Data",
      element: <ActivityPreviewByIdScreen />,
    },
    {
      path: "/preview/activity/:id/:templateId/:base64Data",
      element: <ActivityPreviewByIdActivityTemplateIdScreen />,
    },
    {
      path: "/preview/catchtivity/:id/:base64Data",
      element: <CatchtivityPreviewByIdScreen />,
    },
    {
      path: "/preview/catchxam/:id/:base64Data",
      element: <CatchxamPreviewByIdScreen />,
    },
    {
      path: "/preview/standard-exam/:id/:base64Data",
      element: <StandardExamPreviewByIdScreen />,
    },
    {
      path: "/upload/file-to-application/:base64Data",
      element: <FileToAnyApplicationScreen />,
    },
    {
      path: "/upload/file-to-application/success",
      element: <FileToAnyApplicationSuccessScreen />,
    },
    {
      path: "/solve/activity",
      element: <SolveActivityScreen />,
    },
    {
      path: "*",
      element: <LoginScreen />,
    },
  ];
};

const retrieveDashboardRouterOptionList = () => {
  return [
    {
      path: "/",
      element: <HomeworkScreen />,
      errorElement: <ErrorScreen />,
    },
    {
      path: "/network-error",
      element: <NetworkErrorScreen />,
    },
    {
      path: "/permission-error",
      element: <PermissionErrorScreen />,
    },
    {
      path: "/dashboard",
      element: <DashboardScreen />,
    },
    {
      path: "/profile",
      element: <UserProfileScreen />,
    },
    {
      path: "/homeworks",
      element: <HomeworkScreen />,
    },
    {
      path: "/catchtivities/applications/:catchtivityApplicationId",
      element: <CatchtivityApplicationScreen />,
    },
    {
      path: "/catchxams/applications/:catchxamApplicationId",
      element: <CatchxamApplicationScreen />,
    },
    {
      path: "/standard-exams/applications/:standardExamApplicationId",
      element: <StandardExamApplicationScreen />,
    },
    {
      path: "/etudes",
      element: <EtudeListScreen />,
    },
    {
      path: "/etudes/applications/:etudeApplicationId",
      element: <EtudeApplicationScreen />,
    },
    {
      path: "/practise/catchtivities/:catchtivityId",
      element: <PractiseCatchtivityByIdScreen />,
    },
    {
      path: "/practise/catchxams/:catchxamId",
      element: <PractiseCatchxamByIdScreen />,
    },
    {
      path: "/practise/standard-exams/:standardExamId",
      element: <PractiseStandardExamByIdScreen />,
    },
    {
      path: "/practise/catchtivity-applications/:catchtivityApplicationId/activities/:activityId",
      element: <PractiseApplicationActivityByIdScreen />,
    },
    {
      path: "/practise/catchxam-applications/:catchxamApplicationId/activities/:activityId",
      element: <PractiseApplicationActivityByIdScreen />,
    },
    {
      path: "/practise/standard-exam-applications/:standardExamApplicationId/activities/:activityId",
      element: <PractiseApplicationActivityByIdScreen />,
    },
    {
      path: "/reports/catchxams/:catchxamReportId",
      element: <CatchxamReportScreen />,
    },
    {
      path: "/preview/activity/by-data/:base64Data",
      element: <ActivityPreviewByDataScreen />,
    },
    {
      path: "/preview/activity/by-learner-data/:base64Data",
      element: <ActivityPreviewByLearnerDataScreen />,
    },
    {
      path: "/preview/activity/:id/:base64Data",
      element: <ActivityPreviewByIdScreen />,
    },
    {
      path: "/preview/catchtivity/:id/:base64Data",
      element: <CatchtivityPreviewByIdScreen />,
    },
    {
      path: "/preview/catchxam/:id/:base64Data",
      element: <CatchxamPreviewByIdScreen />,
    },
    {
      path: "/preview/standard-exam/:id/:base64Data",
      element: <StandardExamPreviewByIdScreen />,
    },
    {
      path: "/upload/file-to-application/:base64Data",
      element: <FileToAnyApplicationScreen />,
    },
    {
      path: "/upload/file-to-application/success",
      element: <FileToAnyApplicationSuccessScreen />,
    },
    {
      path: "/solve/activity",
      element: <SolveActivityScreen />,
    },
    {
      path: "/reports",
      element: <ReportListScreen />,
    },
    {
      path: "/plans",
      element: <PlanListScreen />,
    },
    {
      path: "/announcements",
      element: <AnnouncementListScreen />,
    },
    {
      path: "/practise",
      element: <PractiseNowScreen />,
    },
    {
      path: "/contests",
      element: <CheckContestScreen />,
    },
    {
      path: "/contests/:contestId",
      element: <ContestScreen />,
    },
  ];
};

const BaseRouterProvider = () => {
  const { boarding, isFullScreen } = useApp();
  const { userProfile } = useAuth();
  const { pathname } = window.location;

  const RenderMainRouterContent = () => {
    if (boarding === "LANDING" || boarding === "DASHBOARD") {
      return (
        <Router>
          <div
            id="container"
            key={`${boarding}-${userProfile?.id}`}
            className="flex-1 flex flex-col"
          >
            <div className="flex flex-row">
              {isFullScreen ? null : (
                <div className={`h-screen`}>
                  {pathname.startsWith("/upload") ? null : (
                    <MainLeftNavigation />
                  )}
                </div>
              )}
              <div className={`${isFullScreen ? "" : ""} flex-1`}>
                <div className={`${isFullScreen ? "" : "px-5"} h-full`}>
                  <div className="w-full h-screen no-scrollbar overflow-y-auto">
                    <div
                      className={`${
                        userProfile ? "mx-auto container" : ""
                      } h-full`}
                    >
                      <Routes>
                        {boarding === "LANDING"
                          ? retrieveLandingRouterOptionList().map(
                              (routerOption, index) => (
                                <Route
                                  key={index}
                                  path={routerOption.path}
                                  errorElement={routerOption.errorElement}
                                  element={routerOption.element}
                                />
                              )
                            )
                          : retrieveDashboardRouterOptionList().map(
                              (routerOption, index) => (
                                <Route
                                  key={index}
                                  path={routerOption.path}
                                  errorElement={
                                    routerOption.errorElement
                                      ? routerOption.errorElement
                                      : null
                                  }
                                  element={routerOption.element}
                                />
                              )
                            )}
                      </Routes>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
      );
    } else if (boarding === "NETWORK_ERROR") {
      return (
        <div className="h-screen flex items-center justify-center">
          <NetworkErrorScreen />;
        </div>
      );
    } else if (boarding === "PROFILE_SELECTION_REQUIRED") {
      return (
        <div className="w-full h-screen no-scrollbar overflow-y-auto">
          <div className="h-full container m-auto">
            <ProfileSelectionScreen />
          </div>
        </div>
      );
    } else if (boarding === "ACCOUNT_ACTIVATION_REQUIRED") {
      return (
        <div className="h-screen flex items-center justify-center">
          <AccountActivationErrorScreen />
        </div>
      );
    } else if (boarding === "TOKEN_ERROR") {
      return (
        <Router>
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-row">
              {isFullScreen ? null : (
                <div className="h-screen">
                  <MainLeftNavigation />
                </div>
              )}
              <div className="w-full h-screen overflow-y-auto">
                <div className="h-screen container mx-auto">
                  <Routes>
                    <Route
                      path="/"
                      errorElement={<ErrorScreen />}
                      element={<TokenErrorScreen />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </Router>
      );
    } else {
      return (
        <div className="h-screen flex items-center justify-center">
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        </div>
      );
    }
  };

  return RenderMainRouterContent();
};

export default BaseRouterProvider;
