import {
  parseBodyMapFromData,
  parseContentMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import VerticalDividerLine from "../divider/VerticalDividerLine";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import TrueFalseActivityMaterialContent from "./material-content/TrueFalseActivityMaterialContent";

const TrueFalseActivityContent = (props) => {
  const {
    answer,
    data,
    canAnswerQuestion,
    changeAnswer,
    isPreview,
    showCorrectAnswer,
  } = props;
  const contentMap = parseContentMapFromData(data);
  const trueFalseBodyMap = parseBodyMapFromData(data, "TRUE_FALSE");
  const trueFalseMaterialMap = parseMaterialMapFromData(data, "TRUE_FALSE");

  const retrieveCurrentAnswerMap = () => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "TRUE_FALSE"
    );
    return answer.data[foundIndex].answerMap;
  };

  const handleTrueFalseAnswerOnChange = (answer, key, value) => {
    const answerMap = retrieveCurrentAnswerMap();
    if (key === "TRUE") {
      const foundFalseIndex = answerMap.falseList.findIndex(
        (item) => item === value
      );
      if (foundFalseIndex !== -1) {
        answerMap.falseList.splice(foundFalseIndex, 1);
      }
      const foundTrueIndex = answerMap.falseList.findIndex(
        (item) => item === value
      );
      if (foundTrueIndex === -1) {
        answerMap.trueList.push(value);
      }
    } else if (key === "FALSE") {
      const foundTrueIndex = answerMap.trueList.findIndex(
        (item) => item === value
      );
      if (foundTrueIndex !== -1) {
        answerMap.trueList.splice(foundTrueIndex, 1);
      }
      const foundFalseIndex = answerMap.falseList.findIndex(
        (item) => item === value
      );
      if (foundFalseIndex === -1) {
        answerMap.falseList.push(value);
      }
    }
    changeAnswer(answer);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full md:w-[40%]">
        <ActivityBodyContent
          bodyMap={trueFalseBodyMap}
          templateType={"GROUPING"}
        />
      </div>

      <div className="contents md:hidden">
        <DividerLine />
      </div>
      <div className="hidden md:block">
        <VerticalDividerLine />
      </div>
      <div className="w-full md:flex-1">
        <TrueFalseActivityMaterialContent
          uniqueValue={JSON.stringify(data.contentMap)}
          answer={answer}
          materialMap={trueFalseMaterialMap}
          contentMap={contentMap}
          checkCanAnswerQuestion={canAnswerQuestion}
          onChange={handleTrueFalseAnswerOnChange}
          isPreview={isPreview}
          showCorrectAnswer={showCorrectAnswer}
        />
      </div>
    </div>
  );
};

export default TrueFalseActivityContent;
