import { useState } from "react";
import { Document, Page } from "react-pdf";
import BaseImage from "../images/BaseImage";

const PDF = (props) => {
  const { file } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const handleOnDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(1);
    setNumberOfPages(numPages);
  };

  return (
    <Document file={file} onLoadSuccess={handleOnDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} />
      <div className="flex flex-row items-center justify-center">
        {pageNumber === 1 ? null : (
          <div className="px-2">
            <div className="cursor-pointer">
              <BaseImage
                alt="arrow-left"
                src="/icons/arrow-left.png"
                size="small"
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
              />
            </div>
          </div>
        )}
        {Array.from(Array(numberOfPages).keys())
          .filter((index) => index < pageNumber + 5)
          .filter((index) => index > pageNumber - 5)
          .map((index) => (
            <div key={index} className="px-2">
              <p
                className={`${
                  pageNumber === index + 1 ? "text-2xl" : "text-md"
                } cursor-pointer`}
                onClick={() => {
                  setPageNumber(index + 1);
                }}
              >
                {index + 1}
              </p>
            </div>
          ))}
        {numberOfPages === 0 || pageNumber === numberOfPages ? null : (
          <div className="px-2">
            <div className="cursor-pointer">
              <BaseImage
                src="/icons/arrow-right.png"
                alt="arrow-right"
                size="small"
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Document>
  );
};

export default PDF;
