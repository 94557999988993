export const convertDataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const retrieveDocumentTypeFromAcceptedFormat = (format) => {
  if (format === "application/pdf") {
    return "PDF";
  } else if (
    format === "image/jpeg" ||
    format === "image/png" ||
    format === "image/jpg"
  ) {
    return "IMAGE";
  } else if (format === "audio/mp3") {
    return "AUDIO";
  }
};

export const retrieveDocumentTypeFromExtension = (format) => {
  if (format === "pdf") {
    return "PDF";
  } else if (format === "jpeg" || format === "png" || format === "jpg") {
    return "IMAGE";
  } else if (format === "mp3") {
    return "AUDIO";
  }
};
