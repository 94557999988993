import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
  });
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      const container = document.getElementById("container");
      if (!container) return;
      const boundingClientRect = container.getBoundingClientRect();
      setContainerSize({
        width: boundingClientRect.width,
        height: boundingClientRect.height,
      });
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { screenSize, containerSize };
};

export default useScreenSize;
