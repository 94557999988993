import { useEffect, useRef, useState } from "react";
import BaseImage from "../images/BaseImage";
import useScreenSize from "../../hooks/useScreenSize";

const MascotFull = () => {
  const imageRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [left, setLeft] = useState(0);
  const { screenSize } = useScreenSize();

  useEffect(() => {
    if (!imageRef) return;
    if (!imageRef.current) return;
    const { offsetWidth, offsetHeight } = imageRef.current;
    if (offsetWidth > offsetHeight) {
      setHeight(offsetHeight);
      setLeft((offsetWidth - offsetHeight) / 2);
    } else {
      setHeight(offsetWidth);
    }
  }, [imageRef, screenSize]);

  return (
    <div ref={imageRef} className="h-full relative">
      <div className="absolute" style={{ height, width: height, left }}>
        <BaseImage
          src="/mascots/mascot-full.png"
          alt="mascot"
          size="custom"
          className="w-full h-full"
        />
      </div>
      <BaseImage
        src="mascots/mascot-full-background.png"
        alt="mascot"
        size="custom"
        className="w-full h-full"
      />
    </div>
  );
};

export default MascotFull;
