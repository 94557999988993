const BlueVerticalDividerLine = (props) => {
  const { opacity } = props;
  return (
    <div
      className={`w-[2px] h-[40px] my-4 bg-catchup-blue ${
        opacity === "medium" ? "opacity-50" : ""
      }`}
    />
  );
};

export default BlueVerticalDividerLine;
