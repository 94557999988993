import axios from "axios";
import { retrieveAccessTokenFromRefreshTokenWithExpiration } from "../requests/ManagementRequests";

let basicRequestInterceptor = null;
let basicResponseInterceptor = null;

export const setAxiosInterceptor = async (accessToken) => {
  basicRequestInterceptor = axios.interceptors.request.use(
    async (config) => {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  basicResponseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        const userProfileId = localStorage.getItem("userProfileId");
        const { data, err } =
          await retrieveAccessTokenFromRefreshTokenWithExpiration(
            refreshToken,
            userProfileId
          );
        if (err) {
          if (err?.response?.status === 423) {
            axios.interceptors.request.eject(basicRequestInterceptor);
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            window.location.href = "/";
          }
        } else {
          axios.interceptors.request.eject(basicRequestInterceptor);
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${data.accessToken}`;
          basicRequestInterceptor = axios.interceptors.request.use(
            (config) => {
              config.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${data.accessToken}`,
              };
              return config;
            },
            (error) => {
              Promise.reject(error);
            }
          );
          window.location.href = window.location.pathname;
        }

        // setTimeout(() => {
        //   return axios.request(originalRequest);
        // }, 500);
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export const removeAxiosInterceptor = () => {
  axios.interceptors.request.eject(basicRequestInterceptor);
  basicRequestInterceptor = null;
  axios.interceptors.request.eject(basicResponseInterceptor);
  basicResponseInterceptor = null;
};
