import { useAuth } from "../../context/UserContextProvider";
import CatchtivityApplicationCard from "../cards/CatchtivityApplicationCard";
import CatchxamApplicationCard from "../cards/CatchxamApplicationCard";
import StandardExamApplicationCard from "../cards/StandardExamApplicationCard";
import { useState } from "react";
import i18n from "../../language/i18n";
import SelectionTab from "../tabs/SelectionTab";
import BaseLoading from "../loading/BaseLoading";

const HomeworkApplicationListView = () => {
  const {
    activeContentList,
    catchxamApplicationListLoading,
    catchtivityApplicationListLoading,
    standardExamApplicationListLoading,
    language,
  } = useAuth();

  const [selectedId, setSelectedId] = useState("ALL");

  const filterActiveContentList = () => {
    if (selectedId === "ALL") {
      return activeContentList;
    } else {
      return activeContentList.filter(
        (content) => content.completionStatus === selectedId
      );
    }
  };

  const ongoingContentList = activeContentList.filter(
    (content) => content.completionStatus === "ON_GOING"
  );
  const notStartedContentList = activeContentList.filter(
    (content) => content.completionStatus === "NOT_STARTED"
  );
  const completedContentList = activeContentList.filter(
    (content) => content.completionStatus === "COMPLETED"
  );

  const filteredActiveContentList = filterActiveContentList();

  return (
    <div className="h-full flex-1 flex flex-col justify-center">
      <div className="flex-1 flex flex-col">
        {catchtivityApplicationListLoading ||
        catchxamApplicationListLoading ||
        standardExamApplicationListLoading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <div className="w-full">
            <SelectionTab
              optionList={[
                {
                  id: "ALL",
                  title: `${i18n.t("all_homeworks")} (${
                    activeContentList.length
                  })`,
                },
                {
                  id: "ON_GOING",
                  title: `${i18n.t("on_going_homeworks")} (${
                    ongoingContentList.length
                  })`,
                },
                {
                  id: "NOT_STARTED",
                  title: `${i18n.t("not_started_homeworks")} (${
                    notStartedContentList.length
                  })`,
                },
                {
                  id: "COMPLETED",
                  title: `${i18n.t("completed_homeworks")} (${
                    completedContentList.length
                  })`,
                },
              ]}
              selectedId={selectedId}
              handleSelectOnClick={(itemId) => {
                setSelectedId(itemId);
              }}
            />
            <div className="flex flex-row flex-wrap">
              {filteredActiveContentList.map((sortedWork) => (
                <div
                  className="w-full lg:w-1/2 xl:w-1/3 2xl:w-1/4"
                  key={sortedWork.id}
                >
                  {sortedWork.type === "CATCHTIVITY" ? (
                    <CatchtivityApplicationCard
                      catchtivityApplicationDTO={sortedWork}
                      language={language}
                    />
                  ) : sortedWork.type === "CATCHXAM" ? (
                    <CatchxamApplicationCard
                      catchxamApplicationDTO={sortedWork}
                      language={language}
                    />
                  ) : sortedWork.type === "STANDARD_EXAM" ? (
                    <StandardExamApplicationCard
                      standardExamApplicationDTO={sortedWork}
                      language={language}
                    />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// RenderSingleCatchtivityApplicationItem(sortedWork)

export default HomeworkApplicationListView;
