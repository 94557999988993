import { useEffect, useRef, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import useScreenSize from "../../hooks/useScreenSize";

const ScreenCard = (props) => {
  const { isFullScreen } = useApp();
  const { bgColor, opacity } = props;
  const ref = useRef();
  const { screenSize } = useScreenSize();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!ref) return;
    if (!ref.current) return;
    setHeight(ref.current.offsetHeight);
  }, [ref, screenSize]);

  return (
    <div
      ref={ref}
      className={`min-h-full ${bgColor ? bgColor : ""} ${
        opacity ? opacity : "opacity-100"
      } rounded-catchup-xlarge w-full border-catchup-gray-50 bg-catchup-white ${
        isFullScreen ? "shadow-full-card" : "border shadow-card"
      }`}
    >
      <div className="flex flex-col mx-auto p-6" style={{ height }}>
        {props.children}
      </div>
    </div>
  );
};

export default ScreenCard;
