import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import SingleProfileItem from "../../components/user-profiles/SingleProfileItem";
import i18n from "../../language/i18n";
import { useApp } from "../../context/AppContextProvider";
import { NotificationManager } from "react-notifications";
import {
  patchUserOnlyRequiredAction,
  retrieveProfileTokenInformation,
} from "../../requests/ManagementRequests";
import BaseImage from "../../components/images/BaseImage";
import FullCard from "../../components/cards/FullCard";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import RightsReserved from "../../components/landing/RightsReserved";

const ProfileSelectionScreen = () => {
  const { setBoarding, isFullScreen, setIsFullScreen } = useApp();
  const {
    handleStateLogin,
    handleStateLogout,
    handleStateChooseProfile,
    userInformation,
    userProfileSet,
    userProfile,
  } = useAuth();
  const [loadingProfileId, setLoadingProfileId] = useState(null);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  const filterUserProfileSet = () => {
    return userProfileSet
      .filter((userProfileDTO) => userProfileDTO.role === "LEARNER")
      .filter(
        (userProfileDTO) =>
          userProfileDTO.seasonDTO &&
          userProfileDTO.seasonDTO.beginAt < new Date().getTime() &&
          userProfileDTO.seasonDTO.endAt > new Date().getTime()
      );
  };

  const handleOnClick = async (selectedUserProfile) => {
    if (
      selectedUserProfile.role === "LEARNER" ||
      selectedUserProfile.role === "PARENT"
    ) {
      setLoadingProfileId(selectedUserProfile.id);
      const { branchDTOList } = selectedUserProfile;
      if (branchDTOList.length === 0) {
        NotificationManager.error(i18n.t("you_have_not_assigned_any_branches"));
        return;
      }
      const branchDTO = branchDTOList[0];
      handleStateChooseProfile(
        selectedUserProfile,
        branchDTO.gradeDTO.seasonDTO,
        branchDTO.gradeDTO,
        branchDTO
      );
      localStorage.setItem("appState", "CHANGE_PROFILE");

      const refreshToken = localStorage.getItem("refreshToken");
      const { data } = await retrieveProfileTokenInformation(
        selectedUserProfile.id,
        refreshToken
      );
      const { accessToken } = data;
      handleStateLogin(accessToken, refreshToken);
      setLoadingProfileId(null);
      if (
        userInformation.requiredAction === "PROFILE_SELECTION" ||
        userInformation.requiredAction === "LOGOUT"
      ) {
        handlePatchUserRequiredActionOnClick();
      }
      setBoarding("DASHBOARD");
    }
  };

  const handlePatchUserRequiredActionOnClick = async () => {
    await patchUserOnlyRequiredAction({
      id: userInformation.id,
      requiredAction: null,
    });
  };

  const handleLogoutOnClick = () => {
    setBoarding("LANDING");
    handleStateLogout();
    NotificationManager.success(i18n.t("logout_successful_text"));
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        <div className="py-5">
          <div className="flex flex-col items-center justify-center">
            <BaseImage
              size="custom"
              className="h-catchup-large-logo w-catchup-large-logo"
              src="/logos/logo-primary.png"
              alt="main-logo"
            />
          </div>
          <div className="flex flex-col items-center justify-center my-8">
            <p className="text-xl text-catchup-gray">
              {i18n.t("select_profile_text")}
            </p>
          </div>
          {filteredUserProfileSet.length > 0 ? (
            <div>
              <div className="flex flex-row justify-center flex-wrap my-5">
                {filteredUserProfileSet.map((userProfileDTO) => (
                  <div
                    key={userProfileDTO.id}
                    className="w-1/5 min-w-[250px] my-2"
                  >
                    <SingleProfileItem
                      userProfile={userProfileDTO}
                      selectedUserProfileId={userProfile?.id}
                      type={i18n.t(userProfileDTO.role)}
                      onClick={() => {
                        handleOnClick(userProfileDTO);
                      }}
                      selectedProfileId={loadingProfileId}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="h-48 flex flex-col justify-center items-center m-auto border-2 border-catchup-blue rounded-catchup-xlarge">
              <p className="px-4">
                {i18n.t("you_do_not_have_any_user_profiles_yet")}
              </p>
            </div>
          )}

          {filteredUserProfileSet.length === 0 ? (
            <div className="flex flex-col justify-center items-center">
              <div className="my-3">
                <SecondaryButton
                  title={i18n.t("logout")}
                  size="small"
                  onClick={handleLogoutOnClick}
                />
              </div>
            </div>
          ) : null}

          <div className="flex flex-col justify-center items-center mt-8">
            <RightsReserved />
          </div>
        </div>
      </FullCard>
    );
  };

  const filteredUserProfileSet = filterUserProfileSet();

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ProfileSelectionScreen;
