import BaseImage from "../images/BaseImage";

const SingleInformationIconTextItem = (value) => {
  return (
    <div className="w-full flex flex-row items-center gap-x-2 my-2">
      <BaseImage src="/icons/info.png" alt="info" size="small" />
      <div className="flex-1">
        <p className="">{value}</p>
      </div>
    </div>
  );
};

export default SingleInformationIconTextItem;
