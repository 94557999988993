const FullCard = (props) => {
  const { backgroundColor, borderColor, opacity } = props;
  return (
    <div
      className={`min-h-full 
        ${backgroundColor ? backgroundColor : "bg-catchup-white"} 
        ${borderColor ? borderColor : "border-catchup-gray-50"} 
        ${opacity ? opacity : "opacity-100"} 
        rounded-catchup-xlarge w-full `}
    >
      <div className="flex flex-col mx-auto p-6 h-full">{props.children}</div>
    </div>
  );
};

export default FullCard;
