import { useEffect, useRef, useState } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useApp } from "./context/AppContextProvider";
import { useAuth } from "./context/UserContextProvider";
import i18n from "./language/i18n";
import {
  checkWrongActivityByParams,
  queryActivityReviewListByUserIdAndUserProfileId,
  queryCatchtivityApplicationListBySeasonIdAndGradeBranchMapList,
  queryCatchxamApplicationListBySeasonIdAndGradeBranchMapList,
  queryContestListByParams,
  queryEtudeDTOListByUserIdAndUserProfileId,
  queryStandardExamApplicationListBySeasonIdAndGradeBranchMapList,
  retrievePerformanceCatchxamApplication,
} from "./requests/CatchtivityRequests";
import {
  retrieveAccessTokenFromRefreshToken,
  retrieveUserInformationFromToken,
  retrieveUserProfileListFromToken,
} from "./requests/ManagementRequests";
import { retrieveLearnerNumberOfUnReadAnnouncements } from "./requests/NotificationRequests";
import {
  removeAxiosInterceptor,
  setAxiosInterceptor,
} from "./utilization/AxiosUtilization";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import useTabActive from "./hooks/useTabActive";
import endpoints from "./config/endpoints.json";
import connection_production from "./config/connection.json";
import connection_development from "./config/connection_local.json";
import { queryCategoryListByVersionCodeList } from "./requests/CategoryRequests";
import {
  filterCategoryVersionCodeOptionListByGradeDTO,
  retrieveCategoryVersionCodeOptionList,
} from "./utilization/CategoryUtilization";
import {
  queryCatchxamReportDTOByParams,
  queryLearnerCatchxamReportDTOByParams,
} from "./requests/ReportRequets";
import { queryIndividualModalByParams } from "./requests/AIModelConstructorRequests";
import {
  constructBaseNumericIndividualModel,
  constructBaseVerbalIndvidualModel,
} from "./utilization/IndividualModelUtilization";
import {
  calculateEndDateOfWeek,
  calculateStartDateOfWeek,
} from "./utilization/DateUtilization";
import { retrieveDistintCoterieTypeFromCatchtivityApplicationDTO } from "./utilization/CatchtivityUtilization";
import {
  queryEarnedBadgeDTOListByParams,
  queryEarnedPointDTOListByParams,
  queryPersonalStatusByParams,
} from "./requests/GamificationRequests";
import { useMutationObserver } from "./hooks/useMutationObserver";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const App = (props) => {
  const {
    boarding,
    setBoarding,
    theme,
    setTheme,
    managementStompClient,
    mainLeftNavigationState,
    setManagementStompClient,
    storageStompClient,
    setStorageStompClient,
    setMainLeftNavigationState,
  } = useApp();
  const {
    handleStateLogin,
    handleStateLogout,
    handleStateChooseProfile,
    handleStateChangeProfile,
    language,
    setLanguage,
    userInformation,
    userProfile,
    userProfileGrade,
    userProfileBranch,
    userProfileSeason,
    setCategorySet,
    catchtivityApplicationDTOList,
    catchxamApplicationDTOList,
    standardExamApplicationDTOList,
    etudeDTOList,
    setCatchtivityApplicationDTOList,
    setCatchtivityApplicationListLoading,
    setCatchxamApplicationDTOList,
    setCatchxamApplicationListLoading,
    setStandardExamApplicationDTOList,
    setStandardExamApplicationListLoading,
    setActiveContentList,
    setPassiveContentList,
    setEtudeDTOList,
    setEtudeListLoading,
    setActiveEtudeList,
    setPassiveEtudeList,
    setLearnerPerformanceLoading,
    setLearnerPerformance,
    setLearnerCatchxamReportListLoading,
    setLearnerCatchxamReportList,
    setReportDTOList,
    setReportListLoading,
    setIndividualModelList,
    setIndividualModelLoading,
    setNumberOfUnreadAnnouncements,
    setContestList,
    setContestListLoading,
    setPersonalStatus,
    setMyWrongActivityDTOList,
    setMyWrongActivityListLoading,
    setActivityReviewDTOList,
    setActivityReviewListLoading,
    setThisWeekRankingList,
    setThisWeekRankingListLoading,
    setAllTimeRankingList,
    setAllTimeRankingListLoading,
    setEarnedBadgeDTOList,
    setEarnedBadgeDTOListLoading,
  } = useAuth();
  const mutationRef = useRef();
  const isTabActive = useTabActive();
  const [latestUpdateTime, setLatestUpdateTime] = useState(
    new Date().getTime()
  );
  const [firstLoginTime, setFirstLoginTime] = useState(new Date().getTime());
  const [managementSubscribedId, setManagementSubscribedId] = useState(null);

  useMutationObserver(mutationRef, () => {
    const elementList = document.getElementsByClassName("katex-mathml");
    for (const element of elementList) {
      element.style.display = "none";
    }
  });

  useEffect(() => {
    const handleAppStateEventListener = () => {
      const localStorageAppState = localStorage.getItem("appState");
      if (
        localStorageAppState === "LOGIN" ||
        localStorageAppState === "LOGOUT" ||
        localStorageAppState === "CHANGE_PROFILE"
      ) {
        localStorage.removeItem("appState");
        if (localStorageAppState === "CHANGE_PROFILE") {
          const userProfileId = parseFloat(
            localStorage.getItem("userProfileId")
          );
          if (userProfile) {
            if (userProfileId !== userProfile.id) {
              window.location.replace(connection.app.url);
            }
          } else {
            window.location.replace(connection.app.url);
          }
        } else {
          window.location.replace(connection.app.url);
        }
      }
    };
    window.addEventListener("storage", handleAppStateEventListener);
    return () => {
      window.removeEventListener("storage", handleAppStateEventListener);
    };
  }, [userProfile]);

  useEffect(() => {
    if (isTabActive) {
      if (boarding === "DASHBOARD") {
        if (new Date().getTime() - latestUpdateTime > 7200000) {
          retrieveAndSetAccessTokenInformationFromRefreshToken(true);
          setLatestUpdateTime(new Date().getTime());
        }
      }
    }
  }, [isTabActive]);

  useEffect(() => {
    const retrieveAndSetUserInformationAndUserProfileInformationFromToken =
      async () => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          const { data: userData } = await retrieveUserInformationFromToken(
            accessToken
          );
          if (userData.enabled) {
            const { data: userProfileSetData } =
              await retrieveUserProfileListFromToken(accessToken);
            handleStateLogin(accessToken, null, userData, userProfileSetData);
            if (userData.requiredAction === "PROFILE_SELECTION") {
              handleStateChangeProfile();
              setBoarding("PROFILE_SELECTION_REQUIRED");
              return;
            }
            const foundProfileId = localStorage.getItem("userProfileId");
            if (foundProfileId) {
              const foundUserProfile = userProfileSetData.find(
                (userProfile) => userProfile.id === parseFloat(foundProfileId)
              );
              if (foundUserProfile) {
                const seasonId = localStorage.getItem("seasonId");
                const gradeId = localStorage.getItem("gradeId");
                const branchId = localStorage.getItem("branchId");
                if (seasonId && gradeId && branchId) {
                  const { branchDTOList } = foundUserProfile;
                  const foundBranchDTO = branchDTOList.find(
                    (branchDTO) =>
                      branchDTO.id === parseFloat(branchId) &&
                      branchDTO.gradeDTO.id === parseFloat(gradeId) &&
                      branchDTO.gradeDTO.seasonDTO.id === parseFloat(seasonId)
                  );
                  if (foundBranchDTO) {
                    handleStateChooseProfile(
                      foundUserProfile,
                      foundBranchDTO.gradeDTO.seasonDTO,
                      foundBranchDTO.gradeDTO,
                      foundBranchDTO
                    );
                    setBoarding("DASHBOARD");
                    return;
                  }
                }
              }
            }
            handleStateChangeProfile();
            setBoarding("PROFILE_SELECTION_REQUIRED");
          } else {
            handleStateLogout();
            setBoarding("ACCOUNT_ACTIVATION_REQUIRED");
          }
        } else {
          handleStateLogout();
          setBoarding("LANDING");
        }
      };
    const retrieveAndSetMainLeftNavigationState = () => {
      const foundMainLeftNavigationState = localStorage.getItem(
        "mainLeftNavigationState"
      );
      if (foundMainLeftNavigationState) {
        if (
          foundMainLeftNavigationState === "FULL" ||
          foundMainLeftNavigationState === "ICON_ONLY"
        ) {
          setMainLeftNavigationState(foundMainLeftNavigationState);
        } else {
          setMainLeftNavigationState("FULL");
        }
      }
    };
    const retrieveAndSetTheme = () => {
      const foundTheme = localStorage.getItem("theme");
      setTheme(foundTheme);
    };
    const retrieveAndSetLanguage = () => {
      const foundLanguage = localStorage.getItem("language");
      if (foundLanguage) {
        setLanguage(foundLanguage);
      }
    };

    const retrieveAndSetCatchtivityApplicationList = async () => {
      setCatchtivityApplicationListLoading(true);
      const gradeBranchMapList = userProfile.branchDTOList.map((branchDTO) => ({
        gradeId: branchDTO.gradeDTO.id,
        branchIdList: userProfile.branchDTOList
          .filter(
            (innerBranchDTO) =>
              innerBranchDTO.gradeDTO.id === branchDTO.gradeDTO.id
          )
          .map((innerBranchDTO) => innerBranchDTO.id),
      }));
      const { data, err } =
        await queryCatchtivityApplicationListBySeasonIdAndGradeBranchMapList({
          userId: userInformation.id,
          userProfileId: userProfile.id,
          seasonId: userProfileSeason.id,
          gradeBranchMapList,
          deleted: false,
        });
      if (err) {
        console.log(err);
      } else {
        setCatchtivityApplicationDTOList(data.sort((a, b) => b.id - a.id));
      }
      setCatchtivityApplicationListLoading(false);
    };
    const retrieveAndSetCatchxamApplicationList = async () => {
      setCatchxamApplicationListLoading(true);
      const gradeBranchMapList = userProfile.branchDTOList.map((branchDTO) => ({
        gradeId: branchDTO.gradeDTO.id,
        branchIdList: userProfile.branchDTOList
          .filter(
            (innerBranchDTO) =>
              innerBranchDTO.gradeDTO.id === branchDTO.gradeDTO.id
          )
          .map((innerBranchDTO) => innerBranchDTO.id),
      }));
      const { data, err } =
        await queryCatchxamApplicationListBySeasonIdAndGradeBranchMapList({
          userId: userInformation.id,
          userProfileId: userProfile.id,
          seasonId: userProfileSeason.id,
          gradeBranchMapList,
          deleted: false,
        });
      if (err) {
        console.log(err);
      } else {
        setCatchxamApplicationDTOList(data.sort((a, b) => b.id - a.id));
      }
      setCatchxamApplicationListLoading(false);
    };
    const retrieveAndSetStandardExamApplicationList = async () => {
      setStandardExamApplicationListLoading(true);
      const gradeBranchMapList = userProfile.branchDTOList.map((branchDTO) => ({
        gradeId: branchDTO.gradeDTO.id,
        branchIdList: userProfile.branchDTOList
          .filter(
            (innerBranchDTO) =>
              innerBranchDTO.gradeDTO.id === branchDTO.gradeDTO.id
          )
          .map((innerBranchDTO) => innerBranchDTO.id),
      }));
      const { data, err } =
        await queryStandardExamApplicationListBySeasonIdAndGradeBranchMapList({
          userId: userInformation.id,
          userProfileId: userProfile.id,
          seasonId: userProfileSeason.id,
          gradeBranchMapList,
          deleted: false,
        });
      if (err) {
        console.log(err);
      } else {
        setStandardExamApplicationDTOList(data.sort((a, b) => b.id - a.id));
      }
      setStandardExamApplicationListLoading(false);
    };
    const retrieveAndSetEtudeDTOList = async () => {
      setEtudeListLoading(true);
      const { data, err } = await queryEtudeDTOListByUserIdAndUserProfileId({
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setEtudeDTOList(data.sort((a, b) => b.id - a.id));
      setEtudeListLoading(false);
    };
    const retrieveAndSetPerformanceCatchxamApplication = async () => {
      setLearnerPerformanceLoading(true);
      const { data, err } = await retrievePerformanceCatchxamApplication({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        seasonId: userProfileSeason.id,
        gradeId: userProfileGrade.id,
        branchId: userProfileBranch.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setLearnerPerformance(data);
      setLearnerPerformanceLoading(false);
    };
    const retrieveAndSetReportDTOList = async () => {
      setReportListLoading(true);
      const { data, err } = await queryLearnerCatchxamReportDTOByParams({
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
      } else {
        setReportDTOList(data);
      }
      setReportListLoading(false);
    };
    const retrieveAndSetIndividualModelList = async () => {
      const { data, err } = await queryIndividualModalByParams({
        userId: userProfile.userDTO.id,
      });
      if (err) {
        console.log(err);
        setIndividualModelList([
          constructBaseVerbalIndvidualModel(userInformation.id),
          constructBaseNumericIndividualModel(userInformation.id),
        ]);
        return;
      }
      if (data.length === 0) {
        setIndividualModelList([
          constructBaseVerbalIndvidualModel(userInformation.id),
          constructBaseNumericIndividualModel(userInformation.id),
        ]);
      } else {
        const currentData = [];
        const foundVerbal = data.find((item) => item.coterieField === "VERBAL");
        if (foundVerbal) {
          currentData.push(foundVerbal);
        } else {
          currentData.push(
            constructBaseVerbalIndvidualModel(userInformation.id)
          );
        }
        const foundNumeric = data.find(
          (item) => item.coterieField === "NUMERIC"
        );
        if (foundNumeric) {
          currentData.push(foundNumeric);
        } else {
          currentData.push(
            constructBaseNumericIndividualModel(userInformation.id)
          );
        }
        setIndividualModelList(currentData);
      }
      setIndividualModelLoading(false);
    };
    const retrieveAndSetNumberOfUnreadAnnouncements = async () => {
      const { data, err } = await retrieveLearnerNumberOfUnReadAnnouncements({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
        campusId: userProfileSeason.institutionDTO.campusDTO.id,
        institutionId: userProfileSeason.institutionDTO.id,
        seasonId: userProfileSeason.id,
        gradeId: userProfileGrade.id,
        branchId: userProfileBranch.id,
      });
      if (err) {
        console.log(err);
      } else {
        setNumberOfUnreadAnnouncements(data);
      }
    };
    const retrieveAndSetPersonalStatus = async () => {
      const { data, err } = await queryPersonalStatusByParams({
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setPersonalStatus(data);
    };
    const retrieveAndSetThisWeekRankingList = async () => {
      setThisWeekRankingListLoading(true);
      const { data, err } = await queryEarnedPointDTOListByParams({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        seasonId: userProfileSeason.id,
        beginAt: calculateStartDateOfWeek(new Date()).getTime(),
        endAt: calculateEndDateOfWeek(new Date()).getTime(),
      });
      if (err) {
        console.log(err);
        return;
      }
      setThisWeekRankingList(data);
      setThisWeekRankingListLoading(false);
    };
    const retrieveAndSetAllTimeRankingList = async () => {
      setAllTimeRankingListLoading(true);
      const { data, err } = await queryEarnedPointDTOListByParams({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        seasonId: userProfileSeason.id,
        beginAt: userProfileSeason.beginAt,
      });
      if (err) {
        console.log(err);
        return;
      }
      setAllTimeRankingList(data);
      setAllTimeRankingListLoading(false);
    };
    const retrieveAndSetEarnedBadgeList = async () => {
      setEarnedBadgeDTOListLoading(true);
      const { data, err } = await queryEarnedBadgeDTOListByParams({
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setEarnedBadgeDTOList(data);
      setEarnedBadgeDTOListLoading(false);
    };
    const retrieveAndSetMyWrongActivityList = async () => {
      setMyWrongActivityListLoading(true);
      const { data, err } = await checkWrongActivityByParams({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        score: 0,
      });
      if (err) {
        console.log(err);
        return;
      }
      setMyWrongActivityDTOList(data);
      setMyWrongActivityListLoading(false);
    };
    const retrieveAndSetActivityReviewList = async () => {
      setActivityReviewListLoading(true);
      const { data, err } =
        await queryActivityReviewListByUserIdAndUserProfileId({
          userId: userInformation.id,
          userProfileId: userProfile.id,
        });
      if (err) {
        console.log(err);
        return;
      }
      setActivityReviewDTOList(data);
      setActivityReviewListLoading(false);
    };

    if (boarding === "INITIALIZED") {
      removeAxiosInterceptor();
      if (window.location.pathname.startsWith("/preview/activity/")) {
        setBoarding("LANDING");
      } else {
        retrieveAndSetAccessTokenInformationFromRefreshToken(true);
      }
      retrieveAndSetMainLeftNavigationState();
      retrieveAndSetTheme();
      retrieveAndSetLanguage();
    } else if (boarding === "TOKEN_RETRIEVED") {
      retrieveAndSetUserInformationAndUserProfileInformationFromToken();
    } else if (boarding === "DASHBOARD") {
      const { pathname } = window.location;
      if (pathname.startsWith("/upload/file-to-application")) return;
      if (pathname.startsWith("/solve/activity")) return;
      retrieveAndSetCatchtivityApplicationList();
      retrieveAndSetCatchxamApplicationList();
      retrieveAndSetStandardExamApplicationList();
      retrieveAndSetPerformanceCatchxamApplication();
      retrieveAndSetEtudeDTOList();
      retrieveAndSetReportDTOList();
      retrieveAndSetIndividualModelList();
      retrieveAndSetNumberOfUnreadAnnouncements();
      retrieveAndSetPersonalStatus();
      retrieveAndSetThisWeekRankingList();
      retrieveAndSetAllTimeRankingList();
      retrieveAndSetEarnedBadgeList();
      retrieveAndSetMyWrongActivityList();
      retrieveAndSetActivityReviewList();
    }
  }, [boarding]);

  useEffect(() => {
    if (theme === "DARK") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "DARK");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "LIGHT");
    }
  }, [theme]);

  useEffect(() => {
    setLanguage(language);
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
    document.documentElement.lang = language.toLowerCase();
  }, [language]);

  useEffect(() => {
    const retrieveAndSetActivityCategoryList = async () => {
      let currentCategoryVersionCodeOptionList =
        retrieveCategoryVersionCodeOptionList();
      currentCategoryVersionCodeOptionList =
        filterCategoryVersionCodeOptionListByGradeDTO(
          currentCategoryVersionCodeOptionList,
          "MANAGEMENT",
          userProfileGrade
        );
      const versionCodeList = currentCategoryVersionCodeOptionList.map(
        (categoryVersionCode) => categoryVersionCode.value
      );
      const { data, err } = await queryCategoryListByVersionCodeList({
        versionCodeList,
      });
      if (err) {
        console.log(err);
        return;
      }
      setCategorySet(data);
    };
    if (userInformation && userProfile && boarding === "DASHBOARD") {
      retrieveAndSetActivityCategoryList();
    }
  }, [userInformation, userProfile, boarding]);

  useEffect(() => {
    const retrieveAndSetManagementStompClient = (accessToken) => {
      const newManagementSocket = SockJS(
        `${connection.base.management.url}${endpoints.management.context}${endpoints.management.ws.context}`
      );
      const newManagementStompClient = Stomp.over(newManagementSocket);
      newManagementStompClient.connect(
        {
          "X-Authorization": "Bearer " + accessToken,
          "X-Details": JSON.stringify({
            brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
            campusId: userProfileSeason.institutionDTO.campusDTO.id,
            institutionId: userProfileSeason.institutionDTO.id,
            role: "LEARNER",
          }),
        },
        () => {
          setManagementStompClient(newManagementStompClient);
        }
      );
    };
    const retrieveAndSetStorageStompClient = (accessToken) => {
      const newStorageSocket = SockJS(
        `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.ws.context}`
      );
      const newStorageStompClient = Stomp.over(newStorageSocket);
      newStorageStompClient.connect(
        {
          "X-Authorization": "Bearer " + accessToken,
        },
        () => {
          setStorageStompClient(newStorageStompClient);
        }
      );
    };

    if (userInformation && userProfile) {
      if (boarding === "DASHBOARD") {
        const accessToken = localStorage.getItem("accessToken");
        retrieveAndSetManagementStompClient(accessToken);
        retrieveAndSetStorageStompClient(accessToken);
      }
    } else {
      if (managementStompClient) {
        managementStompClient.disconnect();
        setManagementSubscribedId(null);
        setManagementStompClient(null);
      }
      if (storageStompClient) {
        storageStompClient.disconnect();
        setStorageStompClient(null);
      }
    }
  }, [userInformation, userProfile, boarding]);

  useEffect(() => {
    const retrieveAndSetContestList = async (coterieTypeList) => {
      setContestListLoading(true);
      const { data, err } = await queryContestListByParams({
        coterieTypeList,
        level: userProfileGrade.level,
        type: "QUIZ",
        frequencyType: "WEEKLY",
        userId: userInformation.id,
        userProfileId: userProfile.id,
        beginAt: calculateStartDateOfWeek(new Date()).getTime(),
        endAt: calculateEndDateOfWeek(new Date()).getTime(),
        seasonId: userProfileSeason.id,
        gradeId: userProfileGrade.id,
        branchId: userProfileBranch.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setContestListLoading(false);
      setContestList(data);
    };
    if (!userProfileGrade) return;

    const distinctCoterieTypeOptionList =
      retrieveDistintCoterieTypeFromCatchtivityApplicationDTO(
        catchtivityApplicationDTOList
      );
    if (
      distinctCoterieTypeOptionList.findIndex(
        (coterieType) => coterieType === "GENERAL_CULTURE"
      ) === -1
    ) {
      distinctCoterieTypeOptionList.push("GENERAL_CULTURE");
    }
    retrieveAndSetContestList(distinctCoterieTypeOptionList);
  }, [userProfileGrade, catchtivityApplicationDTOList]);

  useEffect(() => {
    if (!userInformation) return;
    retrieveAndSetCatchxamReportDTOList();
  }, [userInformation, catchxamApplicationDTOList]);

  useEffect(() => {
    const contentList = [
      ...catchtivityApplicationDTOList,
      ...catchxamApplicationDTOList,
      ...standardExamApplicationDTOList,
    ];
    setActiveContentList(
      contentList
        .filter((content) => content.endAt > new Date().getTime())
        .sort((a, b) => a.endAt - b.endAt)
    );
    setPassiveContentList(
      contentList
        .filter((content) => content.endAt <= new Date().getTime())
        .sort((a, b) => a.endAt - b.endAt)
    );
  }, [
    catchtivityApplicationDTOList,
    catchxamApplicationDTOList,
    standardExamApplicationDTOList,
  ]);

  useEffect(() => {
    const etudeList = [...etudeDTOList];
    setActiveEtudeList(
      etudeList.filter((work) => work.completionStatus !== "COMPLETED")
    );
    setPassiveEtudeList(
      etudeList.filter((work) => work.completionStatus === "COMPLETED")
    );
  }, [etudeDTOList]);

  useEffect(() => {
    localStorage.setItem("mainLeftNavigationState", mainLeftNavigationState);
  }, [mainLeftNavigationState]);

  useEffect(() => {
    if (managementStompClient) {
      const subscribed = managementStompClient.subscribe(
        `/user/${userInformation.username}/topic/user/required-action`,
        (message) => {
          let { body } = message;
          body = JSON.parse(body);
          if (body === "PROFILE_SELECTION") {
            setBoarding("PROFILE_SELECTION_REQUIRED");
            window.location.replace(connection.app.url);
          } else if (body === "LOGOUT") {
            handleLogoutOnClick();
          }
        }
      );
      setManagementSubscribedId(subscribed.id);
    }
  }, [managementStompClient]);

  const retrieveAndSetCatchxamReportDTOList = async () => {
    setLearnerCatchxamReportListLoading(true);
    const { data, err } = await queryCatchxamReportDTOByParams({
      userId: userInformation.id,
      userProfileId: userProfile.id,
      catchxamApplicationIdList: catchxamApplicationDTOList.map(
        (catchxamApplicationDTO) => catchxamApplicationDTO.id
      ),
    });
    if (err) {
      console.log(err);
      return;
    }
    setLearnerCatchxamReportList(data);
    setLearnerCatchxamReportListLoading(false);
  };

  const retrieveAndSetAccessTokenInformationFromRefreshToken = async (
    changeBoarding
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken) {
      removeAxiosInterceptor();
      setAxiosInterceptor(accessToken);
      const { data, err } = await retrieveAccessTokenFromRefreshToken(
        refreshToken
      );
      if (err) {
        removeAxiosInterceptor();
        setBoarding("TOKEN_ERROR");
      } else {
        removeAxiosInterceptor();
        setAxiosInterceptor(data.accessToken);
        if (err && err.code === "ERR_NETWORK") {
          setBoarding("NETWORK_ERROR");
        } else {
          if (changeBoarding) {
            setBoarding("TOKEN_RETRIEVED");
          }
        }
      }
    } else {
      setBoarding("LANDING");
    }
  };

  const handleLogoutOnClick = () => {
    setBoarding("LANDING");
    handleStateLogout();
    NotificationManager.success(i18n.t("logout_successful_text"));
  };

  const handleCheckAndRefreshToken = async () => {
    if (boarding === "DASHBOARD") {
      setLatestUpdateTime(new Date().getTime());
      if (new Date().getTime() - firstLoginTime > 7200000) {
        retrieveAndSetAccessTokenInformationFromRefreshToken(false);
        setFirstLoginTime(new Date().getTime());
      }
    }
  };

  return (
    <div
      className="h-full text-base font-quicksand text-catchup-gray-600"
      onClick={handleCheckAndRefreshToken}
      ref={mutationRef}
    >
      <NotificationContainer />
      <div className="h-full relative">
        {process.env.REACT_APP_NODE_ENV === "development" ? (
          <div className="absolute left-0 right-0 top-0 bg-catchup-red text-catchup-white flex flex-col items-center justify-center p-1 z-20">
            <p>DEVELOPMENT</p>
          </div>
        ) : null}
        {props.children}
      </div>
    </div>
  );
};

export default App;
