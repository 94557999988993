import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import ClickableHeaderItem from "./ClickableHeaderItem";
import BaseImage from "../images/BaseImage";
import BaseTitle from "../titles/BaseTitle";
import DividerLine from "../divider/DividerLine";
import GradeLabel from "../labels/GradeLabel";
import { retrieveLanguageOptionList } from "../../utilization/HeaderUtilization";

const DashboardHeader = (props) => {
  const {
    title,
    totalItemCount,
    itemName,
    handleReturnOnClick,
    level,
    durationType,
    durationInMinutes,
  } = props;
  const {
    setLanguage,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    personalStatus,
  } = useAuth();

  const retrieveFlagIcon = (type) => {
    if (type === "TR") {
      return "/icons/flags/turkish.png";
    } else if (type === "EN") {
      return "/icons/flags/english.png";
    }
  };

  const constructInformationOptionList = () => {
    const optionList = [];
    if (!userProfile) return optionList;
    if (!userProfileSeason) return optionList;
    if (userProfile.role === "LEARNER") {
      optionList.push({
        id: "campus",
        text: userProfileSeason?.institutionDTO.campusDTO.name,
        icon: <BaseImage src="/icons/campus.png" alt="campus" size="small" />,
      });
      optionList.push({
        id: "institution",
        text: userProfileSeason?.institutionDTO.name,
        icon: (
          <BaseImage
            src="/icons/institution.png"
            alt="institution"
            size="small"
          />
        ),
      });
      optionList.push({
        id: "season",
        text: userProfileSeason.name,
        icon: <BaseImage src="/icons/season.png" alt="season" size="small" />,
      });
      if (userProfileGrade) {
        optionList.push({
          id: "grade",
          text: userProfileGrade.name,
          icon: <BaseImage src="/icons/grade.png" alt="grade" size="small" />,
        });
      }
      if (userProfileBranch) {
        optionList.push({
          id: "branch",
          text: userProfileBranch.name,
          icon: <BaseImage src="/icons/branch.png" alt="branch" size="small" />,
        });
      }
    } else if (userProfile.role === "INDIVIDUAL") {
    }

    return optionList;
  };

  const handleLanguageOnChange = (value) => {
    setLanguage(value);
    window.location.reload();
  };

  const informationOptionList = constructInformationOptionList();

  return (
    <div className="min-h-catchup-header">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-x-2">
          {handleReturnOnClick ? (
            <div>
              <BaseImage
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                size="small"
                onClick={handleReturnOnClick}
              />
            </div>
          ) : null}

          <div>
            <BaseTitle
              title={title}
              totalItemCount={totalItemCount}
              itemName={itemName}
            />
          </div>

          {level ? (
            <div className="my-1">
              <div className="flex flex-row">
                <GradeLabel title={`${i18n.t("grade")}: ${level}`} />
              </div>
            </div>
          ) : null}

          {durationType ? (
            <div className="flex flex-row items-center gap-x-1 my-1">
              <BaseImage src="/icons/timer.png" size="small" alt="timer" />
              <div>
                {durationType === "NONE" ? (
                  <p className="">{i18n.t("indefinite")}</p>
                ) : (
                  <p>
                    {i18n.t(durationType)} {durationInMinutes}
                  </p>
                )}
              </div>
            </div>
          ) : null}
        </div>

        <nav className="flex flex-row justify-between items-center  h-full mx-4">
          <ul className="flex flex-row items-center gap-x-4 mr-5 lg:mr-0 ml-5 lg:ml-0 ">
            {userInformation ? (
              <li>
                <div className="flex flex-row items-center gap-x-2">
                  <div className="flex-1 flex flex-col">
                    <p>
                      {userInformation.firstName} {userInformation.lastName}
                    </p>
                    <p className="text-md">
                      {personalStatus.remainingPoints} {i18n.t("points")}
                    </p>
                  </div>
                  <div>
                    <BaseImage
                      src="/mascots/mascot-user-profile-2.png"
                      alt="mascot"
                      size="large"
                    />
                  </div>
                </div>
              </li>
            ) : null}

            <ClickableHeaderItem
              id="language"
              name={
                <div className="mr-2">
                  <BaseImage
                    size="medium"
                    src="/icons/language.png"
                    alt="language"
                  />
                </div>
              }
              type="IMAGE_DROPDOWN"
              optionList={retrieveLanguageOptionList().map((option) => ({
                ...option,
                id: option.value,
                icon: (
                  <BaseImage
                    size="custom"
                    className="h-5 w-8"
                    src={retrieveFlagIcon(option.value)}
                    alt="flag"
                  />
                ),
                onClick: () => {
                  handleLanguageOnChange(option.value);
                },
              }))}
              showText={true}
            />
            <ClickableHeaderItem
              id="information"
              name={`${userProfileSeason?.institutionDTO.campusDTO.brandDTO.name}`}
              faviconUrl={
                userProfileSeason?.institutionDTO.campusDTO.brandDTO.faviconUrl
              }
              type="SUBTEXT_DROPDOWN"
              optionList={informationOptionList}
            />
          </ul>
        </nav>
      </div>
      <DividerLine />
    </div>
  );
};

export default DashboardHeader;
