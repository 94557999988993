import { useEffect } from "react";
import FullCard from "../../components/cards/FullCard";
import EtudeApplicationListView from "../../components/etudes/EtudeApplicationListView";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import { useLocation } from "react-router-dom";
import { queryEtudeDTOListByUserIdAndUserProfileId } from "../../requests/CatchtivityRequests";
import DashboardHeader from "../../components/headers/DashboardHeader";
import i18n from "../../language/i18n";

const EtudeListScreen = () => {
  const location = useLocation();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    activeEtudeList,
    setEtudeDTOList,
    setEtudeListLoading,
  } = useAuth();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetEtudeDTOList = async () => {
      setEtudeListLoading(true);
      const { data, err } = await queryEtudeDTOListByUserIdAndUserProfileId({
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setEtudeDTOList(data.sort((a, b) => b.id - a.id));
      setEtudeListLoading(false);
    };
    if (location && location.state) {
      if (location.state.state === "FORCE_UPDATE") {
        retrieveAndSetEtudeDTOList();
      }
    }
  }, [location]);

  const RenderMainContent = () => {
    return (
      <FullCard>
        <DashboardHeader
          title={
            activeEtudeList.length === 0
              ? i18n.t("no_active_etude_applications")
              : `${i18n.t("total_active_etude_applications")} (${
                  activeEtudeList.length
                })`
          }
        />
        <EtudeApplicationListView />
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default EtudeListScreen;
