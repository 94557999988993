import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Storage files
 */
export const queryMainFileByIdList = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.context}${endpoints.storage.query["by-idList"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createOrUpdateStorageFileFromCatchtivityApplication = async (
  formData
) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.upload.file["by-catchtivityApplicationId"].context}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createOrUpdateStorageFileFromCatchtivityApplicationFromMobile =
  async (formData) => {
    try {
      const axiosInstance = axios.create();
      const response = await axiosInstance.post(
        `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.upload.file["by-catchtivityApplicationId"]["from-mobile"].context}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const createOrUpdateStorageFileFromCatchxamApplication = async (
  formData
) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.upload.file["by-catchxamApplicationId"].context}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createOrUpdateStorageFileFromCatchxamApplicationFromMobile =
  async (formData) => {
    try {
      const axiosInstance = axios.create();
      const response = await axiosInstance.post(
        `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.upload.file["by-catchxamApplicationId"]["from-mobile"].context}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return { data: response.data, status: response.status };
    } catch (err) {
      return { err };
    }
  };

export const createOrUpdateStorageFileFromEtude = async (formData) => {
  try {
    const response = await axios.post(
      `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.upload.file["by-etudeId"].context}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createOrUpdateStorageFileFromEtudeFromMobile = async (
  formData
) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${connection.base.storage.url}${endpoints.storage.learner.context}${endpoints.storage.learner.upload.file["by-etudeId"]["from-mobile"].context}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
