import i18n from "../../../language/i18n";

const ActivityEmptyContent = () => {
  return (
    <div className="flex flex-col items-center justify-center border-2 border-catchup-orange rounded-catchup-xlarge px-5 py-2 my-5 bg-catchup-orange">
      <div className="">
        <p className="text-catchup-white text-xl">
          {i18n.t("you_have_set_this_activity_as_empty")}
        </p>
      </div>
    </div>
  );
};

export default ActivityEmptyContent;
