import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import BaseImage from "../../images/BaseImage";

const ShowMaterialMediaByContentType = (props) => {
  const { uniqueValue, contentType, src, canFullScreen } = props;
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedFullScreenItem, setSelectedFullScreenItem] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullHeight, setIsFullHeight] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    setIsLoaded(false);
  }, []);

  useEffect(() => {
    if (!isLoaded) return;
    if (!imageRef) return;
    if (!imageRef.current) return;
    const w = imageRef.current.naturalWidth;
    const h = imageRef.current.naturalHeight;
    if (w >= h) {
      setIsFullHeight(false);
    } else {
      setIsFullHeight(true);
    }
  }, [isLoaded, uniqueValue]);

  const RenderShowFullScreenItem = () => {
    return (
      <Modal
        isOpen={showFullScreen}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowFullScreen(false);
          setSelectedFullScreenItem(null);
        }}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
            borderRadius: 20,
            background: "",
            border: "",
            width: "900px",
            maxHeight: "70%",
            overflow: "auto",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.6)",
          },
        }}
        contentLabel=""
      >
        <div className="flex-1 flex flex-col">
          {/* <div className="m-4"> */}
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setShowFullScreen(false);
                setSelectedFullScreenItem(null);
              }}
            />
          </div>
          <div className="flex items-center justify-center h-[500px]">
            <BaseImage
              src={selectedFullScreenItem}
              alt="selected-fullscreen-item"
              size="custom"
              className="w-full"
            />
          </div>
        </div>
      </Modal>
    );
  };

  return contentType === "IMAGE" ? (
    <div key={uniqueValue}>
      {RenderShowFullScreenItem()}
      <div className="my-2">
        <div className="h-full flex flex-row flex-wrap items-center">
          <div
            className={`${
              isFullHeight
                ? "h-catchup-activity-box-item"
                : "max-w-catchup-activity-box-item"
            }  flex flex-col justify-center items-center relative`}
          >
            <BaseImage
              ref={imageRef}
              size="custom"
              className="h-full rounded-catchup-xlarge"
              onLoad={() => {
                setIsLoaded(true);
              }}
              src={src}
            />
            {src !== null &&
            src !== "" &&
            src !== "DEFAULT_OPTION" &&
            canFullScreen ? (
              <div
                className="absolute flex items-center justify-center top-2 right-2  h-6 w-6 cursor-pointer border rounded-catchup-xlarge border-catchup-blue p-1"
                onMouseEnter={(e) => {
                  e.preventDefault();
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFullScreen(true);
                  setSelectedFullScreenItem(src);
                }}
              >
                <BaseImage
                  src="/icons/arrow-up.png"
                  alt="arrow-up"
                  size="custom"
                  className="w-full"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : contentType === "VIDEO" ? (
    <div className="my-2">
      <div className="h-full flex flex-row flex-wrap items-center">
        <div className="h-full flex flex-col justify-center items-center">
          <video
            className="h-catchup-activity-box-item rounded-catchup-xlarge"
            src={src}
            controls
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  ) : contentType === "AUDIO" ? (
    <div className="my-2">
      <div className="h-full flex flex-row flex-wrap items-center">
        <div className="h-full flex flex-col justify-center items-center">
          <audio
            className="h-catchup-activity-box-item rounded-catchup-xlarge"
            src={src}
            controls
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default ShowMaterialMediaByContentType;
