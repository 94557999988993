import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InputGroup from "../../components/groups/InputGroup";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  retrieveTokenInformation,
  retrieveUserInformationFromToken,
  retrieveUserProfileListFromToken,
} from "../../requests/ManagementRequests";
import BaseImage from "../../components/images/BaseImage";
import RightsReserved from "../../components/landing/RightsReserved";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const LoginScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setBoarding, isFullScreen, setIsFullScreen } = useApp();
  const { handleStateLogin } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMap, setErrorMap] = useState({
    username: null,
    password: null,
  });
  const K12_BASE_URL = process.env.REACT_APP_K12_BASE_URL;
  const K12_CLIENT_ID = process.env.REACT_APP_K12_CLIENT_ID;
  const K12_REDIRECT_URI = `${connection.app.url}/login/with-k12`;
  const K12_SCOPE = process.env.REACT_APP_K12_SCOPE;
  const K12_AUTH_URI =
    `${K12_BASE_URL}/GWCore.Web/connect/authorize?response_type=code&client_id=${K12_CLIENT_ID}&redirect_uri=${K12_REDIRECT_URI}&scope=${K12_SCOPE}`.replace(
      " ",
      "%20"
    );

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!state) return;
    const { action, username, password } = state;
    if (action == "LOGIN") {
      handleLogin(username, password);
    }
  }, [state]);

  const checkCommonValidation = () => {
    if (!username || !password) {
      const currentErrorMap = {
        username: null,
        password: null,
      };
      if (!username) {
        currentErrorMap.username = i18n.t("username_required_field");
      }
      if (!password) {
        currentErrorMap.password = i18n.t("password_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const handleOnChange = (type, value) => {
    if (type === "username") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: i18n.t("username_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: null,
        }));
      }
      setUsername(value);
    } else if (type === "password") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: i18n.t("password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: null,
        }));
      }
      setPassword(value);
    }
  };

  const handleLogin = async (username, password) => {
    try {
      const { data, err } = await retrieveTokenInformation({
        username,
        password,
      });
      if (err) {
        NotificationManager.error(i18n.t("invalid_user_credentials_text"));
        return;
      }
      const { accessToken, refreshToken } = data;
      const { data: userData, err: userErr } =
        await retrieveUserInformationFromToken(accessToken);
      if (userErr) {
        console.log(userErr);
        return;
      }
      if (userData.enabled) {
        const { data: userProfileSetData } =
          await retrieveUserProfileListFromToken(accessToken);
        handleStateLogin(
          accessToken,
          refreshToken,
          userData,
          userProfileSetData
        );
        navigate("/");
        setBoarding("PROFILE_SELECTION_REQUIRED");
        NotificationManager.success(i18n.t("login_successful_text"));
      } else {
        navigate("/");
        setBoarding("ACCOUNT_ACTIVATION_REQUIRED");
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const handleLoginOnClick = async () => {
    if (checkCommonValidation()) {
      await handleLogin(username, password);
      localStorage.setItem("appState", "LOGIN");
    }
  };

  const handleLoginWithK12OnClick = () => {
    window.location.href = K12_AUTH_URI;
  };

  const handleOnKeyDownOnClick = async (e) => {
    if (e.key === "Enter") {
      handleLoginOnClick();
    }
  };

  return (
    <div className="h-full flex flex-col justify-center items-center bg-catchup-blue-800 bg-catchup-pattern bg-cover object-scale-down z-0">
      <div className="lg:h-[548px] w-[400px] md:w-[600px] lg:w-[1000px] bg-catchup-white border border-catchup-gray-50 rounded-catchup-great shadow-login z-10">
        <div className="h-full flex-1 flex flex-col lg:flex-row items-center justify-center relative flex-wrap p-10">
          <div className="flex-1 flex flex-col items-center">
            <div className="flex flex-row items-center justify-center lg:mb-20">
              <BaseImage
                src="/logos/logo-primary.png"
                alt="logo"
                size="custom"
                className="w-64"
              />
            </div>
            <div className="lg:absolute lg:bottom-0 lg:left-[138px]">
              <BaseImage
                src="/mascots/mascot-base.png"
                alt="mascot"
                size="custom"
                heightClassName="w-56 h-56"
              />
            </div>
          </div>
          <div className="hidden lg:block h-full bg-catchup-gray-50 w-[1px] mx-5" />
          <div className="flex-1 w-full">
            <div className="flex flex-col h-full">
              <div className="my-4">
                <InputGroup
                  type="text"
                  title={i18n.t("username")}
                  placeholder={i18n.t("username")}
                  value={username}
                  onChange={(e) => {
                    handleOnChange("username", e.target.value.trim());
                  }}
                  errorText={errorMap["username"]}
                  onKeyDown={handleOnKeyDownOnClick}
                />
                <InputGroup
                  type="password"
                  title={i18n.t("password")}
                  placeholder={i18n.t("password")}
                  value={password}
                  onChange={(e) => {
                    handleOnChange("password", e.target.value.trim());
                  }}
                  errorText={errorMap["password"]}
                  onKeyDown={handleOnKeyDownOnClick}
                />
                <div className="flex flex-row">
                  <p
                    className="cursor-pointer font-semibold underline text-catchup-blue-500"
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                  >
                    {i18n.t("forgot_password_text")}
                  </p>
                </div>
              </div>
              <div className="mb-1 mt-2 flex flex-row justify-center">
                <PrimaryButton
                  title={i18n.t("login")}
                  size="small"
                  onClick={handleLoginOnClick}
                />
              </div>
              {/* <div className="my-1 flex flex-row justify-center">
                <p className="text-catchup-gray-400">{i18n.t("or")}</p>
              </div>
              <div className="mt-1 my-2 flex flex-row justify-center">
                <SecondaryButton
                  title={i18n.t("login_with_k12")}
                  size="unlimited"
                  onClick={handleLoginWithK12OnClick}
                />
              </div> */}
              <div className="my-2">
                <div className="flex flex-col items-center">
                  <div>
                    <p className="text-catchup-gray-400">
                      {i18n.t("to_admin_text")}
                    </p>
                  </div>
                  <div>
                    <p
                      className="cursor-pointer  text-catchup-blue-500 underline"
                      onClick={() => {
                        window.open("https://admin.catchupper.com", "_blank");
                      }}
                    >
                      {i18n.t("to_admin_cta")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="my-2">
                <RightsReserved />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
