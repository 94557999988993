import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createActivityAnswerByActivityIdEtudeId,
  evaluateEtudeById,
  patchActivityAnswerWithData,
  queryActivityAnswerListByEtudeId,
  retrieveActivityWithDataById,
  retrieveEtudeById,
} from "../../requests/CatchtivityRequests";
import { queryPlanWeekListByParams } from "../../requests/PlanRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import CatchtivityFooter from "../../components/footers/CatchtivityFooter";
import { useApp } from "../../context/AppContextProvider";
import {
  retrieveActivityAnswerFromAnswerList,
  checkIfAnswerIsEmpty,
  constructActivityAnswerMap,
  constructActivityAnswerStateList,
  findBestFitActivity,
} from "../../utilization/CatchtivityUtilization";
import OrderingActivityContent from "../../components/activities/OrderingActivityContent";
import DropdownActivityContent from "../../components/activities/DropdownActivityContent";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import MCMAActivityContent from "../../components/activities/MCMAActivityContent";
import MatchingActivityContent from "../../components/activities/MatchingActivityContent";
import GroupingActivityContent from "../../components/activities/GroupingActivityContent";
import FillInTheBlanksActivityContent from "../../components/activities/FillInTheBlanksActivityContent";
import OpenEndedActivityContent from "../../components/activities/OpenEndedActivityContent";
import BaseImage from "../../components/images/BaseImage";
import moment from "moment";
import { queryMainFileByIdList } from "../../requests/StorageRequests";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import BaseModal from "../../components/modal/BaseModal";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import ActivityEmptyContent from "../../components/activities/empty-content/ActivityEmptyContent";
import TrueFalseActivityContent from "../../components/activities/TrueFalseActivityContent";
import { queryOutcomeModalByParams } from "../../requests/AIModelConstructorRequests";
import DashboardHeader from "../../components/headers/DashboardHeader";
import ContentTransition from "../../components/transitions/ContentTransition";
import CatchtivityProgressBar from "../../components/progress-bar/CatchtivityProgressBar";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";
import ScreenCard from "../../components/cards/ScreenCard";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const EtudeApplicationScreen = () => {
  const { etudeApplicationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    individualModelList,
    individualModelLoading,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [activityNumber, setActivityNumber] = useState(null);
  const [etude, setEtude] = useState(null);
  const [activity, setActivity] = useState(null);
  const [activityAnswerDTOList, setActivityAnswerDTOList] = useState([]);
  const [activityAnswerDTOListLoading, setActivityAnswerDTOListLoading] =
    useState(true);
  const [randomActivityTemplateIndex, setRandomActivityTemplateIndex] =
    useState(0);
  const [activityLoading, setActivityLoading] = useState({
    self: false,
    answer: false,
  });
  const [planWeekList, setPlanWeekList] = useState([]);
  const [planWeekMap, setPlanWeekMap] = useState({});
  const [planWeekLoading, setPlanWeekLoading] = useState({
    planWeek: false,
    documents: false,
  });
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [outcomeModelList, setOutcomeModelList] = useState([]);
  const [outcomeModelListLoading, setOutcomeModelListLoading] = useState(false);

  useEffect(() => {
    if (pageState === 0) {
      if (isFullScreen === true) {
        setIsFullScreen(false);
      }
    } else {
      if (isFullScreen === false) {
        setIsFullScreen(true);
      }
    }
  }, [pageState]);

  useEffect(() => {
    const retrieveAndSetEtude = async () => {
      const { data, err } = await retrieveEtudeById(etudeApplicationId);
      if (err) {
        console.log(err);
      } else {
        setEtude(data);
      }
    };
    const retrieveAndSetActivityAnswerList = async () => {
      setActivityAnswerDTOListLoading(true);
      const { data, err } = await queryActivityAnswerListByEtudeId({
        etudeId: etudeApplicationId,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setActivityAnswerDTOList(data);
      setActivityAnswerDTOListLoading(false);
    };
    if (checkViewPermissionGranted("catchtivity_application")) {
      retrieveAndSetEtude();
      retrieveAndSetActivityAnswerList();
    } else {
      navigate("/permission-error");
    }
  }, [window.location]);

  useEffect(() => {
    if (!activity) return;
    if (individualModelList.length === 0) return;
    const { bestActivityTemplateList } = findBestFitActivity(
      activity,
      individualModelList,
      outcomeModelList
    );
    const currentRandomIndex = Math.floor(
      Math.random() * bestActivityTemplateList.length
    );
    const bestActivityTemplate = bestActivityTemplateList[currentRandomIndex];
    const { activityTemplateDTOList } = activity;
    setRandomActivityTemplateIndex(
      activityTemplateDTOList.findIndex(
        (activityTemplateDTO) =>
          activityTemplateDTO.type === bestActivityTemplate
      )
    );
  }, [activity, individualModelList]);

  useEffect(() => {
    if (!activity) return;
    if (activityAnswerDTOListLoading) return;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    if (!currentActivityTemplate) return;
    if (currentActivityTemplate.type === "ORDERING") {
      const { answer } = retrieveCurrentActivityAnswer();
      changeActivityAnswer(answer);
    }
  }, [activity, activityAnswerDTOListLoading]);

  useEffect(() => {
    const retrieveAndSetActivityWithData = async () => {
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: true,
      }));
      const activityDTOList = etude.activityDTOList;
      if (activityDTOList.length > 0) {
        const currentActivity = activityDTOList[activityNumber];
        const { data, err } = await retrieveActivityWithDataById(
          currentActivity.id
        );
        if (err) {
          console.log(err);
        } else {
          setActivity(data);
        }
      }
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: false,
      }));
    };
    if (etude && activityNumber === etude.activityDTOList.length) {
      setShowCompleteModal(true);
      setActivity(null);
    } else if (etude && activityNumber !== null && activityNumber >= 0) {
      setActivity(null);
      retrieveAndSetActivityWithData();
    } else {
      setActivity(null);
    }
  }, [etude, activityNumber]);

  useEffect(() => {
    if (!etude) return;
    retrieveAndSetPlanWeekList();
  }, [etude]);

  useEffect(() => {
    if (planWeekList.length === 0) return;
    retrieveAndSetPlanWeekDocuments();
  }, [planWeekList]);

  useEffect(() => {
    const retrieveAndSetOutcomeModelList = async () => {
      setOutcomeModelListLoading(true);
      const { activityDTOList } = etude;
      const categoryIdSet = new Set();
      for (const activityDTO of activityDTOList) {
        const { categoryIdList } = activityDTO;

        for (const categoryId of categoryIdList) {
          categoryIdSet.add(categoryId);
        }
      }
      const { data, err } = await queryOutcomeModalByParams({
        userId: userInformation.id,
        outcomeIdList: Array.from(categoryIdSet),
      });
      if (err) {
        console.log(err);
        return err;
      }
      setOutcomeModelList(data);
      setOutcomeModelListLoading(false);
    };
    if (!etude) return;
    retrieveAndSetOutcomeModelList();
  }, [etude]);

  const retrieveAndSetPlanWeekList = async () => {
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      planWeek: true,
    }));
    const beginAt = moment(new Date().getTime())
      .startOf("isoWeek")
      .toDate()
      .getTime();
    const endAt = moment(new Date().getTime())
      .endOf("isoWeek")
      .toDate()
      .getTime();
    let coterieType;
    if (etude.catchtivityApplicationDTO) {
      coterieType = etude.catchtivityApplicationDTO.catchtivityDTO.coterieType;
    } else if (etude.catchxamApplicationDTO) {
      coterieType = etude.catchxamApplicationDTO.catchxamDTO.coterieType;
    }
    const queryParams = {
      brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
      campusId: userProfileSeason.institutionDTO.campusDTO.id,
      institutionId: userProfileSeason.institutionDTO.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      coterieType,
      beginAt,
      endAt,
    };
    const { data, err } = await queryPlanWeekListByParams(queryParams);
    if (err) {
      console.log(err);
      return;
    }
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      planWeek: false,
    }));
    setPlanWeekList(data);
  };

  const retrieveAndSetPlanWeekDocuments = async () => {
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      documents: true,
    }));
    const planWeekMap = planWeekList.map((planWeek) => {
      const planWeekMap = {};
      const { planWeekDocumentDTOList } = planWeek;
      for (const planWeekDocument of planWeekDocumentDTOList) {
        if (planWeekMap[planWeek.id]) {
          planWeekMap[planWeek.id].push({
            documentId: planWeekDocument.documentId,
          });
        } else {
          planWeekMap[planWeek.id] = [
            { documentId: planWeekDocument.documentId },
          ];
        }
      }
      return planWeekMap;
    });
    if (Object.keys(planWeekMap).length === 0) {
      setPlanWeekLoading((prevPlanWeekLoading) => ({
        ...prevPlanWeekLoading,
        documents: false,
      }));
      return;
    }
    const planWeekPromises = planWeekMap.map(async (planWeek) => {
      if (Object.keys(planWeek).length === 0) return;
      const planWeekId = Object.keys(planWeek)[0];
      const foundPlanWeek = planWeekList.find(
        (planWeek) => planWeek.id === parseFloat(planWeekId)
      );
      const { data, err } = await queryMainFileByIdList({
        idList: planWeek[planWeekId].map((pw) => pw.documentId),
      });
      if (err) {
        console.log(err);
      } else {
        setPlanWeekMap((prevDocumentMap) => ({
          ...prevDocumentMap,
          [planWeekId]: {
            ...foundPlanWeek,
            documentList: data,
          },
        }));
      }
    });
    await Promise.all(planWeekPromises);
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      documents: false,
    }));
  };

  const retrieveCurrentActivityTemplate = () => {
    const foundActivityAnswer = activityAnswerDTOList.find(
      (activityAnswerDTO) =>
        parseFloat(activityAnswerDTO.activityDTO.id) === parseFloat(activity.id)
    );
    if (foundActivityAnswer?.data) {
      const activityAnswerData = JSON.parse(foundActivityAnswer.data);
      return activity.activityTemplateDTOList.find(
        (activityTemplateDTO) =>
          activityTemplateDTO.type === activityAnswerData[0].type
      );
    }
    const desiredActivity = activity.activityTemplateDTOList.find(
      (activityTemplateDTO) =>
        activityTemplateDTO.type === "INSERT_ANY_TEMPLATE_NAME_HERE"
    );
    if (desiredActivity) {
      return desiredActivity;
    } else {
      return activity.activityTemplateDTOList[randomActivityTemplateIndex];
    }
  };

  const retrieveCurrentActivityAnswer = () => {
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    const foundActivityAnswerDTO = activityAnswerDTOList[foundIndex];
    if (foundIndex > -1 && foundActivityAnswerDTO.data) {
      const currentAnswer = foundActivityAnswerDTO;
      return {
        index: foundIndex,
        answer: {
          ...currentAnswer,
          data: JSON.parse(currentAnswer.data),
        },
      };
    } else {
      const currentActivityTemplate = retrieveCurrentActivityTemplate();
      return {
        index: foundIndex,
        answer: {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          activityDTO: {
            id: activity.id,
          },
          etudeDTO: {
            id: etudeApplicationId,
          },
          data: currentActivityTemplate
            ? [
                constructActivityAnswerMap(
                  currentActivityTemplate,
                  JSON.parse(activity.data)
                ),
              ]
            : [],
        },
      };
    }
  };

  const retrieveCurrentActivityAnswerTemplate = () => {
    const { answer } = retrieveCurrentActivityAnswer();
    const { data } = answer;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    return { answerTemplate, answer };
  };

  const checkCanAnswerQuestion = () => {
    if (activity) {
      return true;
    }
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate.isEmpty) {
      return false;
    }
    return false;
  };

  const changeActivityAnswer = (answer) => {
    const copyAnswer = JSON.parse(JSON.stringify(answer));
    copyAnswer.data = JSON.stringify(copyAnswer.data);
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    if (foundIndex === -1) {
      activityAnswerDTOList.push(copyAnswer);
    } else {
      activityAnswerDTOList[foundIndex] = copyAnswer;
    }
    setActivityAnswerDTOList(JSON.parse(JSON.stringify(activityAnswerDTOList)));
  };

  const constructHeaderName = () => {
    if (!etude) return "";
    let name;
    if (etude.catchtivityApplicationDTO) {
      name = etude.catchtivityApplicationDTO.catchtivityDTO.name;
    } else if (etude.catchxamApplicationDTO) {
      name = etude.catchxamApplicationDTO.catchxamDTO.name;
    }
    return name;
  };

  const handleCreateOrUpdateActivityAnswerWithData = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: true,
    }));
    const { index, answer } = retrieveCurrentActivityAnswer();
    const baseAnswer = {
      userId: answer.userId,
      userProfileId: answer.userProfileId,
      data: JSON.stringify(answer.data),
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    };
    if (answer.id) {
      const currentAnswer = {
        ...baseAnswer,
        id: answer.id,
        activityId: answer.activityDTO.id,
        etudeId: answer.etudeDTO.id,
      };
      const { data, err } = await patchActivityAnswerWithData(currentAnswer);
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          ...activityAnswerDTOList[index],
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          etudeDTO: data.etudeDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    } else {
      const newAnswer = {
        ...baseAnswer,
        activity: {
          id: answer.activityDTO.id,
        },
        etude: {
          id: answer.etudeDTO.id,
        },
      };
      const { data, err } = await createActivityAnswerByActivityIdEtudeId(
        newAnswer
      );
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          etudeDTO: data.etudeDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: false,
    }));
  };

  const handleToNextActivity = (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      handleCreateOrUpdateActivityAnswerWithData();
    }
    setActivityNumber(nextActivityNumber);
  };

  const handleSetEmptyOrContinue = async () => {
    const { index, answer } = retrieveCurrentActivityAnswer();
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const { data } = answer;
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    if (answerTemplate) {
      if (answerTemplate.isEmpty) {
        answerTemplate.isEmpty = false;
      } else {
        answerTemplate.isEmpty = true;
      }
      const copyAnswer = JSON.parse(JSON.stringify(answer));
      copyAnswer.data = JSON.stringify(copyAnswer.data);
      activityAnswerDTOList[index] = copyAnswer;
      setActivityAnswerDTOList([...activityAnswerDTOList]);
      handleCreateOrUpdateActivityAnswerWithData();
    }
  };

  const handleToNextActivityWithAsync = async (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      await handleCreateOrUpdateActivityAnswerWithData();
    }
    setActivityNumber(nextActivityNumber);
  };

  const handleToNextActivityCheckCanAnswer = (nextActivityNumber) => {
    if (checkCanAnswerQuestion()) {
      if (nextActivityNumber === etude.activityDTOList.length) {
        handleToNextActivityWithAsync(nextActivityNumber);
      } else {
        handleToNextActivity(nextActivityNumber);
      }
    } else {
      setActivityNumber(nextActivityNumber);
    }
  };

  const handleToPreviousActivity = async () => {
    setActivityNumber(activityNumber - 1);
  };

  const handleGoPreviousScreen = async () => {
    navigate("/etudes", {
      state: { state: "FORCE_UPDATE" },
    });
  };

  const handleCompleteOnClick = async () => {
    const { data, err } = await evaluateEtudeById(etudeApplicationId);
    if (err) {
      console.log(err);
      return;
    }
    navigate("/etudes", {
      state: {
        state: "FORCE_UPDATE",
      },
    });
    setShowCompleteModal(false);
  };

  const RenderCurrentActivityContent = () => {
    if (activity) {
      const currentActivityTemplate = retrieveCurrentActivityTemplate();
      if (currentActivityTemplate) {
        const { answer } = retrieveCurrentActivityAnswer();
        const foundIndex = answer.data.findIndex(
          (answerData) => answerData.type === currentActivityTemplate.type
        );
        if (foundIndex === -1) {
          answer.data.push(
            constructActivityAnswerMap(
              currentActivityTemplate,
              JSON.parse(activity.data)
            )
          );
        }
        if (currentActivityTemplate.type === "ORDERING") {
          return (
            <OrderingActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        } else if (currentActivityTemplate.type === "DROPDOWN") {
          return (
            <DropdownActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        } else if (currentActivityTemplate.type === "MCSA") {
          return (
            <MCSAActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        } else if (currentActivityTemplate.type === "MCMA") {
          return (
            <MCMAActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        } else if (currentActivityTemplate.type === "MATCHING") {
          return (
            <MatchingActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        } else if (currentActivityTemplate.type === "GROUPING") {
          return (
            <GroupingActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        } else if (currentActivityTemplate.type === "FILL_IN_THE_BLANKS") {
          return (
            <FillInTheBlanksActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        } else if (currentActivityTemplate.type === "OPEN_ENDED") {
          return (
            <OpenEndedActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
              userId={userInformation.id}
              userProfileId={userProfile.id}
              etudeId={etude.id}
              activityId={activity.id}
              showMaterialContent={true}
              activityTemplateId={retrieveCurrentActivityTemplate().id}
            />
          );
        } else if (currentActivityTemplate.type === "TRUE_FALSE") {
          return (
            <TrueFalseActivityContent
              answer={answer}
              data={JSON.parse(activity.data)}
              canAnswerQuestion={checkCanAnswerQuestion}
              changeAnswer={changeActivityAnswer}
            />
          );
        }
      }
    }
    return null;
  };

  const RenderSingleKeyValueText = (key, value) => {
    return (
      <div className="flex flex-row justify-between items-center w-full">
        <p className="mx-1 font-bold">{i18n.t(key)}</p>
        <p className="mx-1">{value}</p>
      </div>
    );
  };

  const RenderWeeklyPlanDocuments = () => {
    return (
      <>
        {planWeekLoading.planWeek ? (
          <BaseLoading size="small" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            {RenderSingleKeyValueText("weekly_plan_documents", null)}
            {planWeekLoading.documents ? (
              <BaseLoading
                size="small"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              Object.keys(planWeekMap).map((planWeekId) => {
                const planWeek = planWeekMap[planWeekId];
                return (
                  <div className="w-full my-2">
                    <div className="">
                      <p>{planWeek.coterieType}</p>
                    </div>
                    {planWeek.documentList.map((document) => {
                      const { path, fileName, fileOriginalName } = document;
                      return (
                        <div className="px-4 my-1 cursor-pointer underline">
                          <a
                            href={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
                            target={"_blank"}
                          >
                            {fileOriginalName}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
          </>
        )}
      </>
    );
  };

  const RenderActivitySetEmpty = () => {
    if (!activity) return;
    if (!retrieveActivityAnswerFromAnswerList(activityAnswerDTOList, activity))
      return;
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    return (
      <div
        className="flex flex-row items-center gap-x-2 cursor-pointer"
        onClick={handleSetEmptyOrContinue}
      >
        <BaseImage
          src={
            answerTemplate?.isEmpty
              ? "/icons/checkbox.png"
              : "/icons/empty-checkbox.png"
          }
          size="small"
          onClick={() => {}}
        />
        <p>{i18n.t("i_want_to_set_empty")}</p>
      </div>
    );
  };

  const RenderActivityEmptyContent = () => {
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate?.isEmpty) {
      return <ActivityEmptyContent />;
    }
  };

  const RenderCompleteModal = () => {
    if (!etude) return;
    const stateList = constructActivityAnswerStateList(
      activityAnswerDTOList,
      etude.activityDTOList
    );
    const notExistsItemList = stateList.filter(
      (item) => item.state === "NOT_EXISTS"
    );
    const emptyItemList = stateList.filter((item) => item.state === "EMPTY");
    return (
      <BaseModal
        isOpen={showCompleteModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setActivityNumber(0);
          setShowCompleteModal(false);
        }}
        customSize={"w-[750px]"}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setActivityNumber(0);
                setShowCompleteModal(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div>
                <p className="font-bold">
                  {i18n.t("are_you_sure_do_you_want_to_complete_text")}
                </p>
              </div>
              <DividerLine />

              {notExistsItemList.length > 0 || emptyItemList.length > 0 ? (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/exclamation-red.png"
                    alt="exclamation"
                    size="xlarge"
                  />
                  <div className="flex flex-col">
                    {notExistsItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_did_not_answer_activities_text_1")}
                          {notExistsItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === notExistsItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}
                          {i18n.t("you_did_not_answer_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                    {emptyItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_set_empty_activities_text_1")}
                          {emptyItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === emptyItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}

                          {i18n.t("you_set_empty_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/checkbox.png"
                    alt="checkbox"
                    size="medium"
                  />
                  <div>
                    <p>{i18n.t("you_have_answered_all_activities")}</p>
                  </div>
                </div>
              )}

              <div className="flex justify-end ">
                <div className="flex flex-row gap-x-2">
                  <SecondaryButton
                    title={i18n.t("continue")}
                    size="small"
                    onClick={() => {
                      if (notExistsItemList.length > 0) {
                        setActivityNumber(notExistsItemList[0].index);
                      } else if (emptyItemList.length > 0) {
                        setActivityNumber(emptyItemList[0].index);
                      } else {
                        setActivityNumber(0);
                      }
                      setShowCompleteModal(false);
                    }}
                  />
                  <PrimaryButton
                    title={
                      notExistsItemList.length > 0
                        ? i18n.t("complete_later")
                        : i18n.t("complete")
                    }
                    size="unlimited"
                    onClick={handleCompleteOnClick}
                  />
                </div>
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    if (pageState === 0) {
      if (etude) {
        let name;
        let description;
        let coterieType;
        let level;
        if (etude.catchtivityApplicationDTO) {
          const { catchtivityApplicationDTO } = etude;
          const { catchtivityDTO } = catchtivityApplicationDTO;
          name = catchtivityDTO.name;
          description = catchtivityDTO.description;
          coterieType = catchtivityDTO.coterieType;
          level = catchtivityDTO.level;
        } else if (etude.catchxamApplicationDTO) {
          const { catchxamApplicationDTO } = etude;
          const { catchxamDTO } = catchxamApplicationDTO;
          name = catchxamDTO.name;
          description = catchxamDTO.description;
          coterieType = catchxamDTO.coterieType;
          level = catchxamDTO.level;
        }
        return (
          <ContentTransition
            language={language}
            description={description}
            type={coterieType}
            level={level}
            activityCount={etude.activityDTOList.length}
            answeredActivityCount={activityAnswerDTOList.length}
            loading={
              individualModelLoading ||
              outcomeModelListLoading ||
              activityAnswerDTOListLoading
            }
            planWeekLoading={planWeekLoading}
            planWeekMap={planWeekMap}
            onClick={() => {
              setActivityNumber(0);
              setPageState(1);
            }}
          />
        );
      } else {
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      }
    } else if (pageState === 1) {
      if (etude) {
        return (
          <div className="h-full no-scrollbar overflow-y-auto pb-[200px]">
            {activityLoading.answer ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : activityLoading.self ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : activity ? (
              <div className="flex flex-col w-full">
                <div className="my-1">
                  <CatchtivityProgressBar
                    activityNumber={activityNumber + 1}
                    onActivityChange={handleToNextActivityCheckCanAnswer}
                    totalActivityCount={
                      etude ? etude.activityDTOList.length : 0
                    }
                    showClockTime={false}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row items-center" />
                  {RenderActivitySetEmpty()}
                </div>
                {RenderActivityEmptyContent()}
                <div className="my-2">{RenderCurrentActivityContent()}</div>
              </div>
            ) : null}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className="h-screen p-4 relative">
      {RenderCompleteModal()}
      <ScreenCard>
        <DashboardHeader
          handleReturnOnClick={pageState === 1 ? handleGoPreviousScreen : null}
          title={etude?.catchxamApplicationDTO.catchxamDTO.name}
          level={etude?.catchxamApplicationDTO.catchxamDTO.level}
        />
        {RenderMainContent()}
        {pageState === 1 && activity ? (
          <CatchtivityFooter
            activityNumber={activityNumber}
            totalActivityCount={etude ? etude.activityDTOList.length : 0}
            showTimedProgressBar={false}
            activity={activity}
            activityLoading={activityLoading.self}
            handleLeftArrowOnClick={handleToPreviousActivity}
            handleRightArrowOnClick={handleToNextActivityCheckCanAnswer}
          />
        ) : null}
      </ScreenCard>
    </div>
  );
};

export default EtudeApplicationScreen;
