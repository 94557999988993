import FillInTheBlanksActivityContent from "../../components/activities/FillInTheBlanksActivityContent";
import OpenEndedActivityContent from "../../components/activities/OpenEndedActivityContent";
import GroupingActivityContent from "../../components/activities/GroupingActivityContent";
import MatchingActivityContent from "../../components/activities/MatchingActivityContent";
import MCMAActivityContent from "../../components/activities/MCMAActivityContent";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import DropdownActivityContent from "../../components/activities/DropdownActivityContent";
import OrderingActivityContent from "../../components/activities/OrderingActivityContent";
import FullCard from "../../components/cards/FullCard";
import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import {
  retrieveStandardExamWithActivityDataById,
  retrieveStandardExamWithActivityDataByIdAndToken,
} from "../../requests/CatchtivityRequests";
import { useNavigate, useParams } from "react-router-dom";
import { constructActivityAnswerMap } from "../../utilization/CatchtivityUtilization";
import i18n from "../../language/i18n";
import TrueFalseActivityContent from "../../components/activities/TrueFalseActivityContent";
import ActivitySolutionContent from "../../components/activities/solution-content/ActivitySolutionContent";
import DashboardHeader from "../../components/headers/DashboardHeader";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";

const PractiseStandardExamByIdScreen = () => {
  const navigate = useNavigate();
  const { standardExamId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [loading, setLoading] = useState(false);
  const [selectedActivityCoterieType, setSelectedActivityCoterieType] =
    useState(null);
  const [selectedActivityNumber, setSelectedActivityNumber] = useState(0);
  const [selectedActivityTemplateType, setSelectedActivityTemplateType] =
    useState(null);
  const [dataMap, setDataMap] = useState({});
  const [answer, setAnswer] = useState({
    data: [],
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetStandardExamById = async () => {
      setLoading(true);
      const { data, err } = await retrieveStandardExamWithActivityDataById(
        standardExamId
      );
      if (err) {
        console.log(err);
        return;
      }
      const { standardExamActivityWithDataDTOSet } = data;
      const constructedDataMap = {};
      standardExamActivityWithDataDTOSet.forEach(
        (standardExamActivityWithDataDTO) => {
          const { standardExamActivityItemWithDataDTOSet } =
            standardExamActivityWithDataDTO;
          const constructedActivityData =
            standardExamActivityItemWithDataDTOSet.map(
              (standardExamActivityItemWithDataDTO) => {
                const { activityWithData } =
                  standardExamActivityItemWithDataDTO;
                const { data } = activityWithData;
                return JSON.parse(data);
              }
            );
          constructedDataMap[
            standardExamActivityWithDataDTO.standardExamCoterieType
          ] = constructedActivityData;
        }
      );
      setDataMap(constructedDataMap);
      setLoading(false);
    };
    if (!standardExamId) return;
    retrieveAndSetStandardExamById();
  }, [standardExamId]);

  useEffect(() => {
    const checkAnswerMapExists = (type) => {
      if (
        Object.keys(
          dataMap[selectedActivityCoterieType][selectedActivityNumber]
        ).find((dataKey) => dataKey === "answerMap")
      ) {
        const foundAnswer = dataMap[selectedActivityCoterieType][
          selectedActivityNumber
        ]["answerMap"].find((answer) => answer.type === type);
        if (foundAnswer) {
          return foundAnswer;
        }
      }
      return null;
    };
    const constructAnswerBasedOnData = () => {
      const answer = { data: [] };
      if (
        Object.keys(
          dataMap[selectedActivityCoterieType][selectedActivityNumber]
        ).find((dataKey) => dataKey === "MCSAMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("MCSA");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MCSA" },
                JSON.parse(
                  JSON.stringify(
                    dataMap[selectedActivityCoterieType][selectedActivityNumber]
                  )
                )
              )
        );
      }

      if (answer.data.length > 0) {
        setSelectedActivityTemplateType(answer.data[0].type);
      }
      setAnswer(answer);
    };
    if (!Object.keys(dataMap).length === 0) return;
    if (selectedActivityCoterieType === null) return;
    constructAnswerBasedOnData();
  }, [dataMap, selectedActivityCoterieType, selectedActivityNumber]);

  const retrieveAndSetStandardExamById = async (id, token) => {
    setLoading(true);
    const { data, err } =
      await retrieveStandardExamWithActivityDataByIdAndToken(id, token);
    if (err) {
      console.log(err);
      return;
    }
    const { standardExamActivityWithDataDTOSet } = data;
    const constructedDataMap = {};
    standardExamActivityWithDataDTOSet.forEach(
      (standardExamActivityWithDataDTO) => {
        const { standardExamActivityItemWithDataDTOSet } =
          standardExamActivityWithDataDTO;
        const constructedActivityData =
          standardExamActivityItemWithDataDTOSet.map(
            (standardExamActivityItemWithDataDTO) => {
              const { activityWithData } = standardExamActivityItemWithDataDTO;
              const { data } = activityWithData;
              return JSON.parse(data);
            }
          );
        constructedDataMap[
          standardExamActivityWithDataDTO.standardExamCoterieType
        ] = constructedActivityData;
      }
    );
    setDataMap(constructedDataMap);
    setLoading(false);
  };

  const retrieveTaxonomyNameFromActivityType = (type) => {
    let taxonomyMap = {
      name: "",
    };
    const foundData =
      dataMap[selectedActivityCoterieType][selectedActivityNumber];
    if (type === "ORDERING") {
      taxonomyMap = JSON.parse(foundData["orderingTaxonomyMap"]);
    } else if (type === "DROPDOWN") {
      taxonomyMap = JSON.parse(foundData["dropdownTaxonomyMap"]);
    } else if (type === "MCSA") {
      taxonomyMap = JSON.parse(foundData["MCSATaxonomyMap"]);
    } else if (type === "MCMA") {
      taxonomyMap = JSON.parse(foundData["MCMATaxonomyMap"]);
    } else if (type === "MATCHING") {
      taxonomyMap = JSON.parse(foundData["matchingTaxonomyMap"]);
    } else if (type === "GROUPING") {
      taxonomyMap = JSON.parse(foundData["groupingTaxonomyMap"]);
    } else if (type === "FILL_IN_THE_BLANKS") {
      taxonomyMap = JSON.parse(foundData["fillInTheBlanksTaxonomyMap"]);
    } else if (type === "OPEN_ENDED") {
      taxonomyMap = JSON.parse(foundData["openEndedTaxonomyMap"]);
    } else if (type === "TRUE_FALSE") {
      taxonomyMap = JSON.parse(foundData["trueFalseTaxonomyMap"]);
    }
    return taxonomyMap.name;
  };

  const RenderSelectedActivityContent = () => {
    if (selectedActivityTemplateType === "ORDERING") {
      return (
        <OrderingActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "DROPDOWN") {
      return (
        <DropdownActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCSA") {
      return (
        <MCSAActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCMA") {
      return (
        <MCMAActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MATCHING") {
      return (
        <MatchingActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "GROUPING") {
      return (
        <GroupingActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
      return (
        <FillInTheBlanksActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "OPEN_ENDED") {
      return (
        <OpenEndedActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          showMaterialContent={true}
        />
      );
    } else if (selectedActivityTemplateType === "TRUE_FALSE") {
      return (
        <TrueFalseActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        <DashboardHeader
          title={i18n.t("practise_now")}
          handleReturnOnClick={() => {
            navigate(-1);
          }}
        />
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <div className="flex flex-col gap-3 my-4">
            <p className="font-bold text-lg">{i18n.t("coterie_type")}</p>
            <div className="flex flex-row flex-wrap items-center gap-3 my-4">
              {Object.keys(dataMap).map((key, index) => (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-center ${
                    selectedActivityCoterieType === key
                      ? "bg-catchup-blue"
                      : "bg-catchup-darker-blue"
                  }  text-catchup-white rounded-catchup-xlarge w-[150px] h-[90px] text-center cursor-pointer transition-all duration-300 p-5`}
                  onClick={() => {
                    if (key === selectedActivityCoterieType) return;
                    setSelectedActivityTemplateType(null);
                    setAnswer({
                      data: [],
                    });
                    setSelectedActivityNumber(0);
                    setSelectedActivityCoterieType(key);
                  }}
                >
                  <p className="">{i18n.t(key)}</p>
                </div>
              ))}
            </div>
            {selectedActivityCoterieType ? (
              <>
                <div className="flex flex-col gap-3 my-4">
                  <p className="font-bold text-lg">
                    {i18n.t("activity_number")}
                  </p>
                  <div className="flex flex-row flex-wrap items-center gap-3 my-4">
                    {dataMap[selectedActivityCoterieType].map((data, index) => (
                      <div
                        key={index}
                        className={`flex flex-col items-center justify-center ${
                          selectedActivityNumber === index
                            ? "bg-catchup-blue"
                            : "bg-catchup-darker-blue"
                        }  text-catchup-white rounded-catchup-xlarge w-[90px] h-[45px] text-center cursor-pointer transition-all duration-300 p-5`}
                        onClick={() => {
                          if (index === selectedActivityNumber) return;
                          setSelectedActivityTemplateType(null);
                          setAnswer({
                            data: [],
                          });
                          setSelectedActivityNumber(index);
                        }}
                      >
                        <p className="">{i18n.t(index + 1)}</p>
                      </div>
                    ))}
                  </div>
                  <p className="font-bold text-lg">
                    {i18n.t("activity_template")}
                  </p>
                  <div className="flex flex-row flex-wrap items-center gap-3 my-4">
                    {answer.data.map((answerItem, answerItemIndex) => (
                      <div
                        key={answerItemIndex}
                        className={`flex flex-col items-center justify-center ${
                          selectedActivityTemplateType === answerItem.type
                            ? "bg-catchup-blue"
                            : "bg-catchup-darker-blue"
                        }  text-catchup-white rounded-catchup-xlarge w-[180px] h-[90px] text-center cursor-pointer transition-all duration-300 p-5`}
                        onClick={() => {
                          setSelectedActivityTemplateType(answerItem.type);
                        }}
                      >
                        <p className="">{i18n.t(answerItem.type)}</p>
                        <p className="text-md">
                          (
                          {i18n.t(
                            retrieveTaxonomyNameFromActivityType(
                              answerItem.type
                            )
                          )}
                          )
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <DividerLine />
                <div className="flex flex-col my-2 w-full p-5">
                  {RenderSelectedActivityContent()}
                </div>
                <ActivitySolutionContent
                  activityTemplateType={selectedActivityTemplateType}
                  data={
                    dataMap[selectedActivityCoterieType][selectedActivityNumber]
                  }
                />
              </>
            ) : null}
          </div>
        )}
      </FullCard>
    );
  };

  return (
    <div className="h-full flex flex-col justify-center p-4">
      <div className="h-full">{RenderMainContent()}</div>
    </div>
  );
};

export default PractiseStandardExamByIdScreen;
