import FillInTheBlanksActivityContent from "../../components/activities/FillInTheBlanksActivityContent";
import OpenEndedActivityContent from "../../components/activities/OpenEndedActivityContent";
import GroupingActivityContent from "../../components/activities/GroupingActivityContent";
import MatchingActivityContent from "../../components/activities/MatchingActivityContent";
import MCMAActivityContent from "../../components/activities/MCMAActivityContent";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import DropdownActivityContent from "../../components/activities/DropdownActivityContent";
import OrderingActivityContent from "../../components/activities/OrderingActivityContent";
import FullCard from "../../components/cards/FullCard";
import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import { retrieveStandardExamWithActivityDataByIdAndToken } from "../../requests/CatchtivityRequests";
import { useParams } from "react-router-dom";
import { constructActivityAnswerMap } from "../../utilization/CatchtivityUtilization";
import i18n from "../../language/i18n";
import TrueFalseActivityContent from "../../components/activities/TrueFalseActivityContent";
import ActivitySolutionContent from "../../components/activities/solution-content/ActivitySolutionContent";
import SelectionBox from "../../components/boxes/SelectionBox";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";

const StandardExamPreviewByIdScreen = () => {
  const { id, base64Data } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [loading, setLoading] = useState(false);
  const [selectedActivityCoterieType, setSelectedActivityCoterieType] =
    useState(null);
  const [selectedActivityNumber, setSelectedActivityNumber] = useState(0);
  const [selectedActivityTemplateType, setSelectedActivityTemplateType] =
    useState(null);
  const [dataMap, setDataMap] = useState({});
  const [answer, setAnswer] = useState({
    data: [],
  });
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    if (!base64Data) return;
    const { token } = JSON.parse(decodeURI(atob(base64Data)));
    retrieveAndSetStandardExamById(id, token);
  }, [id, base64Data]);

  useEffect(() => {
    const checkAnswerMapExists = (type) => {
      if (
        Object.keys(
          dataMap[selectedActivityCoterieType][selectedActivityNumber]
        ).find((dataKey) => dataKey === "answerMap")
      ) {
        const foundAnswer = dataMap[selectedActivityCoterieType][
          selectedActivityNumber
        ]["answerMap"].find((answer) => answer.type === type);
        if (foundAnswer) {
          return foundAnswer;
        }
      }
      return null;
    };
    const constructAnswerBasedOnData = () => {
      const answer = { data: [] };
      if (
        Object.keys(
          dataMap[selectedActivityCoterieType][selectedActivityNumber]
        ).find((dataKey) => dataKey === "MCSAMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("MCSA");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MCSA" },
                JSON.parse(
                  JSON.stringify(
                    dataMap[selectedActivityCoterieType][selectedActivityNumber]
                  )
                )
              )
        );
      }

      if (answer.data.length > 0) {
        setSelectedActivityTemplateType(answer.data[0].type);
      }
      setAnswer(answer);
    };
    if (!Object.keys(dataMap).length === 0) return;
    if (selectedActivityCoterieType === null) return;
    constructAnswerBasedOnData();
  }, [dataMap, selectedActivityCoterieType, selectedActivityNumber]);

  const retrieveAndSetStandardExamById = async (id, token) => {
    setLoading(true);
    const { data, err } =
      await retrieveStandardExamWithActivityDataByIdAndToken(id, token);
    if (err) {
      console.log(err);
      return;
    }
    const { standardExamActivityWithDataDTOSet } = data;
    const constructedDataMap = {};
    standardExamActivityWithDataDTOSet.forEach(
      (standardExamActivityWithDataDTO) => {
        const { standardExamActivityItemWithDataDTOSet } =
          standardExamActivityWithDataDTO;
        const constructedActivityData =
          standardExamActivityItemWithDataDTOSet.map(
            (standardExamActivityItemWithDataDTO) => {
              const { activityWithData } = standardExamActivityItemWithDataDTO;
              const { data } = activityWithData;
              return JSON.parse(data);
            }
          );
        constructedDataMap[
          standardExamActivityWithDataDTO.standardExamCoterieType
        ] = constructedActivityData;
      }
    );
    setDataMap(constructedDataMap);
    setLoading(false);
  };

  const retrieveTaxonomyNameFromActivityType = (type) => {
    let taxonomyMap = {
      name: "",
    };
    const foundData =
      dataMap[selectedActivityCoterieType][selectedActivityNumber];
    if (type === "ORDERING") {
      taxonomyMap = JSON.parse(foundData["orderingTaxonomyMap"]);
    } else if (type === "DROPDOWN") {
      taxonomyMap = JSON.parse(foundData["dropdownTaxonomyMap"]);
    } else if (type === "MCSA") {
      taxonomyMap = JSON.parse(foundData["MCSATaxonomyMap"]);
    } else if (type === "MCMA") {
      taxonomyMap = JSON.parse(foundData["MCMATaxonomyMap"]);
    } else if (type === "MATCHING") {
      taxonomyMap = JSON.parse(foundData["matchingTaxonomyMap"]);
    } else if (type === "GROUPING") {
      taxonomyMap = JSON.parse(foundData["groupingTaxonomyMap"]);
    } else if (type === "FILL_IN_THE_BLANKS") {
      taxonomyMap = JSON.parse(foundData["fillInTheBlanksTaxonomyMap"]);
    } else if (type === "OPEN_ENDED") {
      taxonomyMap = JSON.parse(foundData["openEndedTaxonomyMap"]);
    } else if (type === "TRUE_FALSE") {
      taxonomyMap = JSON.parse(foundData["trueFalseTaxonomyMap"]);
    }
    return taxonomyMap.name;
  };

  const RenderSelectedActivityContent = () => {
    if (selectedActivityTemplateType === "ORDERING") {
      return (
        <OrderingActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "DROPDOWN") {
      return (
        <DropdownActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCSA") {
      return (
        <MCSAActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCMA") {
      return (
        <MCMAActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MATCHING") {
      return (
        <MatchingActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "GROUPING") {
      return (
        <GroupingActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
      return (
        <FillInTheBlanksActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "OPEN_ENDED") {
      return (
        <OpenEndedActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          showMaterialContent={true}
        />
      );
    } else if (selectedActivityTemplateType === "TRUE_FALSE") {
      return (
        <TrueFalseActivityContent
          answer={answer}
          data={dataMap[selectedActivityCoterieType][selectedActivityNumber]}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <div className="flex flex-col gap-3 my-4">
            <div className="my-2">
              <p className="font-semibold text-lg">{i18n.t("coterie_type")}</p>
            </div>
            <SelectionBox
              optionList={Object.keys(dataMap).map((key) => ({
                id: key,
                text: i18n.t(key),
              }))}
              selectedId={selectedActivityCoterieType}
              handleSelectOnClick={(itemId) => {
                if (itemId === selectedActivityCoterieType) return;
                setSelectedActivityTemplateType(null);
                setAnswer({
                  data: [],
                });
                setSelectedActivityNumber(0);
                setSelectedActivityCoterieType(itemId);
              }}
            />
            {selectedActivityCoterieType ? (
              <>
                <div className="my-2">
                  <p className="font-semibold text-lg">
                    {i18n.t("activity_number")}
                  </p>
                </div>
                <SelectionBox
                  optionList={dataMap[selectedActivityCoterieType].map(
                    (data, index) => ({
                      id: index,
                      text: index + 1,
                    })
                  )}
                  selectedId={selectedActivityNumber}
                  handleSelectOnClick={(itemId) => {
                    if (itemId === selectedActivityNumber) return;
                    setSelectedActivityTemplateType(null);
                    setAnswer({
                      data: [],
                    });
                    setSelectedActivityNumber(itemId);
                  }}
                />
                <div className="my-2">
                  <p className="font-semibold text-lg">
                    {i18n.t("activity_template")}
                  </p>
                </div>
                <SelectionBox
                  optionList={answer.data.map((answerItem) => ({
                    ...answerItem,
                    id: answerItem.type,
                    text: i18n.t(answerItem.type),
                    subText: i18n.t(
                      retrieveTaxonomyNameFromActivityType(answerItem.type)
                    ),
                  }))}
                  selectedId={selectedActivityTemplateType}
                  handleSelectOnClick={(itemId) => {
                    setSelectedActivityTemplateType(itemId);
                  }}
                />

                <DividerLine />
                <div className="flex flex-col my-2 w-full p-5">
                  {RenderSelectedActivityContent()}
                </div>
                <ActivitySolutionContent
                  activityTemplateType={selectedActivityTemplateType}
                  data={
                    dataMap[selectedActivityCoterieType][selectedActivityNumber]
                  }
                />
              </>
            ) : null}
          </div>
        )}
      </FullCard>
    );
  };

  return (
    <div className="mx-auto container h-full">
      <div className="h-[calc(100vh-15px)] pt-[15px]">
        {RenderMainContent()}
      </div>
    </div>
  );
};

export default StandardExamPreviewByIdScreen;
