import { useEffect, useState } from "react";
import i18n from "../../language/i18n";
import BaseModal from "../modal/BaseModal";
import FullCard from "../cards/FullCard";
import DividerLine from "../divider/DividerLine";
import SingleInformationIconTextItem from "../infos/SingleInformationIconTextItem";
import InputGroup from "../groups/InputGroup";
import PrimaryButton from "../buttons/PrimaryButton";
import { NotificationManager } from "react-notifications";
import {
  createActivityTemplateFeedbackByToken,
  queryActivityTemplateFeedbackListByToken,
} from "../../requests/CatchtivityRequests";
import BaseTitle from "../titles/BaseTitle";
import {
  checkInsertPermissionGrantedByToken,
  checkViewPermissionGrantedByToken,
} from "../../utilization/ScreenUtilization";
import { parseJwt } from "../../utilization/TokenUtilization";
import BaseImage from "../images/BaseImage";

const GiveFeedback = (props) => {
  const { activityId, activityTemplateId, token } = props;
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [activityTemplateFeedbackList, setActivityTemplateFeedbackList] =
    useState([]);
  const [errorMap, setErrorMap] = useState({
    text: null,
  });

  useEffect(() => {
    const retrieveAndSetActivityTemplateFeedbackList = async (
      userId,
      userProfileId
    ) => {
      const { data, err } = await queryActivityTemplateFeedbackListByToken(
        {
          userId,
          userProfileId,
          activityId,
        },
        token
      );
      if (err) {
        console.log(err);
        return;
      }
      setActivityTemplateFeedbackList(data);
    };
    if (!token) return;
    if (!activityId) return;

    const tokenJSON = parseJwt(token);
    const userId = tokenJSON.id;
    const userProfileId = tokenJSON.profileId;
    retrieveAndSetActivityTemplateFeedbackList(userId, userProfileId);
  }, [token, activityId]);

  const findCurrentFeedback = () => {
    return activityTemplateFeedbackList.find(
      (activityTemplateFeedback) =>
        activityTemplateFeedback.activityDTO.id === activityId &&
        activityTemplateFeedback.activityTemplateDTO.id === activityTemplateId
    );
  };

  const handleOnChange = (type, value) => {
    if (type === "text") {
      if (value === null || value.trim() === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          text: i18n.t("text_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          text: null,
        }));
      }
      setText(value);
    }
  };

  const handleSendFeedbackOnClick = async () => {
    if (
      checkInsertPermissionGrantedByToken(
        "activity_template_feedback_list",
        token
      )
    ) {
      const { data, err } = await createActivityTemplateFeedbackByToken(
        {
          activityId,
          activityTemplateId,
          text,
        },
        token
      );
      if (err) {
        console.log(err);
        return;
      }
      setShow(false);
      NotificationManager.success(
        i18n.t("feedback_successfully_received_text")
      );
    } else {
      NotificationManager.error(i18n.t("you_do_not_have_permission_to_insert"));
    }
  };

  const RenderGiveFeedbackModal = () => {
    return (
      <BaseModal
        isOpen={show}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShow(false);
        }}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setShow(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div className="flex flex-row items-center">
                <BaseTitle title={i18n.t("give_feedback")} />
              </div>
              <DividerLine />
              {SingleInformationIconTextItem(
                i18n.t(
                  "you_can_provide_your_feedback_regarding_to_the_activity_template_text"
                )
              )}
              <InputGroup
                type="textarea"
                value={text}
                useMinHeight={true}
                onChange={(event) => {
                  handleOnChange("text", event.target.value);
                }}
                errorText={errorMap.text}
              />
              <div className="mt-5 flex flex-row justify-end">
                <PrimaryButton
                  title={i18n.t("send")}
                  size="small"
                  onClick={handleSendFeedbackOnClick}
                />
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const currentFeedback = findCurrentFeedback();

  if (
    !checkViewPermissionGrantedByToken("activity_template_feedback_list", token)
  )
    return;

  return (
    <>
      {RenderGiveFeedbackModal()}
      <div className="flex flex-row justify-end">
        {currentFeedback ? (
          <div className="opacity-70">
            <p className="italic">{i18n.t("you_have_given_feedback")}</p>
          </div>
        ) : (
          <div
            className="cursor-pointer"
            onClick={() => {
              setShow(true);
            }}
          >
            <p>{i18n.t("give_feedback")}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default GiveFeedback;
