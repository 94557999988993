import {
  parseBodyMapFromData,
  parseContentMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import GroupingActivityMaterialContent from "./material-content/GroupingActivityMaterialContent";

const GroupingActivityContent = (props) => {
  const {
    answer,
    data,
    canAnswerQuestion,
    changeAnswer,
    isPreview,
    showCorrectAnswer,
  } = props;
  const contentMap = parseContentMapFromData(data);
  const groupingBodyMap = parseBodyMapFromData(data, "GROUPING");
  const groupingMaterialMap = parseMaterialMapFromData(data, "GROUPING");

  const handleGroupingAnswerOnChange = (answer, key, value, index) => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "GROUPING"
    );
    const answerMap = answer.data[foundIndex].answerMap;
    if (value) {
      answerMap[key].push(value);
    } else {
      answerMap[key].splice(index, 1);
    }
    changeAnswer(answer);
  };

  return (
    <>
      <ActivityBodyContent
        bodyMap={groupingBodyMap}
        templateType={"GROUPING"}
      />
      <DividerLine />
      <GroupingActivityMaterialContent
        uniqueValue={JSON.stringify(data.contentMap)}
        answer={answer}
        materialMap={groupingMaterialMap}
        contentMap={contentMap}
        checkCanAnswerQuestion={canAnswerQuestion}
        onChange={handleGroupingAnswerOnChange}
        isPreview={isPreview}
        showCorrectAnswer={showCorrectAnswer}
      />
    </>
  );
};

export default GroupingActivityContent;
