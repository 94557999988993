import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import InputGroup from "../groups/InputGroup";
import i18n from "../../language/i18n";
import CatchtivityApplicationCard from "../cards/CatchtivityApplicationCard";
import CatchxamApplicationCard from "../cards/CatchxamApplicationCard";
import { useApp } from "../../context/AppContextProvider";
import BaseLoading from "../loading/BaseLoading";

const PreviousHomeworkListView = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    catchxamApplicationListLoading,
    catchtivityApplicationListLoading,
    standardExamApplicationListLoading,
    passiveContentList,
  } = useAuth();
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const practiseHomeworkCoterieType = localStorage.getItem(
      "practiseHomeworkCoterieType"
    );
    if (!practiseHomeworkCoterieType) return;
    setSelectedCoterieType(practiseHomeworkCoterieType);
  }, []);

  useEffect(() => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      localStorage.removeItem("practiseHomeworkCoterieType");
    } else {
      localStorage.setItem("practiseHomeworkCoterieType", selectedCoterieType);
    }
  }, [selectedCoterieType]);

  const filterContentList = () => {
    return passiveContentList.filter(
      (item) =>
        item.catchtivityDTO?.coterieType === selectedCoterieType ||
        item.catchxamDTO?.coterieType === selectedCoterieType
    );
  };

  const filterCoterieTypeOptionBasedOnWorkList = () => {
    const coterieTypeOptionList = [];
    for (const content of passiveContentList) {
      let currentCoterieType;
      if (content.catchtivityDTO) {
        currentCoterieType = content.catchtivityDTO.coterieType;
      } else if (content.catchxamDTO) {
        currentCoterieType = content.catchxamDTO.coterieType;
      } else {
        continue;
      }
      if (
        coterieTypeOptionList.findIndex(
          (coterieTypeOption) => coterieTypeOption.value === currentCoterieType
        ) === -1
      ) {
        coterieTypeOptionList.push({
          text: i18n.t(currentCoterieType),
          value: currentCoterieType,
        });
      }
    }
    return coterieTypeOptionList;
  };

  const handleOnChange = (type, value) => {
    if (type === "selectedCoterieType") {
      setSelectedCoterieType(value);
    }
  };

  const filteredContentList = filterContentList();
  const filteredCoterieTypeOptionList =
    filterCoterieTypeOptionBasedOnWorkList();

  return (
    <div className="h-full flex flex-col justify-center">
      {catchtivityApplicationListLoading ||
      catchxamApplicationListLoading ||
      standardExamApplicationListLoading ? (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      ) : (
        <div className="h-full">
          <div className="flex flex-row items-center">
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("coterie_type")}
                  placeholder={i18n.t("coterie_type")}
                  value={selectedCoterieType}
                  optionList={filteredCoterieTypeOptionList}
                  onChange={(event) => {
                    handleOnChange("selectedCoterieType", event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap">
            {filteredContentList.map((sortedWork) => (
              <div
                key={sortedWork.id}
                className="w-full lg:w-1/2 xl:w-1/3 2xl:w-1/4"
              >
                {sortedWork.type === "CATCHTIVITY" ? (
                  <CatchtivityApplicationCard
                    isPractise={true}
                    catchtivityApplicationDTO={sortedWork}
                    language={language}
                  />
                ) : sortedWork.type === "CATCHXAM" ? (
                  <CatchxamApplicationCard
                    isPractise={true}
                    catchxamApplicationDTO={sortedWork}
                    language={language}
                  />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviousHomeworkListView;
