import {
  parseBodyMapFromData,
  parseContentMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import OrderingActivityMaterialContent from "./material-content/OrderingActivityMaterialContent";

const OrderingActivityContent = (props) => {
  const {
    answer,
    data,
    canAnswerQuestion,
    changeAnswer,
    isPreview,
    showCorrectAnswer,
  } = props;
  const contentMap = parseContentMapFromData(data);
  const orderingBodyMap = parseBodyMapFromData(data, "ORDERING");
  const orderingMaterialMap = parseMaterialMapFromData(data, "ORDERING");

  const handleOrderingAnswerOnChange = (answer, primaryKey, secondaryKey) => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "ORDERING"
    );
    const answerMap = answer.data[foundIndex].answerMap;
    const prevValue = answerMap[primaryKey];
    answerMap[primaryKey] = answerMap[secondaryKey];
    answerMap[secondaryKey] = prevValue;
    changeAnswer(answer);
  };

  return (
    <>
      <ActivityBodyContent
        bodyMap={orderingBodyMap}
        templateType={"ORDERING"}
      />
      <DividerLine />
      <OrderingActivityMaterialContent
        uniqueValue={JSON.stringify(data.contentMap)}
        answer={answer}
        materialMap={orderingMaterialMap}
        contentMap={contentMap}
        checkCanAnswerQuestion={canAnswerQuestion}
        onChange={handleOrderingAnswerOnChange}
        isPreview={isPreview}
        showCorrectAnswer={showCorrectAnswer}
      />
    </>
  );
};

export default OrderingActivityContent;
