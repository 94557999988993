import FullCard from "../../components/cards/FullCard";
import BaseTitle from "../../components/titles/BaseTitle";
import i18n from "../../language/i18n";

const FileToAnyApplicationSuccessScreen = () => {
  return (
    <div className="relative h-full flex p-4">
      <div className="w-full">
        <FullCard>
          <div className="h-full flex flex-row justify-center items-center gap-x-4">
            <div className="flex flex-col items-center justify-center cursor-pointer gap-x-2">
              <BaseTitle title={i18n.t("upload_successful")} />
              <p className="text-base underline italic">
                {i18n.t("you_can_close_this_screen")}
              </p>
            </div>
          </div>
        </FullCard>
      </div>
    </div>
  );
};

export default FileToAnyApplicationSuccessScreen;
