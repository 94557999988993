import { Dna } from "react-loader-spinner";
import i18n from "../../language/i18n";
import BaseImage from "../images/BaseImage";

const SingleProfileItem = (props) => {
  const { userProfile, selectedProfileId, type, onClick } = props;

  return (
    <div
      className={`p-4 m-2 ${
        userProfile.role === "INDIVIDUAL"
          ? "border-catchup-dark-blue"
          : "border-catchup-blue"
      } border rounded-catchup-xlarge cursor-pointer min-h-full flex flex-col items-center gap-y-2`}
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      }}
      onClick={() => {
        if (userProfile.deleted) {
          return;
        }
        onClick();
      }}
    >
      <p
        className={`italic py-1 ${
          userProfile.deleted ? "text-red-500" : ""
        } text-center`}
      >
        {type}
      </p>
      <BaseImage
        src="/icons/user-profile.png"
        alt="user-profile"
        size="3xlarge"
      />
      <div>
        <p
          className={`text-center ${
            userProfile.deleted ? "text-red-500" : ""
          } text-xl py-1`}
        >
          {userProfile.name}
        </p>
        {userProfile.coterieType ? (
          <p
            className={`text-center ${
              userProfile.deleted ? "text-red-500" : ""
            } py-1`}
          >
            ({i18n.t(userProfile.coterieType)})
          </p>
        ) : null}
      </div>
      {selectedProfileId === userProfile.id ? (
        <div className="flex justify-center items-center">
          <Dna
            visible={true}
            height="36"
            width="36"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : null}
    </div>
  );
};

export default SingleProfileItem;
