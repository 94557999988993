import { useEffect, useState } from "react";
import {
  patchAndForgotUserPassword,
  sendForgotPasswordEmail,
} from "../../requests/ManagementRequests";
import FullCard from "../../components/cards/FullCard";
import BaseTitle from "../../components/titles/BaseTitle";
import BaseImage from "../../components/images/BaseImage";
import i18n from "../../language/i18n";
import DividerLine from "../../components/divider/DividerLine";
import { useNavigate } from "react-router-dom";
import LeftTextRightInputGroup from "../../components/groups/LeftTextRightInputGroup";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { useApp } from "../../context/AppContextProvider";
import SingleInformationIconTextItem from "../../components/infos/SingleInformationIconTextItem";
import { NotificationManager } from "react-notifications";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [pageState, setPageState] = useState(0);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [errorMap, setErrorMap] = useState({
    username: null,
    code: null,
    password: null,
  });

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  const handleOnChange = (type, value) => {
    if (type === "username") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: i18n.t("username_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: null,
        }));
      }
      setUsername(value);
    } else if (type === "code") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: i18n.t("code_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          code: null,
        }));
      }
      setCode(value);
    } else if (type === "password") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: i18n.t("password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: null,
        }));
      }
      setPassword(value);
    }
  };

  const checkCommonValidation = () => {
    if (pageState === 0) {
      if (!username) {
        const currentErrorMap = {
          username: null,
        };
        if (!username) {
          currentErrorMap.username = i18n.t("username_required_field");
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    } else if (pageState === 1) {
      if (!code || !password) {
        const currentErrorMap = {
          code: null,
          password: null,
        };
        if (!code) {
          currentErrorMap.code = i18n.t("code_required_field");
        }
        if (!password) {
          currentErrorMap.password = i18n.t("password_required_field");
        }
        setErrorMap(currentErrorMap);
        return false;
      }
    }

    return true;
  };

  const handleSendForgotPasswordEmailOnClick = async () => {
    if (checkCommonValidation()) {
      const { data, err } = await sendForgotPasswordEmail({
        username,
      });
      if (err) {
        if (err.response.data) {
          NotificationManager.error(i18n.t("notFoundErrorInfo"));
        }
        return;
      }
      NotificationManager.success(
        i18n.t("forgot_password_code_successfully_send_to_email")
      );
      setPageState(1);
    }
  };

  const handleSendChangePasswordOnClick = async () => {
    if (checkCommonValidation()) {
      const { data, err } = await patchAndForgotUserPassword({
        username,
        type: "CHANGE_PASSWORD",
        code,
        password,
      });
      if (err) {
        if (err.response.data) {
          NotificationManager.error(i18n.t(err.response.data));
        }
        return;
      }
      navigate("/login", {
        state: {
          action: "LOGIN",
          username,
          password,
        },
      });
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard isShadowed={true}>
        <div className="">
          <div className="flex flex-row items-center gap-x-2">
            <div className="cursor-pointer">
              <BaseImage
                src="/icons/long-arrow-left.png"
                alt="long-arrow-left"
                size="medium"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <BaseTitle title={i18n.t("forgot_password_screen_header_text")} />
          </div>
        </div>
        <DividerLine />
        <div className="flex flex-col justify-center">
          <div className="flex flex-row items-center">
            <p className="font-bold text-lg">{i18n.t("user_information")}</p>
          </div>

          {SingleInformationIconTextItem(
            i18n.t("forgot_password_provide_your_username_text_1")
          )}

          <div className="w-full flex flex-row flex-wrap">
            <div className="w-full lg:w-1/2 flex flex-row">
              <LeftTextRightInputGroup
                type={"text"}
                title={i18n.t("username")}
                value={username}
                disabled={pageState === 1}
                onChange={(e) => {
                  handleOnChange("username", e.target.value);
                }}
                errorText={errorMap.username}
              />
            </div>
          </div>
        </div>

        {pageState === 1 ? (
          <>
            <DividerLine />
            <div className="flex flex-col justify-center">
              <div className="flex flex-row items-center">
                <p className="font-bold text-lg">
                  {i18n.t("password_information")}
                </p>
              </div>
              {SingleInformationIconTextItem(
                i18n.t("forgot_password_provide_your_username_text_2")
              )}
              <div className="w-full flex flex-row flex-wrap">
                <div className="w-full lg:w-1/2 flex flex-row">
                  <LeftTextRightInputGroup
                    type={"text"}
                    title={i18n.t("code")}
                    value={code}
                    onChange={(e) => {
                      handleOnChange("code", e.target.value);
                    }}
                    errorText={errorMap.code}
                  />
                </div>
                <div className="w-full lg:w-1/2 flex flex-row">
                  <LeftTextRightInputGroup
                    type={"password"}
                    title={i18n.t("password")}
                    value={password}
                    onChange={(e) => {
                      handleOnChange("password", e.target.value);
                    }}
                    errorText={errorMap.password}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {pageState === 0 ? (
          <div className="mt-4 ml-auto">
            <SecondaryButton
              title={i18n.t("send_code")}
              size="unlimited"
              onClick={handleSendForgotPasswordEmailOnClick}
            />
          </div>
        ) : (
          <div className="mt-4 ml-auto">
            <SecondaryButton
              title={i18n.t("change_password")}
              size="unlimited"
              onClick={handleSendChangePasswordOnClick}
            />
          </div>
        )}
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="mx-4">{RenderMainContent()}</div>
    </div>
  );
};

export default ForgotPasswordScreen;
