const SelectionTabFill = (props) => {
  const { optionList, selectedId, handleSelectOnClick } = props;

  return (
    <div className="w-full flex flex-row bg-catchup-gray-50 gap-x-2 rounded-catchup-medium px-4 py-2 justify-center text-center">
      {optionList.map((option) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            handleSelectOnClick(option.id);
          }}
        >
          <p
            className={`${
              selectedId === option.id
                ? "text-catchup-white bg-catchup-blue-500"
                : "text-catchup-gray-300"
            } transition-all duration-300 rounded-catchup-medium px-2 py-1`}
          >
            {option.title}
          </p>
        </div>
      ))}
    </div>
  );
};

export default SelectionTabFill;
