import { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import DraggableDroppableItem from "../../draggable/DraggableDroppableItem";
import ShowMaterialMediaByContentType from "./ShowMaterialMediaByContentType";
import { InlineMath } from "react-katex";
import useScreenSize from "../../../hooks/useScreenSize";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";

const OrderingActivityMaterialContent = (props) => {
  const {
    uniqueValue,
    answer,
    materialMap,
    contentMap,
    checkCanAnswerQuestion,
    onChange,
    isPreview,
    showCorrectAnswer,
  } = props;
  const [selectedTargetKey, setSelectedTargetKey] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const { screenSize } = useScreenSize();
  const [view, setView] = useState("PC");
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "ORDERING",
    drop: () => {},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (!screenSize) return;
    if (screenSize.width <= 768) {
      setView("TABLET");
    } else {
      setView("PC");
    }
  }, [screenSize]);

  useEffect(() => {
    if (!showCorrectAnswer) return;
    const answerMap = answer.data.find(
      (answerData) => answerData.type === "ORDERING"
    ).answerMap;
    Object.keys(answerMap).forEach((answerKey, index) => {
      answerMap[answerKey] = index;
    });
  }, [showCorrectAnswer]);

  const retrieveAnswerMap = () => {
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "ORDERING"
    );
    return answer.data[foundIndex].answerMap;
  };

  const checkAnswerState = (correctAnswer, learnerAnswer) => {
    if (!isPreview) return "HIDDEN";
    if (correctAnswer === learnerAnswer) {
      return "CORRECT";
    }
    return "INCORRECT";
  };

  const handleOrderingActivityItemChange = (selectedKey, materialKey) => {
    if (checkCanAnswerQuestion()) {
      if (selectedKey) {
        onChange(answer, selectedKey, materialKey);
        setSelectedKey(null);
      } else {
        setSelectedKey(materialKey);
      }
    }
  };

  const calculateMarginTop = (index) => {
    if (index === 0) {
      return 0;
    } else if (index === 1) {
      return 150;
    } else if (index % 2 === 0) {
      return -150;
    } else if (index % 2 === 1) {
      return 0;
    }
    return 0;
  };

  const answerMap = retrieveAnswerMap();

  return (
    <div className="flex flex-row flex-wrap">
      {Object.keys(answerMap).map((materialKey, index) => {
        const learnerAnswerState = checkAnswerState(
          answerMap[materialKey] + "",
          index + ""
        );
        return (
          <div className="w-full lg:w-1/2" key={index}>
            <div
              className={`flex flex-row items-center my-4 mx-2`}
              style={{
                marginTop:
                  view === "PC"
                    ? calculateMarginTop(parseFloat(materialKey))
                    : 0,
              }}
            >
              <div className="mr-3">
                <div
                  className={`${
                    learnerAnswerState === "EMPTY"
                      ? selectedKey === materialKey
                        ? "border-catchup-blue"
                        : "border-catchup-lighter-gray"
                      : learnerAnswerState === "CORRECT"
                      ? "border-catchup-green"
                      : learnerAnswerState === "INCORRECT"
                      ? "border-catchup-red"
                      : ""
                  } min-h-catchup-activity-outer-box-item w-catchup-activity-box-item flex flex-col items-center justify-center  border-2 rounded-catchup-xlarge cursor-pointer transition-all duration-300`}
                >
                  <div
                    className={`${
                      selectedKey === materialKey
                        ? "border-2 border-catchup-light-gray"
                        : "border-2 border-catchup-blue"
                    } flex flex-col items-center justify-center transition-all duration-300 rounded-full w-[50px] h-[50px]`}
                  >
                    <p className="">{parseFloat(materialKey) + 1}</p>
                  </div>
                </div>
              </div>

              <DraggableDroppableItem
                item={{ index: materialKey }}
                type={"ORDERING"}
                dropRef={drop}
                component={
                  <div
                    className={`${
                      canDrop
                        ? selectedKey !== materialKey
                          ? selectedTargetKey === materialKey
                            ? "bg-catchup-light-blue rounded-catchup-xlarge"
                            : "bg-catchup-light-blue rounded-catchup-xlarge opacity-40"
                          : "rounded-none"
                        : ""
                    } flex-1 min-h-catchup-activity-outer-box-item flex flex-col items-center justify-center border-2 rounded-catchup-xlarge cursor-pointer p-3 ${
                      learnerAnswerState === "EMPTY"
                        ? "border-catchup-blue"
                        : learnerAnswerState === "CORRECT"
                        ? "border-catchup-green"
                        : learnerAnswerState === "INCORRECT"
                        ? "border-catchup-red"
                        : ""
                    }`}
                    onMouseDown={() => {
                      if (checkCanAnswerQuestion()) {
                        setSelectedKey(materialKey);
                      }
                    }}
                  >
                    {contentMap.type === "TEXT" ? (
                      <p className="text-xl whitespace-pre-wrap">
                        {constructInputWithSpecialExpressionList(
                          materialMap[answerMap[materialKey]]
                        ).map((inputPart, index) => (
                          <span
                            key={index}
                            className={`${
                              inputPart.isBold ? "font-bold" : ""
                            } ${inputPart.isUnderline ? "underline" : ""}`}
                          >
                            {inputPart.isEquation ? (
                              <span className="text-2xl">
                                <InlineMath math={inputPart.value} />
                              </span>
                            ) : (
                              inputPart.value
                            )}
                          </span>
                        ))}
                      </p>
                    ) : (
                      <ShowMaterialMediaByContentType
                        uniqueValue={`${uniqueValue}-${index}`}
                        contentType={contentMap.type}
                        src={materialMap[answerMap[materialKey]]}
                        canFullScreen={true}
                      />
                    )}
                  </div>
                }
                target={selectedTargetKey}
                setTarget={setSelectedTargetKey}
                moveCardHandler={() => {
                  handleOrderingActivityItemChange(
                    selectedKey,
                    selectedTargetKey
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderingActivityMaterialContent;
