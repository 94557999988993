import { useEffect, useState } from "react";
import useScreenSize from "../../../hooks/useScreenSize";
import i18n from "../../../language/i18n";
import ShowMaterialMediaByContentType from "./ShowMaterialMediaByContentType";
import { InlineMath } from "react-katex";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";
import BaseImage from "../../images/BaseImage";
import DividerLine from "../../divider/DividerLine";

const TrueFalseActivityMaterialContent = (props) => {
  const {
    uniqueValue,
    answer,
    materialMap,
    contentMap,
    checkCanAnswerQuestion,
    onChange,
    isPreview,
    showCorrectAnswer,
  } = props;

  const { screenSize } = useScreenSize();
  const [shuffleOptionList, setShuffleOptionList] = useState([]);

  useEffect(() => {
    const optionList = [];
    optionList.push(...materialMap.trueList);
    optionList.push(...materialMap.falseList);
    // setShuffleOptionList(shuffleArray(optionList));
    setShuffleOptionList(optionList);
  }, []);

  useEffect(() => {
    if (!showCorrectAnswer) return;
    answer.data.find(
      (answerData) => answerData.type === "TRUE_FALSE"
    ).answerMap = materialMap;
  }, [showCorrectAnswer]);

  const retrieveAnswer = () => {
    if (!answer)
      return {
        answerMap: {
          trueList: [],
          falseList: [],
        },
      };
    return answer.data.find((answerData) => answerData.type === "TRUE_FALSE");
  };

  const retrieveAnswerMap = () => {
    const { answerMap } = retrieveAnswer();
    return answerMap;
  };

  const checkAnswerState = (correctAnswer, learnerAnswer) => {
    if (!isPreview) return "HIDDEN";
    if (correctAnswer === learnerAnswer) {
      return "CORRECT";
    }
    return "INCORRECT";
  };

  const answerMap = retrieveAnswerMap();
  return (
    <div className="">
      <div className="hidden md:block">
        <span className="font-semibold text-xl opacity-60">
          {i18n.t("please_select_true_false_text")}
        </span>
      </div>
      <div className="hidden md:contents">
        <DividerLine />
      </div>
      <div className="flex flex-row justify-end items-center gap-x-2">
        <div className="w-[50px]">
          <p className="font-bold text-lg">{i18n.t("true")}</p>
        </div>
        <div className="w-[50px]">
          <p className="font-bold text-lg">{i18n.t("false")}</p>
        </div>
      </div>
      {checkCanAnswerQuestion() ? (
        <div className={`flex flex-row w-full justify-center flex-wrap`}>
          {shuffleOptionList.map((shuffleOption, index) => {
            const correctAnswer =
              materialMap.trueList.find(
                (trueItems) => trueItems === shuffleOption
              ) !== undefined
                ? "TRUE"
                : "FALSE";
            const learnerAnswer =
              answerMap.trueList.find(
                (trueItems) => trueItems === shuffleOption
              ) !== undefined
                ? "TRUE"
                : "FALSE";

            const learnerAnswerState = checkAnswerState(
              correctAnswer,
              learnerAnswer
            );

            return (
              <div
                key={index}
                className={`w-full flex flex-row items-center justify-center cursor-pointer my-2 ${
                  learnerAnswerState === "CORRECT"
                    ? "border-2 border-catchup-green rounded-catchup-xlarge"
                    : learnerAnswerState === "INCORRECT"
                    ? "border-2 border-catchup-red rounded-catchup-xlarge"
                    : ""
                }`}
              >
                <div className="flex-1">
                  {contentMap.type === "TEXT" ? (
                    <p className="text-xl p-2 whitespace-pre-wrap">
                      {constructInputWithSpecialExpressionList(
                        shuffleOption
                      ).map((inputPart, index) => (
                        <span
                          key={index}
                          className={`${inputPart.isBold ? "font-bold" : ""} ${
                            inputPart.isUnderline ? "underline" : ""
                          }`}
                        >
                          {inputPart.isEquation ? (
                            <span className="text-2xl">
                              <InlineMath math={inputPart.value} />
                            </span>
                          ) : (
                            inputPart.value
                          )}
                        </span>
                      ))}
                    </p>
                  ) : (
                    <ShowMaterialMediaByContentType
                      key={`${uniqueValue}-${index}`}
                      contentType={contentMap.type}
                      src={shuffleOption}
                      canFullScreen={true}
                    />
                  )}
                </div>
                <div className="flex flex-row items-center gap-x-2">
                  <div className="w-[50px]">
                    <div className="flex flex-col items-center justify-center">
                      <BaseImage
                        src={
                          answerMap.trueList.includes(shuffleOption)
                            ? "/icons/checkbox.png"
                            : "/icons/empty-checkbox.png"
                        }
                        alt="checkbox-empty-checkbox"
                        size="small"
                        onClick={() => {
                          onChange(answer, "TRUE", shuffleOption);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-[50px]">
                    <div className="flex flex-col items-center justify-center">
                      <BaseImage
                        src={
                          answerMap.falseList.includes(shuffleOption)
                            ? "/icons/checkbox.png"
                            : "/icons/empty-checkbox.png"
                        }
                        alt="checkbox-empty-checkbox"
                        size="small"
                        onClick={() => {
                          onChange(answer, "FALSE", shuffleOption);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {answerMap.trueList.map((item) => (
            <div className="flex flex-row items-center gap-x-2">
              <div className="flex-1">
                <p>{item}</p>
              </div>
              <div className="w-[50px]">
                <p className="underline">{i18n.t("true")}</p>
              </div>
            </div>
          ))}
          {answerMap.falseList.map((item) => (
            <div className="flex flex-row items-center gap-x-2">
              <div className="flex-1">
                <p>{item}</p>
              </div>
              <div className="w-[50px]">
                <p className="underline">{i18n.t("false")}</p>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TrueFalseActivityMaterialContent;
