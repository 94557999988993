const colors = [
  "#FF5733", // Fiery Red
  "#3357FF", // Royal Blue
  "#FF33A1", // Fuchsia Pink
  "#33FFF0", // Aqua Blue
  "#FF9633", // Tangerine
  "#8D33FF", // Violet
  "#FFD733", // Sunny Yellow
  "#33FF6D", // Mint Green
  "#FFB833", // Amber
  "#33C7FF", // Sky Blue
  "#FF33E4", // Magenta
  "#5EFF33", // Neon Green
  "#FF3380", // Hot Pink
  "#33FFAF", // Seafoam Green
  "#3383FF", // Dodger Blue
  "#FF9533", // Pumpkin Orange
  "#BF33FF", // Electric Purple
  "#FFDA33", // Golden Yellow
  "#33FF89", // Turquoise
  "#FF3333", // Red
  "#33BFFF", // Light Blue
  "#FF33B3", // Pink
  "#5BFF33", // Light Green
  "#FF3385", // Rose
  "#33FFC9", // Aquamarine
  "#33A1FF", // Azure
  "#FF7A33", // Cantaloupe
  "#A533FF", // Lavender
  "#FFE033", // Mustard Yellow
  "#33FF7A", // Apple Green
  "#FF4D33", // Bright Red
  "#33FF95", // Light Turquoise
  "#FFC433", // Peach
  "#33FF7D", // Green Apple
  "#FF338A", // Raspberry Pink
  "#33FFE4", // Light Aqua
  "#336AFF", // Cobalt Blue
  "#FF3370", // Raspberry
  "#33FF95", // Light Green
  "#FFA633", // Orange
  "#33FF57", // Lime Green
];

export const shuffleArray = (array) => {
  const copyArray = JSON.parse(JSON.stringify(array));
  for (let i = copyArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
  }
  return copyArray;
};

export const getColorByIndex = (index) => {
  return colors[index];
};
