import { useState } from "react";
import Modal from "react-modal";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";
import { InlineMath } from "react-katex";
import BaseImage from "../../images/BaseImage";
import i18n from "../../../language/i18n";

const ShowBodyMediaByContentType = (props) => {
  const { index, type, value, size } = props;
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedFullScreenItem, setSelectedFullScreenItem] = useState(null);

  const convertToPercentage = (size) => {
    if (size === "1/3") {
      return "w-small-media";
    } else if (size === "1/2") {
      return "w-medium-media";
    } else if (size === "1") {
      return "w-large-media";
    }
  };

  const retrieveValueParts = (value) => {
    let currentIndex = 0;
    const valuePartList = [];
    let copyValue = JSON.parse(JSON.stringify(value));
    currentIndex = 0;
    let checkText = "--TEXT--";
    while (copyValue.includes(checkText)) {
      const firstIndex = copyValue.indexOf(checkText);
      let textValue = copyValue.substring(0, firstIndex);
      let addition = 0;
      if (textValue.trim() !== "") {
        if ((textValue.split("__").length - 1) % 2 === 1) {
          textValue = textValue + "__";
          addition += 2;
        }
        if ((textValue.split("**").length - 1) % 2 === 1) {
          textValue = textValue + "**";
          addition += 2;
        }
        if ((textValue.split("`").length - 1) % 2 === 1) {
          textValue = textValue + "`";
          addition += 1;
        }
        valuePartList.push(
          <span
            key={`${index}-${currentIndex}`}
            className="text-xl whitespace-pre-wrap"
          >
            {constructInputWithSpecialExpressionList(textValue).map(
              (inputPart, index) => (
                <span
                  key={index}
                  className={`${inputPart.isBold ? "font-bold" : ""} ${
                    inputPart.isUnderline ? "underline" : ""
                  }`}
                >
                  {inputPart.isEquation ? (
                    <span className="text-2xl">
                      <InlineMath math={inputPart.value} />
                    </span>
                  ) : (
                    inputPart.value
                  )}
                </span>
              )
            )}
          </span>
        );
      }
      const subValue = copyValue.substring(firstIndex + checkText.length);
      const secondIndex =
        subValue.indexOf(checkText) +
        checkText.length +
        textValue.length -
        addition;
      currentIndex++;
      valuePartList.push(
        <span
          key={`${index}-${currentIndex}`}
          className={`text-xl font-bold whitespace-pre-wrap`}
        >
          {constructInputWithSpecialExpressionList(
            copyValue.substring(firstIndex + checkText.length, secondIndex)
          ).map((inputPart, index) => (
            <span
              key={index}
              className={`${inputPart.isBold ? "font-bold" : ""} ${
                inputPart.isUnderline ? "underline" : ""
              }`}
            >
              {inputPart.isEquation ? (
                <span className="text-2xl">
                  <InlineMath math={inputPart.value} />
                </span>
              ) : (
                inputPart.value
              )}
            </span>
          ))}
        </span>
      );
      copyValue = copyValue.substring(secondIndex + checkText.length);
      if ((copyValue.split("`").length - 1) % 2 === 1) {
        copyValue = "`" + copyValue;
      }
    }
    checkText = "--IMAGE--";
    while (copyValue.includes(checkText)) {
      const firstIndex = copyValue.indexOf(checkText);
      const textValue = copyValue.substring(0, firstIndex);
      if (textValue.trim() !== "") {
        currentIndex++;
        valuePartList.push(
          <p key={`${index}_${currentIndex}`} className="text-xl">
            {textValue}
          </p>
        );
      }
      const subValue = copyValue.substring(firstIndex + checkText.length);
      const secondIndex =
        subValue.indexOf(checkText) + checkText.length + textValue.length;
      const imageSource = copyValue.substring(
        firstIndex + checkText.length,
        secondIndex
      );
      currentIndex++;
      valuePartList.push(
        <div key={`${index}-${currentIndex}`} className="relative w-[200px]">
          <BaseImage
            src={imageSource}
            alt="media"
            size="custom"
            className="rounded-catchup-xlarge"
          />
          <div
            className="absolute flex items-center justify-center top-2 right-2  h-6 w-6 cursor-pointer border rounded-catchup-xlarge border-catchup-blue p-1"
            onClick={(event) => {
              setShowFullScreen(true);
              setSelectedFullScreenItem(imageSource);
            }}
          >
            <BaseImage
              src="/icons/arrow-up.png"
              alt="arrow-up"
              size="custom"
              className="w-full"
            />
          </div>
        </div>
      );
      copyValue = copyValue.substring(secondIndex + checkText.length);
    }
    checkText = "--VIDEO--";
    while (copyValue.includes(checkText)) {
      const firstIndex = copyValue.indexOf(checkText);
      const textValue = copyValue.substring(0, firstIndex);
      if (textValue.trim() !== "") {
        currentIndex++;
        valuePartList.push(
          <p key={`${index}-${currentIndex}`} className="text-xl">
            {textValue}
          </p>
        );
      }
      const subValue = copyValue.substring(firstIndex + checkText.length);
      const secondIndex =
        subValue.indexOf(checkText) + checkText.length + textValue.length;
      const videoSource = copyValue.substring(
        firstIndex + checkText.length,
        secondIndex
      );
      currentIndex++;
      valuePartList.push(
        <video
          key={`${index}-${currentIndex}`}
          src={videoSource}
          className={`w-[200px] rounded-catchup-xlarge`}
        />
      );
      copyValue = copyValue.substring(secondIndex + checkText.length);
    }
    checkText = "--AUDIO--";
    while (copyValue.includes(checkText)) {
      const firstIndex = copyValue.indexOf(checkText);
      const textValue = copyValue.substring(0, firstIndex);
      if (textValue.trim() !== "") {
        currentIndex++;
        valuePartList.push(
          <p key={`${index}-${currentIndex}`} className="text-xl">
            {textValue}
          </p>
        );
      }
      const subValue = copyValue.substring(firstIndex + checkText.length);
      const secondIndex =
        subValue.indexOf(checkText) + checkText.length + textValue.length;
      const audioSource = copyValue.substring(
        firstIndex + checkText.length,
        secondIndex
      );
      currentIndex++;
      valuePartList.push(
        <audio
          key={`${index}-${currentIndex}`}
          src={audioSource}
          className={`w-[200px] rounded-catchup-xlarge`}
        />
      );
      copyValue = copyValue.substring(secondIndex + checkText.length);
    }
    if (copyValue.trim() !== "") {
      currentIndex++;
      if ((copyValue.split("__").length - 1) % 2 === 1) {
        copyValue = "__" + copyValue;
      }
      if ((copyValue.split("**").length - 1) % 2 === 1) {
        copyValue = "**" + copyValue;
      }
      if ((copyValue.split("`").length - 1) % 2 === 1) {
        copyValue = "`" + copyValue;
      }
      const regexMatchImageText = copyValue.match(/<image>([\s\S]*?)<\/image>/);
      if (regexMatchImageText) {
        const imageText = regexMatchImageText[1];
        valuePartList.push(
          <div className="bg-catchup-gray-50 relative px-4 py-4 rounded-md mt-2">
            <div className="absolute -top-3 bg-catchup-white border rounded-md px-2 left-2">
              <p className="font-bold">{i18n.t("image_description")}</p>
            </div>
            <span
              key={`${index}-${currentIndex}`}
              className="text-xl whitespace-pre-wrap "
            >
              {imageText}
            </span>
          </div>
        );
      } else {
        valuePartList.push(
          <span
            key={`${index}-${currentIndex}`}
            className="text-xl whitespace-pre-wrap"
          >
            {constructInputWithSpecialExpressionList(copyValue).map(
              (inputPart, index) => (
                <span
                  key={index}
                  className={`${inputPart.isBold ? "font-bold" : ""} ${
                    inputPart.isUnderline ? "underline" : ""
                  }`}
                >
                  {inputPart.isEquation ? (
                    <span className="text-2xl">
                      <InlineMath math={inputPart.value} />
                    </span>
                  ) : (
                    inputPart.value
                  )}
                </span>
              )
            )}
          </span>
        );
      }
    }
    return valuePartList;
  };

  const RenderShowFullScreenItem = () => {
    return (
      <Modal
        isOpen={showFullScreen}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setShowFullScreen(false);
          setSelectedFullScreenItem(null);
        }}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
            borderRadius: 20,
            background: "",
            border: "",
            width: "900px",
            maxHeight: "70%",
            overflow: "auto",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.6)",
          },
        }}
        contentLabel=""
      >
        <div className="flex-1 flex flex-col">
          {/* <div className="m-4"> */}
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setShowFullScreen(false);
                setSelectedFullScreenItem(null);
              }}
            />
          </div>
          <div className="flex items-center justify-center h-[500]">
            <BaseImage
              src={selectedFullScreenItem}
              alt="selected-fullscreen-item"
              size="custom"
              className="w-full"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const RenderMainContent = () => {
    if (type === "TEXT") {
      return (
        <div className="mb-3 flex flex-row flex-wrap items-center mx-auto w-full">
          <span>{retrieveValueParts(value)}</span>
        </div>
      );
    } else if (type === "IMAGE") {
      return (
        <div className="mb-3 flex flex-col items-center relative">
          <div
            className={`${
              size ? `${convertToPercentage(size)}` : ""
            } rounded-catchup-xlarge relative`}
          >
            <BaseImage
              src={value}
              alt="body-image"
              size="custom"
              className="w-full rounded-catchup-xlarge"
            />
            <div
              className="absolute flex items-center justify-center top-2 right-2  h-6 w-6 cursor-pointer border rounded-catchup-xlarge border-catchup-blue p-1"
              onClick={() => {
                setShowFullScreen(true);
                setSelectedFullScreenItem(value);
              }}
            >
              <BaseImage
                src="/icons/arrow-up.png"
                alt="arrow-up"
                size="custom"
                className="w-full"
              />
            </div>
          </div>
        </div>
      );
    } else if (type === "VIDEO") {
      return (
        <div className="mb-3 flex flex-col items-center">
          <video
            src={value}
            className={`${
              size ? `${convertToPercentage(size)}` : ""
            } rounded-catchup-xlarge`}
            controls
          />
        </div>
      );
    } else if (type === "AUDIO") {
      return (
        <div className="mb-3 flex flex-col items-center">
          <audio src={value} controls className={`rounded-catchup-xlarge`} />
        </div>
      );
    }
  };

  return (
    <div key={index} className="w-full">
      {RenderShowFullScreenItem()}
      {RenderMainContent()}
    </div>
  );
};

export default ShowBodyMediaByContentType;
