import i18n from "../../language/i18n";

const SearchFriends = (props) => {
  const { seasonId } = props;

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2 overflow-y-auto no-scrollbar blur-sm">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("find_friends")}
          </p>
        </div>
      </div>
      <div className="flex-1 min-h-[200px]"></div>
    </div>
  );
};

export default SearchFriends;
