import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DropdownActivityContent from "../../components/activities/DropdownActivityContent";
import FillInTheBlanksActivityContent from "../../components/activities/FillInTheBlanksActivityContent";
import GroupingActivityContent from "../../components/activities/GroupingActivityContent";
import MatchingActivityContent from "../../components/activities/MatchingActivityContent";
import MCMAActivityContent from "../../components/activities/MCMAActivityContent";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import OpenEndedActivityContent from "../../components/activities/OpenEndedActivityContent";
import OrderingActivityContent from "../../components/activities/OrderingActivityContent";
import FullCard from "../../components/cards/FullCard";
import { useApp } from "../../context/AppContextProvider";
import i18n from "../../language/i18n";
import { constructActivityAnswerMap } from "../../utilization/CatchtivityUtilization";
import ActivityEmptyContent from "../../components/activities/empty-content/ActivityEmptyContent";
import TrueFalseActivityContent from "../../components/activities/TrueFalseActivityContent";
import SelectionBox from "../../components/boxes/SelectionBox";
import DividerLine from "../../components/divider/DividerLine";

const ActivityPreviewByLearnerDataScreen = () => {
  const { base64Data } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const data = JSON.parse(
    decodeURI(atob(base64Data.replaceAll("CATCHUPPER_SLASH", "/")))
  );
  const [answer, setAnswer] = useState({
    data: [],
  });
  const [selectedActivityTemplateType, setSelectedActivityTemplateType] =
    useState(null);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    const checkAnswerMapExists = (type) => {
      if (Object.keys(data).find((dataKey) => dataKey === "answerMap")) {
        const foundAnswer = data["answerMap"].find(
          (answer) => answer.type === type
        );
        if (foundAnswer) {
          return foundAnswer;
        }
      }
      return null;
    };
    const constructAnswerBasedOnData = () => {
      const answer = { data: [] };
      if (
        Object.keys(data).find((dataKey) => dataKey === "orderingMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("ORDERING");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "ORDERING" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (
        Object.keys(data).find((dataKey) => dataKey === "dropdownMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("DROPDOWN");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "DROPDOWN" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (Object.keys(data).find((dataKey) => dataKey === "MCSAMaterialMap")) {
        const foundAnswer = checkAnswerMapExists("MCSA");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MCSA" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (Object.keys(data).find((dataKey) => dataKey === "MCMAMaterialMap")) {
        const foundAnswer = checkAnswerMapExists("MCMA");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MCMA" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (
        Object.keys(data).find((dataKey) => dataKey === "matchingMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("MATCHING");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "MATCHING" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (
        Object.keys(data).find((dataKey) => dataKey === "groupingMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("GROUPING");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "GROUPING" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (
        Object.keys(data).find(
          (dataKey) => dataKey === "fillInTheBlanksMaterialMap"
        )
      ) {
        const foundAnswer = checkAnswerMapExists("FILL_IN_THE_BLANKS");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "FILL_IN_THE_BLANKS" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (
        Object.keys(data).find((dataKey) => dataKey === "openEndedMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("OPEN_ENDED");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "OPEN_ENDED" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (
        Object.keys(data).find((dataKey) => dataKey === "trueFalseMaterialMap")
      ) {
        const foundAnswer = checkAnswerMapExists("TRUE_FALSE");
        answer.data.push(
          foundAnswer
            ? foundAnswer
            : constructActivityAnswerMap(
                { type: "TRUE_FALSE" },
                JSON.parse(JSON.stringify(data))
              )
        );
      }
      if (answer.data.length > 0) {
        setSelectedActivityTemplateType(answer.data[0].type);
      }
      setAnswer(answer);
    };
    constructAnswerBasedOnData();
  }, [base64Data]);

  const retrieveTaxonomyNameFromActivityType = (type) => {
    let taxonomyMap = {
      name: "",
    };
    if (type === "ORDERING") {
      taxonomyMap = JSON.parse(data["orderingTaxonomyMap"]);
    } else if (type === "DROPDOWN") {
      taxonomyMap = JSON.parse(data["dropdownTaxonomyMap"]);
    } else if (type === "MCSA") {
      taxonomyMap = JSON.parse(data["MCSATaxonomyMap"]);
    } else if (type === "MCMA") {
      taxonomyMap = JSON.parse(data["MCMATaxonomyMap"]);
    } else if (type === "MATCHING") {
      taxonomyMap = JSON.parse(data["matchingTaxonomyMap"]);
    } else if (type === "GROUPING") {
      taxonomyMap = JSON.parse(data["groupingTaxonomyMap"]);
    } else if (type === "FILL_IN_THE_BLANKS") {
      taxonomyMap = JSON.parse(data["fillInTheBlanksTaxonomyMap"]);
    } else if (type === "OPEN_ENDED") {
      taxonomyMap = JSON.parse(data["openEndedTaxonomyMap"]);
    } else if (type === "TRUE_FALSE") {
      taxonomyMap = JSON.parse(data["trueFalseTaxonomyMap"]);
    }
    return taxonomyMap.name;
  };

  const RenderSelectedActivityContent = () => {
    if (selectedActivityTemplateType === "ORDERING") {
      return (
        <OrderingActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "DROPDOWN") {
      return (
        <DropdownActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCSA") {
      return (
        <MCSAActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCMA") {
      return (
        <MCMAActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MATCHING") {
      return (
        <MatchingActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "GROUPING") {
      return (
        <GroupingActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
      return (
        <FillInTheBlanksActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "OPEN_ENDED") {
      return (
        <OpenEndedActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          showMaterialContent={true}
        />
      );
    } else if (selectedActivityTemplateType === "TRUE_FALSE") {
      return (
        <TrueFalseActivityContent
          answer={answer}
          data={data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        <div className="my-2">
          <p className="font-semibold text-lg">{i18n.t("activity_template")}</p>
        </div>
        <SelectionBox
          optionList={answer.data.map((answerItem) => ({
            ...answerItem,
            id: answerItem.type,
            text: i18n.t(answerItem.type),
            subText: i18n.t(
              retrieveTaxonomyNameFromActivityType(answerItem.type)
            ),
          }))}
          selectedId={selectedActivityTemplateType}
          handleSelectOnClick={(itemId) => {
            setSelectedActivityTemplateType(itemId);
          }}
        />
        <DividerLine />
        <div className="flex flex-col my-2 w-full p-5">
          {answer?.data[0]?.isEmpty ? <ActivityEmptyContent /> : null}
          {RenderSelectedActivityContent()}
        </div>
      </FullCard>
    );
  };

  return (
    <div className="mx-auto container h-full">
      <div className="h-[calc(100vh-15px)] pt-[15px]">
        {RenderMainContent()}
      </div>
    </div>
  );
};

export default ActivityPreviewByLearnerDataScreen;
