import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const DraggableDroppableItem = (props) => {
  const ref = useRef(null);
  const { item, type, component, moveCardHandler, target, setTarget, dropRef } =
    props;

  const [, drop] = useDrop({
    accept: type,
    hover() {
      if (!ref.current) {
        return;
      }
      if (item.index && target !== item.index) {
        setTarget(item.index);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        moveCardHandler();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  return (
    <div
      className={`${
        isDragging ? "w-[0px] opacity-0" : "w-full opacity-100"
      } transition-all duration-500`}
      ref={dropRef}
    >
      <div ref={ref} className="w-full" style={{ opacity }}>
        {component}
      </div>
    </div>
  );
};

export default DraggableDroppableItem;
