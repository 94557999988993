import { useEffect, useState, useTransition } from "react";
import i18n from "../../language/i18n";
import InputGroup from "../groups/InputGroup";
import { retrieveLanguageOptionList } from "../../utilization/HeaderUtilization";
import PrimaryButton from "../buttons/PrimaryButton";
import { patchUser } from "../../requests/ManagementRequests";
import BaseImage from "../images/BaseImage";

const Settings = (props) => {
  const { userInformation, setUserInformation, lang, setLang } = props;
  const [id, setId] = useState(null);
  const [username, setUsername] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [identityNumber, setIdentityNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [aboutMe, setAboutMe] = useState(null);
  const [language, setLanguage] = useState("TR");
  const [isChanged, setIsChanged] = useState(false);
  const [errorMap, setErrorMap] = useState({
    firstName: null,
    lastName: null,
    aboutMe: null,
  });

  useEffect(() => {
    if (!userInformation) return;
    setId(userInformation.id);
    setUsername(userInformation.username);
    setFirstName(userInformation.firstName);
    setLastName(userInformation.lastName);
    setIdentityNumber(userInformation.identityNumber);
    setEmail(userInformation.email);
    setAboutMe(userInformation.aboutMe);
  }, [userInformation]);

  useEffect(() => {
    if (!userInformation) return;
    let currentIsChanged = false;
    if (firstName !== userInformation.firstName) {
      currentIsChanged = true;
    }
    if (!currentIsChanged && lastName !== userInformation.lastName) {
      currentIsChanged = true;
    }
    if (!currentIsChanged && aboutMe !== userInformation.aboutMe) {
      currentIsChanged = true;
    }
    if (!currentIsChanged && language !== lang) {
      currentIsChanged = true;
    }
    setIsChanged(currentIsChanged);
  }, [userInformation, firstName, lastName, aboutMe, language]);

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const checkCommonValidation = () => {
    if (
      !firstName ||
      firstName.trim() === "" ||
      !lastName ||
      lastName.trim() === "" ||
      !aboutMe ||
      aboutMe.trim() === ""
    ) {
      const currentErrorMap = {};
      if (!firstName || firstName.trim() === "") {
        currentErrorMap.firstName = i18n.t("first_name_required_field");
      }
      if (!lastName || lastName.trim() === "") {
        currentErrorMap.lastName = i18n.t("last_name_required_field");
      }
      if (!aboutMe || aboutMe.trim() === "") {
        currentErrorMap.aboutMe = i18n.t("about_me_required_field");
      }
      setErrorMap(currentErrorMap);
      return false;
    }
    return true;
  };

  const handleOnChange = (key, value) => {
    if (key === "firstName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: i18n.t("first_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: null,
        }));
      }
      setFirstName(value);
    } else if (key === "lastName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: i18n.t("last_name_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: null,
        }));
      }
      setLastName(value);
    } else if (key === "aboutMe") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          aboutMe: i18n.t("about_me_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          aboutMe: null,
        }));
      }
      setAboutMe(value);
    } else if (key === "language") {
      setLanguage(value);
    }
  };

  const handleUpdateUserInformationOnClick = async () => {
    if (checkCommonValidation()) {
      const newUserInformation = {
        id,
        firstName,
        lastName,
        aboutMe,
        identityNumber,
        email,
      };
      const { data, err } = await patchUser(newUserInformation);
      if (err) {
        console.log(err);
        return err;
      }
      setUserInformation(data);
      if (lang !== language) {
        setLang(language);
        window.location.reload();
      }
      setIsChanged(false);
    }
  };

  const languageOptionList = retrieveLanguageOptionList();

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2 overflow-y-auto no-scrollbar">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("settings")}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row flex-wrap gap-y-1">
        <div className="w-full">
          <div className="">
            <InputGroup
              type="text"
              value={username}
              disabled={true}
              title={i18n.t("username")}
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="">
            <InputGroup
              type="select"
              title={i18n.t("language")}
              value={language}
              optionList={languageOptionList}
              onChange={(event) => {
                handleOnChange("language", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center gap-x-2">
          <div className="flex-1">
            <div className="">
              <InputGroup
                type="text"
                value={firstName}
                title={i18n.t("first_name")}
                onChange={(event) => {
                  handleOnChange("firstName", event.target.value);
                }}
                errorText={errorMap.firstName}
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="">
              <InputGroup
                type="text"
                value={lastName}
                title={i18n.t("last_name")}
                onChange={(event) => {
                  handleOnChange("lastName", event.target.value);
                }}
                errorText={errorMap.lastName}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="">
            <InputGroup
              type="textarea"
              limit={255}
              value={aboutMe}
              title={i18n.t("about_me")}
              onChange={(event) => {
                handleOnChange("aboutMe", event.target.value);
              }}
              errorText={errorMap.aboutMe}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-row gap-x-2">
            <div className="flex-1">
              <BaseImage src="icons/info.png" alt="info" size="small" />
            </div>
            <p className="text-catchup-gray-400">
              {i18n.t("update_user_information_settings_warning_text")}
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="">
            <InputGroup
              type="number"
              value={identityNumber}
              disabled={true}
              title={i18n.t("identity_number")}
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="">
            <InputGroup
              type="text"
              value={email}
              disabled={true}
              title={i18n.t("email")}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end mt-5">
        <PrimaryButton
          title={i18n.t("update")}
          disabled={!isChanged}
          size="unlimited"
          onClick={handleUpdateUserInformationOnClick}
        />
      </div>
    </div>
  );
};

export default Settings;
