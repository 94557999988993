import { useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import DraggableItem from "../../draggable/DraggableItem";
import DroppableItem from "../../draggable/DroppableItem";
import ShowMaterialMediaByContentType from "./ShowMaterialMediaByContentType";
import { InlineMath } from "react-katex";
import useScreenSize from "../../../hooks/useScreenSize";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";
import DividerLine from "../../divider/DividerLine";

const GroupingActivityMaterialContent = (props) => {
  const {
    uniqueValue,
    answer,
    materialMap,
    contentMap,
    checkCanAnswerQuestion,
    onChange,
    isPreview,
    showCorrectAnswer,
  } = props;
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedTargetKey, setSelectedTargetKey] = useState(null);
  const [isShuffled, setIsShuffled] = useState(false);
  const [shuffledMaterialList, setShuffledMaterialList] = useState([]);
  const { screenSize, containerSize } = useScreenSize();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "GROUPING",
    drop: () => {},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const ref = useRef(null);
  const itemsRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    if (!ref) return;
    if (!ref.current) return;
    if (!screenSize) return;
    setMaxWidth(ref.current.offsetWidth - 12);
  }, [ref, screenSize]);

  useEffect(() => {
    if (!itemsRef) return;
    if (!itemsRef.current) return;
    if (!containerSize) return;
    itemsRef.current.style.width = containerSize.width - 200;
  }, [itemsRef, containerSize]);

  useEffect(() => {
    const shuffleArray = (array) => {
      if (!isShuffled) {
        const copyArray = JSON.parse(JSON.stringify(array));
        for (let i = copyArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
        }
        setIsShuffled(true);
        return copyArray;
      }
      return array;
    };
    const materialList = [];
    Object.keys(materialMap).forEach((materialKey) => {
      for (const materialValue of materialMap[materialKey]) {
        materialList.push(materialValue);
      }
    });
    setShuffledMaterialList(shuffleArray(materialList));
  }, []);

  useEffect(() => {
    if (!showCorrectAnswer) return;
    answer.data.find((answerData) => answerData.type === "GROUPING").answerMap =
      materialMap;
  }, [showCorrectAnswer]);

  const retrieveAnswerMap = () => {
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "GROUPING"
    );
    const answerMap = answer.data[foundIndex].answerMap;
    return answerMap;
  };

  const retrieveFilteredMaterialList = (answerMap) => {
    const selectedValueList = [];
    Object.keys(answerMap).forEach((key) => {
      answerMap[key].forEach((value) => {
        selectedValueList.push(value);
      });
    });
    return shuffledMaterialList.filter(
      (material) =>
        selectedValueList.findIndex((value) => material === value) === -1
    );
  };

  const checkAnswerState = (correctAnswerList, learnerAnswer) => {
    if (!isPreview) return "HIDDEN";
    if (!learnerAnswer) return "EMPTY";
    if (!correctAnswerList) return "EMPTY";
    const foundIndex = correctAnswerList.findIndex(
      (correctAnswer) => correctAnswer === learnerAnswer
    );
    if (foundIndex !== -1) {
      return "CORRECT";
    }
    return "INCORRECT";
  };

  const handleGroupingActivityItemOnChange = (
    selectedTargetKey,
    selectedValue
  ) => {
    if (checkCanAnswerQuestion()) {
      onChange(answer, selectedTargetKey, selectedValue);
      setSelectedValue(null);
    }
  };

  const answerMap = retrieveAnswerMap();
  const filteredMaterialList = retrieveFilteredMaterialList(answerMap);

  return (
    <>
      <div
        ref={itemsRef}
        className="flex-1 flex flex-row gap-x-4 gap-y-4 overflow-auto py-2"
      >
        {filteredMaterialList.map((materialValue, index) => {
          return (
            <DraggableItem
              key={index}
              item={{ index: materialValue }}
              type={"GROUPING"}
              component={
                <div
                  className={`${
                    selectedValue === materialValue
                      ? "border-catchup-blue"
                      : "border-catchup-lighter-gray"
                  } h-catchup-activity-covering-box-item flex flex-col items-center justify-center border-2 rounded-catchup-xlarge cursor-pointer transition-all duration-300`}
                  onMouseDown={() => {
                    if (checkCanAnswerQuestion()) {
                      setSelectedValue(materialValue);
                    }
                  }}
                  onMouseUp={() => {
                    if (checkCanAnswerQuestion()) {
                      setSelectedValue(null);
                    }
                  }}
                >
                  {contentMap.type === "TEXT" ? (
                    <div
                      className={`flex flex-col items-center justify-center m-4 min-h-[64px] min-w-[200px]`}
                    >
                      <p className="text-xl text-center whitespace-pre-wrap">
                        {constructInputWithSpecialExpressionList(
                          materialValue
                        ).map((inputPart, index) => (
                          <span
                            key={index}
                            className={`${
                              inputPart.isBold ? "font-bold" : ""
                            } ${inputPart.isUnderline ? "underline" : ""}`}
                          >
                            {inputPart.isEquation ? (
                              <span className="text-2xl">
                                <InlineMath math={inputPart.value} />
                              </span>
                            ) : (
                              inputPart.value
                            )}
                          </span>
                        ))}
                      </p>
                    </div>
                  ) : (
                    <ShowMaterialMediaByContentType
                      key={`${uniqueValue}-${index}`}
                      contentType={contentMap.type}
                      src={materialValue}
                      canFullScreen={true}
                    />
                  )}
                </div>
              }
              moveCardHandler={() => {
                handleGroupingActivityItemOnChange(
                  selectedTargetKey,
                  selectedValue
                );
              }}
            />
          );
        })}
      </div>
      {filteredMaterialList.length > 0 ? <DividerLine /> : null}
      {Object.keys(answerMap).map((answerMapKey, index) => (
        <div key={index} className="flex flex-row w-full">
          <div className="w-1/3">
            <div
              className={`border-catchup-blue h-catchup-activity-outer-box-item flex flex-col items-center justify-center  border-2 rounded-catchup-xlarge transition-all duration-300 my-3`}
            >
              <div
                className={`flex flex-col items-center justify-center transition-all duration-300 m-4`}
              >
                <p className="text-xl p-5 whitespace-pre-wrap">
                  {constructInputWithSpecialExpressionList(answerMapKey).map(
                    (inputPart, index) => (
                      <span
                        key={index}
                        className={`${inputPart.isBold ? "font-bold" : ""} ${
                          inputPart.isUnderline ? "underline" : ""
                        }`}
                      >
                        {inputPart.isEquation ? (
                          <span className="text-2xl">
                            <InlineMath math={inputPart.value} />
                          </span>
                        ) : (
                          inputPart.value
                        )}
                      </span>
                    )
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="mx-4 w-[2px] bg-catchup-lighter-gray"></div>
          <div className="flex-1" ref={ref}>
            <div className="h-full py-3">
              <div
                className={`${
                  canDrop
                    ? selectedTargetKey === answerMapKey
                      ? "bg-catchup-light-blue"
                      : "bg-catchup-light-blue opacity-40"
                    : ""
                } flex-1 border-catchup-blue rounded-catchup-xlarge border-2 h-full p-1`}
              >
                <DroppableItem
                  item={{ index: answerMapKey }}
                  type={"GROUPING"}
                  target={selectedTargetKey}
                  setTarget={setSelectedTargetKey}
                  dropRef={drop}
                  component={
                    <div
                      className="h-full flex-1 flex flex-row items-center overflow-x-auto"
                      style={{
                        maxWidth: maxWidth,
                      }}
                    >
                      {answerMap[answerMapKey].map(
                        (answerMapValue, answerMapIndex) => {
                          const learnerAnswerState = checkAnswerState(
                            materialMap[answerMapKey],
                            answerMapValue
                          );
                          return (
                            <div className="p-1">
                              <div className="h-catchup-activity-box-item">
                                <div
                                  className={`${
                                    learnerAnswerState === "EMPTY"
                                      ? "border-catchup-lighter-gray"
                                      : learnerAnswerState === "CORRECT"
                                      ? "border-catchup-green"
                                      : learnerAnswerState === "INCORRECT"
                                      ? "border-catchup-red"
                                      : "border-catchup-blue"
                                  } border-2 rounded-catchup-xlarge h-full flex flex-col items-center justify-center transition-all duration-300 cursor-pointer`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (checkCanAnswerQuestion()) {
                                      onChange(
                                        answer,
                                        answerMapKey,
                                        null,
                                        answerMapIndex
                                      );
                                      setSelectedValue(null);
                                    }
                                  }}
                                >
                                  {contentMap.type === "TEXT" ? (
                                    <div
                                      className={`flex flex-col items-center justify-center transition-all duration-300 min-h-[64px] min-w-[200px]`}
                                    >
                                      <div className="m-2">
                                        <p className="text-xl text-center whitespace-pre-wrap">
                                          {constructInputWithSpecialExpressionList(
                                            answerMapValue
                                          ).map((inputPart, index) => (
                                            <span
                                              key={index}
                                              className={`${
                                                inputPart.isBold
                                                  ? "font-bold"
                                                  : ""
                                              } ${
                                                inputPart.isUnderline
                                                  ? "underline"
                                                  : ""
                                              }`}
                                            >
                                              {inputPart.isEquation ? (
                                                <span className="text-2xl">
                                                  <InlineMath
                                                    math={inputPart.value}
                                                  />
                                                </span>
                                              ) : (
                                                inputPart.value
                                              )}
                                            </span>
                                          ))}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <ShowMaterialMediaByContentType
                                      key={`${uniqueValue}-${index}`}
                                      contentType={contentMap.type}
                                      src={answerMapValue}
                                      canFullScreen={false}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default GroupingActivityMaterialContent;
