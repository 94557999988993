import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import SearchFriends from "../../components/dashboards/SearchFriends";
import Settings from "../../components/dashboards/Settings";
import Mascot from "../../components/dashboards/Mascot";
import Badge from "../../components/dashboards/Badge";
import ProfileWithBanner from "../../components/dashboards/ProfileWithBanner";
import Status from "../../components/dashboards/Status";
import useScreenSize from "../../hooks/useScreenSize";

const UserProfileScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    setLanguage,
    userInformation,
    setUserInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    passiveContentList,
    etudeDTOList,
    thisWeekRankingList,
    allTimeRankingList,
    earnedBadgeDTOList,
    personalStatus,
  } = useAuth();
  const BASE_PADDING = 8;
  const { screenSize } = useScreenSize();
  const [view, setView] = useState("TABLET");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!screenSize) return;
    if (screenSize.width <= 1280) {
      setView("TABLET");
    } else {
      setView("PC");
    }
  }, [screenSize]);

  const RenderTabletContent = () => {
    return (
      <div className="flex flex-row flex-wrap" style={{ rowGap: BASE_PADDING }}>
        <div className="w-full">
          <ProfileWithBanner
            useAbsolute={false}
            userId={userInformation.id}
            userProfileId={userProfile.id}
            language={language}
            firstName={userInformation.firstName}
            lastName={userInformation.lastName}
            aboutMe={userInformation.aboutMe}
            dateOfBirth={userInformation.dateOfBirth}
            thisWeekRankingList={thisWeekRankingList}
            branchName={userProfileBranch.name}
            gradeName={userProfileGrade.name}
            seasonName={userProfileSeason.name}
            institutionName={userProfileSeason.institutionDTO.name}
          />
        </div>
        <div className="w-full flex flex-row">
          <div className="w-[60%]" style={{ paddingRight: BASE_PADDING / 2 }}>
            <Badge earnedBadgeDTOList={earnedBadgeDTOList} />
          </div>
          <div className="w-[40%]" style={{ paddingLeft: BASE_PADDING / 2 }}>
            <SearchFriends seasonId={userProfileSeason} />
          </div>
        </div>
        <div className="w-full flex flex-row">
          <div className="w-1/2" style={{ paddingRight: BASE_PADDING / 2 }}>
            <Status
              userId={userInformation.id}
              userProfileId={userProfile.id}
              contentList={passiveContentList}
              etudeList={etudeDTOList}
              allTimeRankingList={allTimeRankingList}
              thisWeekRankingList={thisWeekRankingList}
              remainingPoints={personalStatus.remainingPoints}
            />
          </div>
          <div className="w-1/2" style={{ paddingLeft: BASE_PADDING / 2 }}>
            <Mascot remainingPoints={personalStatus.remainingPoints} />
          </div>
        </div>
        <div className="w-full" style={{ paddingLeft: BASE_PADDING / 2 }}>
          <Settings
            userInformation={userInformation}
            setUserInformation={setUserInformation}
            lang={language}
            setLang={setLanguage}
          />
        </div>
      </div>
    );
  };

  const RenderPCMainContent = () => {
    return (
      <div className="flex flex-row flex-wrap" style={{ rowGap: BASE_PADDING }}>
        <div className="w-full">
          <ProfileWithBanner
            useAbsolute={true}
            userId={userInformation.id}
            userProfileId={userProfile.id}
            language={language}
            firstName={userInformation.firstName}
            lastName={userInformation.lastName}
            aboutMe={userInformation.aboutMe}
            dateOfBirth={userInformation.dateOfBirth}
            thisWeekRankingList={thisWeekRankingList}
            branchName={userProfileBranch.name}
            gradeName={userProfileGrade.name}
            seasonName={userProfileSeason.name}
            institutionName={userProfileSeason.institutionDTO.name}
          />
        </div>
        <div className="w-[70%]" style={{ paddingRight: BASE_PADDING / 2 }}>
          <div
            className="w-full flex flex-row"
            style={{ paddingBottom: BASE_PADDING / 2 }}
          >
            <div className="w-[65%]" style={{ paddingRight: BASE_PADDING / 2 }}>
              <Badge earnedBadgeDTOList={earnedBadgeDTOList} />
            </div>
            <div
              className="w-[35%]"
              style={{
                paddingLeft: BASE_PADDING / 2,
              }}
            >
              <SearchFriends seasonId={userProfileSeason} />
            </div>
          </div>
          <div
            className="w-full flex flex-row h-[325px]"
            style={{ paddingTop: BASE_PADDING / 2 }}
          >
            <div className="w-[50%]" style={{ paddingRight: BASE_PADDING / 2 }}>
              <Status
                userId={userInformation.id}
                userProfileId={userProfile.id}
                contentList={passiveContentList}
                etudeList={etudeDTOList}
                allTimeRankingList={allTimeRankingList}
                thisWeekRankingList={thisWeekRankingList}
                remainingPoints={personalStatus.remainingPoints}
              />
            </div>
            <div className="w-[50%]" style={{ paddingLeft: BASE_PADDING / 2 }}>
              <Mascot remainingPoints={personalStatus.remainingPoints} />
            </div>
          </div>
        </div>
        <div className="w-[30%]" style={{ paddingLeft: BASE_PADDING / 2 }}>
          <Settings
            userInformation={userInformation}
            setUserInformation={setUserInformation}
            lang={language}
            setLang={setLanguage}
          />
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    if (view === "TABLET") {
      return RenderTabletContent();
    } else if (view === "PC") {
      return RenderPCMainContent();
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default UserProfileScreen;
