import { useRef, useState } from "react";
import i18n from "../../language/i18n";
import SelectionTabFill from "../tabs/SelectionTabFill";
import MascotWithCrown from "../mascots/MascotWithCrown";
import SecondaryButton from "../buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import BaseImage from "../images/BaseImage";

const Leaderboard = (props) => {
  const {
    detailed,
    useMascot,
    userId,
    userProfileId,
    userProfileBranchId,
    thisWeekRankingList,
    allTimeRankingList,
  } = props;
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("THIS_WEEK");

  const filterCurrentRankingList = () => {
    if (selectedId === "THIS_WEEK") {
      return thisWeekRankingList;
    } else {
      return allTimeRankingList;
    }
  };

  const retrieveCurrentOverallRanking = () => {
    return filteredCurrentRankingList.find(
      (ranking) =>
        ranking.userId === userId && ranking.userProfileId === userProfileId
    );
  };

  const retrieveCurrentBranchRanking = () => {
    const branchRankingList = JSON.parse(
      JSON.stringify(
        filteredCurrentRankingList.filter(
          (ranking) => ranking.branchId === userProfileBranchId
        )
      )
    );
    const rankMap = new Map();
    let currentRank = 1;
    branchRankingList.forEach((item, index) => {
      if (!rankMap.has(item.totalPoints)) {
        rankMap.set(item.totalPoints, currentRank);
        currentRank++;
      }
    });
    const assignedRankingList = branchRankingList.map((item) => ({
      ...item,
      rank: rankMap.get(item.totalPoints),
    }));
    return assignedRankingList.find(
      (ranking) =>
        ranking.userId === userId && ranking.userProfileId === userProfileId
    );
  };

  const RenderTopPlaceLearnerInLeaderboard = (currentRanking) => {
    let currentHeight = "";
    let currentBackground = "";
    if (currentRanking.rank === 1) {
      currentHeight = "h-36";
      currentBackground = "bg-catchup-gray-400";
    } else if (currentRanking.rank === 2) {
      currentHeight = "h-24";
      currentBackground = "bg-catchup-gray-300";
    } else if (currentRanking.rank === 3) {
      currentHeight = "h-12";
      currentBackground = "bg-catchup-gray-200";
    }
    return (
      <div className="w-1/4">
        <div className="w-full flex flex-col items-center">
          {useMascot ? (
            <div className="w-[60px]">
              <MascotWithCrown rank={currentRanking.rank} showRank={false} />
            </div>
          ) : (
            <BaseImage
              src="badges/coterie-type-total.png"
              alt="crown"
              size="4xlarge"
            />
          )}

          <p
            className={`${
              currentRanking.userId === userId &&
              currentRanking.userProfileId === userProfileId
                ? "text-catchup-blue-500"
                : "text-catchup-gray-800"
            } text-md font-semibold text-center mt-1`}
          >
            {currentRanking.firstName}
          </p>
          <p
            className={`${
              currentRanking.userId === userId &&
              currentRanking.userProfileId === userProfileId
                ? "text-catchup-blue-500"
                : "text-catchup-gray-800"
            } text-md font-semibold text-center`}
          >
            {currentRanking.lastName}
          </p>
          {detailed ? (
            <p
              className={`${
                currentRanking.userId === userId &&
                currentRanking.userProfileId === userProfileId
                  ? "text-catchup-blue-500"
                  : "text-catchup-gray-800"
              } text-md font-semibold text-center`}
            >
              ({currentRanking.branchName})
            </p>
          ) : null}

          <p className="text-sm text-catchup-gray-400 text-center">
            {currentRanking.totalPoints} {i18n.t("points")}
          </p>
        </div>
        <div
          className={`${currentHeight} ${currentBackground} w-full rounded-t-catchup-medium`}
        >
          <div className="h-full flex flex-col items-center pt-2">
            <p className="text-catchup-white text-3xl text-center">
              #{currentRanking.rank}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const RenderSingleLearnerInLeaderboard = (currentRanking) => {
    return (
      <div
        className={`${
          currentRanking.userId === userId &&
          currentRanking.userProfileId === userProfileId
            ? "text-catchup-blue-500 font-semibold"
            : "text-catchup-gray-300"
        } flex flex-row items-center justify-between  gap-x-2 my-1`}
      >
        <div className="flex flex-row items-center gap-x-2">
          <div>
            <p className="text-lg">#{currentRanking.rank}</p>
          </div>
          <div className="w-[40px]">
            <MascotWithCrown rank={currentRanking.rank} />
          </div>
          <div className="">
            <p className="">
              {currentRanking.firstName} {currentRanking.lastName}
              {detailed ? (
                <>
                  {" "}
                  ({currentRanking.gradeName} - {currentRanking.branchName})
                </>
              ) : null}
            </p>
          </div>
        </div>
        <div>
          <p>
            {currentRanking.totalPoints} {i18n.t("points")}
          </p>
        </div>
      </div>
    );
  };

  const filteredCurrentRankingList = filterCurrentRankingList();
  const currentOverallRanking = retrieveCurrentOverallRanking();
  const currentBranchRanking = retrieveCurrentBranchRanking();

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl">
      <div className="px-4 py-2">
        <div className="flex flex-row justify-center items-center mb-2">
          <div>
            <p className="text-catchup-gray-600 font-semibold text-2xl">
              {i18n.t("leaderboard")}
            </p>
          </div>
        </div>
        <div className="mb-2">
          <SelectionTabFill
            optionList={[
              { id: "ALL", title: i18n.t("all_time") },
              { id: "THIS_WEEK", title: i18n.t("this_week") },
            ]}
            selectedId={selectedId}
            handleSelectOnClick={(id) => {
              setSelectedId(id);
            }}
          />
        </div>
      </div>
      <div className="w-full flex flex-row justify-center items-end">
        {filteredCurrentRankingList.length > 1
          ? RenderTopPlaceLearnerInLeaderboard(filteredCurrentRankingList[1])
          : null}

        {filteredCurrentRankingList.length > 0
          ? RenderTopPlaceLearnerInLeaderboard(filteredCurrentRankingList[0])
          : null}

        {filteredCurrentRankingList.length > 2
          ? RenderTopPlaceLearnerInLeaderboard(filteredCurrentRankingList[2])
          : null}
      </div>
      <div className="flex-1 min-h-0 flex flex-col shadow-leaderboard rounded-t-catchup-xlarge py-2 px-4">
        <div className="flex-1 min-h-0 flex flex-col overflow-y-auto no-scrollbar">
          {filteredCurrentRankingList
            .slice(3)
            .map((currentRanking, rankIndex) => (
              <div key={rankIndex}>
                {RenderSingleLearnerInLeaderboard(currentRanking)}
              </div>
            ))}
        </div>

        {currentOverallRanking && currentBranchRanking ? (
          <div className="flex flex-row bg-catchup-gray-50 px-2 py-2 mt-4 rounded-catchup-medium">
            <div className="flex-1 text-center">
              <div>
                <p className="text-catchup-gray-500 font-semibold text-md">
                  {i18n.t("total_points")}
                </p>
              </div>
              <div>
                <p className="text-catchup-gray-500 text-md">
                  {currentOverallRanking.totalPoints}
                </p>
              </div>
            </div>
            <div className="h-full w-[1px] bg-catchup-gray-200"></div>
            <div className="flex-1 text-center">
              <div>
                <p className="text-catchup-gray-500 font-semibold text-md">
                  {i18n.t("overall_rank")}
                </p>
              </div>
              <div>
                <p className="text-catchup-gray-500 text-md">
                  #{currentOverallRanking.rank}
                </p>
              </div>
            </div>
            <div className="h-full w-[1px] bg-catchup-gray-200"></div>
            <div className="flex-1 text-center">
              <div>
                <p className="text-catchup-gray-500 font-semibold text-md">
                  {i18n.t("branch_rank")}
                </p>
              </div>
              <div>
                <p className="text-catchup-gray-500 text-md">
                  #{currentBranchRanking.rank}
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex flex-col mt-4">
          <SecondaryButton
            title={i18n.t("to_contest")}
            size="unlimited"
            onClick={() => {
              navigate("/contests");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
