import { useEffect, useState } from "react";
import BaseImage from "../images/BaseImage";
import PrimaryButton from "../buttons/PrimaryButton";
import { InlineMath } from "react-katex";
import { ignoreMathematicalExpression } from "../../utilization/CatchtivityUtilization";

const Mascot = (props) => {
  const {
    showInitialText,
    text,
    subText,
    subTextList,
    primaryButtonText,
    primaryButtonOnClick,
  } = props;
  const [showText, setShowText] = useState(false);
  const [textItemList, setTextItemList] = useState([]);
  const [subTextItemList, setSubTextItemList] = useState([]);
  const [bubbleHeight, setBubbleHeight] = useState(0);
  const [bubbleWidth, setBubbleWidth] = useState(0);
  const SUB_TEXT_LENGTH_MULTIPLIER = 1.43; // 1.0 for base 1.15 for large
  const BASE_LETTER_WIDTH = 6.66 * SUB_TEXT_LENGTH_MULTIPLIER;
  const BASE_LETTER_HEIGHT = 24;
  const MAX_TEXT_LENGTH = 60;
  const MIN_WIDTH = 180 * SUB_TEXT_LENGTH_MULTIPLIER;
  const OFFSET_WIDTH = 100;
  const OFFSET_HEIGHT = primaryButtonText && primaryButtonOnClick ? 100 : 48;

  useEffect(() => {
    if (showInitialText) setShowText(showInitialText);
  }, [showInitialText]);

  useEffect(() => {
    let currentBubbleHeight = 0;
    let currentBubbleWidth = 0;
    if (text) {
      const currentTextItemList = convertTextToTextList(text);
      const convertedTextItemList = [];
      for (const item of currentTextItemList) {
        convertedTextItemList.push({
          isEquation: false,
          value: item,
        });
      }
      setTextItemList(convertedTextItemList);
      const textLength = text.length;
      currentBubbleHeight += convertedTextItemList.length;
      currentBubbleWidth = textLength;
    }
    if (subText) {
      const currentSubTextItemList = convertTextToTextList(subText);
      const convertedSubTextItemList = [];
      for (const item of currentSubTextItemList) {
        convertedSubTextItemList.push({
          isEquation: false,
          value: item,
        });
      }
      setSubTextItemList(convertedSubTextItemList);
      const subTextLength = subText.length;
      currentBubbleHeight += convertedSubTextItemList.length;
      if (subTextLength > currentBubbleWidth) {
        currentBubbleWidth = subTextLength;
      }
    } else {
      if (subTextList && subTextList.length > 0) {
        const currentSubTextItemList = [];
        let totalLength = 0;
        for (const subText of subTextList) {
          if (subText.isEquation) {
            currentSubTextItemList.push({
              isEquation: subText.isEquation,
              value: subText.value,
            });
            totalLength += subText.value.length;
          } else {
            const currentSubTextList = convertTextToTextList(subText.value);
            for (const item of currentSubTextList) {
              currentSubTextItemList.push({
                isEquation: subText.isEquation,
                value: item,
              });
              totalLength += item.length;
            }
            currentBubbleHeight += currentSubTextList.length;
          }
        }
        let totalMathematicalExpressionText = "";
        currentSubTextItemList
          .filter((item) => item.isEquation)
          .forEach((item) => {
            totalMathematicalExpressionText += ignoreMathematicalExpression(
              item.value
            );
          });
        currentBubbleHeight += Math.floor(
          totalMathematicalExpressionText.length / MAX_TEXT_LENGTH
        );
        if (totalLength > currentBubbleWidth) {
          currentBubbleWidth = totalLength;
        }
        setSubTextItemList(currentSubTextItemList);
      }
    }

    currentBubbleHeight *= BASE_LETTER_HEIGHT;
    setBubbleHeight(currentBubbleHeight);
    currentBubbleWidth *= BASE_LETTER_WIDTH;
    setBubbleWidth(Math.min(currentBubbleWidth, MIN_WIDTH));
  }, [text, subText, subTextList]);

  const convertTextToTextList = (text) => {
    const currentSplittedTextList = [];
    const splittedTextList = text.split(" ");
    let currentText = "";
    for (const splittedText of splittedTextList) {
      currentText += `${splittedText} `;
      const currentTextLength = currentText.length * SUB_TEXT_LENGTH_MULTIPLIER;
      if (currentTextLength >= MAX_TEXT_LENGTH) {
        if (currentText.trim() !== "") {
          currentSplittedTextList.push(
            currentText.substring(0, currentTextLength - 1)
          );
        }
        currentText = "";
      }
    }
    const currentTextLength = currentText.length * SUB_TEXT_LENGTH_MULTIPLIER;
    if (currentText.trim() !== "") {
      currentSplittedTextList.push(
        currentText.substring(0, currentTextLength - 1)
      );
    }
    return currentSplittedTextList;
  };

  return (
    <div className="relative bg-catchup-mascot">
      <div>
        <BaseImage
          src="/mascots/mascot-base.png"
          alt="mascot-base"
          size="custom"
          onClick={() => {
            setShowText(true);
          }}
          heightClassName="w-40 h-40"
        />
      </div>
      <div
        className="absolute"
        style={{
          left: -(bubbleWidth + OFFSET_WIDTH),
          top: -(bubbleHeight + OFFSET_HEIGHT),
          right: OFFSET_WIDTH,
        }}
      >
        {showText ? (
          <div
            className="relative flex justify-end bg-catchup-white cursor-pointer rounded-catchup-2xlarge"
            onClick={() => {
              setShowText(false);
            }}
          >
            <div className="bg-catchup-white rounded-catchup-2xlarge text-catchup-blue-800 py-6 px-6 z-20 shadow-input">
              {textItemList.length > 0 ? (
                <p className="">
                  {textItemList.map((item, index) =>
                    item.isEquation ? (
                      <span className="text-2xl">
                        <InlineMath key={index} math={item.value} />
                      </span>
                    ) : (
                      <span key={index} className="font-semibold text-lg">
                        {item.value}
                      </span>
                    )
                  )}
                </p>
              ) : null}

              {subTextItemList.length > 0 ? (
                <p className="">
                  {subTextItemList.map((item, index) =>
                    item.isEquation ? (
                      <span className="text-2xl">
                        <InlineMath key={index} math={item.value} />
                      </span>
                    ) : (
                      <span className="text-lg">{item.value}</span>
                    )
                  )}
                </p>
              ) : null}

              {primaryButtonText && primaryButtonOnClick ? (
                <div className="flex flex-row justify-end mt-5">
                  <PrimaryButton
                    title={primaryButtonText}
                    size="small"
                    onClick={primaryButtonOnClick}
                  />
                </div>
              ) : null}
            </div>

            <div
              className="absolute bg-catchup-white right-10 -bottom-3 z-10"
              style={{ rotate: "25deg" }}
            >
              <div className="">
                <div className="w-12 h-12 shadow-input" />
              </div>
            </div>
            <div
              className="absolute bg-catchup-white z-30"
              style={{ bottom: -1, right: 44 }}
            >
              <div className="">
                <div className="" style={{ width: 49, height: 3 }} />
              </div>
            </div>
            <div
              className="absolute bg-catchup-white z-30"
              style={{ bottom: -2, right: 45 }}
            >
              <div className="">
                <div className="" style={{ width: 45, height: 3 }} />
              </div>
            </div>
            <div
              className="absolute bg-catchup-white z-30"
              style={{ bottom: -3, right: 46 }}
            >
              <div className="">
                <div className="" style={{ width: 41, height: 3 }} />
              </div>
            </div>
            <div
              className="absolute bg-catchup-white z-30"
              style={{ bottom: -4, right: 47 }}
            >
              <div className="">
                <div className="" style={{ width: 37, height: 3 }} />
              </div>
            </div>
            <div
              className="absolute bg-catchup-white z-30"
              style={{ bottom: -5, right: 48 }}
            >
              <div className="">
                <div className="" style={{ width: 33, height: 3 }} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Mascot;
