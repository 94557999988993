import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import {
  createActivityAnswerByActivityIdContestId,
  createActivityProgressByActivityIdContestId,
  evaluateContestByParams,
  patchActivityAnswerWithData,
  patchActivityProgress,
  queryActivityAnswerListByContestId,
  retrieveActivityWithDataById,
  retrieveContestById,
  retrieveContestProgress,
} from "../../requests/CatchtivityRequests";
import i18n from "../../language/i18n";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import {
  checkIfAnswerIsEmpty,
  constructActivityAnswerMap,
  constructActivityAnswerStateList,
  retrieveClockTimeLeft,
} from "../../utilization/CatchtivityUtilization";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import CatchtivityFooter from "../../components/footers/CatchtivityFooter";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import BaseModal from "../../components/modal/BaseModal";
import BaseImage from "../../components/images/BaseImage";
import DashboardHeader from "../../components/headers/DashboardHeader";
import ContentTransition from "../../components/transitions/ContentTransition";
import CatchtivityProgressBar from "../../components/progress-bar/CatchtivityProgressBar";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";
import ScreenCard from "../../components/cards/ScreenCard";

const ContestScreen = () => {
  const navigate = useNavigate();
  const { contestId } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
  } = useAuth();
  const [activityNumber, setActivityNumber] = useState(null);
  const [pageState, setPageState] = useState(0);
  const [contestDTO, setContestDTO] = useState(null);
  const [activity, setActivity] = useState(null);
  const [activityAnswerDTOList, setActivityAnswerDTOList] = useState([]);
  const [activityAnswerDTOListLoading, setActivityAnswerDTOListLoading] =
    useState(true);
  const [contestProgressDTO, setContestProgressDTO] = useState(null);
  const [contestProgressDTOLoading, setContestProgressDTOLoading] =
    useState(true);
  const [currentActivityInitialTimestamp, setCurrentActivityInitialTimestamp] =
    useState(new Date().getTime());
  const [activityLoading, setActivityLoading] = useState({
    self: false,
    answer: false,
    progress: false,
  });
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  useEffect(() => {
    if (pageState === 0) {
      if (isFullScreen === true) {
        setIsFullScreen(false);
      }
    } else {
      if (isFullScreen === false) {
        setIsFullScreen(true);
      }
    }
  }, [pageState]);

  useEffect(() => {
    const retrieveAndSetContest = async () => {
      const { data, err } = await retrieveContestById(contestId);
      if (err) {
        console.log(err);
        return;
      }
      setContestDTO(data);
    };
    const retrieveAndSetContestProgress = async () => {
      setContestProgressDTOLoading(true);
      const { data, err } = await retrieveContestProgress({
        id: contestId,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setContestProgressDTO(data);
      setContestProgressDTOLoading(false);
    };
    const retrieveAndSetActivityAnswerList = async () => {
      setActivityAnswerDTOListLoading(true);
      const { data, err } = await queryActivityAnswerListByContestId({
        contestId,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setActivityAnswerDTOList(data);
      setActivityAnswerDTOListLoading(false);
    };

    if (!contestId) return;
    retrieveAndSetContest();
    retrieveAndSetActivityAnswerList();
    retrieveAndSetContestProgress();
  }, [contestId]);

  useEffect(() => {
    if (!activity) return;
    setCurrentActivityInitialTimestamp(new Date().getTime());
  }, [activity]);

  useEffect(() => {
    const retrieveAndSetActivityWithData = async () => {
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: true,
      }));
      const activityDTOList = contestDTO.activityDTOList;
      if (activityDTOList.length > 0) {
        const currentActivity = activityDTOList[activityNumber];
        const { data, err } = await retrieveActivityWithDataById(
          currentActivity.id
        );
        if (err) {
          console.log(err);
        } else {
          setActivity(data);
        }
      }
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: false,
      }));
    };
    if (activityNumber === 0) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
    }
    if (contestDTO && activityNumber === contestDTO.activityDTOList.length) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
      setShowCompleteModal(true);
      setActivity(null);
    } else if (contestDTO && activityNumber !== null && activityNumber >= 0) {
      setActivity(null);
      retrieveAndSetActivityWithData();
    } else {
      setActivity(null);
    }
  }, [contestDTO, activityNumber]);

  const retrieveCurrentActivityTemplate = () => {
    return activity.activityTemplateDTOList.find(
      (activityTemplateDTO) => activityTemplateDTO.type === "MCSA"
    );
  };

  const retrieveCurrentActivityAnswer = () => {
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    const foundActivityAnswerDTO = activityAnswerDTOList[foundIndex];
    if (foundIndex > -1 && foundActivityAnswerDTO.data) {
      const currentAnswer = foundActivityAnswerDTO;
      return {
        index: foundIndex,
        answer: {
          ...currentAnswer,
          data: JSON.parse(currentAnswer.data),
        },
      };
    } else {
      const currentActivityTemplate = retrieveCurrentActivityTemplate();
      return {
        index: foundIndex,
        answer: {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          activityDTO: {
            id: activity.id,
          },
          contestDTO: {
            id: contestId,
          },
          data: currentActivityTemplate
            ? [
                constructActivityAnswerMap(
                  currentActivityTemplate,
                  JSON.parse(activity.data)
                ),
              ]
            : [],
        },
      };
    }
  };

  const retrieveTotalTimeSpentInSeconds = () => {
    let timeSpent = 0;
    if (contestProgressDTO) {
      contestProgressDTO.activityProgressDTOSet.forEach(
        (activityProgressDTO) => {
          timeSpent += activityProgressDTO.timeSpent;
        }
      );
    }
    return timeSpent / 1000;
  };

  const retrieveTotalTimeSpentInMinutes = () => {
    return retrieveTotalTimeSpentInSeconds() / 60;
  };

  const checkCanAnswerQuestion = () => {
    return true;
  };

  const checkCanStartTimer = () => {
    if (!activity) return false;
    const { answer } = retrieveCurrentActivityAnswer();
    return (
      pageState === 1 &&
      !showCompleteModal &&
      !activityLoading.answer &&
      !activityLoading.progress &&
      checkIfAnswerIsEmpty(answer)
    );
  };

  const changeActivityAnswer = (answer) => {
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    if (activityAnswerDTOList[foundIndex]) return;
    const copyAnswer = JSON.parse(JSON.stringify(answer));
    copyAnswer.data = JSON.stringify(copyAnswer.data);
    if (foundIndex === -1) {
      activityAnswerDTOList.push(copyAnswer);
    } else {
      activityAnswerDTOList[foundIndex] = copyAnswer;
    }
    setActivityAnswerDTOList(JSON.parse(JSON.stringify(activityAnswerDTOList)));
  };

  const calculateDurationInMinutes = () => {
    if (!contestDTO) return 1;
    const { coterieType } = contestDTO;
    if (coterieType === "GENERAL_CULTURE") return 1;
    return 2;
  };

  const patchAndSetActivityProgress = async (activityProgressId) => {
    const { data, err } = await patchActivityProgress({
      id: activityProgressId,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
      activityId: activity.id,
      contestId: contestDTO.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    });
    if (err) {
      console.log(err);
    } else {
      const foundIndex = contestProgressDTO.activityProgressDTOSet.findIndex(
        (activityProgressDTO) =>
          parseFloat(activityProgressDTO.id) === parseFloat(activityProgressId)
      );
      contestProgressDTO.activityProgressDTOSet[foundIndex] = {
        ...contestProgressDTO.activityProgressDTOSet[foundIndex],
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        contestDTO: data.contestDTO,
      };
      setContestProgressDTO(JSON.parse(JSON.stringify(contestProgressDTO)));
    }
  };

  const createAndSetActivityProgress = async () => {
    const { data, err } = await createActivityProgressByActivityIdContestId({
      userId: userInformation.id,
      userProfileId: userProfile.id,
      timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
      activity: {
        id: activity.id,
      },
      contest: {
        id: contestDTO.id,
      },
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    });
    if (err) {
      console.log(err);
    } else {
      contestProgressDTO.activityProgressDTOSet.push({
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        contestDTO: data.contestDTO,
      });
      setContestProgressDTO(JSON.parse(JSON.stringify(contestProgressDTO)));
    }
  };

  const createOrPatchActivityProgress = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: true,
    }));
    const foundActivityProgressDTO =
      contestProgressDTO.activityProgressDTOSet.find(
        (activityProgressDTO) =>
          parseFloat(activityProgressDTO.activityDTO.id) ===
          parseFloat(activity.id)
      );
    if (foundActivityProgressDTO) {
      await patchAndSetActivityProgress(foundActivityProgressDTO.id);
    } else {
      await createAndSetActivityProgress();
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: false,
    }));
  };

  const handleCreateOrUpdateActivityAnswerWithData = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: true,
    }));
    const { index, answer } = retrieveCurrentActivityAnswer();
    const baseAnswer = {
      userId: answer.userId,
      userProfileId: answer.userProfileId,
      data: JSON.stringify(answer.data),
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    };
    if (answer.id) {
      const currentAnswer = {
        ...baseAnswer,
        id: answer.id,
        activityId: answer.activityDTO.id,
        contestId: answer.contestDTO.id,
      };
      const { data, err } = await patchActivityAnswerWithData(currentAnswer);
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          ...activityAnswerDTOList[index],
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          contestDTO: data.contestDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    } else {
      const newAnswer = {
        ...baseAnswer,
        activity: {
          id: answer.activityDTO.id,
        },
        contest: {
          id: answer.contestDTO.id,
        },
      };
      const { data, err } = await createActivityAnswerByActivityIdContestId(
        newAnswer
      );
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          contestDTODTO: data.contestDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: false,
    }));
  };

  const handleToNextActivity = async (nextActivityNumber) => {
    handleCreateOrUpdateActivityAnswerWithData();
    createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleToNextActivityWithAsync = async (nextActivityNumber) => {
    await handleCreateOrUpdateActivityAnswerWithData();
    await createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleToNextActivityCheckCanAnswer = (nextActivityNumber) => {
    if (checkCanAnswerQuestion()) {
      if (nextActivityNumber === contestDTO.activityDTOList.length) {
        handleToNextActivityWithAsync(nextActivityNumber);
      } else {
        handleToNextActivity(nextActivityNumber);
      }
    } else {
      setActivityNumber(nextActivityNumber);
    }
  };

  const handleGoPreviousScreen = async () => {
    if (pageState === 1) {
      if (activity) {
        await createOrPatchActivityProgress();
        setCurrentActivityInitialTimestamp(new Date().getTime());
      }
    }
    navigate("/contests", {
      state: { state: "FORCE_UPDATE" },
    });
  };

  const handleEvaluateContestOnClick = async () => {
    const { data, err } = await evaluateContestByParams({
      id: contestId,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    });
    if (err) {
      console.log(err);
      return;
    }
    navigate("/homeworks", {
      state: {
        state: "FORCE_UPDATE",
      },
    });
    setShowCompleteModal(false);
  };

  const handleOnTimerEnds = (valid) => {
    if (!valid) return;
    handleToNextActivityCheckCanAnswer(activityNumber + 1);
  };

  const RenderCurrentActivityContent = () => {
    if (!activity) return null;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    if (!currentActivityTemplate) return null;
    const { answer } = retrieveCurrentActivityAnswer();
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === currentActivityTemplate.type
    );
    if (foundIndex === -1) {
      answer.data.push(
        constructActivityAnswerMap(
          currentActivityTemplate,
          JSON.parse(activity.data)
        )
      );
    }
    if (currentActivityTemplate.type === "MCSA") {
      return (
        <MCSAActivityContent
          isPreview={!checkIfAnswerIsEmpty(answer)}
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    }
  };

  const RenderCompleteModal = () => {
    if (!contestDTO) return;
    const stateList = constructActivityAnswerStateList(
      activityAnswerDTOList,
      contestDTO.activityDTOList
    );
    const notExistsItemList = stateList.filter(
      (item) => item.state === "NOT_EXISTS"
    );
    const emptyItemList = stateList.filter((item) => item.state === "EMPTY");
    return (
      <BaseModal
        isOpen={showCompleteModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setActivityNumber(0);
          setShowCompleteModal(false);
        }}
        customSize={"w-[750px]"}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setActivityNumber(0);
                setShowCompleteModal(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div>
                <p className="font-bold">
                  {i18n.t("are_you_sure_do_you_want_to_complete_text")}
                </p>
              </div>
              <DividerLine />

              {notExistsItemList.length > 0 || emptyItemList.length > 0 ? (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/exclamation-red.png"
                    alt="exclamation"
                    size="xlarge"
                  />
                  <div className="flex flex-col">
                    {notExistsItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_did_not_answer_activities_text_1")}
                          {notExistsItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === notExistsItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}
                          {i18n.t("you_did_not_answer_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                    {emptyItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_set_empty_activities_text_1")}
                          {emptyItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === emptyItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}

                          {i18n.t("you_set_empty_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/checkbox.png"
                    alt="checkbox"
                    size="medium"
                  />
                  <div>
                    <p>{i18n.t("you_have_answered_all_activities")}</p>
                  </div>
                </div>
              )}

              <div className="flex justify-end ">
                <div className="flex flex-row gap-x-2">
                  <SecondaryButton
                    title={i18n.t("continue")}
                    size="small"
                    onClick={() => {
                      setActivityNumber(0);
                      setShowCompleteModal(false);
                    }}
                  />
                  <PrimaryButton
                    title={i18n.t("complete")}
                    size="small"
                    onClick={handleEvaluateContestOnClick}
                  />
                </div>
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    if (pageState === 0) {
      if (!contestDTO)
        return (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        );
      return (
        <ContentTransition
          language={language}
          type={contestDTO.coterieType}
          level={contestDTO.level}
          activityCount={contestDTO.activityDTOList.length}
          beginAt={contestDTO.beginAt}
          endAt={contestDTO.endAt}
          durationType={"EACH"}
          durationInMinutes={durationInMinutes}
          answeredActivityCount={activityAnswerDTOList.length}
          totalTimeSpent={retrieveTotalTimeSpentInMinutes(
            contestProgressDTO?.activityProgressDTOSet
          )}
          loading={
            contestDTO.beginAt > new Date().getTime() ||
            contestDTO.endAt < new Date().getTime() ||
            activityAnswerDTOListLoading ||
            contestProgressDTOLoading
          }
          onClick={() => {
            setActivityNumber(0);
            setPageState(1);
          }}
        />
      );
    } else if (pageState === 1) {
      if (!contestDTO) return;
      return (
        <div className="h-full no-scrollbar overflow-y-auto pb-[200px]">
          {activityLoading.answer ||
          activityLoading.progress ||
          activityLoading.self ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : activity ? (
            <div className="flex flex-col w-full">
              <div className="my-1">
                <CatchtivityProgressBar
                  activityNumber={activityNumber + 1}
                  totalActivityCount={
                    contestDTO ? contestDTO.activityDTOList.length : 0
                  }
                  showClockTime={true}
                  clockTimeLeft={retrieveClockTimeLeft(
                    "CONTEST",
                    null,
                    "EACH",
                    durationInMinutes,
                    contestProgressDTO?.activityProgressDTOSet,
                    activity
                  )}
                  startTimer={
                    checkCanStartTimer()
                      ? retrieveClockTimeLeft(
                          "CONTEST",
                          null,
                          "EACH",
                          1,
                          contestProgressDTO?.activityProgressDTOSet,
                          activity
                        ) > 0
                      : false
                  }
                  handleOnTimerEnds={handleOnTimerEnds}
                />
              </div>
              <div className="my-2">{RenderCurrentActivityContent()}</div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  const durationInMinutes = calculateDurationInMinutes();

  return (
    <div className="h-screen p-4">
      {RenderCompleteModal()}
      <ScreenCard>
        <DashboardHeader
          handleReturnOnClick={pageState === 1 ? handleGoPreviousScreen : null}
          title={i18n.t("contest")}
          durationType={pageState === 1 ? "EACH" : null}
          durationInMinutes={pageState === 1 ? durationInMinutes : null}
        />
        {RenderMainContent()}
        {pageState === 1 && activity ? (
          <CatchtivityFooter
            activityNumber={activityNumber}
            totalActivityCount={
              contestDTO ? contestDTO.activityDTOList.length : 0
            }
            showTimedProgressBar={false}
            activity={activity}
            activityLoading={activityLoading.self}
            activityProgressList={contestProgressDTO?.activityProgressDTOSet}
            durationType={"EACH"}
            durationInMinutes={durationInMinutes}
            handleRightArrowOnClick={handleToNextActivityCheckCanAnswer}
          />
        ) : null}
      </ScreenCard>
    </div>
  );
};

export default ContestScreen;
