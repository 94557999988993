import { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";

const TimedProgressBar = (props) => {
  const {
    title,
    startTimer,
    showRemainingTime,
    totalTimeInSeconds,
    remainingTimeInSeconds,
    handleOnTimerEnds,
  } = props;
  const [timerRemainingTimeInSeconds, setTimerRemainingTimeInSeconds] =
    useState(remainingTimeInSeconds);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  let timerRemainingTimeInSecondsValue = null;

  useEffect(() => {
    timerRemainingTimeInSecondsValue = remainingTimeInSeconds;
    setTimerRemainingTimeInSeconds(timerRemainingTimeInSeconds);
    if (timerIntervalId) {
      clearInterval(timerIntervalId);
    }
    if (startTimer) {
      const currentTimerIntervalId = setInterval(() => {
        timerRemainingTimeInSecondsValue =
          timerRemainingTimeInSecondsValue - 0.05;
        setTimerRemainingTimeInSeconds(timerRemainingTimeInSecondsValue);
      }, 50);
      setTimerIntervalId(currentTimerIntervalId);
    } else {
      if (timerIntervalId) {
        clearInterval(timerIntervalId);
        setTimerIntervalId(null);
      }
    }
  }, [remainingTimeInSeconds, startTimer]);

  useEffect(() => {
    if (timerRemainingTimeInSeconds < 0) {
      if (timerIntervalId) {
        clearInterval(timerIntervalId);
      }
      handleOnTimerEnds(startTimer);
    }
  }, [timerRemainingTimeInSeconds]);

  return (
    <ProgressBar
      title={title}
      showRemainingTime={showRemainingTime}
      remainingTimeInSeconds={timerRemainingTimeInSeconds}
      totalTimeInSeconds={totalTimeInSeconds}
    />
  );
};

export default TimedProgressBar;
