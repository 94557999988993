import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import ApproveButton from "../buttons/ApproveButton";
import PrimaryButton from "../buttons/PrimaryButton";
import BaseImage from "../images/BaseImage";
import { Tooltip } from "react-tooltip";
import DividerLine from "../divider/DividerLine";
import GradeLabel from "../labels/GradeLabel";
import { constructWeekName } from "../../utilization/DateUtilization";
import ProgressBar from "../progress-bar/ProgressBar";
import DeleteButton from "../buttons/DeleteButton";
import SecondaryButton from "../buttons/SecondaryButton";

const CatchxamApplicationCard = (props) => {
  const navigate = useNavigate();
  const { isPractise, catchxamApplicationDTO, language } = props;
  const ONE_DAY = 86400000;

  let borderColor;
  let deadlineAtColor;
  let showProgressBar;
  let showRemainingDays;
  let showWaitingForEvaluate;
  let canNavigate;

  if (!catchxamApplicationDTO) return;
  const {
    beginAt,
    endAt,
    catchxamDTO,
    completionStatus,
    completionPercentage,
  } = catchxamApplicationDTO;
  const { name, description, coterieType, level, activityDTOList } =
    catchxamDTO;

  const currentTime = new Date();
  const currentTimestamp = currentTime.getTime();
  const beginDate = new Date(beginAt);
  const endDate = new Date(endAt);

  const calculateRemainingDays = (providedTime) => {
    const remainingTime = providedTime - currentTimestamp;
    return Math.ceil(remainingTime / 1000 / 60 / 60 / 24);
  };

  if (isPractise) {
    borderColor = "border-catchup-gray-50";
    deadlineAtColor = "text-catchup-gray-400";
    showRemainingDays = false;
    showWaitingForEvaluate = false;
    canNavigate = false;
  } else {
    if (beginAt > currentTimestamp) {
      if (beginAt - currentTimestamp > ONE_DAY) {
        borderColor = "border-catchup-gray-50";
        deadlineAtColor = "text-catchup-gray-400";
        showRemainingDays = true;
        canNavigate = false;
        showWaitingForEvaluate = false;
      } else {
        borderColor = "border-catchup-gray-50";
        deadlineAtColor = "text-catchup-gray-400";
        showRemainingDays = true;
        canNavigate = false;
        showWaitingForEvaluate = false;
      }
    } else if (beginAt < currentTimestamp && endAt > currentTimestamp) {
      if (endAt - currentTimestamp > ONE_DAY) {
        borderColor = "border-catchup-blue-100";
        deadlineAtColor = "text-catchup-gray-400";
        showRemainingDays = false;
        canNavigate = true;
        showWaitingForEvaluate = false;
      } else {
        borderColor = "border-catchup-blue-100";
        deadlineAtColor = "text-catchup-red";
        showRemainingDays = false;
        canNavigate = true;
        showWaitingForEvaluate = false;
      }
    } else {
      borderColor = "border-catchup-blue-100";
      deadlineAtColor = "text-catchup-gray-400";
      showRemainingDays = false;
      canNavigate = false;
      showWaitingForEvaluate = true;
    }
  }

  if (isPractise) {
    showProgressBar = false;
  } else {
    if (completionStatus === "NOT_STARTED") {
      showProgressBar = false;
    } else if (completionStatus === "ON_GOING") {
      showProgressBar = true;
    } else if (completionStatus === "TIME_UP") {
      showProgressBar = true;
    } else if (completionStatus === "COMPLETED") {
      showProgressBar = false;
      borderColor = "border-catchup-green";
      deadlineAtColor = "text-catchup-gray-400";
    }
  }

  return (
    <div
      className={`${borderColor} bg-catchup-white border-2 rounded-catchup-xlarge p-4 mx-2 my-4`}
    >
      <div className="flex flex-row gap-x-2">
        <div>
          <BaseImage size="large" src="/icons/catchxam.png" alt="catchxam" />
        </div>
        <div className="flex-1 flex flex-row justify-between items-center flex-wrap">
          <div className="flex-1 flex flex-col">
            <p className="font-semibold text-catchup-blue-500 text-lg">
              {i18n.t("catchxam")}
            </p>
            <p>{constructWeekName(beginDate, endDate)}</p>
          </div>
          <div className="flex flex-row">
            <GradeLabel title={`${i18n.t("grade")}: ${level}`} />
          </div>
        </div>
      </div>
      <div className="my-2">
        <DividerLine />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <p className="font-semibold">{name}</p>
          <div className="flex flex-row items-center gap-x-2 text-catchup-gray-300">
            <div>
              <p>{i18n.t(coterieType)}</p>
            </div>
            <img
              className="w-1 h-1"
              src="/icons/small-dot-gray.png"
              alt="small-dot-gray"
            />
            <div>
              <p>
                {activityDTOList.length} {i18n.t("activity")}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 mt-2">
            <p className={`${deadlineAtColor}`}>{`${i18n.t(
              "deadline_date"
            )}: ${endDate.toLocaleString(language)}`}</p>
          </div>
        </div>
        <div>
          <Tooltip id="info-description" />
          <div className="mr-2">
            <BaseImage
              size="small"
              src="/icons/info.png"
              alt="info"
              dataToolTipId="info-description"
              dataToolTipContent={description}
              dataToolTipPlace="top"
              dataToolTipVariant="info"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        {showRemainingDays ? (
          <div className="flex flex-col p-2 rounded-xl" onClick={() => {}}>
            <p>{`${i18n.t(
              "remaining_days_left_text_1"
            )}${calculateRemainingDays(beginAt)}${i18n.t(
              "remaining_days_left_text_2"
            )}`}</p>
          </div>
        ) : null}
        {showProgressBar ? (
          <div className="w-full my-1">
            <ProgressBar
              remainingTimeInSeconds={100 - completionPercentage * 100}
              totalTimeInSeconds={100}
              height="h-3"
              color="bg-catchup-blue-400"
              borderColor="border-catchup-blue-400"
            />
          </div>
        ) : (
          <div className="w-full my-1">
            <div className="h-3" />
          </div>
        )}
      </div>
      <div className="my-2">
        <DividerLine />
      </div>

      {canNavigate ? (
        completionStatus === "COMPLETED" ? (
          <div className="flex-1 flex flex-col mt-2">
            <ApproveButton
              title={i18n.t(completionStatus)}
              size="unlimited"
              textOnly={true}
              onClick={() => {}}
            />
          </div>
        ) : completionStatus === "NOT_STARTED" ||
          completionStatus === "ON_GOING" ? (
          <div className="flex-1 flex flex-col mt-2">
            <PrimaryButton
              title={
                completionStatus === "NOT_STARTED"
                  ? i18n.t("START")
                  : i18n.t("CONTINUE")
              }
              size="unlimited"
              onClick={() => {
                navigate(
                  `/catchxams/applications/${catchxamApplicationDTO.id}`
                );
              }}
            />
          </div>
        ) : completionStatus === "TIME_UP" ? (
          <div className="flex-1 flex flex-col mt-2">
            <DeleteButton
              title={i18n.t(completionStatus)}
              textOnly={true}
              size="unlimited"
              onClick={() => {}}
            />
          </div>
        ) : null
      ) : null}
      {showWaitingForEvaluate ? (
        <div className="flex-1 flex flex-col mt-2">
          <SecondaryButton
            title={i18n.t("waiting_for_evaluation")}
            size="unlimited"
            textOnly={true}
            onClick={() => {}}
          />
        </div>
      ) : null}
      {isPractise ? (
        <div className="flex-1 flex flex-col mt-2">
          <SecondaryButton
            title={i18n.t("practise_now")}
            size="unlimited"
            textOnly={true}
            onClick={() => {
              navigate(`/practise/catchxams/${catchxamDTO.id}`);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CatchxamApplicationCard;
