import { useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import DraggableItem from "../../draggable/DraggableItem";
import DroppableItem from "../../draggable/DroppableItem";
import ShowMaterialMediaByContentType from "./ShowMaterialMediaByContentType";
import { InlineMath } from "react-katex";
import useScreenSize from "../../../hooks/useScreenSize";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";
import DividerLine from "../../divider/DividerLine";

const MatchingActivityMaterialContent = (props) => {
  const {
    uniqueValue,
    answer,
    materialMap,
    contentMap,
    checkCanAnswerQuestion,
    onChange,
    isPreview,
    showCorrectAnswer,
  } = props;
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedTargetKey, setSelectedTargetKey] = useState(null);
  const [isShuffled, setIsShuffled] = useState(false);
  const [shuffledMaterialList, setShuffledMaterialList] = useState([]);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "MATCHING",
    drop: () => {},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const { containerSize } = useScreenSize();
  const itemsRef = useRef(null);

  useEffect(() => {
    const shuffleArray = (array) => {
      if (!isShuffled) {
        const copyArray = JSON.parse(JSON.stringify(array));
        for (let i = copyArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
        }
        setIsShuffled(true);
        return copyArray;
      }
      return array;
    };
    const materialList = [];
    Object.keys(materialMap).forEach((materialKey) => {
      materialList.push(materialMap[materialKey]);
    });
    setShuffledMaterialList(shuffleArray(materialList));
  }, []);

  useEffect(() => {
    if (!showCorrectAnswer) return;
    answer.data.find((answerData) => answerData.type === "MATCHING").answerMap =
      materialMap;
  }, [showCorrectAnswer]);

  useEffect(() => {
    if (!itemsRef) return;
    if (!itemsRef.current) return;
    if (!containerSize) return;
    itemsRef.current.style.width = containerSize.width - 220;
  }, [itemsRef, containerSize]);

  const retrieveAnswerMap = () => {
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "MATCHING"
    );
    const answerMap = answer.data[foundIndex].answerMap;
    const sortedAnswerMapKeys = Object.keys(answerMap).sort((a, b) =>
      answerMap[a]
        ? answerMap[b]
          ? answerMap[a].localeCompare(answerMap[b])
          : 1
        : -1
    );
    const sortedAnswerMap = {};
    for (const answerMapKey of sortedAnswerMapKeys) {
      sortedAnswerMap[answerMapKey] = answerMap[answerMapKey];
    }
    return sortedAnswerMap;
  };

  const retrieveFilteredMaterialList = (answerMap) => {
    const selectedValueList = [];
    Object.keys(answerMap).forEach((key) => {
      selectedValueList.push(answerMap[key]);
    });

    return shuffledMaterialList.filter(
      (material) =>
        selectedValueList.findIndex((value) => material === value) === -1
    );
  };

  const checkAnswerState = (correctAnswer, learnerAnswer) => {
    if (!isPreview) return "HIDDEN";
    if (!learnerAnswer) return "EMPTY";
    if (correctAnswer === learnerAnswer) {
      return "CORRECT";
    }
    return "INCORRECT";
  };

  const handleMatchingActivityItemOnChange = (
    selectedTargetKey,
    selectedValue
  ) => {
    if (checkCanAnswerQuestion()) {
      onChange(answer, selectedTargetKey, selectedValue);
      setSelectedValue(null);
    }
  };

  const answerMap = retrieveAnswerMap();
  const filteredMaterialList = retrieveFilteredMaterialList(answerMap);

  return (
    <>
      <div
        ref={itemsRef}
        className="flex-1 flex flex-row gap-x-4 gap-y-4 overflow-auto py-2"
      >
        {filteredMaterialList.map((materialValue, index) => (
          <DraggableItem
            index={index}
            item={{ index: materialValue }}
            type={"MATCHING"}
            component={
              <div
                className={`${
                  selectedValue === materialValue
                    ? "border-catchup-blue"
                    : "border-catchup-lighter-gray"
                } h-catchup-activity-covering-box-item flex flex-col items-center justify-center  border-2 rounded-catchup-xlarge cursor-pointer transition-all duration-300`}
                onMouseDown={() => {
                  if (checkCanAnswerQuestion()) {
                    setSelectedValue(materialValue);
                  }
                }}
                onMouseUp={() => {
                  if (checkCanAnswerQuestion()) {
                    setSelectedValue(null);
                  }
                }}
              >
                {contentMap.type === "TEXT" ? (
                  <div
                    className={`flex flex-col items-center justify-center m-4 min-h-[64px] min-w-[200px]`}
                  >
                    <p className="text-xl p-5 whitespace-pre-wrap">
                      {constructInputWithSpecialExpressionList(
                        materialValue
                      ).map((inputPart, index) => (
                        <span
                          key={index}
                          className={`${inputPart.isBold ? "font-bold" : ""} ${
                            inputPart.isUnderline ? "underline" : ""
                          }`}
                        >
                          {inputPart.isEquation ? (
                            <span className="text-2xl">
                              <InlineMath math={inputPart.value} />
                            </span>
                          ) : (
                            inputPart.value
                          )}
                        </span>
                      ))}
                    </p>
                  </div>
                ) : (
                  <ShowMaterialMediaByContentType
                    key={`${uniqueValue}-${index}`}
                    contentType={contentMap.type}
                    src={materialValue}
                    canFullScreen={true}
                  />
                )}
              </div>
            }
            moveCardHandler={() => {
              handleMatchingActivityItemOnChange(
                selectedTargetKey,
                selectedValue
              );
            }}
          />
        ))}
      </div>
      {filteredMaterialList.length > 0 ? <DividerLine /> : null}
      {Object.keys(answerMap).map((answerMapKey, index) => {
        const learnerAnswerState = checkAnswerState(
          materialMap[answerMapKey],
          answerMap[answerMapKey]
        );

        return (
          <div key={index} className="flex flex-row w-full">
            <div className="w-1/3">
              <div
                className={`h-catchup-activity-outer-box-item flex flex-col items-center justify-center  border-2 rounded-catchup-xlarge transition-all duration-300 my-3 ${
                  learnerAnswerState === "EMPTY"
                    ? "border-catchup-blue"
                    : learnerAnswerState === "CORRECT"
                    ? "border-catchup-green"
                    : learnerAnswerState === "INCORRECT"
                    ? "border-catchup-red"
                    : "border-catchup-blue"
                }`}
              >
                <div
                  className={`flex flex-col items-center justify-center transition-all duration-300 m-4`}
                >
                  <p className="text-xl p-5 whitespace-pre-wrap">
                    {constructInputWithSpecialExpressionList(answerMapKey).map(
                      (inputPart, index) => (
                        <span
                          key={index}
                          className={`${inputPart.isBold ? "font-bold" : ""} ${
                            inputPart.isUnderline ? "underline" : ""
                          }`}
                        >
                          {inputPart.isEquation ? (
                            <span className="text-2xl">
                              <InlineMath math={inputPart.value} />
                            </span>
                          ) : (
                            inputPart.value
                          )}
                        </span>
                      )
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="mx-4 w-[2px] bg-catchup-lighter-gray"></div>
            <div className="flex-1">
              <div
                className={`${
                  canDrop
                    ? selectedTargetKey === answerMapKey
                      ? "bg-catchup-light-blue"
                      : "bg-catchup-light-blue opacity-40"
                    : ""
                } h-catchup-activity-outer-box-item flex flex-col items-center justify-center  border-2 rounded-catchup-xlarge cursor-pointer transition-all duration-300 my-3 ${
                  learnerAnswerState === "EMPTY"
                    ? "border-catchup-blue"
                    : learnerAnswerState === "CORRECT"
                    ? "border-catchup-green"
                    : learnerAnswerState === "INCORRECT"
                    ? "border-catchup-red"
                    : "border-catchup-blue"
                }`}
                onClick={() => {
                  if (checkCanAnswerQuestion()) {
                    setSelectedValue(null);
                  }
                }}
              >
                <DroppableItem
                  item={{ index: answerMapKey }}
                  type={"MATCHING"}
                  target={selectedTargetKey}
                  setTarget={setSelectedTargetKey}
                  dropRef={drop}
                  component={
                    <div
                      className={`h-full flex-1 flex flex-row items-center justify-center `} // w-[calc((100vw_-_214px)_/_2)]
                      onClick={(e) => {
                        e.preventDefault();
                        if (checkCanAnswerQuestion()) {
                          handleMatchingActivityItemOnChange(
                            answerMapKey,
                            null
                          );
                        }
                      }}
                    >
                      {contentMap.type === "TEXT" ? (
                        <p className="text-xl p-5 whitespace-pre-wrap">
                          {constructInputWithSpecialExpressionList(
                            answerMap[answerMapKey]
                          ).map((inputPart, index) => (
                            <span
                              key={index}
                              className={`${
                                inputPart.isBold ? "font-bold" : ""
                              } ${inputPart.isUnderline ? "underline" : ""}`}
                            >
                              {inputPart.isEquation ? (
                                <span className="text-2xl">
                                  <InlineMath math={inputPart.value} />
                                </span>
                              ) : (
                                inputPart.value
                              )}
                            </span>
                          ))}
                        </p>
                      ) : (
                        <ShowMaterialMediaByContentType
                          key={`${uniqueValue}-${index}`}
                          contentType={contentMap.type}
                          src={answerMap[answerMapKey]}
                          canFullScreen={false}
                        />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MatchingActivityMaterialContent;
