import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-notifications/lib/notifications.css";
import "react-tooltip/dist/react-tooltip.css";
import "katex/dist/katex.min.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserContextProvider from "./context/UserContextProvider";
import AppContextProvider from "./context/AppContextProvider";
import BaseRouterProvider from "./router/BaseRouterProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Modal from "react-modal";
import { pdfjs } from "react-pdf";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
  BarElement,
  LinearScale,
  Title,
  CategoryScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  ArcElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
Modal.setAppElement("#root");

root.render(
  // <React.StrictMode>
  <AppContextProvider>
    <UserContextProvider>
      <DndProvider backend={HTML5Backend}>
        <App>
          <div className="bg-catchup-pattern-dashboard">
            <BaseRouterProvider />
          </div>
        </App>
      </DndProvider>
    </UserContextProvider>
  </AppContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
