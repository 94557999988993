import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createActivityAnswerByActivityIdCatchtivityApplicationId,
  createActivityProgressByActivityIdCatchtivityApplicationId,
  patchActivityAnswerWithData,
  patchActivityProgress,
  queryActivityAnswerListByCatchtivityApplicationId,
  retrieveActivityWithDataById,
  retrieveCatchtivityApplicationById,
  retrieveCatchtivityApplicationProgress,
  hintActivityWithAI,
} from "../../requests/CatchtivityRequests";
import { queryPersonalStatusByParams } from "../../requests/GamificationRequests";
import { queryPlanWeekListByParams } from "../../requests/PlanRequests";
import { checkViewPermissionGranted } from "../../utilization/ScreenUtilization";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullCard from "../../components/cards/FullCard";
import CatchtivityFooter from "../../components/footers/CatchtivityFooter";
import { useApp } from "../../context/AppContextProvider";
import {
  retrieveActivityAnswerFromAnswerList,
  checkIfAnswerIsEmpty,
  constructActivityAnswerMap,
  constructActivityAnswerStateList,
  findBestFitActivity,
  retrieveTotalTimeSpentInMinutes,
  retrieveClockTimeLeft,
  parseBodyMapFromData,
  parseMaterialMapFromData,
  parseContentMapFromData,
  constructInputWithSpecialExpressionList,
  constructActivityItemListWithAnswersForAI,
} from "../../utilization/CatchtivityUtilization";
import OrderingActivityContent from "../../components/activities/OrderingActivityContent";
import DropdownActivityContent from "../../components/activities/DropdownActivityContent";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import MCMAActivityContent from "../../components/activities/MCMAActivityContent";
import MatchingActivityContent from "../../components/activities/MatchingActivityContent";
import GroupingActivityContent from "../../components/activities/GroupingActivityContent";
import FillInTheBlanksActivityContent from "../../components/activities/FillInTheBlanksActivityContent";
import OpenEndedActivityContent from "../../components/activities/OpenEndedActivityContent";
import BaseImage from "../../components/images/BaseImage";
import moment from "moment";
import { queryMainFileByIdList } from "../../requests/StorageRequests";
import BaseModal from "../../components/modal/BaseModal";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import ActivityEmptyContent from "../../components/activities/empty-content/ActivityEmptyContent";
import TrueFalseActivityContent from "../../components/activities/TrueFalseActivityContent";
import { queryOutcomeModalByParams } from "../../requests/AIModelConstructorRequests";
import ContentTransition from "../../components/transitions/ContentTransition";
import DashboardHeader from "../../components/headers/DashboardHeader";
import CatchtivityProgressBar from "../../components/progress-bar/CatchtivityProgressBar";
import Mascot from "../../components/mascots/Mascot";
import { NotificationManager } from "react-notifications";
import BaseLoading from "../../components/loading/BaseLoading";
import DividerLine from "../../components/divider/DividerLine";
import ScreenCard from "../../components/cards/ScreenCard";

const CatchtivityApplicationScreen = () => {
  const { catchtivityApplicationId } = useParams();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen, storageStompClient } = useApp();
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    individualModelList,
    individualModelListLoading,
    personalStatus,
    setPersonalStatus,
  } = useAuth();
  const [pageState, setPageState] = useState(0);
  const [activityNumber, setActivityNumber] = useState(null);
  const [catchtivityApplication, setCatchtivityApplication] = useState(null);
  const [activity, setActivity] = useState(null);
  const [activityAnswerDTOList, setActivityAnswerDTOList] = useState([]);
  const [activityAnswerDTOListLoading, setActivityAnswerDTOListLoading] =
    useState(true);
  const [randomActivityTemplateIndex, setRandomActivityTemplateIndex] =
    useState(0);
  const [
    catchtivityApplicationProgressDTO,
    setCatchtivityApplicationProgressDTO,
  ] = useState(null);
  const [
    catchtivityApplicationProgressDTOLoading,
    setCatchtivityApplicationProgressDTOLoading,
  ] = useState(true);
  const [currentActivityInitialTimestamp, setCurrentActivityInitialTimestamp] =
    useState(new Date().getTime());
  const [activityLoading, setActivityLoading] = useState({
    self: false,
    answer: false,
    progress: false,
  });
  const [planWeekList, setPlanWeekList] = useState([]);
  const [planWeekMap, setPlanWeekMap] = useState({});
  const [planWeekLoading, setPlanWeekLoading] = useState({
    planWeek: false,
    documents: false,
  });
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [outcomeModelList, setOutcomeModelList] = useState([]);
  const [outcomeModelListLoading, setOutcomeModelListLoading] = useState(false);
  const [catchtivityHintMap, setCatchtivityHintMap] = useState({});
  const MAX_NUMBER_OF_OPEN_ENDED_COUNT = 2;
  const POINTS_ACTIVITY_HINT = 200;
  const INSURANCE_RATIO = 0.5;

  useEffect(() => {
    if (pageState === 0) {
      if (isFullScreen === true) {
        setIsFullScreen(false);
      }
    } else {
      if (isFullScreen === false) {
        setIsFullScreen(true);
      }
    }
  }, [pageState]);

  useEffect(() => {
    const retrieveAndSetCatchtivityApplication = async () => {
      const { data, err } = await retrieveCatchtivityApplicationById(
        catchtivityApplicationId
      );
      if (err) {
        console.log(err);
        return;
      }
      setCatchtivityApplication(data);
    };
    const retrieveAndSetCatchtivityApplicationProgress = async () => {
      setCatchtivityApplicationProgressDTOLoading(true);
      const { data, err } = await retrieveCatchtivityApplicationProgress({
        id: catchtivityApplicationId,
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setCatchtivityApplicationProgressDTO(data);
      setCatchtivityApplicationProgressDTOLoading(false);
    };
    const retrieveAndSetActivityAnswerList = async () => {
      setActivityAnswerDTOListLoading(true);
      const { data, err } =
        await queryActivityAnswerListByCatchtivityApplicationId({
          catchtivityApplicationId,
          userId: userInformation.id,
          userProfileId: userProfile.id,
        });
      if (err) {
        console.log(err);
        return;
      }
      setActivityAnswerDTOList(data);
      setActivityAnswerDTOListLoading(false);
    };
    const retrieveAndSetPersonalStatus = async () => {
      const { data, err } = await queryPersonalStatusByParams({
        userId: userInformation.id,
        userProfileId: userProfile.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setPersonalStatus(data);
    };
    if (checkViewPermissionGranted("catchtivity_application")) {
      retrieveAndSetCatchtivityApplication();
      retrieveAndSetCatchtivityApplicationProgress();
      retrieveAndSetActivityAnswerList();
      retrieveAndSetPersonalStatus();
    } else {
      navigate("/permission-error");
    }
  }, [window.location]);

  useEffect(() => {
    if (!activity) return;
    if (individualModelList.length === 0) return;
    let openEndedCount = 0;
    let mcsaCount = 0;
    let otherCount = 0;
    for (const activityAnswerDTO of activityAnswerDTOList) {
      const { data } = activityAnswerDTO;
      const dataJSONList = JSON.parse(data);
      if (
        dataJSONList.findIndex((dataJSON) => dataJSON.type === "OPEN_ENDED") !==
        -1
      ) {
        openEndedCount += 1;
      } else if (
        dataJSONList.findIndex((dataJSON) => dataJSON.type === "MCSA") !== -1
      ) {
        mcsaCount += 1;
      } else {
        otherCount += 1;
      }
    }
    const activityCount =
      catchtivityApplication.catchtivityDTO.activityDTOList.length;
    const currentRatio = (activityCount - otherCount) / activityCount;
    if (!activityCount) return;
    const { bestActivityTemplateList, activityTemplateValueMap } =
      findBestFitActivity(activity, individualModelList, outcomeModelList);
    const currentRandomIndex = Math.floor(
      Math.random() * bestActivityTemplateList.length
    );
    let bestActivityTemplate = bestActivityTemplateList[currentRandomIndex];
    if (
      bestActivityTemplate === "OPEN_ENDED" &&
      openEndedCount >= MAX_NUMBER_OF_OPEN_ENDED_COUNT &&
      Object.keys(activityTemplateValueMap).length > 1
    ) {
      const copyActivityTemplateValueMap = JSON.parse(
        JSON.stringify(activityTemplateValueMap)
      );
      delete copyActivityTemplateValueMap["OPEN_ENDED"];
      let secondBestScore = Number.MAX_SAFE_INTEGER;
      for (const activityTemplateValueMapKey of Object.keys(
        copyActivityTemplateValueMap
      )) {
        const currentScore =
          copyActivityTemplateValueMap[activityTemplateValueMapKey];
        if (currentScore < secondBestScore)
          bestActivityTemplate = activityTemplateValueMapKey;
      }
    } else if (
      currentRatio <= INSURANCE_RATIO &&
      Object.keys(activityTemplateValueMap).findIndex(
        (key) => key === "MCSA"
      ) !== -1
    ) {
      bestActivityTemplate = "MCSA";
    }

    const { activityTemplateDTOList } = activity;
    if (bestActivityTemplate) {
      setRandomActivityTemplateIndex(
        activityTemplateDTOList.findIndex(
          (activityTemplateDTO) =>
            activityTemplateDTO.type === bestActivityTemplate
        )
      );
    } else {
      setRandomActivityTemplateIndex(0);
    }
  }, [activity, activityAnswerDTOList, individualModelList, outcomeModelList]);

  useEffect(() => {
    if (!activity) return;
    setCurrentActivityInitialTimestamp(new Date().getTime());
  }, [activity]);

  useEffect(() => {
    if (!activity) return;
    if (activityAnswerDTOListLoading) return;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    if (!currentActivityTemplate) return;
    if (currentActivityTemplate.type === "ORDERING") {
      const { answer } = retrieveCurrentActivityAnswer();
      changeActivityAnswer(answer);
    }
  }, [activity, activityAnswerDTOListLoading]);

  useEffect(() => {
    const retrieveAndSetActivityWithData = async () => {
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: true,
      }));
      const activityDTOList =
        catchtivityApplication.catchtivityDTO.activityDTOList;
      if (activityDTOList.length > 0) {
        const currentActivity = activityDTOList[activityNumber];
        const { data, err } = await retrieveActivityWithDataById(
          currentActivity.id
        );
        if (err) {
          console.log(err);
        } else {
          setActivity(data);
        }
      }
      setActivityLoading((prevActivityLoading) => ({
        ...prevActivityLoading,
        self: false,
      }));
    };
    if (activityNumber === 0) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
    }
    if (
      catchtivityApplication &&
      activityNumber ===
        catchtivityApplication.catchtivityDTO.activityDTOList.length
    ) {
      setCurrentActivityInitialTimestamp(new Date().getTime());
      setShowCompleteModal(true);
      setActivity(null);
    } else if (
      catchtivityApplication &&
      activityNumber !== null &&
      activityNumber >= 0
    ) {
      setActivity(null);
      retrieveAndSetActivityWithData();
    } else {
      setActivity(null);
    }
  }, [catchtivityApplication, activityNumber]);

  useEffect(() => {
    if (!catchtivityApplication) return;
    retrieveAndSetPlanWeekList();
  }, [catchtivityApplication]);

  useEffect(() => {
    if (planWeekList.length === 0) return;
    retrieveAndSetPlanWeekDocuments();
  }, [planWeekList]);

  useEffect(() => {
    const retrieveAndSetOutcomeModelList = async () => {
      setOutcomeModelListLoading(true);
      const { catchtivityDTO } = catchtivityApplication;
      const { activityDTOList } = catchtivityDTO;
      const categoryIdSet = new Set();
      for (const activityDTO of activityDTOList) {
        const { categoryIdList } = activityDTO;

        for (const categoryId of categoryIdList) {
          categoryIdSet.add(categoryId);
        }
      }
      const { data, err } = await queryOutcomeModalByParams({
        userId: userInformation.id,
        outcomeIdList: Array.from(categoryIdSet),
      });
      if (err) {
        console.log(err);
        return err;
      }
      setOutcomeModelList(data);
      setOutcomeModelListLoading(false);
    };
    if (!catchtivityApplication) return;
    retrieveAndSetOutcomeModelList();
  }, [catchtivityApplication]);

  const retrieveAndSetPlanWeekList = async () => {
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      planWeek: true,
    }));
    const beginAt = moment(new Date().getTime())
      .startOf("isoWeek")
      .toDate()
      .getTime();
    const endAt = moment(new Date().getTime())
      .endOf("isoWeek")
      .toDate()
      .getTime();
    const queryParams = {
      brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
      campusId: userProfileSeason.institutionDTO.campusDTO.id,
      institutionId: userProfileSeason.institutionDTO.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      coterieType: catchtivityApplication.catchtivityDTO.coterieType,
      beginAt,
      endAt,
    };
    const { data, err } = await queryPlanWeekListByParams(queryParams);
    if (err) {
      console.log(err);
      return;
    }
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      planWeek: false,
    }));
    setPlanWeekList(data);
  };

  const retrieveAndSetPlanWeekDocuments = async () => {
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      documents: true,
    }));
    const planWeekMap = planWeekList.map((planWeek) => {
      const planWeekMap = {};
      const { planWeekDocumentDTOList } = planWeek;
      for (const planWeekDocument of planWeekDocumentDTOList) {
        if (planWeekMap[planWeek.id]) {
          planWeekMap[planWeek.id].push({
            documentId: planWeekDocument.documentId,
          });
        } else {
          planWeekMap[planWeek.id] = [
            { documentId: planWeekDocument.documentId },
          ];
        }
      }
      return planWeekMap;
    });
    if (Object.keys(planWeekMap).length === 0) {
      setPlanWeekLoading((prevPlanWeekLoading) => ({
        ...prevPlanWeekLoading,
        documents: false,
      }));
      return;
    }
    const planWeekPromises = planWeekMap.map(async (planWeek) => {
      if (Object.keys(planWeek).length === 0) return;
      const planWeekId = Object.keys(planWeek)[0];
      const foundPlanWeek = planWeekList.find(
        (planWeek) => planWeek.id === parseFloat(planWeekId)
      );
      const { data, err } = await queryMainFileByIdList({
        idList: planWeek[planWeekId].map((pw) => pw.documentId),
      });
      if (err) {
        console.log(err);
      } else {
        setPlanWeekMap((prevDocumentMap) => ({
          ...prevDocumentMap,
          [planWeekId]: {
            ...foundPlanWeek,
            documentList: data,
          },
        }));
      }
    });
    await Promise.all(planWeekPromises);
    setPlanWeekLoading((prevPlanWeekLoading) => ({
      ...prevPlanWeekLoading,
      documents: false,
    }));
  };

  const retrieveCurrentActivityTemplate = () => {
    const foundActivityAnswer = activityAnswerDTOList.find(
      (activityAnswerDTO) =>
        parseFloat(activityAnswerDTO.activityDTO.id) === parseFloat(activity.id)
    );
    if (foundActivityAnswer?.data) {
      const activityAnswerData = JSON.parse(foundActivityAnswer.data);
      return activity.activityTemplateDTOList.find(
        (activityTemplateDTO) =>
          activityTemplateDTO.type === activityAnswerData[0].type
      );
    }
    const desiredActivity = activity.activityTemplateDTOList.find(
      (activityTemplateDTO) =>
        activityTemplateDTO.type === "INSERT_ANY_TEMPLATE_NAME_HERE"
    );
    if (desiredActivity) {
      return desiredActivity;
    } else {
      return activity.activityTemplateDTOList[randomActivityTemplateIndex];
    }
  };

  const retrieveCurrentActivityAnswer = () => {
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    const foundActivityAnswerDTO = activityAnswerDTOList[foundIndex];
    if (foundIndex > -1 && foundActivityAnswerDTO.data) {
      const currentAnswer = foundActivityAnswerDTO;
      return {
        index: foundIndex,
        answer: {
          ...currentAnswer,
          data: JSON.parse(currentAnswer.data),
        },
      };
    } else {
      const currentActivityTemplate = retrieveCurrentActivityTemplate();
      return {
        index: foundIndex,
        answer: {
          userId: userInformation.id,
          userProfileId: userProfile.id,
          activityDTO: {
            id: activity.id,
          },
          catchtivityApplicationDTO: {
            id: catchtivityApplicationId,
          },
          data: currentActivityTemplate
            ? [
                constructActivityAnswerMap(
                  currentActivityTemplate,
                  JSON.parse(activity.data)
                ),
              ]
            : [],
        },
      };
    }
  };

  const retrieveCurrentActivityAnswerTemplate = () => {
    const { answer } = retrieveCurrentActivityAnswer();
    const { data } = answer;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    return { answerTemplate, answer };
  };

  const retrieveCurrentHintText = () => {
    if (!activity) return null;
    if (catchtivityHintMap[activity.id]) {
      return i18n.t("here_is_your_hint");
    } else {
      if (personalStatus.remainingPoints - POINTS_ACTIVITY_HINT < 0)
        return i18n.t("ooops_text");
      return i18n.t("i_am_here_for_you");
    }
  };

  const retrieveCurrentHintSubText = () => {
    if (!activity) return null;
    if (catchtivityHintMap[activity.id]) {
      let hintSubText = "";
      Object.keys(catchtivityHintMap[activity.id]).forEach((key) => {
        hintSubText += catchtivityHintMap[activity.id][key].value + " ";
      });
      return hintSubText.substring(0, hintSubText.length - 1);
    } else {
      if (personalStatus.remainingPoints - POINTS_ACTIVITY_HINT <= 0)
        return i18n.t("you_do_not_have_enough_points_for_a_hint");
      return i18n.t("do_you_need_help_for_the_activity");
    }
  };

  const canShowPrimaryButton = () => {
    if (!activity) return false;
    if (personalStatus.remainingPoints - POINTS_ACTIVITY_HINT <= 0)
      return false;
    if (catchtivityHintMap[activity.id]) return false;
    return true;
  };

  const checkCanAnswerQuestion = () => {
    if (activity) {
      const activityData = JSON.parse(activity.data);
      let durationMap = null;

      if (activityData.durationMap) {
        durationMap = JSON.parse(activityData.durationMap);
      }
      if (
        retrieveClockTimeLeft(
          "CATCHTIVITY",
          null,
          catchtivityApplication?.durationType,
          catchtivityApplication?.durationInMinutes,
          catchtivityApplicationProgressDTO?.activityProgressDTOSet,
          activity
        ) < 0 ||
        (durationMap
          ? retrieveClockTimeLeft(
              "ACTIVITY",
              durationMap.duration,
              catchtivityApplication?.durationType,
              catchtivityApplication?.durationInMinutes,
              catchtivityApplicationProgressDTO?.activityProgressDTOSet,
              activity
            ) < 0
          : false)
      ) {
        return false;
      } else {
        return true;
      }
    }
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate.isEmpty) {
      return false;
    }
    return false;
  };

  const createOrPatchActivityProgress = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: true,
    }));
    const foundActivityProgressDTO =
      catchtivityApplicationProgressDTO.activityProgressDTOSet.find(
        (activityProgressDTO) =>
          parseFloat(activityProgressDTO.activityDTO.id) ===
          parseFloat(activity.id)
      );
    if (foundActivityProgressDTO) {
      await patchAndSetActivityProgress(foundActivityProgressDTO.id);
    } else {
      await createAndSetActivityProgress();
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      progress: false,
    }));
  };

  const createAndSetActivityProgress = async () => {
    const { data, err } =
      await createActivityProgressByActivityIdCatchtivityApplicationId({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
        activity: {
          id: activity.id,
        },
        catchtivityApplication: {
          id: catchtivityApplication.id,
        },
        seasonId: userProfileSeason.id,
        gradeId: userProfileGrade.id,
        branchId: userProfileBranch.id,
      });
    if (err) {
      console.log(err);
    } else {
      catchtivityApplicationProgressDTO.activityProgressDTOSet.push({
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        catchtivityApplicationDTO: data.catchtivityApplicationDTO,
      });
      setCatchtivityApplicationProgressDTO(
        JSON.parse(JSON.stringify(catchtivityApplicationProgressDTO))
      );
    }
  };

  const patchAndSetActivityProgress = async (activityProgressId) => {
    const { data, err } = await patchActivityProgress({
      id: activityProgressId,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      timeSpent: new Date().getTime() - currentActivityInitialTimestamp,
      activityId: activity.id,
      catchtivityApplicationId: catchtivityApplication.id,
      branchId: userProfileBranch.id,
      gradeId: userProfileGrade.id,
      seasonId: userProfileSeason.id,
    });
    if (err) {
      console.log(err);
    } else {
      const foundIndex =
        catchtivityApplicationProgressDTO.activityProgressDTOSet.findIndex(
          (activityProgressDTO) =>
            parseFloat(activityProgressDTO.id) ===
            parseFloat(activityProgressId)
        );
      catchtivityApplicationProgressDTO.activityProgressDTOSet[foundIndex] = {
        ...catchtivityApplicationProgressDTO.activityProgressDTOSet[foundIndex],
        id: data.id,
        userId: data.userId,
        userProfileId: data.userProfileId,
        timeSpent: data.timeSpent,
        activityDTO: data.activityDTO,
        catchtivityApplicationDTO: data.catchtivityApplicationDTO,
      };
      setCatchtivityApplicationProgressDTO(
        JSON.parse(JSON.stringify(catchtivityApplicationProgressDTO))
      );
    }
  };

  const checkIsQuicktivityContent = () => {
    if (!activity) return;
    const data = JSON.parse(activity.data);
    if (data.durationMap) {
      const durationMap = JSON.parse(data.durationMap);
      if (durationMap.durationType === "EACH") {
        return true;
      }
    }
    return false;
  };

  const changeActivityAnswer = (answer) => {
    const copyAnswer = JSON.parse(JSON.stringify(answer));
    copyAnswer.data = JSON.stringify(copyAnswer.data);
    const foundIndex = activityAnswerDTOList.findIndex(
      (activityAnswer) =>
        activityAnswer.activityDTO.id === parseFloat(activity.id)
    );
    if (foundIndex === -1) {
      activityAnswerDTOList.push(copyAnswer);
    } else {
      activityAnswerDTOList[foundIndex] = copyAnswer;
    }
    setActivityAnswerDTOList(JSON.parse(JSON.stringify(activityAnswerDTOList)));
  };

  const handleHintActivityWithAIOnClick = async () => {
    if (!activity) return null;
    const data = JSON.parse(activity.data);
    const contentMap = parseContentMapFromData(data);
    if (contentMap.type !== "TEXT") return;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    if (!currentActivityTemplate) return null;
    let itemList = [];
    if (currentActivityTemplate.type === "ORDERING") {
      const orderingBodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const orderingMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        orderingBodyMap,
        orderingMaterialMap,
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "DROPDOWN") {
      const dropdownBodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const dropdownMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        dropdownBodyMap,
        dropdownMaterialMap,
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "MCSA") {
      const MCSABodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const MCSAMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        MCSABodyMap,
        MCSAMaterialMap,
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "MCMA") {
      const MCMABodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const MCMAMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        MCMABodyMap,
        MCMAMaterialMap,
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "MATCHING") {
      const matchingBodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const matchingMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        matchingBodyMap,
        matchingMaterialMap,
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "GROUPING") {
      const groupingBodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const groupingMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        groupingBodyMap,
        groupingMaterialMap,
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      const fillInTheBlanksBodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const fillInTheBlanksMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        fillInTheBlanksBodyMap,
        fillInTheBlanksMaterialMap,
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "OPEN_ENDED") {
      const openEndedBodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        openEndedBodyMap,
        {},
        currentActivityTemplate.type
      );
    } else if (currentActivityTemplate.type === "TRUE_FALSE") {
      const trueFalseBodyMap = parseBodyMapFromData(
        data,
        currentActivityTemplate.type
      );
      const trueFalseMaterialMap = parseMaterialMapFromData(
        data,
        currentActivityTemplate.type
      );
      itemList = constructActivityItemListWithAnswersForAI(
        trueFalseBodyMap,
        trueFalseMaterialMap,
        currentActivityTemplate.type
      );
    }

    const hintWithAI = {
      itemList,
      temperature: 0,
      level: activity.level,
      coterieType: activity.coterieType,
      activityId: activity.id,
      activityTemplateId: currentActivityTemplate.id,
      activityTemplateType: currentActivityTemplate.type,
      userId: userInformation.id,
      userProfileId: userProfile.id,
      brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
      campusId: userProfileSeason.institutionDTO.campusDTO.id,
      institutionId: userProfileSeason.institutionDTO.id,
    };

    const { data: hintData, err: hintErr } = await hintActivityWithAI(
      hintWithAI
    );
    if (hintErr) {
      NotificationManager.error(i18n.t("error_occurred_on_asking_hint"));
      return;
    }
    personalStatus.remainingPoints =
      personalStatus.remainingPoints - POINTS_ACTIVITY_HINT;
    setPersonalStatus(JSON.parse(JSON.stringify(personalStatus)));
    catchtivityHintMap[activity.id] = hintData.hintMap;
    setCatchtivityHintMap(JSON.parse(JSON.stringify(catchtivityHintMap)));
  };

  const handleCreateOrUpdateActivityAnswerWithData = async () => {
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: true,
    }));
    const { index, answer } = retrieveCurrentActivityAnswer();
    const baseAnswer = {
      userId: answer.userId,
      userProfileId: answer.userProfileId,
      data: JSON.stringify(answer.data),
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      branchId: userProfileBranch.id,
    };
    if (answer.id) {
      const currentAnswer = {
        ...baseAnswer,
        id: answer.id,
        activityId: answer.activityDTO.id,
        catchtivityApplicationId: answer.catchtivityApplicationDTO.id,
      };
      const { data, err } = await patchActivityAnswerWithData(currentAnswer);
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          ...activityAnswerDTOList[index],
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          catchtivityApplicationDTO: data.catchtivityApplicationDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    } else {
      const newAnswer = {
        ...baseAnswer,
        activity: {
          id: answer.activityDTO.id,
        },
        catchtivityApplication: {
          id: answer.catchtivityApplicationDTO.id,
        },
      };
      const { data, err } =
        await createActivityAnswerByActivityIdCatchtivityApplicationId(
          newAnswer
        );
      if (err) {
        console.log(err);
      } else {
        activityAnswerDTOList[index] = {
          id: data.id,
          userId: data.userId,
          userProfileId: data.userProfileId,
          activityDTO: data.activityDTO,
          catchtivityApplicationDTO: data.catchtivityApplicationDTO,
          data: data.data,
          deleted: data.deleted,
        };
        setActivityAnswerDTOList(
          JSON.parse(
            JSON.stringify(activityAnswerDTOList.sort((a, b) => b.id - a.id))
          )
        );
      }
    }
    setActivityLoading((prevActivityLoading) => ({
      ...prevActivityLoading,
      answer: false,
    }));
  };

  const handleToNextActivity = (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      handleCreateOrUpdateActivityAnswerWithData();
    }
    createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleSetEmptyOrContinue = async () => {
    const { index, answer } = retrieveCurrentActivityAnswer();
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    const { data } = answer;
    const answerTemplate = data.find(
      (datum) => datum.type === currentActivityTemplate.type
    );
    if (answerTemplate) {
      if (answerTemplate.isEmpty) {
        answerTemplate.isEmpty = false;
      } else {
        answerTemplate.isEmpty = true;
      }
      const copyAnswer = JSON.parse(JSON.stringify(answer));
      copyAnswer.data = JSON.stringify(copyAnswer.data);
      activityAnswerDTOList[index] = copyAnswer;
      setActivityAnswerDTOList([...activityAnswerDTOList]);
      handleCreateOrUpdateActivityAnswerWithData();
    }
  };

  const handleToNextActivityWithAsync = async (nextActivityNumber) => {
    const { answer } = retrieveCurrentActivityAnswer();
    if (!checkIfAnswerIsEmpty(answer)) {
      await handleCreateOrUpdateActivityAnswerWithData();
    }
    await createOrPatchActivityProgress();
    setActivityNumber(nextActivityNumber);
  };

  const handleToNextActivityCheckCanAnswer = (nextActivityNumber) => {
    if (checkCanAnswerQuestion()) {
      if (
        nextActivityNumber ===
        catchtivityApplication.catchtivityDTO.activityDTOList.length
      ) {
        handleToNextActivityWithAsync(nextActivityNumber);
      } else {
        handleToNextActivity(nextActivityNumber);
      }
    } else {
      setActivityNumber(nextActivityNumber);
    }
  };

  const handleToPreviousActivity = async () => {
    await createOrPatchActivityProgress();
    setActivityNumber(activityNumber - 1);
  };

  const handleGoPreviousScreen = async () => {
    if (pageState === 1) {
      if (activity) {
        await createOrPatchActivityProgress();
        setCurrentActivityInitialTimestamp(new Date().getTime());
      }
    }
    navigate("/homeworks", {
      state: { state: "FORCE_UPDATE" },
    });
  };

  const handleOnTimerEnds = (valid) => {
    if (!valid) return;
    handleToNextActivityCheckCanAnswer(activityNumber + 1);
  };

  const RenderCurrentActivityContent = () => {
    if (!activity) return null;
    const currentActivityTemplate = retrieveCurrentActivityTemplate();
    if (!currentActivityTemplate) return null;
    const { answer } = retrieveCurrentActivityAnswer();
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === currentActivityTemplate.type
    );
    if (foundIndex === -1) {
      answer.data.push(
        constructActivityAnswerMap(
          currentActivityTemplate,
          JSON.parse(activity.data)
        )
      );
    }
    if (currentActivityTemplate.type === "ORDERING") {
      return (
        <OrderingActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    } else if (currentActivityTemplate.type === "DROPDOWN") {
      return (
        <DropdownActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    } else if (currentActivityTemplate.type === "MCSA") {
      return (
        <MCSAActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    } else if (currentActivityTemplate.type === "MCMA") {
      return (
        <MCMAActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    } else if (currentActivityTemplate.type === "MATCHING") {
      return (
        <MatchingActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    } else if (currentActivityTemplate.type === "GROUPING") {
      return (
        <GroupingActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    } else if (currentActivityTemplate.type === "FILL_IN_THE_BLANKS") {
      return (
        <FillInTheBlanksActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    } else if (currentActivityTemplate.type === "OPEN_ENDED") {
      return (
        <OpenEndedActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
          userId={userInformation.id}
          userProfileId={userProfile.id}
          catchtivityApplicationId={catchtivityApplication.id}
          activityId={activity.id}
          activityTemplateId={retrieveCurrentActivityTemplate().id}
          showMaterialContent={true}
          storageStompClient={storageStompClient}
        />
      );
    } else if (currentActivityTemplate.type === "TRUE_FALSE") {
      return (
        <TrueFalseActivityContent
          answer={answer}
          data={JSON.parse(activity.data)}
          canAnswerQuestion={checkCanAnswerQuestion}
          changeAnswer={changeActivityAnswer}
        />
      );
    }
  };

  const RenderActivitySetEmpty = () => {
    if (!activity) return;
    if (!retrieveActivityAnswerFromAnswerList(activityAnswerDTOList, activity))
      return;
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    return (
      <div
        className="flex flex-row items-center gap-x-2 cursor-pointer"
        onClick={handleSetEmptyOrContinue}
      >
        <BaseImage
          src={
            answerTemplate?.isEmpty
              ? "/icons/checkbox.png"
              : "/icons/empty-checkbox.png"
          }
          alt="checkbox-empty-checkbox"
          size="small"
          onClick={() => {}}
        />
        <p>{i18n.t("i_want_to_set_empty")}</p>
      </div>
    );
  };

  const RenderActivityEmptyContent = () => {
    const { answerTemplate } = retrieveCurrentActivityAnswerTemplate();
    if (answerTemplate?.isEmpty) {
      return <ActivityEmptyContent />;
    }
  };

  const RenderCompleteModal = () => {
    if (!catchtivityApplication) return;
    const stateList = constructActivityAnswerStateList(
      activityAnswerDTOList,
      catchtivityApplication.catchtivityDTO.activityDTOList
    );
    const notExistsItemList = stateList.filter(
      (item) => item.state === "NOT_EXISTS"
    );
    const emptyItemList = stateList.filter((item) => item.state === "EMPTY");
    return (
      <BaseModal
        isOpen={showCompleteModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setActivityNumber(0);
          setShowCompleteModal(false);
        }}
        customSize={"w-[750px]"}
      >
        <div className="flex-1 flex flex-col">
          <div className="ml-auto px-5 py-3">
            <BaseImage
              src="/icons/cross-red.png"
              alt="cross-red"
              size="medium"
              onClick={() => {
                setActivityNumber(0);
                setShowCompleteModal(false);
              }}
            />
          </div>
          <div className="">
            <FullCard>
              <div>
                <p className="font-bold">
                  {i18n.t("are_you_sure_do_you_want_to_complete_text")}
                </p>
              </div>
              <DividerLine />

              {notExistsItemList.length > 0 || emptyItemList.length > 0 ? (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/exclamation-red.png"
                    alt="exclamation"
                    size="xlarge"
                  />
                  <div className="flex flex-col">
                    {notExistsItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_did_not_answer_activities_text_1")}
                          {notExistsItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === notExistsItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}
                          {i18n.t("you_did_not_answer_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                    {emptyItemList.length > 0 ? (
                      <div>
                        <p>
                          {i18n.t("you_set_empty_activities_text_1")}
                          {emptyItemList.map((item, index) => (
                            <>
                              <span className="font-bold">
                                {item.index + 1}
                              </span>
                              {index === emptyItemList.length - 1 ? null : (
                                <span className="font-bold">, </span>
                              )}
                            </>
                          ))}

                          {i18n.t("you_set_empty_activities_text_2")}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-x-2">
                  <BaseImage
                    src="/icons/checkbox.png"
                    alt="checkbox"
                    size="medium"
                  />
                  <div>
                    <p>{i18n.t("you_have_answered_all_activities")}</p>
                  </div>
                </div>
              )}

              <div className="flex justify-end ">
                <div className="flex flex-row gap-x-2">
                  <SecondaryButton
                    title={i18n.t("continue")}
                    size="small"
                    onClick={() => {
                      if (notExistsItemList.length > 0) {
                        setActivityNumber(notExistsItemList[0].index);
                      } else if (emptyItemList.length > 0) {
                        setActivityNumber(emptyItemList[0].index);
                      } else {
                        setActivityNumber(0);
                      }
                      setShowCompleteModal(false);
                    }}
                  />
                  <PrimaryButton
                    title={
                      notExistsItemList.length > 0
                        ? i18n.t("complete_later")
                        : i18n.t("complete")
                    }
                    size="unlimited"
                    onClick={() => {
                      navigate("/homeworks", {
                        state: {
                          state: "FORCE_UPDATE",
                        },
                      });
                      setShowCompleteModal(false);
                    }}
                  />
                </div>
              </div>
            </FullCard>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderMainContent = () => {
    if (pageState === 0) {
      if (catchtivityApplication) {
        return (
          <ContentTransition
            language={language}
            description={catchtivityApplication.catchtivityDTO.description}
            type={catchtivityApplication.catchtivityDTO.coterieType}
            level={catchtivityApplication.catchtivityDTO.level}
            activityCount={
              catchtivityApplication.catchtivityDTO.activityDTOList.length
            }
            beginAt={catchtivityApplication.beginAt}
            endAt={catchtivityApplication.endAt}
            durationType={catchtivityApplication.durationType}
            durationInMinutes={catchtivityApplication.durationInMinutes}
            answeredActivityCount={activityAnswerDTOList.length}
            totalTimeSpent={retrieveTotalTimeSpentInMinutes(
              catchtivityApplicationProgressDTO?.activityProgressDTOSet
            )}
            loading={
              catchtivityApplication.beginAt > new Date().getTime() ||
              catchtivityApplication.endAt < new Date().getTime() ||
              individualModelListLoading ||
              outcomeModelListLoading ||
              activityAnswerDTOListLoading ||
              catchtivityApplicationProgressDTOLoading
            }
            planWeekLoading={planWeekLoading}
            planWeekMap={planWeekMap}
            onClick={() => {
              setActivityNumber(0);
              setPageState(1);
            }}
          />
        );
      } else {
        return (
          <div className="h-full flex flex-col items-center justify-center">
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          </div>
        );
      }
    } else if (pageState === 1) {
      if (catchtivityApplication) {
        return (
          <div className="h-full no-scrollbar overflow-y-auto pb-[200px]">
            {activityLoading.answer || activityLoading.progress ? (
              <div className="h-full flex flex-col items-center justify-center">
                <BaseLoading
                  size="large"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              </div>
            ) : activityLoading.self ? (
              <div className="h-full flex flex-col items-center justify-center">
                <BaseLoading
                  size="large"
                  color="#57C2D3"
                  secondaryColor="#57C2D3"
                />
              </div>
            ) : activity ? (
              <div className="flex flex-col w-full">
                <div className="my-1">
                  <CatchtivityProgressBar
                    activityNumber={activityNumber + 1}
                    onActivityChange={handleToNextActivityCheckCanAnswer}
                    totalActivityCount={
                      catchtivityApplication
                        ? catchtivityApplication.catchtivityDTO.activityDTOList
                            .length
                        : 0
                    }
                    showClockTime={
                      catchtivityApplication?.durationType === "NONE"
                        ? false
                        : true
                    }
                    clockTimeLeft={retrieveClockTimeLeft(
                      "CATCHTIVITY",
                      null,
                      catchtivityApplication?.durationType,
                      catchtivityApplication?.durationInMinutes,
                      catchtivityApplicationProgressDTO?.activityProgressDTOSet,
                      activity
                    )}
                    startTimer={
                      pageState === 1 &&
                      !showCompleteModal &&
                      !activityLoading.answer &&
                      !activityLoading.progress
                        ? retrieveClockTimeLeft(
                            "CATCHTIVITY",
                            null,
                            catchtivityApplication?.durationType,
                            catchtivityApplication?.durationInMinutes,
                            catchtivityApplicationProgressDTO?.activityProgressDTOSet,
                            activity
                          ) > 0
                        : false
                    }
                    handleOnTimerEnds={handleOnTimerEnds}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row items-center" />
                  {RenderActivitySetEmpty()}
                </div>
                {RenderActivityEmptyContent()}
                <div className="my-2">{RenderCurrentActivityContent()}</div>
              </div>
            ) : null}
          </div>
        );
      }
    }
    return null;
  };

  const isQuicktivityContent = checkIsQuicktivityContent();
  const hintText = retrieveCurrentHintText();
  const hintSubText = retrieveCurrentHintSubText();
  const mathematicalHintSubTextList =
    constructInputWithSpecialExpressionList(hintSubText);
  // const mathematicalHintSubTextList = constructInputWithSpecialExpressionList(
  //   "Öncelikle her bir denklemin çözümü için bilinmeyenleri yalnız bırakarak çözüm yapmalısın. İlk denklemde `A`'yı, ikinci denklemde `B`'yi ve üçüncü denklemde `C`'yi bul. Sonra bu değerleri toplayarak `A + B + C` toplamını hesapla. Her adımda işlem yaparken kesirlerle çarpma ve bölme kurallarını dikkatlice uygula. Sonrasında da `\\frac{1}{2}` işlemini bitirdikten sonra devam edebilirsin."
  // );
  const showPrimaryButton = canShowPrimaryButton();

  return (
    <div className="h-screen p-4 relative">
      {pageState === 0 ? (
        <div className="hidden md:block absolute bottom-0 right-10 z-20">
          <Mascot
            showInitialText={true}
            text={i18n.t("are_you_ready_to_start_homework")}
            subText={`${i18n.t("i_am_here_if_you_need_any_help")}`}
          />
        </div>
      ) : activity ? (
        <div className="hidden md:block absolute bottom-0 right-44 z-20">
          <Mascot
            showInitialText={false}
            text={hintText}
            subText={
              mathematicalHintSubTextList.length === 1 ? hintSubText : null
            }
            // subText={
            //   "Yıldızlar ve gezegenlerin şekilleri genellikle küresel olduğu için bu özellik her ikisi için de geçerlidir."
            // }
            // subText={
            //   "Yıldızlar ve gezegenlerin şekilleri genellikle küresel olduğu için bu özellik her ikisi için de geçerlidir. Ayrıca, aralarındaki mesafeler genellikle çok büyük olduğu için ışık yılı birimi ile ifade edilir. Bu nedenle,I ve IV numaralı özellikler yıldızlar ve gezegenler için ortaktır."
            // }
            // subText={
            //   "Öğrencinin notlarına göre, belgesel uzay araştırmalarının tarihçesini ve bu araştırmaların teknolojik gelişmelere nasıl katkıda bulunduğunu ele alıyor. Uzay sondaları ve istasyonları gibi örnekler, uzay araştırmalarının teknolojik yönünü vurguluyor. Güneş panelleri ve tükenmez kalem gibi teknolojilerin hayatımıza girmesi, bu araştırmaların pratik sonuçlarını gösteriyor. Dolayısıyla, belgeselin ana konusu teknoloji ve uzay araştırmaları arasındaki etkileşimi inceliyor. Bu bağlamda, doğru cevap 'Teknoloji ve uzay araştırmaları arasındaki ilişki' olmalıdır."
            // }
            // subText={
            //   "Uzay teleskoplarının yer tabanlı teleskoplara göre avantajları arasında, atmosferin neden olduğu bozulmalar ve ışık kirliliği olmadan daha net görüntüler elde edebilmeleri yer alır. Bu nedenle, ifadelerden II ve III doğrudur. Ancak, I. ifade yanlıştır çünkü uzay teleskopları, genellikle yer tabanlı teleskoplara göre çok daha pahalıdır. Atmosfer dışında çalıştıkları için, uzay teleskopları atmosferin görüntü üzerindeki etkilerinden (örneğin, hava dalgalanmaları) muaf olur ve bu da daha keskin ve detaylı gözlemler yapmalarını sağlar."
            // }
            // subText={
            //   "Uzay teleskoplarının yer tabanlı teleskoplara göre avantajları arasında, atmosferin neden olduğu bozulmalar ve ışık kirliliği olmadan daha net görüntüler elde edebilmeleri yer alır. Bu nedenle, ifadelerden II ve III doğrudur. Ancak, I. ifade yanlıştır çünkü uzay teleskopları, genellikle yer tabanlı teleskoplara göre çok daha pahalıdır. Atmosfer dışında çalıştıkları için, uzay teleskopları atmosferin görüntü üzerindeki etkilerinden (örneğin, hava dalgalanmaları) muaf olur ve bu da daha keskin ve detaylı gözlemler yapmalarını sağlar. Atmosfer dışında çalıştıkları için, uzay teleskopları atmosferin görüntü üzerindeki etkilerinden (örneğin, hava dalgalanmaları) muaf olur ve bu da daha keskin ve detaylı gözlemler yapmalarını sağlar. Atmosfer dışında çalıştıkları için, uzay teleskopları atmosferin görüntü üzerindeki etkilerinden (örneğin, hava dalgalanmaları) muaf olur ve bu da daha keskin ve detaylı gözlemler yapmalarını sağlar. Atmosfer dışında çalıştıkları için, uzay teleskopları atmosferin görüntü üzerindeki etkilerinden (örneğin, hava dalgalanmaları) muaf olur ve bu da daha keskin ve detaylı gözlemler yapmalarını sağlar."
            // }
            subTextList={
              mathematicalHintSubTextList.length === 1
                ? null
                : mathematicalHintSubTextList
            }
            primaryButtonText={showPrimaryButton ? i18n.t("yes") : null}
            primaryButtonOnClick={handleHintActivityWithAIOnClick}
          />
        </div>
      ) : null}

      {RenderCompleteModal()}
      <ScreenCard>
        <DashboardHeader
          handleReturnOnClick={pageState === 1 ? handleGoPreviousScreen : null}
          title={catchtivityApplication?.catchtivityDTO.name}
          level={
            pageState === 1
              ? catchtivityApplication?.catchtivityDTO.level
              : null
          }
          durationType={
            pageState === 1 ? catchtivityApplication?.durationType : null
          }
          durationInMinutes={
            pageState === 1 ? catchtivityApplication?.durationInMinutes : null
          }
        />
        {RenderMainContent()}
        {pageState === 1 && activity ? (
          <CatchtivityFooter
            activityNumber={activityNumber}
            totalActivityCount={
              catchtivityApplication
                ? catchtivityApplication.catchtivityDTO.activityDTOList.length
                : 0
            }
            showTimedProgressBar={isQuicktivityContent}
            activity={activity}
            activityLoading={
              isQuicktivityContent
                ? activityLoading.self || activityLoading.progress
                : activityLoading.self
            }
            activityProgressList={
              catchtivityApplicationProgressDTO?.activityProgressDTOSet
            }
            durationType={catchtivityApplication?.durationType}
            durationInMinutes={catchtivityApplication?.durationInMinutes}
            handleLeftArrowOnClick={handleToPreviousActivity}
            handleRightArrowOnClick={handleToNextActivityCheckCanAnswer}
          />
        ) : null}
      </ScreenCard>
    </div>
  );
};

export default CatchtivityApplicationScreen;
