import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import BaseLoading from "../loading/BaseLoading";
import InputGroup from "../groups/InputGroup";
import i18n from "../../language/i18n";
import ActivityTemplateLabel from "../labels/ActivityTemplateLabel";
import BrandLabel from "../labels/BrandLabel";
import PersonalLabel from "../labels/PersonalLabel";
import PublishingHouseLabel from "../labels/PublishingHouseLabel";
import DividerLine from "../divider/DividerLine";
import BaseImage from "../images/BaseImage";
import { useNavigate } from "react-router-dom";

const MyWrongActivitiesByTopicView = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    categorySet,
    catchxamApplicationListLoading,
    catchtivityApplicationListLoading,
    standardExamApplicationListLoading,
    passiveContentList,
    myWrongActivityDTOList,
    myWrongActivityListLoading,
    activityReviewDTOList,
    activityReviewListLoading,
    handleUpdateAndSetActivityReviewList,
  } = useAuth();
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [selectedMainCategoryCode, setSelectedMainCategoryCode] =
    useState("DEFAULT_OPTION");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const practiseMyWrongActivitiesCoterieType = localStorage.getItem(
      "practiseMyWrongActivitiesCoterieType"
    );
    if (!practiseMyWrongActivitiesCoterieType) return;
    setSelectedCoterieType(practiseMyWrongActivitiesCoterieType);
    const practiseMyWrongActivitiesMainCategoryCode = localStorage.getItem(
      "practiseMyWrongActivitiesMainCategoryCode"
    );
    if (!practiseMyWrongActivitiesMainCategoryCode) return;
    setSelectedMainCategoryCode(practiseMyWrongActivitiesMainCategoryCode);
  }, []);

  useEffect(() => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      localStorage.removeItem("practiseMyWrongActivitiesCoterieType");
    } else {
      localStorage.setItem(
        "practiseMyWrongActivitiesCoterieType",
        selectedCoterieType
      );
    }
  }, [selectedCoterieType]);

  useEffect(() => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      localStorage.removeItem("practiseMyWrongActivitiesMainCategoryCode");
    } else {
      localStorage.setItem(
        "practiseMyWrongActivitiesMainCategoryCode",
        selectedMainCategoryCode
      );
    }
  }, [selectedMainCategoryCode]);

  const filterCoterieTypeOptionBasedOnWorkList = () => {
    const coterieTypeOptionList = [];
    for (const content of passiveContentList) {
      let currentCoterieType;
      if (content.catchtivityDTO) {
        currentCoterieType = content.catchtivityDTO.coterieType;
      } else if (content.catchxamDTO) {
        currentCoterieType = content.catchxamDTO.coterieType;
      } else {
        continue;
      }
      if (
        coterieTypeOptionList.findIndex(
          (coterieTypeOption) => coterieTypeOption.value === currentCoterieType
        ) === -1
      ) {
        coterieTypeOptionList.push({
          text: i18n.t(currentCoterieType),
          value: currentCoterieType,
        });
      }
    }
    return coterieTypeOptionList;
  };

  const filterMyWrongActivityListBasedOnCoterieType = (activityDTOList) => {
    return activityDTOList.filter(
      (item) => item.activityCoterieType === selectedCoterieType
    );
  };

  const filterMainCategoryOptionList = (activityDTOList) => {
    const mainCategoryOptionList = [];
    activityDTOList.forEach((item) => {
      const { categoryIdList } = item;
      for (const categoryId of categoryIdList) {
        const foundCategory = categorySet.find(
          (category) => category.id === categoryId
        );
        if (!foundCategory) continue;
        const { code } = foundCategory;
        const splittedCodeList = code.split(".");
        const constructedCode = `${splittedCodeList[0]}.${splittedCodeList[1]}.${splittedCodeList[2]}.`;
        const foundMainCategory = categorySet.find(
          (category) => category.code === constructedCode
        );
        if (
          mainCategoryOptionList.findIndex(
            (mainCategoryOption) =>
              mainCategoryOption.value === foundMainCategory.code
          ) === -1
        ) {
          mainCategoryOptionList.push({
            text: foundMainCategory.name,
            value: foundMainCategory.code,
          });
        }
      }
    });
    return mainCategoryOptionList;
  };

  const filterMyWrongActivityListBasedOnMainCategoryCode = (
    activityDTOList
  ) => {
    return activityDTOList.filter((item) => {
      const { categoryIdList } = item;
      let exists = false;
      for (const categoryId of categoryIdList) {
        const foundCategory = categorySet.find(
          (category) => category.id === categoryId
        );
        if (!foundCategory) continue;
        if (foundCategory.code.startsWith(selectedMainCategoryCode)) {
          exists = true;
          break;
        }
      }
      return exists;
    });
  };

  const handleOnChange = (type, value) => {
    if (type === "selectedCoterieType") {
      setSelectedCoterieType(value);
      setSelectedMainCategoryCode("DEFAULT_OPTION");
    } else if (type === "selectedMainCategoryCode") {
      setSelectedMainCategoryCode(value);
    }
  };

  const RenderSingleUserProfileActivityItem = (item) => {
    const {
      activityId,
      activityTemplateId,
      activityTemplateName,
      categoryIdList,
      catchtivityApplicationId,
      catchxamApplicationId,
      standardExamApplicationId,
    } = item;
    const foundActivityReview = activityReviewDTOList.find(
      (activityReview) =>
        activityReview.activityDTO.id === activityId &&
        activityReview.activityTemplateDTO.id === activityTemplateId &&
        (activityReview.catchtivityApplicationDTO?.id ===
          catchtivityApplicationId ||
          activityReview.catchxamApplicationDTO?.id === catchxamApplicationId ||
          activityReview.standardExamApplicationDTO?.id ===
            standardExamApplicationId)
    );
    let isStarred = false;
    if (foundActivityReview) {
      isStarred = foundActivityReview.isStarred;
    }

    return (
      <div
        className={`w-full border border-catchup-white rounded-catchup-medium px-2 py-2`}
      >
        <div className="flex flex-row items-center">
          <div className="flex-1 flex flex-row gap-x-2">
            {foundActivityReview ? null : (
              <div className="w-2 h-2 bg-catchup-red rounded-full mt-[6px]" />
            )}
            <div className="flex flex-1 flex-col">
              <div className="mr-6">
                {categoryIdList.map((categoryId) => {
                  const foundCategory = categorySet.find(
                    (category) => category.id === categoryId
                  );
                  if (!foundCategory) return;
                  return (
                    <div className="flex flex-row items-center gap-x-2">
                      <p className="text-catchup-gray-400">
                        {foundCategory.code} {foundCategory.name}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-row items-center gap-x-1 my-2 flex-wrap">
                {isStarred ? (
                  <BaseImage
                    src="/icons/star-fill.png"
                    alt="star"
                    size="small"
                  />
                ) : null}
                {catchtivityApplicationId ? (
                  <BrandLabel
                    title={i18n.t("catchtivity")}
                    icon={
                      <BaseImage
                        src="/icons/catchtivity-purple.png"
                        alt="catchtivity"
                        size="xsmall"
                      />
                    }
                    font={"text-md"}
                  />
                ) : null}
                {catchxamApplicationId ? (
                  <PersonalLabel
                    title={i18n.t("catchxam")}
                    icon={
                      <BaseImage
                        src="/icons/catchxam-blue.png"
                        alt="catchxam"
                        size="xsmall"
                      />
                    }
                    font={"text-md"}
                  />
                ) : null}
                {standardExamApplicationId ? (
                  <PublishingHouseLabel
                    title={i18n.t("standard_exam")}
                    icon={
                      <BaseImage
                        src="/icons/standard-exam-orange.png"
                        alt="standard-exam"
                        size="xsmall"
                      />
                    }
                    font={"text-md"}
                  />
                ) : null}
                <ActivityTemplateLabel title={i18n.t(activityTemplateName)} />
              </div>
            </div>
          </div>
          <div className="">
            <BaseImage
              src="/icons/header-arrow-right.png"
              alt="arrow-right"
              size="medium"
              onClick={() => {
                if (!foundActivityReview) {
                  handleUpdateAndSetActivityReviewList(
                    activityTemplateId,
                    activityId,
                    catchtivityApplicationId,
                    catchxamApplicationId,
                    standardExamApplicationId
                  );
                }
                let navigateString = "/practise";

                if (catchtivityApplicationId) {
                  navigateString += `/catchtivity-applications/${catchtivityApplicationId}`;
                } else if (catchxamApplicationId) {
                  navigateString += `/catchxam-applications/${catchxamApplicationId}`;
                } else if (standardExamApplicationId) {
                  navigateString += `/standard-exam-applications/${standardExamApplicationId}`;
                }
                navigateString += `/activities/${activityId}`;

                navigate(navigateString);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const filteredCoterieTypeOptionList =
    filterCoterieTypeOptionBasedOnWorkList();
  const filteredMyWrongActivityListByCoterieType =
    filterMyWrongActivityListBasedOnCoterieType(myWrongActivityDTOList);
  const filteredMainCategoryOptionList = filterMainCategoryOptionList(
    filteredMyWrongActivityListByCoterieType
  );
  const filteredMyWrongActivityListByCoterieTypeAndMainCategoryCode =
    filterMyWrongActivityListBasedOnMainCategoryCode(
      filteredMyWrongActivityListByCoterieType
    );

  return (
    <div className="h-full flex flex-col justify-center">
      {catchtivityApplicationListLoading ||
      catchxamApplicationListLoading ||
      standardExamApplicationListLoading ||
      myWrongActivityListLoading ||
      activityReviewListLoading ? (
        <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
      ) : (
        <div className="h-full">
          <div className="flex flex-row items-center">
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("coterie_type")}
                  placeholder={i18n.t("coterie_type")}
                  value={selectedCoterieType}
                  optionList={filteredCoterieTypeOptionList}
                  onChange={(event) => {
                    handleOnChange("selectedCoterieType", event.target.value);
                  }}
                />
              </div>
            </div>
            {selectedCoterieType !== "DEFAULT_OPTION" ? (
              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="mx-2">
                  <InputGroup
                    type="select"
                    title={i18n.t("main_topic")}
                    placeholder={i18n.t("main_topic")}
                    value={selectedMainCategoryCode}
                    optionList={filteredMainCategoryOptionList}
                    onChange={(event) => {
                      handleOnChange(
                        "selectedMainCategoryCode",
                        event.target.value
                      );
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {selectedCoterieType !== "DEFAULT_OPTION" &&
          selectedMainCategoryCode !== "DEFAULT_OPTION" ? (
            <div className="flex flex-row flex-wrap gap-y-2 mt-2">
              {filteredMyWrongActivityListByCoterieTypeAndMainCategoryCode.map(
                (myWrongActivityDTO, index) => (
                  <div key={index} className="w-full xl:w-1/2">
                    <div className="mx-2">
                      {RenderSingleUserProfileActivityItem(myWrongActivityDTO)}
                      {(filteredMyWrongActivityListByCoterieTypeAndMainCategoryCode.length %
                        2 ===
                        0 &&
                        index >
                          filteredMyWrongActivityListByCoterieTypeAndMainCategoryCode.length -
                            3) ||
                      (filteredMyWrongActivityListByCoterieTypeAndMainCategoryCode.length %
                        2 ===
                        1 &&
                        index >
                          filteredMyWrongActivityListByCoterieTypeAndMainCategoryCode.length -
                            2) ? null : (
                        <DividerLine />
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default MyWrongActivitiesByTopicView;
