import i18n from "../../language/i18n";
import BaseBadge from "../badge/BaseBadge";
import BaseImage from "../images/BaseImage";

const Badge = (props) => {
  const { earnedBadgeDTOList } = props;

  const filterEarnedBadgeDTOList = (earnedBadgeDTOList) => {
    return earnedBadgeDTOList.slice(0, 3);
  };

  const constructNextEarnedBadgeDTO = (earnedBadgeDTOList) => {
    const filterEarnedBadgeDTOList = earnedBadgeDTOList.filter(
      (earnedBadgeDTO) => earnedBadgeDTO.level !== 5
    );
    if (filterEarnedBadgeDTOList.length === 0) return;

    const lastEarnedBadgeDTO =
      filterEarnedBadgeDTOList[filterEarnedBadgeDTOList.length - 1];
    return {
      ...lastEarnedBadgeDTO,
      level: lastEarnedBadgeDTO.level + 1,
    };
  };

  const sortEarnedBadgeDTOList = (earnedBadgeDTOList) => {
    const copyEarnedBadgeDTOList = JSON.parse(
      JSON.stringify(earnedBadgeDTOList)
    );
    return copyEarnedBadgeDTOList.sort((a, b) => {
      if (a.badgeDTO.sourceType !== b.badgeDTO.sourceType) {
        return a.badgeDTO.sourceType.localeCompare(b.badgeDTO.sourceType);
      }
      return a.badgeDTO.badgeType.localeCompare(b.badgeDTO.badgeType);
    });
  };
  const sortedEarnedBadgeDTOList = sortEarnedBadgeDTOList(earnedBadgeDTOList);

  const filteredBadgeDTOList = filterEarnedBadgeDTOList(
    sortedEarnedBadgeDTOList
  );
  const nextEarnedBadgeDTO = constructNextEarnedBadgeDTO(
    sortedEarnedBadgeDTOList
  );

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2 overflow-y-auto no-scrollbar">
      <div className="flex flex-col gap-y-2">
        <div>
          <p className="text-catchup-gray-600">{i18n.t("total")}</p>
        </div>
        <div>
          <p className="font-semibold text-3xl text-catchup-gray-600">
            {earnedBadgeDTOList.length} {i18n.t("badge")}
          </p>
          <p className="text-catchup-gray-600">
            {i18n.t("you_are_close_to_your_next_badge_text")}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between ">
          <div className="relative flex flex-row items-center flex-wrap">
            {filteredBadgeDTOList.map((earnedBadgeDTO, index) => (
              <div key={index}>
                <BaseBadge earnedBadgeDTO={earnedBadgeDTO} size="4xlarge" />
              </div>
            ))}
          </div>
          {nextEarnedBadgeDTO ? (
            <>
              <div className="">
                <BaseImage
                  src="icons/long-arrow-right.png"
                  alt="arrow"
                  size="large"
                />
              </div>
              <div className="relative">
                <BaseBadge
                  earnedBadgeDTO={nextEarnedBadgeDTO}
                  size="6xlarge"
                  blur={true}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex flex-row justify-end mt-3">
          <div
            className="flex flex-row items-center gap-x-1 cursor-pointer"
            onClick={() => {}}
          >
            <div>
              <p className="font-semibold text-catchup-gray-600">
                {i18n.t("see_all_collection")}
              </p>
            </div>
            <div>
              <BaseImage
                src="/icons/diagonal-arrow.png"
                size="small"
                alt="diagonal-arrow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Badge;
