const OutcomeDetail = (props) => {
  const { categoryActivityAnswerDTO } = props;

  const RenderRecursivelyCategoryList = (
    categoryActivityAnswerDTO,
    recursiveCount
  ) => {
    let score = 0;
    const {
      categoryDTO,
      activityScoreDTOSet,
      subCategoryActivityAnswerDTOList,
    } = categoryActivityAnswerDTO;
    for (const activityScoreDTO of activityScoreDTOSet) {
      score += activityScoreDTO.score;
    }
    const percentage = (score / activityScoreDTOSet.length) * 100;
    return (
      <div className="flex flex-col">
        <div
          className="flex-1 flex flex-row bg-catchup-dark-blue text-catchup-white border-b border-b-catchup-white"
          style={{
            paddingLeft: recursiveCount * 20,
            opacity: 1 - 0.05 * recursiveCount,
          }}
        >
          <div className="flex-1">
            <p className="p-2">{categoryDTO.name}</p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className={`${percentage < 40 ? "text-catchup-red" : ""} p-2`}>
              {activityScoreDTOSet.length}
            </p>
          </div>
          <div
            className="flex justify-center items-center w-[75px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className={`${percentage < 40 ? "text-catchup-red" : ""} p-2`}>
              {score}
            </p>
          </div>
          <div
            className="flex justify-center items-center w-[100px] bg-catchup-white text-catchup-dark-blue border-r border-b border-catchup-dark-blue text-center"
            style={{
              fontWeight: "bold",
            }}
          >
            <p className={`${percentage < 40 ? "text-catchup-red" : ""} p-2`}>
              {percentage.toFixed(2)}%
            </p>
          </div>
        </div>
        {subCategoryActivityAnswerDTOList &&
        subCategoryActivityAnswerDTOList.length > 0
          ? subCategoryActivityAnswerDTOList.map(
              (subCategoryActivityAnswerDTO, index) => (
                <div key={index}>
                  {RenderRecursivelyCategoryList(
                    subCategoryActivityAnswerDTO,
                    recursiveCount + 1
                  )}
                </div>
              )
            )
          : null}
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex-1 flex flex-row bg-catchup-white font-bold">
          <div className="flex-1"></div>
          <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
            <p className="p-2">#</p>
          </div>
          <div className="w-[75px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-white text-center">
            <p className="p-2">✓</p>
          </div>
          <div className="w-[100px] bg-catchup-dark-blue text-catchup-white border-r border-r-catchup-dark-blue text-center">
            <p className="p-2">%</p>
          </div>
        </div>
      </div>
      {categoryActivityAnswerDTO
        ? RenderRecursivelyCategoryList(categoryActivityAnswerDTO, 0)
        : null}
    </>
  );
};

export default OutcomeDetail;
