import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import i18n from "../../language/i18n";

const BaseLoading = (props) => {
  const { height, width, size, color, secondaryColor, hideText } = props;
  const [initialTimestamp, setInitialTimestamp] = useState(
    new Date().getTime()
  );
  const [currentTimestamp, setCurrentTimeStamp] = useState(
    new Date().getTime()
  );
  let timerId;

  useEffect(() => {
    timerId = setInterval(() => {
      setCurrentTimeStamp(new Date().getTime());
    }, [1000]);

    return () => {
      if (timerId) {
        clearInterval(timerId);
        timerId = null;
      }
    };
  }, []);

  const retrieveLoadingText = () => {
    if (currentTimestamp - initialTimestamp < 5000) {
      return i18n.t("its_getting_ready");
    } else if (currentTimestamp - initialTimestamp < 15000) {
      return i18n.t("please_keep_waiting_loading_is_about_to_finish");
    } else if (currentTimestamp - initialTimestamp < 30000) {
      return i18n.t("unexpectedly_long_loading_time");
    } else {
      return i18n.t("loading_should_not_take_this_long_please_report_us");
    }
  };

  let currentHeight;
  let currentWidth;
  let textClassName;
  let textWidth;

  if (size === "small") {
    currentHeight = 18;
    currentWidth = 18;
    textClassName = "text-sm";
    textWidth = 40;
  } else if (size === "medium") {
    currentHeight = 48;
    currentWidth = 48;
    textClassName = "text-md mt-5";
    textWidth = 150;
  } else if (size === "large") {
    currentHeight = 72;
    currentWidth = 72;
    textClassName = "text-base mt-4";
    textWidth = 200;
  } else if (size === "xlarge") {
    currentHeight = 96;
    currentWidth = 96;
    textClassName = "text-lg mt-5";
    textWidth = 250;
  } else {
    currentHeight = height;
    currentWidth = width;
  }

  const loadingText = retrieveLoadingText();

  // px-4 py-2
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-center items-center">
        <Oval
          height={currentHeight}
          width={currentWidth}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          color={color}
          secondaryColor={secondaryColor}
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
      {!hideText && size !== "small" ? (
        <div
          className={`${textClassName} text-center`}
          style={{ width: textWidth }}
        >
          <p className="font-bold">{loadingText}</p>
        </div>
      ) : null}
    </div>
  );
};

export default BaseLoading;
