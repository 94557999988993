import React, { useEffect, useRef, useState } from "react";
import {
  Stage,
  Image,
  Rect,
  Layer,
  Arrow,
  Circle,
  Line,
  Transformer,
  Text,
} from "react-konva";
import { v4 as uuid } from "uuid";
import i18n from "../../language/i18n";
import BaseImage from "../images/BaseImage";
import PrimaryButton from "../buttons/PrimaryButton";
import DividerLine from "../divider/DividerLine";

const Konva = (props) => {
  const { initialItemList, initialWidth, handleAfterCompleteOnClick } = props;
  const [selectedDrawState, setSelectedDrawState] = useState("SELECT");
  const [selectedId, setSelectedId] = useState(null);
  const [rectangleList, setRectangleList] = useState([]);
  const [circleList, setCircleList] = useState([]);
  const [arrowList, setArrowList] = useState([]);
  const [lineList, setLineList] = useState([]);
  const [textList, setTextList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [width, setWidth] = useState(0);
  const stageRef = useRef(null);
  const paintRef = useRef(null);
  const transformerRef = useRef(null);
  const size = 600;

  useEffect(() => {
    setSelectedId(null);
    paintRef.current = false;
  }, [selectedDrawState]);

  useEffect(() => {
    if (initialWidth <= 400) {
      setWidth(300);
    } else if (initialWidth <= 552) {
      setWidth(400);
    } else if (initialWidth <= 852) {
      setWidth(552);
    } else if (initialWidth <= 1152) {
      setWidth(852);
    } else if (initialWidth <= 1452) {
      setWidth(1152);
    } else if (initialWidth >= 1536) {
      setWidth(1452);
    }
  }, [initialWidth]);

  useEffect(() => {
    if (!initialItemList) return;
    if (initialItemList.length === 0) return;
    let currentY = 20;
    initialItemList.forEach((item, index) => {
      const id = uuid();
      if (item.type === "TEXT") {
        textList.push({
          id,
          text: item.value,
          x: 0,
          y: currentY,
        });
        currentY += 30;
      } else if (item.type === "IMAGE") {
        imageList.push({
          id,
          x: 0,
          y: currentY,
          image: item.value,
          height: 200,
        });
        currentY += 220;
      }
    });
    setTextList(JSON.parse(JSON.stringify(textList)));
    setImageList(JSON.parse(JSON.stringify(imageList)));
  }, [initialItemList]);

  const retrieveMousePosition = () => {
    if (!stageRef) return;
    if (!stageRef.current) return;
    const stage = stageRef.current;
    const pos = stage.getPointerPosition();
    if (!pos) return;
    const x = pos.x;
    const y = pos.y;
    return { x, y };
  };

  const handleCompleteOnClick = () => {
    if (!stageRef) return;
    if (!stageRef.current) return;
    const dataUri = stageRef.current.toDataURL();
    if (!dataUri) return;
    handleAfterCompleteOnClick(dataUri);
  };

  const handleOnMouseUp = () => {
    if (!paintRef.current) return;
    if (selectedDrawState === "SELECT") return;
    const { x, y } = retrieveMousePosition();
    if (selectedDrawState === "RECTANGLE") {
      const foundRectangle = rectangleList.find(
        (rectangle) => rectangle.id === selectedId
      );
      if (!foundRectangle) return;
      foundRectangle.height = y - foundRectangle.y;
      foundRectangle.width = x - foundRectangle.x;
      setRectangleList(JSON.parse(JSON.stringify(rectangleList)));
    } else if (selectedDrawState === "CIRCLE") {
      const foundCircle = circleList.find((circle) => circle.id === selectedId);
      if (!foundCircle) return;
      foundCircle.radius =
        ((x - foundCircle.x) ** 2 + (y - foundCircle.y) ** 2) ** 0.5;
      setCircleList(JSON.parse(JSON.stringify(circleList)));
    } else if (selectedDrawState === "ARROW") {
      const foundArrow = arrowList.find((arrow) => arrow.id === selectedId);
      if (!foundArrow) return;
      foundArrow.points = [foundArrow.points[0], foundArrow.points[1], x, y];
      setArrowList(JSON.parse(JSON.stringify(arrowList)));
    } else if (selectedDrawState === "SCRIBBLE") {
      const foundLine = lineList.find((line) => line.id === selectedId);
      if (!foundLine) return;
      foundLine.points.push(x, y);
      setLineList(JSON.parse(JSON.stringify(lineList)));
    }
    paintRef.current = false;
  };

  const handleOnMouseDown = (e) => {
    if (selectedDrawState === "SELECT") return;
    paintRef.current = true;
    const { x, y } = retrieveMousePosition();
    const id = uuid();
    setSelectedId(id);
    if (selectedDrawState === "RECTANGLE") {
      rectangleList.push({
        id,
        x,
        y,
        height: 1,
        width: 1,
        stroke: "#000000",
        strokeWidth: 4,
      });
    } else if (selectedDrawState === "CIRCLE") {
      circleList.push({
        id,
        x,
        y,
        radius: 1,
        stroke: "#000000",
        strokeWidth: 4,
      });
      setCircleList(JSON.parse(JSON.stringify(circleList)));
    } else if (selectedDrawState === "ARROW") {
      arrowList.push({
        id,
        points: [x, y, x, y],
        color: "#000000",
        stroke: "#000000",
        strokeWidth: 4,
      });
      setArrowList(JSON.parse(JSON.stringify(arrowList)));
    } else if (selectedDrawState === "SCRIBBLE") {
      lineList.push({
        id,
        points: [x, y],
        stroke: "#000000",
        strokeWidth: 4,
      });
      setLineList(JSON.parse(JSON.stringify(lineList)));
    }
  };

  const handleOnMouseMove = () => {
    if (!paintRef.current) return;
    if (selectedDrawState === "SELECT") return;
    const { x, y } = retrieveMousePosition();
    if (selectedDrawState === "RECTANGLE") {
      const foundRectangle = rectangleList.find(
        (rectangle) => rectangle.id === selectedId
      );
      if (!foundRectangle) return;
      foundRectangle.height = y - foundRectangle.y;
      foundRectangle.width = x - foundRectangle.x;
      setRectangleList(JSON.parse(JSON.stringify(rectangleList)));
    } else if (selectedDrawState === "CIRCLE") {
      const foundCircle = circleList.find((circle) => circle.id === selectedId);
      if (!foundCircle) return;
      foundCircle.radius =
        ((x - foundCircle.x) ** 2 + (y - foundCircle.y) ** 2) ** 0.5;
      setCircleList(JSON.parse(JSON.stringify(circleList)));
    } else if (selectedDrawState === "ARROW") {
      const foundArrow = arrowList.find((arrow) => arrow.id === selectedId);
      if (!foundArrow) return;
      foundArrow.points = [foundArrow.points[0], foundArrow.points[1], x, y];
      setArrowList(JSON.parse(JSON.stringify(arrowList)));
    } else if (selectedDrawState === "SCRIBBLE") {
      const foundLine = lineList.find((line) => line.id === selectedId);
      if (!foundLine) return;
      foundLine.points.push(x, y);
      setLineList(JSON.parse(JSON.stringify(lineList)));
    }
  };

  const handleShapeOnClick = (e) => {
    if (!transformerRef) return;
    if (!transformerRef.current) return;
    if (!selectedDrawState === "SELECT") return;
    const { currentTarget } = e;
    setSelectedId(currentTarget.attrs.id);
    transformerRef.current.nodes([currentTarget]);
  };

  const handleDeleteShapeOnClick = () => {
    if (!transformerRef) return;
    if (!transformerRef.current) return;
    const foundRectangleIndex = rectangleList.findIndex(
      (rectangle) => rectangle.id === selectedId
    );
    if (foundRectangleIndex > -1) {
      rectangleList.splice(foundRectangleIndex, 1);
      setRectangleList(JSON.parse(JSON.stringify(rectangleList)));
    }
    const foundCircleIndex = circleList.findIndex(
      (circle) => circle.id === selectedId
    );
    if (foundCircleIndex > -1) {
      circleList.splice(foundCircleIndex, 1);
      setCircleList(JSON.parse(JSON.stringify(circleList)));
    }
    const foundArrowIndex = arrowList.findIndex(
      (arrow) => arrow.id === selectedId
    );
    if (foundArrowIndex > -1) {
      arrowList.splice(foundArrowIndex, 1);
      setArrowList(JSON.parse(JSON.stringify(arrowList)));
    }
    const foundLineIndex = lineList.findIndex((line) => line.id === selectedId);
    if (foundLineIndex > -1) {
      lineList.splice(foundLineIndex, 1);
      setLineList(JSON.parse(JSON.stringify(lineList)));
    }
    transformerRef.current.nodes([]);
    setSelectedId(null);
  };

  const RenderTextListContent = () => {
    return textList.map((text) => (
      <Text key={text.id} id={text.id} x={text.x} y={text.y} text={text.text} />
    ));
  };

  const RenderImageListContent = () => {
    return imageList.map((image) => (
      <URLImage
        key={image.id}
        id={image.id}
        x={image.x}
        y={image.y}
        src={image.image}
        height={image.height}
      />
    ));
  };

  const RenderRectangleContent = () => {
    return rectangleList.map((rectangle) => (
      <Rect
        key={rectangle.id}
        id={rectangle.id}
        x={rectangle.x}
        y={rectangle.y}
        height={rectangle.height}
        width={rectangle.width}
        stroke={rectangle.stroke}
        strokeWidth={rectangle.strokeWidth}
        onClick={handleShapeOnClick}
        draggable={isDraggable}
      />
    ));
  };

  const RenderCircleContent = () => {
    return circleList.map((circle) => (
      <Circle
        key={circle.id}
        id={circle.id}
        x={circle.x}
        y={circle.y}
        radius={circle.radius}
        stroke={circle.stroke}
        strokeWidth={circle.strokeWidth}
        onClick={handleShapeOnClick}
        draggable={isDraggable}
      />
    ));
  };

  const RenderArrowContent = () => {
    return arrowList.map((arrow) => (
      <Arrow
        key={arrow.id}
        id={arrow.id}
        points={arrow.points}
        fill={arrow.color}
        stroke={arrow.stroke}
        strokeWidth={arrow.strokeWidth}
        onClick={handleShapeOnClick}
        draggable={isDraggable}
      />
    ));
  };

  const RenderLineContent = () => {
    return lineList.map((line) => (
      <Line
        key={line.id}
        id={line.id}
        points={line.points}
        lineCap="round"
        lineJoin="round"
        stroke={line.stroke}
        strokeWidth={line.strokeWidth}
        onClick={handleShapeOnClick}
        draggable={isDraggable}
      />
    ));
  };

  const isDraggable = selectedDrawState === "SELECT";

  return (
    <div>
      <div className="flex flex-row items-center justify-between mx-4">
        <div className="flex flex-row items-center justify-start gap-x-2">
          <div>
            <BaseImage
              src={
                selectedDrawState === "SELECT"
                  ? "/icons/select-selected.png"
                  : "/icons/select.png"
              }
              alt="select"
              size="medium"
              onClick={() => {
                setSelectedDrawState("SELECT");
              }}
            />
          </div>
          <div className="mx-4"></div>
          <div>
            <BaseImage
              src={
                selectedDrawState === "RECTANGLE"
                  ? "/icons/rectangle-selected.png"
                  : "/icons/rectangle.png"
              }
              alt="select"
              size="medium"
              onClick={() => {
                setSelectedDrawState("RECTANGLE");
              }}
            />
          </div>
          <div>
            <BaseImage
              src={
                selectedDrawState === "CIRCLE"
                  ? "/icons/circle-selected.png"
                  : "/icons/circle.png"
              }
              alt="select"
              size="medium"
              onClick={() => {
                setSelectedDrawState("CIRCLE");
              }}
            />
          </div>
          <div>
            <BaseImage
              src={
                selectedDrawState === "ARROW"
                  ? "/icons/line-selected.png"
                  : "/icons/line.png"
              }
              alt="select"
              size="medium"
              onClick={() => {
                setSelectedDrawState("ARROW");
              }}
            />
          </div>
          <div>
            <BaseImage
              src={
                selectedDrawState === "SCRIBBLE"
                  ? "/icons/scribble-selected.png"
                  : "/icons/scribble.png"
              }
              alt="select"
              size="medium"
              onClick={() => {
                setSelectedDrawState("SCRIBBLE");
              }}
            />
          </div>
        </div>

        <div className="flex flex-row items-center justify-start gap-x-4">
          <div>
            <BaseImage
              src="/icons/refresh.png"
              alt="refresh"
              size="medium"
              onClick={() => {
                setRectangleList([]);
                setCircleList([]);
                setArrowList([]);
                setLineList([]);
              }}
            />
          </div>
          {selectedDrawState === "SELECT" && selectedId ? (
            <div>
              <BaseImage
                src="/icons/eraser.png"
                alt="eraser"
                size="medium"
                onClick={handleDeleteShapeOnClick}
              />
            </div>
          ) : null}
        </div>
      </div>
      <DividerLine />
      <div>
        <Stage
          ref={stageRef}
          height={size}
          width={width}
          onMouseUp={handleOnMouseUp}
          onMouseDown={handleOnMouseDown}
          onMouseMove={handleOnMouseMove}
        >
          <Layer>
            <Rect
              id={"background"}
              x={0}
              y={0}
              height={size}
              width={width}
              fill="white"
              onClick={() => {
                if (!transformerRef) return;
                if (!transformerRef.current) return;
                transformerRef.current.nodes([]);
                setSelectedId(null);
              }}
            />
          </Layer>
          <Layer>
            {RenderTextListContent()}
            {RenderImageListContent()}
            {RenderRectangleContent()}
            {RenderCircleContent()}
            {RenderArrowContent()}
            {RenderLineContent()}
            <Transformer ref={transformerRef} />
          </Layer>
        </Stage>
      </div>
      <DividerLine />
      <div className="flex flex-row justify-end items-center">
        <div>
          <PrimaryButton
            title={i18n.t("complete")}
            size="small"
            onClick={handleCompleteOnClick}
          />
        </div>
      </div>
    </div>
  );
};

class URLImage extends React.Component {
  state = {
    image: null,
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener("load", this.handleLoad);
  }
  calculateWidth() {
    if (!this.image) return 0;
    const imageWidth = this.image.width;
    const imageHeight = this.image.height;
    return (imageWidth / imageHeight) * this.props.height;
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.crossOrigin = "anonymous";
    this.image.addEventListener("load", this.handleLoad);
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <Image
        // key={this.props.key}
        id={this.props.id}
        x={this.props.x}
        y={this.props.y}
        width={this.calculateWidth()}
        height={this.props.height}
        image={this.state.image}
        ref={(node) => {
          this.imageNode = node;
        }}
      />
    );
  }
}

export default Konva;
