import { useEffect, useRef, useState } from "react";
import BaseImage from "../images/BaseImage";
import useScreenSize from "../../hooks/useScreenSize";

const MascotWithCrown = (props) => {
  const { rank, showRank } = props;
  const { screenSize } = useScreenSize();
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const crownRef = useRef(null);
  const [rankLeft, setRankLeft] = useState(0);
  const [crownLeft, setCrownLeft] = useState(0);
  const [crownWidth, setCrownWidth] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!rank) return;
    if (!loaded) return;
    if (!imageRef) return;
    if (!imageRef.current) return;

    const { offsetWidth: imageOffsetWidth } = imageRef.current;
    if (showRank) {
      if (!textRef) return;
      if (!textRef.current) return;
      const { offsetWidth: textOffsetWidth } = textRef.current;
      setRankLeft((imageOffsetWidth - textOffsetWidth) / 2);
    }
    if (canShowCrown) {
      if (!crownRef) return;
      if (!crownRef.current) return;
      const { offsetWidth: crownOffsetWidth } = crownRef.current;
      setCrownWidth(imageOffsetWidth / 2);
      setCrownLeft((imageOffsetWidth - crownOffsetWidth) / 2);
    }
  }, [
    imageRef,
    textRef,
    crownRef,
    screenSize,
    loaded,
    crownWidth,
    rank,
    showRank,
  ]);

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const checkCanShowCrown = () => {
    if (rank <= 3) {
      return true;
    }
    return false;
  };

  const canShowCrown = checkCanShowCrown();

  return (
    <div className="relative w-full">
      <div ref={imageRef}>
        <BaseImage
          src="/mascots/mascot-user-profile.png"
          alt="mascot"
          size="custom"
          currentClassName="w-full h-full"
          onLoad={handleOnLoad}
        />
      </div>

      {rank ? (
        <>
          {canShowCrown ? (
            <div
              ref={crownRef}
              className="absolute top-1"
              style={{ left: crownLeft }}
            >
              <img
                src="/icons/crown.png"
                alt="crown"
                className="h-full"
                style={{ width: crownWidth }}
              />
            </div>
          ) : null}

          {showRank ? (
            <div
              ref={textRef}
              className="absolute -bottom-[20px]"
              style={{ left: rankLeft }}
            >
              <p className="rounded-full text-xl font-semibold bg-catchup-white text-catchup-blue-700 shadow-text px-2 py-1">
                #{rank}
              </p>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default MascotWithCrown;
