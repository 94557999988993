import i18n from "../language/i18n";

export const retrieveMonthNameByIndex = (index) => {
  if (index === 0) {
    return i18n.t("january");
  } else if (index === 1) {
    return i18n.t("february");
  } else if (index === 2) {
    return i18n.t("march");
  } else if (index === 3) {
    return i18n.t("april");
  } else if (index === 4) {
    return i18n.t("may");
  } else if (index === 5) {
    return i18n.t("june");
  } else if (index === 6) {
    return i18n.t("july");
  } else if (index === 7) {
    return i18n.t("august");
  } else if (index === 8) {
    return i18n.t("september");
  } else if (index === 9) {
    return i18n.t("october");
  } else if (index === 10) {
    return i18n.t("november");
  } else if (index === 11) {
    return i18n.t("december");
  }
};

export const calculateStartDateOfWeek = (date) => {
  // Calculate the difference between the date's day of the month and its day of the week
  var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

  // Set the date to the start of the week by setting it to the calculated difference
  const startDate = new Date(date.setDate(diff));
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  return adjustForTimezone(startDate);
};

export const calculateEndDateOfWeek = (date) => {
  // Calculate the date of the last day of the week by adding the difference between the day of the month and the day of the week, then adding 6.
  var lastday = date.getDate() - (date.getDay() - 1) + 6;
  // Set the date to the calculated last day of the week.
  const endDate = new Date(date.setDate(lastday));
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(999);
  return adjustForTimezone(endDate);
};

export const adjustForTimezone = (date) => {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() - timeOffsetInMS);
  return date;
};

export const constructWeekName = (beginDate, endDate) => {
  let currentEndDate;
  if (endDate) {
    currentEndDate = endDate;
  } else {
    currentEndDate = new Date(beginDate);
    currentEndDate.setDate(beginDate.getDate() + 4);
  }
  if (beginDate.getFullYear() !== currentEndDate.getFullYear()) {
    return `${beginDate.getDate()} ${retrieveMonthNameByIndex(
      beginDate.getMonth()
    )} ${beginDate.getFullYear()} - ${currentEndDate.getDate()} ${retrieveMonthNameByIndex(
      currentEndDate.getMonth()
    )} ${currentEndDate.getFullYear()}`;
  } else {
    if (beginDate.getMonth() !== currentEndDate.getMonth()) {
      return `${beginDate.getDate()} ${retrieveMonthNameByIndex(
        beginDate.getMonth()
      )} - ${currentEndDate.getDate()} ${retrieveMonthNameByIndex(
        currentEndDate.getMonth()
      )} ${currentEndDate.getFullYear()}`;
    } else {
      return `${beginDate.getDate()} - ${currentEndDate.getDate()} ${retrieveMonthNameByIndex(
        currentEndDate.getMonth()
      )} ${currentEndDate.getFullYear()}`;
    }
  }
};
