import { useState } from "react";

const MediaDropdown = (props) => {
  const { id, answer, optionList } = props;
  const [showDropdown, setShowDropdown] = useState(null);

  return (
    <div
      key={id}
      className="w-full relative"
      onMouseEnter={() => {
        setShowDropdown(true);
      }}
      onMouseLeave={() => {
        setShowDropdown(false);
      }}
    >
      <div className="w-full flex flex-col items-center justify-center">
        {answer}
      </div>
      <ul
        className={`absolute ${
          showDropdown ? "opacity-100 visible" : "opacity-0 invisible"
        } flex flex-col items-center w-[300px] rounded-catchup-xlarge border-3 transition-all duration-300 border-catchup-blue bg-catchup-white px-4 py-4 translate-x-1/2 right-1/2 mt-2 z-10`}
      >
        {optionList.map((option, index) => (
          <li
            key={option.id}
            className={`${
              option.listItemClassNames ? option.listItemClassNames : ""
            }`}
          >
            <div
              className={`w-full flex flex-col my-2 ${
                option.divClassNames ? option.divClassNames : ""
              }`}
              onClick={option.onClick}
            >
              {option.media}
            </div>
            {index !== optionList.length - 1 ? (
              <div className="w-full border my-1 border-catchup-light-blue rounded-full" />
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MediaDropdown;
