import { useEffect, useRef, useState } from "react";
import BaseImage from "../images/BaseImage";

const HoverViewButton = (props) => {
  const {
    title,
    src,
    alt,
    size,
    widthClassName,
    heightClassName,
    optionList,
    optionSize,
  } = props;
  const ref = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!ref) return;
    if (!ref.current) return;
    setHeight(ref.current.offsetHeight);
  }, [ref]);

  return (
    <div
      className="relative h-full flex flex-col justify-center"
      onMouseEnter={() => {
        setShowDropdown(true);
      }}
      onMouseLeave={() => {
        setShowDropdown(false);
      }}
    >
      <div className="flex flex-row items-center gap-x-2">
        <BaseImage
          src={src}
          alt={alt}
          size={size}
          widthClassName={widthClassName}
          heightClassName={heightClassName}
        />
        {title ? <p className="">{title}</p> : null}
      </div>
      {optionList.length > 0 ? (
        <div
          ref={ref}
          className={`absolute ${
            showDropdown ? "opacity-100 visible" : "opacity-0 invisible"
          } flex flex-col ${
            optionSize === "small"
              ? "w-catchup-small-dropdown"
              : optionSize === "medium"
              ? "w-catchup-medium-dropdown"
              : ""
          } rounded-catchup-xlarge transition-all duration-300 border-catchup-gray-100 bg-catchup-white px-4 py-4 translate-x-1/4 right-1/2 mt-2 z-10 shadow-dropdown`}
          style={{ marginTop: height + 50 }}
        >
          {optionList.map((option, index) => (
            <div
              key={index}
              className={`${
                index !== optionList.length - 1
                  ? "border-b border-b-catchup-gray-50"
                  : ""
              } cursor-pointer`}
              onClick={async () => {
                await option.onClick();
                setShowDropdown(false);
              }}
            >
              <div className={`flex flex-row items-center gap-x-2 my-1`}>
                <div>{option.icon}</div>
                <div className="flex-1">
                  <p className="px-1">{option.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default HoverViewButton;
