import { InlineMath } from "react-katex";
import i18n from "../../../language/i18n";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";

const ActivitySolutionContent = (props) => {
  const { activityTemplateType, data } = props;

  if (!activityTemplateType) return;
  let solutionMap;
  if (activityTemplateType === "ORDERING") {
    if (data["orderingSolutionMap"])
      solutionMap = JSON.parse(data["orderingSolutionMap"]);
  } else if (activityTemplateType === "DROPDOWN") {
    if (data["dropdownSolutionMap"])
      solutionMap = JSON.parse(data["dropdownSolutionMap"]);
  } else if (activityTemplateType === "MCSA") {
    if (data["MCSASolutionMap"])
      solutionMap = JSON.parse(data["MCSASolutionMap"]);
  } else if (activityTemplateType === "MCMA") {
    if (data["MCMASolutionMap"])
      solutionMap = JSON.parse(data["MCMASolutionMap"]);
  } else if (activityTemplateType === "MATCHING") {
    if (data["matchingSolutionMap"])
      solutionMap = JSON.parse(data["matchingSolutionMap"]);
  } else if (activityTemplateType === "GROUPING") {
    if (data["groupingSolutionMap"])
      solutionMap = JSON.parse(data["groupingSolutionMap"]);
  } else if (activityTemplateType === "FILL_IN_THE_BLANKS") {
    if (data["fillInTheBlanksSolutionMap"])
      solutionMap = JSON.parse(data["fillInTheBlanksSolutionMap"]);
  } else if (activityTemplateType === "OPEN_ENDED") {
    if (data["openEndedSolutionMap"])
      solutionMap = JSON.parse(data["openEndedSolutionMap"]);
  } else if (activityTemplateType === "TRUE_FALSE") {
    if (data["trueFalseSolutionMap"])
      solutionMap = JSON.parse(data["trueFalseSolutionMap"]);
  }
  if (!solutionMap) return;
  if (Object.keys(solutionMap).length === 0) return;
  return (
    <div className="mx-2">
      <div className="p-4 border-catchup-blue border-2 rounded-catchup-xlarge">
        <p className="text-xl font-bold text-center mb-3">
          {i18n.t("solution")}
        </p>
        {Object.keys(solutionMap).map((key, index) => {
          const currentItem = JSON.parse(solutionMap[key]);
          const { value } = currentItem;
          return (
            <p className="my-3">
              <span
                key={`${key}_${index}`}
                className="text-xl whitespace-pre-wrap"
              >
                {constructInputWithSpecialExpressionList(value).map(
                  (inputPart, index) => (
                    <span
                      key={index}
                      className={`${inputPart.isBold ? "font-bold" : ""} ${
                        inputPart.isUnderline ? "underline" : ""
                      }`}
                    >
                      {inputPart.isEquation ? (
                        <span className="text-2xl">
                          <InlineMath math={inputPart.value} />
                        </span>
                      ) : (
                        inputPart.value
                      )}
                    </span>
                  )
                )}
              </span>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default ActivitySolutionContent;
