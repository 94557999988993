import i18n from "../../language/i18n";

const RightsReserved = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div>
        <p className="text-catchup-gray-200 text-md">
          {i18n.t("all_rights_reserved_text")}
        </p>
      </div>
      <div>
        <p className="cursor-pointer text-md text-catchup-blue-500 underline">
          {i18n.t("privacy_policy")}
        </p>
      </div>
    </div>
  );
};

export default RightsReserved;
