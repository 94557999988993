import { useNavigate } from "react-router-dom";
import i18n from "../../language/i18n";
import MascotFull from "../mascots/MascotFull";
import BaseImage from "../images/BaseImage";

const Mascot = (props) => {
  const navigate = useNavigate();
  const { remainingPoints } = props;

  return (
    <div className="h-full flex flex-col bg-catchup-white rounded-2xl px-4 py-2">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <p className="text-catchup-gray-600 font-semibold text-2xl">
            {i18n.t("my_mascot")}
          </p>
        </div>
        {/* <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/shop");
          }}
        >
          <p className="text-catchup-blue-700 underline">{i18n.t("to_shop")}</p>
        </div> */}
      </div>
      <div className="m-auto h-[200px] w-full flex flex-row items-center justify-center">
        <div
          className={`${remainingPoints ? "w-2/3" : "w-full"} h-full blur-md`}
        >
          <MascotFull />
        </div>

        {remainingPoints !== undefined ? (
          <div className="w-1/3">
            <div className="p-2">
              <div className="flex flex-col items-center gap-y-1">
                <BaseImage
                  src="/icons/points.png"
                  alt="points"
                  size="3xlarge"
                  onClick={() => {}}
                />
                <div className="text-center">
                  <p className="text-catchup-gray-400 text-lg">
                    {i18n.t("my_remaining_points")}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-catchup-blue-600 font-semibold text-3xl">
                    {remainingPoints}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Mascot;
