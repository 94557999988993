import { useEffect, useState } from "react";
import i18n from "../../language/i18n";
import { useAuth } from "../../context/UserContextProvider";

const Clock = () => {
  const { language } = useAuth();
  const [currentTimestamp, setCurrentTimestamp] = useState(
    new Date().getTime()
  );
  let intervalId = null;

  useEffect(() => {
    intervalId = setInterval(() => {
      setCurrentTimestamp(new Date().getTime());
    }, [1000]);
    return () => {
      clearInterval(intervalId);
      intervalId = null;
    };
  }, []);

  const retrieveCurrentClockHourAndMinute = () => {
    const currentDate = new Date(currentTimestamp);
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const retrieveCurrentDateAndDay = () => {
    const currentDate = new Date(currentTimestamp);

    const optionsDate = { month: "long", day: "2-digit", year: "numeric" };
    const optionsDay = { weekday: "long" };

    const formattedDate = new Intl.DateTimeFormat(language, optionsDate).format(
      currentDate
    );
    const formattedDay = new Intl.DateTimeFormat(language, optionsDay).format(
      currentDate
    );

    return `${formattedDate} | ${formattedDay}`;
  };

  const currentClockHourAndMinute = retrieveCurrentClockHourAndMinute();
  const currentDateAndDay = retrieveCurrentDateAndDay();
  return (
    <div className="h-full bg-catchup-white rounded-2xl px-4 py-2">
      <div className="h-1/3 flex flex-col justify-center">
        <p className="text-catchup-gray-300">{i18n.t("today")}</p>
      </div>
      <div className="h-1/3 flex flex-col justify-center">
        <p className="text-catchup-blue-900 text-3xl">
          {currentClockHourAndMinute}
        </p>
      </div>
      <div className="h-1/3 flex flex-col justify-center">
        <p className="text-catchup-blue-600">{currentDateAndDay}</p>
      </div>
    </div>
  );
};

export default Clock;
