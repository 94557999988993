import {
  parseBodyMapFromData,
  parseContentMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import VerticalDividerLine from "../divider/VerticalDividerLine";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import FillInTheBlanksActivityMaterialContent from "./material-content/FillInTheBlanksActivityMaterialContent";

const FillInTheBlanksActivityContent = (props) => {
  const {
    answer,
    data,
    canAnswerQuestion,
    changeAnswer,
    isPreview,
    showCorrectAnswer,
  } = props;
  const contentMap = parseContentMapFromData(data);
  const fillInTheBlanksBodyMap = parseBodyMapFromData(
    data,
    "FILL_IN_THE_BLANKS"
  );
  const fillInTheBlanksMaterialMap = parseMaterialMapFromData(
    data,
    "FILL_IN_THE_BLANKS"
  );
  const fillInTheBlanksIncorrectList = data.fillInTheBlanksIncorrectList
    ? JSON.parse(data.fillInTheBlanksIncorrectList)
    : [];

  const retrieveCurrentAnswerMap = () => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "FILL_IN_THE_BLANKS"
    );
    return answer.data[foundIndex].answerMap;
  };

  const constructAnswerOptionList = () => {
    const optionList = [];
    Object.keys(fillInTheBlanksMaterialMap).forEach((key) => {
      const array = JSON.parse(fillInTheBlanksMaterialMap[key]);
      for (const item of array) {
        const foundIndex = optionList.findIndex((option) => option === item);
        if (foundIndex === -1) {
          optionList.push(item);
        }
      }
    });
    fillInTheBlanksIncorrectList.forEach((value) => {
      optionList.push(value);
    });
    return optionList;
  };

  const handleFillInTheBlanksAnswerOnChange = (answer, key, value) => {
    const answerMap = retrieveCurrentAnswerMap();
    answerMap[key] = value;
    changeAnswer(answer);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full md:w-[60%]">
        <ActivityBodyContent
          bodyMap={fillInTheBlanksBodyMap}
          answerMap={retrieveCurrentAnswerMap()}
          contentMap={contentMap}
          templateType={"FILL_IN_THE_BLANKS"}
        />
      </div>
      <div className="contents md:hidden">
        <DividerLine />
      </div>
      <div className="hidden md:contents">
        <VerticalDividerLine />
      </div>
      <div className="w-full md:flex-1">
        <FillInTheBlanksActivityMaterialContent
          uniqueValue={JSON.stringify(data.contentMap)}
          answer={answer}
          optionList={constructAnswerOptionList()}
          materialMap={fillInTheBlanksMaterialMap}
          contentMap={contentMap}
          checkCanAnswerQuestion={canAnswerQuestion}
          onChange={handleFillInTheBlanksAnswerOnChange}
          isPreview={isPreview}
          showCorrectAnswer={showCorrectAnswer}
        />
      </div>
    </div>
  );
};

export default FillInTheBlanksActivityContent;
