import {
  parseBodyMapFromData,
  parseContentMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import VerticalDividerLine from "../divider/VerticalDividerLine";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import MCMAActivityMaterialContent from "./material-content/MCMAActivityMaterialContent";

const MCMAActivityContent = (props) => {
  const { answer, data, canAnswerQuestion, changeAnswer, isPreview } = props;
  const contentMap = parseContentMapFromData(data);
  const MCMABodyMap = parseBodyMapFromData(data, "MCMA");
  const MCMAMaterialMap = parseMaterialMapFromData(data, "MCMA");

  const handleMCMAAnswerOnChange = (answer, key, value) => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "MCMA"
    );
    const answerMap = answer.data[foundIndex].answerMap;
    const foundSubIndex = answerMap[key].findIndex(
      (answerMaterialKey) => answerMaterialKey === value
    );
    if (foundSubIndex === -1) {
      answerMap[key].push(value);
    } else {
      answerMap[key].splice(foundSubIndex, 1);
    }
    changeAnswer(answer);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full md:w-[60%]">
        <ActivityBodyContent bodyMap={MCMABodyMap} templateType={"MCMA"} />
      </div>
      <div className="contents md:hidden">
        <DividerLine />
      </div>
      <div className="hidden md:block">
        <VerticalDividerLine />
      </div>
      <div className="w-full md:flex-1">
        <MCMAActivityMaterialContent
          uniqueValue={JSON.stringify(data.contentMap)}
          answer={answer}
          materialMap={MCMAMaterialMap}
          contentMap={contentMap}
          checkCanAnswerQuestion={canAnswerQuestion}
          onChange={handleMCMAAnswerOnChange}
          isPreview={isPreview}
        />
      </div>
    </div>
  );
};

export default MCMAActivityContent;
