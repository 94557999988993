import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InputGroup from "../../components/groups/InputGroup";
import LandingHeader from "../../components/headers/LandingHeader";
import i18n from "../../language/i18n";
import { createBasicUser } from "../../requests/ManagementRequests";
import { retrieveGenderOptionList } from "../../utilization/ManagementUtilization";
import { useApp } from "../../context/AppContextProvider";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useApp();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [gender, setGender] = useState("MALE");
  const [dateOfBirth, setDateOfBirth] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [errorMap, setErrorMap] = useState({
    username: null,
    password: null,
    email: null,
    firstName: null,
    lastName: null,
    identityNumber: null,
    dateOfBirth: null,
    gender: null,
  });

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  const handleOnChange = (type, value) => {
    if (type === "username") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: i18n.t("username_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          username: null,
        }));
      }
      setUsername(value);
    } else if (type === "password") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: i18n.t("password_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          password: null,
        }));
      }
      setPassword(value);
    } else if (type === "email") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: i18n.t("email_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          email: null,
        }));
      }
      setEmail(value);
    } else if (type === "firstName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: i18n.t("firstName_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          firstName: null,
        }));
      }
      setFirstName(value);
    } else if (type === "lastName") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: i18n.t("lastName_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          lastName: null,
        }));
      }
      setLastName(value);
    } else if (type === "identityNumber") {
      if (value === null || value === "") {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: i18n.t("identityNumber_required_field"),
        }));
      } else {
        setErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          identityNumber: null,
        }));
      }
      setIdentityNumber(value);
    } else if (type === "dateOfBirth") {
      setDateOfBirth(value);
    } else if (type === "gender") {
      setGender(value);
    }
  };

  const handleRegisterOnClick = async () => {
    if (
      !username ||
      username.trim() === "" ||
      !password ||
      password.trim() === "" ||
      !email ||
      email.trim() === "" ||
      !firstName ||
      firstName.trim() === "" ||
      !lastName ||
      lastName.trim() === "" ||
      !identityNumber ||
      identityNumber.trim() === ""
    ) {
      const currentErrorMap = {
        username: null,
        password: null,
        email: null,
        firstName: null,
        lastName: null,
        identityNumber: null,
      };
      if (!username || username.trim() === "") {
        currentErrorMap.username = i18n.t("username_required_field");
      }
      if (!password || password.trim() === "") {
        currentErrorMap.password = i18n.t("password_required_field");
      }
      if (!email || email.trim() === "") {
        currentErrorMap.email = i18n.t("email_required_field");
      }
      if (!firstName || firstName.trim() === "") {
        currentErrorMap.firstName = i18n.t("firstName_required_field");
      }
      if (!lastName || lastName.trim() === "") {
        currentErrorMap.lastName = i18n.t("lastName_required_field");
      }
      if (!identityNumber || identityNumber.trim() === "") {
        currentErrorMap.identityNumber = i18n.t(
          "identityNumber_required_field"
        );
      }
      setErrorMap(currentErrorMap);
      return;
    }

    const newBasicUser = {
      username,
      password,
      email,
      firstName,
      lastName,
      identityNumber,
      dateOfBirth: new Date(dateOfBirth).getTime(),
      gender,
    };
    const { err } = await createBasicUser(newBasicUser);
    if (err) {
      if (err.response.data) {
        NotificationManager.error(i18n.t(err.response.data));
      } else {
        console.log(err);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="text-amber-700 bg-amber-200 dark:bg-amber-700 dark:text-amber-200 bg-">
      <div className="container mx-auto min-h-screen text-amber-600 bg-amber-100 dark:bg-amber-600 dark:text-amber-100">
        <LandingHeader />
        <div className="py-5">
          <p className="text-3xl text-center">
            {i18n.t("register_screen_header_text")}
          </p>
          <div className="flex flex-col max-w-md mx-auto my-3">
            <InputGroup
              type="text"
              title={i18n.t("username")}
              placeholder={i18n.t("username")}
              value={username}
              onChange={(e) => {
                handleOnChange("username", e.target.value);
              }}
              errorText={errorMap["username"]}
            />
            <InputGroup
              type="password"
              title={i18n.t("password")}
              placeholder={i18n.t("password")}
              value={password}
              onChange={(e) => {
                handleOnChange("password", e.target.value);
              }}
              errorText={errorMap["password"]}
            />
            <InputGroup
              type="text"
              title={i18n.t("email")}
              placeholder={i18n.t("email")}
              value={email}
              onChange={(e) => {
                handleOnChange("email", e.target.value);
              }}
              errorText={errorMap["email"]}
            />
            <InputGroup
              type="text"
              title={i18n.t("first_name")}
              placeholder={i18n.t("first_name")}
              value={firstName}
              onChange={(e) => {
                handleOnChange("firstName", e.target.value);
              }}
              errorText={errorMap["firstName"]}
            />
            <InputGroup
              type="text"
              title={i18n.t("last_name")}
              placeholder={i18n.t("last_name")}
              value={lastName}
              onChange={(e) => {
                handleOnChange("lastName", e.target.value);
              }}
              errorText={errorMap["lastName"]}
            />
            <InputGroup
              type="text"
              title={i18n.t("identity_number")}
              placeholder={i18n.t("identity_number")}
              value={identityNumber}
              onChange={(e) => {
                handleOnChange("identityNumber", e.target.value);
              }}
              errorText={errorMap["identityNumber"]}
            />
            <InputGroup
              type="date"
              title={i18n.t("date_of_birth")}
              placeholder={i18n.t("date_of_birth")}
              value={dateOfBirth}
              onChange={(e) => {
                handleOnChange("dateOfBirth", e.target.value);
              }}
              errorText={errorMap["dateOfBirth"]}
            />
            <InputGroup
              type="select"
              title={i18n.t("gender")}
              placeholder={i18n.t("gender")}
              value={gender}
              optionList={retrieveGenderOptionList()}
              onChange={(e) => {
                handleOnChange("gender", e.target.value);
              }}
              errorText={errorMap["gender"]}
            />
            <div className="mt-4">
              <PrimaryButton
                title={i18n.t("register")}
                size="small"
                onClick={handleRegisterOnClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
