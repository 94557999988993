import { InlineMath } from "react-katex";
import { constructInputWithSpecialExpressionList } from "../../../utilization/CatchtivityUtilization";
import ShowMaterialMediaByContentType from "./ShowMaterialMediaByContentType";
import i18n from "../../../language/i18n";
import BaseImage from "../../images/BaseImage";
import DividerLine from "../../divider/DividerLine";

const MCMAActivityMaterialContent = (props) => {
  const {
    uniqueValue,
    answer,
    materialMap,
    contentMap,
    checkCanAnswerQuestion,
    onChange,
    isPreview,
  } = props;

  const retrieveAnswerMap = () => {
    const foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "MCMA"
    );
    return answer.data[foundIndex].answerMap;
  };

  const retrieveCorrectAnswerList = () => {
    return JSON.parse(Object.keys(materialMap)[0]);
  };

  const checkAnswerState = (
    correctAnswerList,
    currentAnswer,
    learnerAnswer
  ) => {
    if (!isPreview) return "HIDDEN";
    if (currentAnswer !== learnerAnswer) return "EMPTY";
    const foundIndex = correctAnswerList.findIndex(
      (correctAnswer) => correctAnswer === learnerAnswer
    );
    if (foundIndex !== -1) {
      return "CORRECT";
    }
    return "INCORRECT";
  };

  const answerMap = retrieveAnswerMap();
  const correctAnswerList = retrieveCorrectAnswerList();

  return Object.keys(materialMap).map((materialKey, index) => {
    return (
      <div key={index} className="flex flex-row items-center my-1">
        <div className="flex-1 flex flex-col justify-center border-catchup-lighter-gray rounded-catchup-xlarge px-5">
          <div className="hidden md:block">
            <span className="font-semibold text-xl opacity-60">
              {i18n.t("please_select_mcma_text")}
            </span>
          </div>
          <div className="hidden md:contents">
            <DividerLine />
          </div>
          {checkCanAnswerQuestion() ? (
            <div className="flex flex-row w-full flex-wrap ">
              {materialMap[materialKey].map((materialSubKey, index) => {
                const foundAnswer = answerMap[materialKey].find(
                  (learnerAnswer) => learnerAnswer === materialSubKey
                );
                const learnerAnswerState = checkAnswerState(
                  correctAnswerList,
                  materialSubKey,
                  foundAnswer
                );
                const foundIndex = correctAnswerList.findIndex(
                  (correctAnswer) => correctAnswer === materialSubKey
                );
                return (
                  <div
                    key={index}
                    className={`w-full flex flex-row items-center justify-center cursor-pointer my-2 gap-x-2 ${
                      (learnerAnswerState === "EMPTY" && foundIndex !== -1) ||
                      learnerAnswerState === "CORRECT"
                        ? "border-2 border-catchup-green rounded-catchup-xlarge"
                        : learnerAnswerState === "INCORRECT"
                        ? "border-2 border-catchup-red rounded-catchup-xlarge"
                        : ""
                    }`}
                    onClick={() => {
                      onChange(answer, materialKey, materialSubKey);
                    }}
                  >
                    <div className="">
                      <BaseImage
                        src={
                          answerMap[materialKey].includes(materialSubKey)
                            ? "/icons/checkbox.png"
                            : "/icons/empty-checkbox.png"
                        }
                        alt="checkbox-empty-checkbox"
                        size="small"
                        onClick={() => {}}
                      />
                    </div>
                    <div className="flex-1">
                      {contentMap.type === "TEXT" ? (
                        <p className="text-xl whitespace-pre-wrap">
                          {constructInputWithSpecialExpressionList(
                            materialSubKey
                          ).map((inputPart, index) => (
                            <span
                              key={index}
                              className={`${
                                inputPart.isBold ? "font-bold" : ""
                              } ${inputPart.isUnderline ? "underline" : ""}`}
                            >
                              {inputPart.isEquation ? (
                                <span className="text-2xl">
                                  <InlineMath math={inputPart.value} />
                                </span>
                              ) : (
                                inputPart.value
                              )}
                            </span>
                          ))}
                        </p>
                      ) : (
                        <ShowMaterialMediaByContentType
                          key={`${uniqueValue}-${index}`}
                          contentType={contentMap.type}
                          src={materialSubKey}
                          canFullScreen={true}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p key={materialKey} className="text-xl">
              {constructInputWithSpecialExpressionList(
                answerMap[materialKey]
              ).map((inputPart, index) => (
                <span
                  key={index}
                  className={`${inputPart.isBold ? "font-bold" : ""} ${
                    inputPart.isUnderline ? "underline" : ""
                  }`}
                >
                  {inputPart.isEquation ? (
                    <span className="text-2xl">
                      <InlineMath math={inputPart.value} />
                    </span>
                  ) : (
                    inputPart.value
                  )}
                </span>
              ))}
            </p>
          )}
        </div>
      </div>
    );
  });
};

export default MCMAActivityMaterialContent;
