import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Category
 */
export const queryCategoryListByVersionCodeList = async (
  queryCategoryParams
) => {
  try {
    const response = await axios.post(
      `${connection.base.category.url}${endpoints.categories.context}${endpoints.categories.query["by-versionCodeList"].context}`,
      queryCategoryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
