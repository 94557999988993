const NUMBER_OF_ACTIVITY_TEMPLATE = 9;
const NUMBER_OF_TAXONOMY = 6;
const INITIAL_TAXONOMY_VALUE = 1 / NUMBER_OF_TAXONOMY;
const INITIAL_TEMPLATE_VALUE = 1 / NUMBER_OF_ACTIVITY_TEMPLATE;

export const constructBaseVerbalIndvidualModel = (userId) => {
  return {
    bloomBloomAnalyze: INITIAL_TAXONOMY_VALUE,
    bloomBloomApply: INITIAL_TAXONOMY_VALUE,
    bloomBloomCreate: INITIAL_TAXONOMY_VALUE,
    bloomBloomEvaluate: INITIAL_TAXONOMY_VALUE,
    bloomBloomRemember: INITIAL_TAXONOMY_VALUE,
    bloomBloomUnderstand: INITIAL_TAXONOMY_VALUE,
    dropdown: INITIAL_TEMPLATE_VALUE,
    coterieField: "VERBAL",
    fillInTheBlanks: INITIAL_TEMPLATE_VALUE,
    grouping: INITIAL_TEMPLATE_VALUE,
    matching: INITIAL_TEMPLATE_VALUE,
    mcma: INITIAL_TEMPLATE_VALUE,
    mcsa: INITIAL_TEMPLATE_VALUE,
    openEnded: INITIAL_TEMPLATE_VALUE,
    ordering: INITIAL_TEMPLATE_VALUE,
    trueFalse: INITIAL_TEMPLATE_VALUE,
    userId,
  };
};

export const constructBaseNumericIndividualModel = (userId) => {
  return {
    bloomBloomAnalyze: INITIAL_TAXONOMY_VALUE,
    bloomBloomApply: INITIAL_TAXONOMY_VALUE,
    bloomBloomCreate: INITIAL_TAXONOMY_VALUE,
    bloomBloomEvaluate: INITIAL_TAXONOMY_VALUE,
    bloomBloomRemember: INITIAL_TAXONOMY_VALUE,
    bloomBloomUnderstand: INITIAL_TAXONOMY_VALUE,
    dropdown: INITIAL_TEMPLATE_VALUE,
    coterieField: "NUMERIC",
    fillInTheBlanks: INITIAL_TEMPLATE_VALUE,
    grouping: INITIAL_TEMPLATE_VALUE,
    matching: INITIAL_TEMPLATE_VALUE,
    mcma: INITIAL_TEMPLATE_VALUE,
    mcsa: INITIAL_TEMPLATE_VALUE,
    openEnded: INITIAL_TEMPLATE_VALUE,
    ordering: INITIAL_TEMPLATE_VALUE,
    trueFalse: INITIAL_TEMPLATE_VALUE,
    userId,
  };
};
