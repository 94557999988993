import { useEffect, useState } from "react";
import { useApp } from "../../context/AppContextProvider";
import { useAuth } from "../../context/UserContextProvider";
import Welcome from "../../components/dashboards/Welcome";
import Clock from "../../components/dashboards/Clock";
import Homework from "../../components/dashboards/Homework";
import DoYouKnow from "../../components/dashboards/DoYouKnow";
import Profile from "../../components/dashboards/Profile";
import Mascot from "../../components/dashboards/Mascot";
import Report from "../../components/dashboards/Report";
import Plan from "../../components/dashboards/Plan";
import Leaderboard from "../../components/dashboards/Leaderboard";
import Announcement from "../../components/dashboards/Announcement";
import useScreenSize from "../../hooks/useScreenSize";

const DashboardScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    activeContentList,
    userInformation,
    userProfile,
    userProfileBranch,
    personalStatus,
    language,
    catchtivityApplicationListLoading,
    catchxamApplicationListLoading,
    standardExamApplicationListLoading,
    thisWeekRankingList,
    allTimeRankingList,
    earnedBadgeDTOList,
  } = useAuth();
  const BASE_PADDING = 8;
  const { screenSize } = useScreenSize();
  const [view, setView] = useState("TABLET");

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    if (!screenSize) return;
    if (screenSize.width <= 1280) {
      setView("TABLET");
    } else {
      setView("PC");
    }
  }, [screenSize]);

  const retrieveCurrentRanking = () => {
    return thisWeekRankingList.find(
      (ranking) =>
        ranking.userId === userInformation.id &&
        ranking.userProfileId === userProfile.id
    );
  };

  const RenderLaptopMainContent = () => {
    return (
      <div className="w-full">
        <div>
          <Welcome name={userInformation.firstName} />
        </div>
        <div className="flex-1 flex flex-row">
          <div
            className="flex-1"
            style={{ paddingRight: BASE_PADDING / 2, paddingTop: BASE_PADDING }}
          >
            <Clock />
          </div>
          <div
            className="flex-1"
            style={{ paddingLeft: BASE_PADDING / 2, paddingTop: BASE_PADDING }}
          >
            <DoYouKnow />
          </div>
        </div>
        <div className="">
          <div className="" style={{ paddingTop: BASE_PADDING }}>
            <Homework
              contentList={activeContentList
                .filter(
                  (content) =>
                    content.completionStatus === "NOT_STARTED" ||
                    content.completionStatus === "ON_GOING"
                )
                .slice(0, 3)}
              loading={
                catchxamApplicationListLoading ||
                catchtivityApplicationListLoading ||
                standardExamApplicationListLoading
              }
              language={language}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-row">
          <div
            className="flex-1"
            style={{ paddingRight: BASE_PADDING / 2, paddingTop: BASE_PADDING }}
          >
            <Profile
              firstName={userInformation.firstName}
              lastName={userInformation.lastName}
              branchName={userProfileBranch.name}
              ranking={currentRanking}
              badgeDTOList={earnedBadgeDTOList}
            />
          </div>
          <div
            className="flex-1"
            style={{ paddingLeft: BASE_PADDING / 2, paddingTop: BASE_PADDING }}
          >
            <Mascot remainingPoints={personalStatus.remainingPoints} />
          </div>
        </div>
        <div className="">
          <div className="h-[600px]" style={{ paddingTop: BASE_PADDING }}>
            <div className="h-full">
              <Report />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-row">
          <div
            className="flex-1 h-[700px]"
            style={{ paddingRight: BASE_PADDING / 2, paddingTop: BASE_PADDING }}
          >
            <div className="h-[500px]">
              <div className="h-full">
                <Plan />
              </div>
            </div>
            <div className="h-[300px]">
              <div
                className="h-full"
                style={{
                  paddingTop: BASE_PADDING,
                  paddingBottom: BASE_PADDING,
                }}
              >
                <Announcement />
              </div>
            </div>
          </div>
          <div
            className="flex-1 h-[800px]"
            style={{ paddingLeft: BASE_PADDING / 2, paddingTop: BASE_PADDING }}
          >
            <Leaderboard
              detailed={false}
              useMascot={true}
              userId={userInformation.id}
              userProfileId={userProfile.id}
              userProfileBranchId={userProfileBranch.id}
              thisWeekRankingList={thisWeekRankingList}
              allTimeRankingList={allTimeRankingList}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderPCMainContent = () => {
    return (
      <div className="h-full">
        <div className="h-full flex flex-row">
          <div className="w-3/4 h-full">
            <div className="h-[40%]">
              <div className="h-1/3">
                <div className="h-full" style={{ paddingBottom: BASE_PADDING }}>
                  <Welcome name={userInformation.firstName} />
                </div>
              </div>
              <div className="h-2/3 flex flex-row">
                <div className="w-1/4 h-full">
                  <div
                    className="h-1/2 w-full"
                    style={{
                      paddingBottom: BASE_PADDING / 2,
                      paddingRight: BASE_PADDING / 2,
                    }}
                  >
                    <div className="h-full ">
                      <Clock />
                    </div>
                  </div>
                  <div
                    className="h-1/2 w-full"
                    style={{
                      paddingTop: BASE_PADDING / 2,
                      paddingRight: BASE_PADDING / 2,
                    }}
                  >
                    <div className="h-full">
                      <DoYouKnow />
                    </div>
                  </div>
                </div>
                <div className="w-3/4 h-full">
                  <div
                    className="h-full"
                    style={{
                      paddingLeft: BASE_PADDING / 2,
                      paddingLeft: BASE_PADDING / 2,
                    }}
                  >
                    <Homework
                      contentList={activeContentList
                        .filter(
                          (content) =>
                            content.completionStatus === "NOT_STARTED" ||
                            content.completionStatus === "ON_GOING"
                        )
                        .slice(0, 3)}
                      loading={
                        catchxamApplicationListLoading ||
                        catchtivityApplicationListLoading ||
                        standardExamApplicationListLoading
                      }
                      language={language}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[60%]">
              <div className="h-full flex flex-row">
                <div className="w-2/3 flex flex-col">
                  <div className="h-[220px] flex flex-row">
                    <div
                      className="h-full w-1/2"
                      style={{
                        paddingRight: BASE_PADDING / 2,
                        paddingTop: BASE_PADDING,
                        paddingBottom: BASE_PADDING,
                      }}
                    >
                      <Profile
                        firstName={userInformation.firstName}
                        lastName={userInformation.lastName}
                        branchName={userProfileBranch.name}
                        ranking={currentRanking}
                        badgeDTOList={earnedBadgeDTOList}
                      />
                    </div>
                    <div
                      className="h-full w-1/2"
                      style={{
                        paddingLeft: BASE_PADDING / 2,
                        paddingTop: BASE_PADDING,
                        paddingBottom: BASE_PADDING,
                      }}
                    >
                      <Mascot
                        remainingPoints={personalStatus.remainingPoints}
                      />
                    </div>
                  </div>
                  <div className="flex-1 min-h-0">
                    <Report />
                  </div>
                </div>
                <div className="w-1/3">
                  <div
                    className="h-full"
                    style={{
                      paddingLeft: BASE_PADDING,
                      paddingTop: BASE_PADDING,
                    }}
                  >
                    <Plan />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/4 h-full">
            <div style={{ height: "75%" }}>
              <div
                className="h-full"
                style={{
                  paddingBottom: BASE_PADDING / 2,
                  paddingLeft: BASE_PADDING,
                }}
              >
                <Leaderboard
                  detailed={false}
                  useMascot={true}
                  userId={userInformation.id}
                  userProfileId={userProfile.id}
                  userProfileBranchId={userProfileBranch.id}
                  thisWeekRankingList={thisWeekRankingList}
                  allTimeRankingList={allTimeRankingList}
                />
              </div>
            </div>
            <div style={{ height: "25%" }}>
              <div
                className="h-full"
                style={{
                  paddingTop: BASE_PADDING / 2,
                  paddingLeft: BASE_PADDING,
                }}
              >
                <Announcement />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const currentRanking = retrieveCurrentRanking();

  if (view === "TABLET") {
    return <div className="p-4">{RenderLaptopMainContent()}</div>;
  } else if (view === "PC") {
    return (
      <div className="h-screen">
        <div className="h-full p-4">{RenderPCMainContent()}</div>
      </div>
    );
  }
};

export default DashboardScreen;
