import { useEffect, useRef, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import { useApp } from "../../context/AppContextProvider";
import BaseLoading from "../../components/loading/BaseLoading";
import i18n from "../../language/i18n";
import BaseImage from "../../components/images/BaseImage";
import { solveActivityWithAIOnlyImage } from "../../requests/CatchtivityRequests";
import { constructInputWithSpecialExpressionList } from "../../utilization/CatchtivityUtilization";
import { InlineMath } from "react-katex";

const SolveActivityScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();
  const [uploadingStatus, setUploadingStatus] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadBase64, setUploadBase64] = useState(null);
  const [activitySolution, setActivitySolution] = useState(null);
  const inputFileRef = useRef(null);

  console.log(activitySolution);

  useEffect(() => {
    if (isFullScreen === false) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetUploadBase64 = async () => {
      const currentUploadBase64 = await convertToBase64(uploadFile);
      setUploadBase64(currentUploadBase64);
    };

    if (!uploadFile) {
      setUploadBase64(null);
    } else {
      retrieveAndSetUploadBase64();
    }
  }, [uploadFile]);

  useEffect(() => {
    const retrieveAndSetActivitySolution = async () => {
      setUploadingStatus("UPLOADING");
      const { data, err } = await solveActivityWithAIOnlyImage({
        imageData: uploadBase64,
      });
      if (err) {
        console.log(err);
        setUploadingStatus("FAILED");
        return;
      }
      setUploadingStatus("SUCCESS");
      setActivitySolution(data);
    };

    if (!uploadBase64) {
      setActivitySolution(null);
    } else {
      retrieveAndSetActivitySolution();
    }
  }, [uploadBase64]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const RenderActivitySolutionContent = () => {
    if (!activitySolution) return;
    const { solutionMap } = activitySolution;
    return (
      <div className="w-full">
        <div>
          <img src={uploadBase64} />
        </div>
        <div className="my-2">
          <p className="font-semibold">{i18n.t("solution")}</p>
        </div>
        <div className="">
          {Object.keys(solutionMap).map((key, index) => (
            <div key={index} className="my-1">
              {constructInputWithSpecialExpressionList(
                solutionMap[key].value
              ).map((inputPart, index) => (
                <span
                  key={index}
                  className={`${inputPart.isBold ? "font-bold" : ""} ${
                    inputPart.isUnderline ? "underline" : ""
                  }`}
                >
                  {inputPart.isEquation ? (
                    <span className="text-2xl">
                      <InlineMath math={inputPart.value} />
                    </span>
                  ) : (
                    inputPart.value
                  )}
                </span>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="h-full">
      <div className="p-4">
        <FullCard>
          {activitySolution ? (
            <RenderActivitySolutionContent />
          ) : (
            <div className="h-full flex flex-row justify-center items-center gap-x-4">
              <div
                className="flex flex-row items-center justify-center cursor-pointer gap-x-2"
                onClick={() => {
                  setUploadFile(null);
                  inputFileRef.current.click();
                }}
              >
                {uploadingStatus === "UPLOADING" ? (
                  <BaseLoading
                    size="large"
                    color="#57C2D3"
                    secondaryColor="#57C2D3"
                  />
                ) : (
                  <>
                    <BaseImage
                      src="/icons/upload-device.png"
                      alt="upload"
                      size="small"
                    />
                    <p className="text-base underline italic">
                      {i18n.t("i_want_to_upload_from_my_phone")}
                    </p>
                    <input
                      className="hidden"
                      type="file"
                      id="file"
                      ref={inputFileRef}
                      accept="image/jpeg, image/png, image/jpg"
                      multiple={false}
                      onChange={(event) => {
                        setUploadFile(event.target.files[0]);
                      }}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </FullCard>
      </div>
    </div>
  );
};

export default SolveActivityScreen;
