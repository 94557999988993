import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/AppContextProvider";
import FullCard from "../../components/cards/FullCard";
import DashboardHeader from "../../components/headers/DashboardHeader";
import i18n from "../../language/i18n";
import BaseLoading from "../../components/loading/BaseLoading";
import {
  patchActivityReview,
  queryActivityAnswerListByActivityIdAndCatchtivityApplicationId,
  queryActivityAnswerListByActivityIdAndCatchxamApplicationId,
  queryActivityAnswerListByActivityIdAndStandardExamApplicationId,
  retrieveActivityWithDataById,
  solveActivityWithAI,
} from "../../requests/CatchtivityRequests";
import { useAuth } from "../../context/UserContextProvider";
import OrderingActivityContent from "../../components/activities/OrderingActivityContent";
import DropdownActivityContent from "../../components/activities/DropdownActivityContent";
import MCSAActivityContent from "../../components/activities/MCSAActivityContent";
import MCMAActivityContent from "../../components/activities/MCMAActivityContent";
import MatchingActivityContent from "../../components/activities/MatchingActivityContent";
import GroupingActivityContent from "../../components/activities/GroupingActivityContent";
import FillInTheBlanksActivityContent from "../../components/activities/FillInTheBlanksActivityContent";
import OpenEndedActivityContent from "../../components/activities/OpenEndedActivityContent";
import TrueFalseActivityContent from "../../components/activities/TrueFalseActivityContent";
import ActivitySolutionContent from "../../components/activities/solution-content/ActivitySolutionContent";
import {
  constructActivityItemListWithAnswersForAI,
  parseBodyMapFromData,
  parseMaterialMapFromData,
} from "../../utilization/CatchtivityUtilization";
import { NotificationManager } from "react-notifications";
import BaseImage from "../../components/images/BaseImage";

const PractiseApplicationActivityByIdScreen = () => {
  const navigate = useNavigate();
  const {
    catchtivityApplicationId,
    catchxamApplicationId,
    standardExamApplicationId,
    activityId,
  } = useParams();
  const { isFullScreen, setIsFullScreen } = useApp();
  const {
    userInformation,
    userProfile,
    activityReviewDTOList,
    setActivityReviewDTOList,
  } = useAuth();
  const [activityDTO, setActivityDTO] = useState({
    data: null,
  });
  const [answerDataList, setAnswerDataList] = useState([]);
  const [selectedActivityTemplateType, setSelectedActivityTemplateType] =
    useState(null);
  const [answer, setAnswer] = useState({
    data: [],
  });
  const [loadingMap, setLoadingMap] = useState({
    activity: false,
    answer: false,
    solution: false,
  });

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetActivityWithData = async () => {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        activity: true,
      }));
      const { data, err } = await retrieveActivityWithDataById(activityId);
      if (err) {
        console.log(err);
        return;
      }
      setActivityDTO({ ...data, data: JSON.parse(data.data) });
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        activity: false,
      }));
    };
    const retrieveAndSetActivityAnswerDTOListByCatchtivityApplicationId =
      async () => {
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          answer: true,
        }));
        const { data, err } =
          await queryActivityAnswerListByActivityIdAndCatchtivityApplicationId({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            activityId,
            catchtivityApplicationId,
          });
        if (err) {
          console.log(err);
          return;
        }
        data.forEach((item) => {
          item.data = JSON.parse(item.data);
        });
        setAnswerDataList(data);
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          answer: false,
        }));
      };
    const retrieveAndSetActivityAnswerDTOListByCatchxamApplicationId =
      async () => {
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          answer: true,
        }));
        const { data, err } =
          await queryActivityAnswerListByActivityIdAndCatchxamApplicationId({
            userId: userInformation.id,
            userProfileId: userProfile.id,
            activityId,
            catchxamApplicationId,
          });
        if (err) {
          console.log(err);
          return;
        }
        data.forEach((item) => {
          item.data = JSON.parse(item.data);
        });
        setAnswerDataList(data);
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          answer: false,
        }));
      };
    const retrieveAndSetActivityAnswerDTOListByStandardExamApplicationId =
      async () => {
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          answer: true,
        }));
        const { data, err } =
          await queryActivityAnswerListByActivityIdAndStandardExamApplicationId(
            {
              userId: userInformation.id,
              userProfileId: userProfile.id,
              activityId,
              standardExamApplicationId,
            }
          );
        if (err) {
          console.log(err);
          return;
        }
        data.forEach((item) => {
          item.data = JSON.parse(item.data);
        });
        setAnswerDataList(data);
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          answer: false,
        }));
      };
    if (!activityId) return;
    retrieveAndSetActivityWithData();
    if (catchtivityApplicationId) {
      retrieveAndSetActivityAnswerDTOListByCatchtivityApplicationId();
    } else if (catchxamApplicationId) {
      retrieveAndSetActivityAnswerDTOListByCatchxamApplicationId();
    } else if (standardExamApplicationId) {
      retrieveAndSetActivityAnswerDTOListByStandardExamApplicationId();
    }
  }, [
    catchtivityApplicationId,
    catchxamApplicationId,
    standardExamApplicationId,
    activityId,
  ]);

  useEffect(() => {
    if (answerDataList.length === 0) return;
    const answerData = answerDataList[0];
    const { data } = answerData;
    if (data.length === 0) return;
    for (const item of data) {
      answer.data.push(item);
    }
    const { type } = data[0];
    setSelectedActivityTemplateType(type);
    setAnswer(answer);
  }, [answerDataList]);

  useEffect(() => {
    const solveWithAIAndUpdateActivity = async () => {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        solution: true,
      }));
      let itemList = [];
      const { level, coterieType, data } = activityDTO;
      if (selectedActivityTemplateType === "ORDERING") {
        const orderingBodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const orderingMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          orderingBodyMap,
          orderingMaterialMap,
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "DROPDOWN") {
        const dropdownBodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const dropdownMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          dropdownBodyMap,
          dropdownMaterialMap,
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "MCSA") {
        const MCSABodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const MCSAMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          MCSABodyMap,
          MCSAMaterialMap,
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "MCMA") {
        const MCMABodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const MCMAMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          MCMABodyMap,
          MCMAMaterialMap,
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "MATCHING") {
        const matchingBodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const matchingMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          matchingBodyMap,
          matchingMaterialMap,
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "GROUPING") {
        const groupingBodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const groupingMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          groupingBodyMap,
          groupingMaterialMap,
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
        const fillInTheBlanksBodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const fillInTheBlanksMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          fillInTheBlanksBodyMap,
          fillInTheBlanksMaterialMap,
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "OPEN_ENDED") {
        const openEndedBodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          openEndedBodyMap,
          {},
          selectedActivityTemplateType
        );
      } else if (selectedActivityTemplateType === "TRUE_FALSE") {
        const trueFalseBodyMap = parseBodyMapFromData(
          data,
          selectedActivityTemplateType
        );
        const trueFalseMaterialMap = parseMaterialMapFromData(
          data,
          selectedActivityTemplateType
        );
        itemList = constructActivityItemListWithAnswersForAI(
          trueFalseBodyMap,
          trueFalseMaterialMap,
          selectedActivityTemplateType
        );
      }

      const solveWithAI = {
        itemList,
        temperature: 0,
        level,
        coterieType,
        activityTemplateType: selectedActivityTemplateType,
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfile.seasonDTO.institutionDTO.campusDTO.brandDTO.id,
        campusId: userProfile.seasonDTO.institutionDTO.campusDTO.id,
        institutionId: userProfile.seasonDTO.institutionDTO.id,
        activityId,
      };
      const { data: solutionData, err: solutionErr } =
        await solveActivityWithAI(solveWithAI);
      if (solutionErr) {
        NotificationManager.error(i18n.t("error_occurred_on_solving_activity"));
        return;
      }
      const { solutionMap } = solutionData;
      Object.keys(solutionMap).forEach((key) => {
        solutionMap[key] = JSON.stringify(solutionMap[key]);
      });
      const activityWithSolutionDTO = JSON.parse(JSON.stringify(activityDTO));
      if (selectedActivityTemplateType === "ORDERING") {
        activityWithSolutionDTO.data["orderingSolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "DROPDOWN") {
        activityWithSolutionDTO.data["dropdownSolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "MCSA") {
        activityWithSolutionDTO.data["MCSASolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "MCMA") {
        activityWithSolutionDTO.data["MCMASolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "MATCHING") {
        activityWithSolutionDTO.data["matchingSolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "GROUPING") {
        activityWithSolutionDTO.data["groupingSolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
        activityWithSolutionDTO.data["fillInTheBlanksSolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "OPEN_ENDED") {
        activityWithSolutionDTO.data["openEndedSolutionMap"] =
          JSON.stringify(solutionMap);
      } else if (selectedActivityTemplateType === "TRUE_FALSE") {
        activityWithSolutionDTO.data["trueFalseSolutionMap"] =
          JSON.stringify(solutionMap);
      }
      setActivityDTO(activityWithSolutionDTO);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        solution: false,
      }));
    };
    if (!selectedActivityTemplateType) return;
    if (!activityDTO.data) return;
    const { contentMap } = activityDTO.data;
    const { type } = JSON.parse(contentMap);
    if (type !== "TEXT") return;

    let solutionMap;
    if (selectedActivityTemplateType === "ORDERING") {
      if (activityDTO.data["orderingSolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["orderingSolutionMap"]);
    } else if (selectedActivityTemplateType === "DROPDOWN") {
      if (activityDTO.data["dropdownSolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["dropdownSolutionMap"]);
    } else if (selectedActivityTemplateType === "MCSA") {
      if (activityDTO.data["MCSASolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["MCSASolutionMap"]);
    } else if (selectedActivityTemplateType === "MCMA") {
      if (activityDTO.data["MCMASolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["MCMASolutionMap"]);
    } else if (selectedActivityTemplateType === "MATCHING") {
      if (activityDTO.data["matchingSolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["matchingSolutionMap"]);
    } else if (selectedActivityTemplateType === "GROUPING") {
      if (activityDTO.data["groupingSolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["groupingSolutionMap"]);
    } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
      if (activityDTO.data["fillInTheBlanksSolutionMap"])
        solutionMap = JSON.parse(
          activityDTO.data["fillInTheBlanksSolutionMap"]
        );
    } else if (selectedActivityTemplateType === "OPEN_ENDED") {
      if (activityDTO.data["openEndedSolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["openEndedSolutionMap"]);
    } else if (selectedActivityTemplateType === "TRUE_FALSE") {
      if (activityDTO.data["trueFalseSolutionMap"])
        solutionMap = JSON.parse(activityDTO.data["trueFalseSolutionMap"]);
    }
    if (!solutionMap || Object.keys(solutionMap).length === 0) {
      // solveWithAIAndUpdateActivity();
    }
  }, [activityDTO, selectedActivityTemplateType]);

  const retrieveTaxonomyNameFromActivityType = (type) => {
    let taxonomyMap = {
      name: "",
    };
    if (type === "ORDERING") {
      taxonomyMap = JSON.parse(activityDTO.data["orderingTaxonomyMap"]);
    } else if (type === "DROPDOWN") {
      taxonomyMap = JSON.parse(activityDTO.data["dropdownTaxonomyMap"]);
    } else if (type === "MCSA") {
      taxonomyMap = JSON.parse(activityDTO.data["MCSATaxonomyMap"]);
    } else if (type === "MCMA") {
      taxonomyMap = JSON.parse(activityDTO.data["MCMATaxonomyMap"]);
    } else if (type === "MATCHING") {
      taxonomyMap = JSON.parse(activityDTO.data["matchingTaxonomyMap"]);
    } else if (type === "GROUPING") {
      taxonomyMap = JSON.parse(activityDTO.data["groupingTaxonomyMap"]);
    } else if (type === "FILL_IN_THE_BLANKS") {
      taxonomyMap = JSON.parse(activityDTO.data["fillInTheBlanksTaxonomyMap"]);
    } else if (type === "OPEN_ENDED") {
      taxonomyMap = JSON.parse(activityDTO.data["openEndedTaxonomyMap"]);
    } else if (type === "TRUE_FALSE") {
      taxonomyMap = JSON.parse(activityDTO.data["trueFalseTaxonomyMap"]);
    }
    return taxonomyMap.name;
  };

  const findActivityReview = () => {
    if (!answer) return;
    if (!answer.data) return;
    if (answer.data.length === 0) return;
    const { activityTemplateId } = answer.data[0];
    return activityReviewDTOList.find(
      (activityReview) =>
        activityReview.activityDTO.id === parseFloat(activityId) &&
        activityReview.activityTemplateDTO.id ===
          parseFloat(activityTemplateId) &&
        (activityReview.catchtivityApplicationDTO?.id ===
          parseFloat(catchtivityApplicationId) ||
          activityReview.catchxamApplicationDTO?.id ===
            parseFloat(catchxamApplicationId) ||
          activityReview.standardExamApplicationDTO?.id ===
            parseFloat(standardExamApplicationId))
    );
  };

  const checkIsStarred = () => {
    if (!foundActivityReview) return false;
    const { isStarred } = foundActivityReview;
    return isStarred;
  };

  const constructMaterialTypeOptionList = () => {
    if (answerDataList.length === 0) return [];
    return answerDataList[0].data.map((answerItem) => ({
      ...answerItem,
      id: answerItem.type,
      text: i18n.t(answerItem.type),
      subText: i18n.t(retrieveTaxonomyNameFromActivityType(answerItem.type)),
    }));
  };

  const handlePatchActivityReviewOnClick = async () => {
    if (!foundActivityReview) return;
    const { data, err } = await patchActivityReview({
      id: foundActivityReview.id,
      isStarred: !isStarred,
    });
    if (err) {
      console.log(err);
      return;
    }
    foundActivityReview.isStarred = data.isStarred;
    setActivityReviewDTOList(JSON.parse(JSON.stringify(activityReviewDTOList)));
  };

  const RenderSelectedActivityContent = () => {
    if (selectedActivityTemplateType === "ORDERING") {
      return (
        <OrderingActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "DROPDOWN") {
      return (
        <DropdownActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCSA") {
      return (
        <MCSAActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MCMA") {
      return (
        <MCMAActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
        />
      );
    } else if (selectedActivityTemplateType === "MATCHING") {
      return (
        <MatchingActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "GROUPING") {
      return (
        <GroupingActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "FILL_IN_THE_BLANKS") {
      return (
        <FillInTheBlanksActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    } else if (selectedActivityTemplateType === "OPEN_ENDED") {
      return (
        <OpenEndedActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          showMaterialContent={true}
        />
      );
    } else if (selectedActivityTemplateType === "TRUE_FALSE") {
      return (
        <TrueFalseActivityContent
          answer={answer}
          data={activityDTO.data}
          canAnswerQuestion={() => {
            return true;
          }}
          changeAnswer={(answer) => {
            setAnswer(JSON.parse(JSON.stringify(answer)));
          }}
          isPreview={true}
          showCorrectAnswer={true}
        />
      );
    }
  };

  const RenderMainContent = () => {
    return (
      <FullCard>
        <DashboardHeader
          title={i18n.t("practise_now")}
          handleReturnOnClick={() => {
            navigate(-1);
          }}
        />
        {loadingMap.activity || loadingMap.answer ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            <div className="flex flex-row justify-end items-center gap-x-2">
              <BaseImage
                src={
                  isStarred ? "/icons/star-fill.png" : "/icons/star-empty.png"
                }
                alt="star"
                size="medium"
                onClick={handlePatchActivityReviewOnClick}
              />
            </div>
            <div className="flex flex-col my-2 w-full p-5">
              {RenderSelectedActivityContent()}
            </div>
            {/* {loadingMap.solution ? (
              <BaseLoading
                size="large"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              <ActivitySolutionContent
                activityTemplateType={selectedActivityTemplateType}
                data={activityDTO.data}
              />
            )} */}
          </>
        )}
      </FullCard>
    );
  };

  const foundActivityReview = findActivityReview();
  console.log(foundActivityReview);
  const isStarred = checkIsStarred();

  return (
    <div className="h-full flex flex-col justify-center p-4">
      <div className="h-full">{RenderMainContent()}</div>
    </div>
  );
};

export default PractiseApplicationActivityByIdScreen;
