const ProgressBar = (props) => {
  const {
    title,
    showRemainingTime,
    totalTimeInSeconds,
    remainingTimeInSeconds,
    color,
    borderColor,
    height,
  } = props;

  const retrieveMinutes = () => {
    return parseInt(remainingTimeInSeconds / 60);
  };

  const retrieveSeconds = (minutes) => {
    const minuteInSeconds = minutes * 60;
    return parseInt(remainingTimeInSeconds - minuteInSeconds);
  };

  const calculatePercentage = () => {
    if (remainingTimeInSeconds < 0) {
      return (totalTimeInSeconds / totalTimeInSeconds) * 100;
    } else {
      return (
        ((totalTimeInSeconds - remainingTimeInSeconds) / totalTimeInSeconds) *
        100
      );
    }
  };

  const currentMinutes = retrieveMinutes();
  const currentSeconds = retrieveSeconds(currentMinutes);

  return (
    <div className="relative w-full ">
      <div className="">
        {title ? (
          <div className="absolute top-2 left-6">
            <span className="text-xl">{title}</span>
          </div>
        ) : null}

        {showRemainingTime ? (
          <div className="absolute top-2 right-6">
            <span className="flex flex-row text-xl">
              <p className="mx-1">
                {currentMinutes < 0
                  ? `00`
                  : currentMinutes < 10
                  ? `0${currentMinutes}`
                  : currentMinutes}{" "}
              </p>
              :
              <p className="mx-1">
                {currentSeconds < 0
                  ? `00`
                  : currentSeconds < 10
                  ? `0${currentSeconds}`
                  : currentSeconds}{" "}
              </p>
            </span>
          </div>
        ) : null}

        <div
          className={`w-full border ${
            borderColor ? borderColor : "border-catchup-red"
          } rounded-full ${height ? height : ""}`}
        >
          <div
            className={`rounded-full ${color ? color : "bg-catchup-red"} ${
              height ? height : "h-catchup-quicktivity"
            }`}
            style={{ width: `${calculatePercentage()}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
