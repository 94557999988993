import { useEffect, useState } from "react";
import i18n from "../../language/i18n";

const DoYouKnow = () => {
  const [randomNumber, setRandomNumber] = useState(1);

  useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * 60) + 1);
  }, []);

  return (
    <div className="h-full bg-catchup-white rounded-2xl px-4 py-2">
      <div className="h-1/3 flex flex-col">
        <p className="text-catchup-gray-900 text-xl">{i18n.t("do_you_know")}</p>
      </div>
      <div className="h-2/3 flex flex-col">
        <p className="text-catchup-gray-300">
          {i18n.t(`do_you_know_text_${randomNumber}`)}
        </p>
      </div>
    </div>
  );
};

export default DoYouKnow;
