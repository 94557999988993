import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import {
  createAnnouncementActionByAnnouncementId,
  queryLearnerAnnouncementDTOList,
} from "../../requests/NotificationRequests";
import BaseImage from "../images/BaseImage";
import DividerLine from "../divider/DividerLine";
import BaseLoading from "../loading/BaseLoading";

const AnnouncementListView = (props) => {
  const { isMinimized } = props;
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    numberOfUnreadAnnouncements,
    setNumberOfUnreadAnnouncements,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [announcementDTOList, setAnnouncementDTOList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  useEffect(() => {
    const retrieveAndSetAnnouncementDTOList = async () => {
      setLoading(true);
      const { data, err } = await queryLearnerAnnouncementDTOList({
        userId: userInformation.id,
        userProfileId: userProfile.id,
        brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
        campusId: userProfileSeason.institutionDTO.campusDTO.id,
        institutionId: userProfileSeason.institutionDTO.id,
        seasonId: userProfileSeason.id,
        gradeId: userProfileGrade.id,
        branchId: userProfileBranch.id,
      });
      if (err) {
        console.log(err);
        return;
      }
      setAnnouncementDTOList(
        JSON.parse(
          JSON.stringify(data.sort((a, b) => b.createdAt - a.createdAt))
        )
      );
      setTotalPageNumber(Math.ceil(data.length / 10));
      setPageNumber(0);
      setLoading(false);
    };
    retrieveAndSetAnnouncementDTOList();
  }, []);

  const retrieveCreatedForText = (type) => {
    if (type === "USER") {
      return i18n.t("announcement_created_for_user");
    } else if (type === "USER_PROFILE") {
      return i18n.t("announcement_created_for_user_profile");
    } else if (type === "BRAND") {
      return i18n.t("announcement_created_for_brand");
    } else if (type === "CAMPUS") {
      return i18n.t("announcement_created_for_campus");
    } else if (type === "INSTITUTION") {
      return i18n.t("announcement_created_for_institution");
    } else if (type === "SEASON") {
      return i18n.t("announcement_created_for_season");
    } else if (type === "GRADE") {
      return i18n.t("announcement_created_for_grade");
    } else if (type === "BRANCH") {
      return i18n.t("announcement_created_for_branch");
    }
  };

  const filterAnnouncementDTOList = () => {
    const startIndex = pageNumber * 10;
    const endIndex = (pageNumber + 1) * 10;
    return announcementDTOList.slice(startIndex, endIndex);
  };

  const handleMarkAsReadOnClick = async (announcement) => {
    const { data, err } = await createAnnouncementActionByAnnouncementId({
      userId: userInformation.id,
      userProfileId: userProfile.id,
      announcement: {
        id: announcement.id,
        type: announcement.type,
      },
      type: "READ",
    });
    if (err) {
      console.log(err);
    } else {
      const foundIndex = announcementDTOList.findIndex(
        (announcementDTO) =>
          parseFloat(announcementDTO.id) === parseFloat(announcement.id)
      );
      announcementDTOList[foundIndex].actionType = "READ";
      setAnnouncementDTOList(JSON.parse(JSON.stringify(announcementDTOList)));
      setNumberOfUnreadAnnouncements(numberOfUnreadAnnouncements - 1);
    }
  };

  const RenderSingleAnnouncementItem = (announcement) => {
    return (
      <div className="p-4">
        <div className="flex flex-row justify-between">
          <div className="m-2">
            <BaseImage
              src="/icons/announcement.png"
              alt="announcement"
              size="medium"
            />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-col mr-5">
              <div className="my-2 flex flex-row items-end">
                <p
                  className={`${
                    announcement.actionType === "READ"
                      ? "font-normal"
                      : "font-bold"
                  } text-xl`}
                >
                  {i18n.t(announcement.name)}
                </p>
                <p
                  className={`${
                    announcement.actionType === "READ"
                      ? "font-normal"
                      : "font-bold"
                  } ml-2 text-sm italic`}
                >
                  ({retrieveCreatedForText(announcement.type)})
                </p>
              </div>
              <div
                className={`${
                  announcement.actionType === "READ"
                    ? "font-normal"
                    : "font-bold"
                } my-2`}
              >
                <p className="text-base">{i18n.t(announcement.description)}</p>
              </div>
              <div className="mt-auto flex flex-col justify-between">
                <p
                  className={`${
                    announcement.actionType === "READ"
                      ? "font-normal"
                      : "font-bold"
                  } text-sm text-catchup-gray`}
                >
                  {new Date(announcement.createdAt).toLocaleString(language)}
                </p>
                {announcement.actionType !== "READ" ? (
                  <p
                    className="text-sm underline text-catchup-red cursor-pointer"
                    onClick={() => {
                      handleMarkAsReadOnClick(announcement);
                    }}
                  >
                    {i18n.t("mark_as_read")}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {announcement.documentType === "IMAGE" ||
          announcement.documentType === "VIDEO" ||
          announcement.documentType === "AUDIO" ? (
            <div className="w-[300px]">
              {announcement.documentType === "IMAGE" ? (
                <BaseImage
                  src={announcement.documentUrl}
                  alt="document"
                  size="custom"
                  className="w-full rounded-catchup-xlarge"
                />
              ) : announcement.documentType === "VIDEO" ? (
                <video
                  src={announcement.documentUrl}
                  className={"w-full h-auto rounded-catchup-xlarge"}
                  controls
                />
              ) : announcement.documentType === "AUDIO" ? (
                <audio
                  src={announcement.documentUrl}
                  className={"w-full h-auto rounded-catchup-xlarge"}
                  controls
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderMinimizedSingleAnnouncementItem = (announcement) => {
    return (
      <div className="">
        <div className="flex flex-row justify-between gap-x-2">
          <div className="w-2 h-2 bg-catchup-red rounded-full mt-4" />
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-col">
              <div className="flex flex-row items-end">
                <p
                  className={`${
                    announcement.actionType === "READ" ? "" : "font-semibold"
                  }`}
                >
                  {i18n.t(announcement.name)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    return (
      <div>
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            {filteredAnnouncementDTOList.map((announcement, index) => (
              <div key={announcement.id} className="">
                {RenderSingleAnnouncementItem(announcement)}
                {filteredAnnouncementDTOList.length - 1 !== index ? (
                  <DividerLine />
                ) : null}
              </div>
            ))}
            <div className="w-1/2 mx-auto">
              <div className="flex flex-row items-center justify-center flex-wrap gap-x-2 gap-y-2">
                {Array.from(Array(totalPageNumber).keys()).map(
                  (item, index) => (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => {
                        setPageNumber(index);
                      }}
                    >
                      <p
                        className={`${
                          pageNumber === index
                            ? "font-bold text-xl"
                            : "text-base"
                        }`}
                      >
                        {index + 1}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const RenderMinimizedMainContent = () => {
    const minimizedAnnouncementDTOList = filteredAnnouncementDTOList.slice(
      0,
      5
    );
    return (
      <div>
        {loading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <div className="mt-4">
            {minimizedAnnouncementDTOList.map((announcement, index) => (
              <div key={announcement.id} className="">
                {RenderMinimizedSingleAnnouncementItem(announcement)}
                {filteredAnnouncementDTOList.length - 1 !== index ? (
                  <DividerLine />
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const filteredAnnouncementDTOList = filterAnnouncementDTOList();

  if (isMinimized) {
    return <div className="w-full">{RenderMinimizedMainContent()}</div>;
  } else {
    return <div className="m-4">{RenderMainContent()}</div>;
  }
};

export default AnnouncementListView;
