import { useState } from "react";
import BaseImage from "../images/BaseImage";
import BaseLoading from "../loading/BaseLoading";

const PrimaryButton = (props) => {
  const { title, size, onClick, disabled, iconPosition, textOnly, iconOnly } =
    props;
  const [loading, setLoading] = useState(false);

  const internalOnClick = async (e) => {
    e.stopPropagation();
    if (loading) return;
    if (disabled) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  let currentWidthClassName;
  let currentHeightClassName;
  let currentLoadingSize;
  if (size === "small") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-8";
    currentLoadingSize = 14;
  } else if (size === "medium") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-10";
    currentLoadingSize = 16;
  } else if (size === "large") {
    currentWidthClassName = "w-32";
    currentHeightClassName = "h-12";
    currentLoadingSize = 18;
  } else if (size === "unlimited") {
    currentLoadingSize = 14;
    currentHeightClassName = "h-8";
  }

  return (
    <button
      className={`border border-catchup-blue-500 bg-catchup-blue-500 text-catchup-white rounded-catchup-button ${
        loading
          ? ""
          : disabled
          ? "opacity-50"
          : "hover:bg-catchup-blue-700 active:bg-catchup-blue-500"
      } transition duration-300 ${currentWidthClassName} ${currentHeightClassName}`}
      onClick={internalOnClick}
    >
      {loading ? (
        <BaseLoading
          height={currentLoadingSize}
          width={currentLoadingSize}
          color="#ffffff"
          secondaryColor="#ffffff"
          hideText={true}
        />
      ) : (
        <div className="flex flex-row justify-center items-center gap-x-2 px-4">
          {iconPosition === "left" ? (
            <BaseImage
              src="/icons/primary-button-arrow-left.png"
              alt="primary-button-arrow-left"
              size="xsmall"
            />
          ) : null}
          {iconOnly ? null : <p className="">{title}</p>}
          {textOnly || iconPosition === "left" ? null : (
            <BaseImage
              src="/icons/primary-button-arrow-right.png"
              alt="primary-button-arrow-right"
              size="xsmall"
            />
          )}
        </div>
      )}
    </button>
  );
};

export default PrimaryButton;
