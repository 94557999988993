import { useParams } from "react-router-dom";
import FullCard from "../../components/cards/FullCard";
import i18n from "../../language/i18n";
import { useAuth } from "../../context/UserContextProvider";
import { Tooltip } from "react-tooltip";
import BaseImage from "../../components/images/BaseImage";
import OutcomeDetail from "../../components/outcomes/OutcomeDetail";
import BaseLoading from "../../components/loading/BaseLoading";

const CatchxamReportScreen = () => {
  const { catchxamReportId } = useParams();
  const {
    language,
    userInformation,
    userProfile,
    userProfileSeason,
    userProfileGrade,
    userProfileBranch,
    learnerCatchxamReportList,
    learnerCatchxamReportListLoading,
  } = useAuth();

  const retrieveLearnerCatchxamReport = () => {
    return learnerCatchxamReportList.find(
      (learnerCatchxamReport) =>
        learnerCatchxamReport.id === parseFloat(catchxamReportId)
    );
  };

  const calculateScore = () => {
    const learnerCatchxamReport = retrieveLearnerCatchxamReport();
    if (learnerCatchxamReport) {
      return (
        learnerCatchxamReport.score /
        learnerCatchxamReport.catchxamApplicationDTO.catchxamDTO.activityDTOList
          .length
      );
    }
    return 0;
  };

  const RenderDividerLine = () => {
    return <div className="bg-catchup-light-gray h-[2px] w-full" />;
  };

  const RenderSingleKeyValueItem = (key, value, isAtBottom) => {
    return (
      <div className="flex flex-row ">
        <div
          className={`${
            isAtBottom ? "" : "border-b border-b-catchup-white"
          } w-[200px] border border-catchup-dark-blue bg-catchup-dark-blue text-catchup-white`}
        >
          <p className="p-2">{key}</p>
        </div>
        <div className="flex-1 border border-catchup-dark-blue">
          <p className="p-2">{value}</p>
        </div>
      </div>
    );
  };

  const RenderActivityAnswerList = () => {
    const learnerCatchxamReport = retrieveLearnerCatchxamReport();
    if (learnerCatchxamReport) {
      return (
        <div className="flex flex-row flex-wrap justify-center items-center">
          <Tooltip id="correct-incorrect-answer" />
          {learnerCatchxamReport.catchxamUserDTO.activityTemplateScoreDTOList.map(
            (activityActivityTemplateScoreDTO, index) => {
              const foundAnswer = JSON.parse(
                activityActivityTemplateScoreDTO.data
              ).find((answerData) => answerData.type === "MCSA");
              let correctAnswer;
              let learnerAnswer;
              if (foundAnswer) {
                correctAnswer = Object.keys(foundAnswer.answerMap)[0];
                learnerAnswer = foundAnswer.answerMap[correctAnswer];
              }
              return (
                <div className="flex flex-col m-2">
                  <div className="flex flex-col justify-center items-center">
                    <div className="my-1">
                      <p>{`${index + 1}`}</p>
                    </div>
                    {activityActivityTemplateScoreDTO.score === 1 ? (
                      <div className="my-1">
                        <BaseImage
                          src="/icons/checkbox.png"
                          alt="checkbox"
                          size="small"
                        />
                      </div>
                    ) : (
                      <div className="my-1">
                        <BaseImage
                          src="/icons/cross-red.png"
                          alt="info"
                          size="small"
                          dataTooltipId="correct-incorrect-answer"
                          dataTooltipContent={`${i18n.t(
                            "correct_answer"
                          )}: ${correctAnswer}, ${i18n.t(
                            "learner_answer"
                          )}: ${learnerAnswer}`}
                          dataTooltipPlace="top"
                          dataTooltipVariant="info"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          )}
        </div>
      );
    }
    return null;
  };

  const RenderMainContent = () => {
    const learnerCatchxamReport = retrieveLearnerCatchxamReport();
    if (learnerCatchxamReport) {
      return (
        <div className="h-full mx-4 py-4">
          <FullCard>
            <div className="w-full mx-auto">
              <div className="w-full flex flex-col my-5">
                <div className="flex flex-row flex-wrap">
                  <div className="w-full">
                    {RenderSingleKeyValueItem(
                      i18n.t("learner_name"),
                      `${userInformation.firstName} ${userInformation.lastName}`
                    )}
                  </div>
                </div>
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/2">
                    {RenderSingleKeyValueItem(
                      i18n.t("branch_name"),
                      `${
                        userProfile.branchDTOList.length > 0
                          ? `${userProfileBranch.name} / ${userProfileGrade.name}`
                          : null
                      }`
                    )}
                  </div>
                  <div className="w-full lg:w-1/2">
                    {RenderSingleKeyValueItem(
                      i18n.t("institution_name"),
                      `${
                        userProfile.branchDTOList.length > 0
                          ? `${userProfileSeason.institutionDTO.name}`
                          : null
                      }`
                    )}
                  </div>
                </div>
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/2">
                    {RenderSingleKeyValueItem(
                      i18n.t("catchxam_name"),
                      `${learnerCatchxamReport.catchxamApplicationDTO.catchxamDTO.name}`
                    )}
                  </div>
                  <div className="w-full lg:w-1/2">
                    {RenderSingleKeyValueItem(
                      i18n.t("coterie_name"),
                      `${i18n.t(
                        learnerCatchxamReport.catchxamApplicationDTO.catchxamDTO
                          .coterieType
                      )}`
                    )}
                  </div>
                </div>
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/2">
                    {RenderSingleKeyValueItem(
                      i18n.t("start_end_date"),
                      `${new Date(
                        learnerCatchxamReport.catchxamApplicationDTO.beginAt
                      ).toLocaleString(language)} - ${new Date(
                        learnerCatchxamReport.catchxamApplicationDTO.endAt
                      ).toLocaleString(language)}`
                    )}
                  </div>
                  <div className="w-full lg:w-1/2">
                    {RenderSingleKeyValueItem(
                      i18n.t("score"),
                      `${(calculateScore() * 100).toFixed(2)}%`
                    )}
                  </div>
                </div>
              </div>

              {RenderDividerLine()}
              {RenderActivityAnswerList()}
              {RenderDividerLine()}

              <div className="w-full flex flex-col my-5 text-sm">
                {learnerCatchxamReport.categoryActivityAnswerDTOList.map(
                  (categoryActivityAnswerDTO) => (
                    <OutcomeDetail
                      categoryActivityAnswerDTO={categoryActivityAnswerDTO}
                    />
                  )
                )}
              </div>
            </div>
          </FullCard>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="h-full flex flex-col">
      <div className="h-full">
        {learnerCatchxamReportListLoading ? (
          <BaseLoading size="large" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          RenderMainContent()
        )}
      </div>
    </div>
  );
};

export default CatchxamReportScreen;
