import { useEffect, useRef, useState } from "react";

const BaseBadge = (props) => {
  const { earnedBadgeDTO, blur, size } = props;
  const { badgeDTO, level } = earnedBadgeDTO;
  const { coterieType, sourceType, badgeType } = badgeDTO;
  const imageCategoryRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!imageCategoryRef) return;
    if (!imageCategoryRef.current) return;
    if (!loaded) return;
    const { offsetWidth, offsetHeight } = imageCategoryRef.current;
    setWidth(offsetWidth);
    setHeight(offsetHeight);
  }, [imageCategoryRef, loaded]);

  const constructSourceTypeUrl = (sourceType, badgeType) => {
    let currentSourceType = sourceType;
    if (
      badgeType === "CORRECT" ||
      badgeType === "REVIEW" ||
      badgeType === "TIME_SPENT"
    ) {
      currentSourceType = `${currentSourceType}-${badgeType}`;
    }
    currentSourceType = currentSourceType.toLowerCase().replace("_", "-");
    return `/badges/source-type-${currentSourceType}.png`;
  };

  const constructCoterieTypeUrl = (coterieType) => {
    let currentCoterieType = coterieType;
    if (currentCoterieType === "MANAGEMENT") {
      currentCoterieType = "TOTAL";
    }
    currentCoterieType = currentCoterieType.toLowerCase().replace("_", "-");
    return `/badges/coterie-type-${currentCoterieType}.png`;
  };

  const constructBadgeTypeUrl = (sourceType, badgeType, coterieType, level) => {
    const currentBadgeType = badgeType.toLowerCase().replace("_", "-");
    let currentSourceType = sourceType.toLowerCase().replace("_", "-");
    let currentLevel = level;
    if (sourceType !== "LOGIN") {
      if (coterieType === "MANAGEMENT") {
        if (badgeType === "COUNT") {
          currentSourceType += "-total";
        }

        currentLevel = currentLevel + 1;
      }
    }

    return `/badges/badge-type-${currentBadgeType}-${currentSourceType}-level-${currentLevel}.png`;
  };

  const constructDegreeUrl = (level) => {
    return `/badges/degree-level-${level}.png`;
  };

  let className = "";

  if (size === "xsmall") {
    className = "w-4";
  } else if (size === "small") {
    className = "w-6";
  } else if (size === "medium") {
    className = "w-8";
  } else if (size === "large") {
    className = "w-10";
  } else if (size === "xlarge") {
    className = "w-12";
  } else if (size === "2xlarge") {
    className = "w-16";
  } else if (size === "3xlarge") {
    className = "w-20";
  } else if (size === "4xlarge") {
    className = "w-24";
  } else if (size === "5xlarge") {
    className = "w-32";
  } else if (size === "6xlarge") {
    className = "w-40";
  } else if (size === "7xlarge") {
    className = "w-48";
  } else if (size === "8xlarge") {
    className = "w-56";
  }

  const sourceTypeUrl = constructSourceTypeUrl(sourceType, badgeType);
  const coterieTypeUrl = constructCoterieTypeUrl(coterieType);
  const badgeTypeUrl = constructBadgeTypeUrl(
    sourceType,
    badgeType,
    coterieType,
    level
  );
  const degreeUrl = constructDegreeUrl(level);

  const handleOnLoad = () => {
    setLoaded(true);
  };

  return (
    <div
      className={`relative shadow-badge rounded-catchup-medium z-0 ml-1 ${
        blur ? "blur-sm" : ""
      }`}
    >
      <img
        ref={imageCategoryRef}
        src={sourceTypeUrl}
        onLoad={handleOnLoad}
        className={`${className}`}
        alt="badge-source-type"
      />
      <div
        className="absolute z-10"
        style={{ width: width * 0.6, left: width * 0.35, top: height * 0.4 }}
      >
        <img
          src={coterieTypeUrl}
          className="w-full h-full"
          alt="badge-coterie-type"
        />
      </div>
      <div
        className="absolute z-10"
        style={{ width: width * 0.5, left: width * 0.05, top: height * 0.25 }}
      >
        <img src={degreeUrl} className="w-full h-full" alt="badge-degree" />
      </div>
      <div
        className="absolute z-20"
        style={{
          height: height * 0.3,
          top: height * 0.05,
          right: 0,
        }}
      >
        <img src={badgeTypeUrl} className="w-full h-full" alt="badge-level" />
      </div>
    </div>
  );
};

export default BaseBadge;
