import { useEffect, useState } from "react";
import i18n from "../../language/i18n";
import { retrieveMonthNameByIndex } from "../../utilization/DateUtilization";
import DividerLine from "../divider/DividerLine";
import BlueVerticalDividerLine from "../divider/BlueVerticalDividerLine";
import { useAuth } from "../../context/UserContextProvider";
import InputGroup from "../groups/InputGroup";
import BaseTitle from "../titles/BaseTitle";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import {
  queryCustomPlanByParams,
  queryPlanListByParams,
} from "../../requests/PlanRequests";
import { queryMainFileByIdList } from "../../requests/StorageRequests";
import { retrieveDistintCoterieTypeFromCatchtivityApplicationDTO } from "../../utilization/CatchtivityUtilization";
import BaseImage from "../images/BaseImage";
import SelectionTab from "../../components/tabs/SelectionTab";
import BaseLoading from "../loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const PlanCardView = (props) => {
  const { isMinimized } = props;
  const {
    catchtivityApplicationDTOList,
    userProfileSeason,
    userProfileGrade,
    categorySet,
  } = useAuth();
  const [availablePlanList, setAvailablePlanList] = useState([]);
  const [plan, setPlan] = useState(null);
  const [canQueryPlan, setCanQueryPlan] = useState(false);
  const [selectedVersionCode, setSelectedVersionCode] =
    useState("DEFAULT_OPTION");
  const [selectedCoterieType, setSelectedCoterieType] =
    useState("DEFAULT_OPTION");
  const [documentMap, setDocumentMap] = useState({});
  const [canQueryDocuments, setCanQueryDocuments] = useState(false);
  const [planWeekState, setPlanWeekState] = useState("PRESENT");
  const [loadingMap, setLoadingMap] = useState({
    plan: false,
    documentPlanWeekMap: {},
  });

  useEffect(() => {
    const plansCoterieType = localStorage.getItem("plansCoterieType");
    if (!plansCoterieType) return;
    setSelectedCoterieType(plansCoterieType);
  }, []);

  useEffect(() => {
    retriveAvailablePlanList();
  }, []);

  useEffect(() => {
    if (
      selectedCoterieType !== "DEFAULT_OPTION" &&
      selectedVersionCode !== "DEFAULT_OPTION"
    ) {
      setCanQueryPlan(true);
    } else {
      setCanQueryPlan(false);
    }
  }, [selectedCoterieType, selectedVersionCode]);

  useEffect(() => {
    if (canQueryPlan) {
      retrieveAndSetPlan();
    } else {
      setPlan(null);
    }
  }, [canQueryPlan]);

  useEffect(() => {
    if (plan && plan !== "NOT_FOUND") {
      const currentPlanWeekLoadingMap = {};
      plan.planWeekDTOList.forEach((planWeek) => {
        currentPlanWeekLoadingMap[planWeek.id] = true;
      });
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        documentPlanWeekMap: currentPlanWeekLoadingMap,
      }));
      setCanQueryDocuments(true);
    }
  }, [plan]);

  useEffect(() => {
    if (canQueryDocuments) {
      const documentMapPromises = Object.keys(
        loadingMap.documentPlanWeekMap
      ).map(async (loadingMapPlanWeekId) => {
        if (loadingMap.documentPlanWeekMap[loadingMapPlanWeekId]) {
          const planWeekId = parseFloat(loadingMapPlanWeekId);
          const foundPlanWeek = plan.planWeekDTOList.find(
            (planWeekDTO) => planWeekDTO.id === planWeekId
          );
          const documentIdList = foundPlanWeek.planWeekDocumentDTOList.map(
            (planWeekDocument) => planWeekDocument.documentId
          );
          if (documentIdList.length > 0) {
            const { data, err } = await queryMainFileByIdList({
              idList: documentIdList,
            });
            if (err) {
              console.log(err);
            } else {
              setDocumentMap((prevDocumentMap) => ({
                ...prevDocumentMap,
                [planWeekId]: data,
              }));
            }
          } else {
            setDocumentMap((prevDocumentMap) => ({
              ...prevDocumentMap,
              [planWeekId]: [],
            }));
          }
          setCanQueryDocuments(false);
          setLoadingMap((prevLoadingMap) => ({
            ...prevLoadingMap,
            documentPlanWeekMap: {
              ...prevLoadingMap.documentPlanWeekMap,
              [planWeekId]: false,
            },
          }));
        }
      });
      Promise.all(documentMapPromises);
    }
  }, [canQueryDocuments]);

  useEffect(() => {
    if (selectedCoterieType === "DEFAULT_OPTION") {
      setSelectedVersionCode("DEFAULT_OPTION");
    } else {
      localStorage.setItem("plansCoterieType", selectedCoterieType);
      const filteredCategoryCodeOptionList =
        filterCategoryVersionCodeOptionList();
      if (filteredCategoryCodeOptionList.length === 1) {
        const plansVersionCode = filteredCategoryCodeOptionList[0].value;
        setSelectedVersionCode(plansVersionCode);
        localStorage.setItem("plansVersionCode", plansVersionCode);
      } else {
        const plansVersionCode = localStorage.getItem("plansVersionCode");
        setSelectedVersionCode(plansVersionCode);
      }
    }
  }, [selectedCoterieType]);

  const retriveAvailablePlanList = async () => {
    const { data, err } = await queryCustomPlanByParams({
      brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
      campusId: userProfileSeason.institutionDTO.campusDTO.id,
      institutionId: userProfileSeason.institutionDTO.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
    });
    if (err) {
      console.log(err);
      return;
    }
    setAvailablePlanList(data);
  };

  const retrieveAndSetPlan = async () => {
    setLoadingMap({
      plan: true,
      documentPlanWeekMap: {},
    });
    let queryParams = {
      brandId: userProfileSeason.institutionDTO.campusDTO.brandDTO.id,
      campusId: userProfileSeason.institutionDTO.campusDTO.id,
      institutionId: userProfileSeason.institutionDTO.id,
      seasonId: userProfileSeason.id,
      gradeId: userProfileGrade.id,
      coterieType: selectedCoterieType,
      versionCode: selectedVersionCode,
    };
    const { data, err } = await queryPlanListByParams(queryParams);
    if (err) {
      console.log(err);
    } else {
      if (data.length > 0) {
        const foundPlan = data.shift();
        setPlan(foundPlan);
      } else {
        setPlan("NOT_FOUND");
      }
    }
    setLoadingMap({
      plan: false,
      documentPlanWeekMap: {},
    });
  };

  const filterCategoryVersionCodeOptionList = () => {
    const filteredPlanList = availablePlanList.filter(
      (plan) => plan.coterieType === selectedCoterieType
    );
    if (filteredPlanList.length === 0) return [];
    return filteredPlanList.map((plan) => ({
      text: i18n.t(plan.versionCode),
      value: plan.versionCode,
    }));
  };

  const filterCoterieTypeOptionBasedOnAvailablePlanList = (
    distinctCoterieTypeOptionList
  ) => {
    const filteredCoterieTypeOptionList = [];
    const filteredAvailablePlanList = availablePlanList.filter((plan) =>
      distinctCoterieTypeOptionList.includes(plan.coterieType)
    );
    filteredAvailablePlanList.forEach((plan) => {
      const foundIndex = filteredCoterieTypeOptionList.findIndex(
        (coterieTypeOption) => coterieTypeOption.value === plan.coterieType
      );
      if (foundIndex === -1) {
        filteredCoterieTypeOptionList.push({
          text: i18n.t(plan.coterieType),
          value: plan.coterieType,
        });
      }
    });
    return filteredCoterieTypeOptionList;
  };

  const constructWeekName = (beginDate, endDate) => {
    if (beginDate.getFullYear() !== endDate.getFullYear()) {
      return `${beginDate.getDate()} ${retrieveMonthNameByIndex(
        beginDate.getMonth()
      )} ${beginDate.getFullYear()} - ${
        endDate.getDate() - 2
      } ${retrieveMonthNameByIndex(
        endDate.getMonth()
      )} ${endDate.getFullYear()}`;
    } else {
      if (beginDate.getMonth() !== endDate.getMonth()) {
        return `${beginDate.getDate()} ${retrieveMonthNameByIndex(
          beginDate.getMonth()
        )} - ${endDate.getDate()} ${retrieveMonthNameByIndex(
          endDate.getMonth()
        )} ${endDate.getFullYear()}`;
      } else {
        return `${beginDate.getDate()} - ${
          endDate.getDate() - 2
        } ${retrieveMonthNameByIndex(
          endDate.getMonth()
        )} ${endDate.getFullYear()}`;
      }
    }
  };

  const handleOnChange = (type, value) => {
    setCanQueryPlan(false);
    if (type === "selectedCoterieType") {
      setSelectedVersionCode("DEFAULT_OPTION");
      setSelectedCoterieType(value);
    } else if (type === "selectedVersionCode") {
      setSelectedVersionCode(value);
    }
  };

  const RenderPlanWeekEmptyCategoryContent = () => {
    return (
      <div className="mb-2">
        <div className="flex flex-row items-center gap-x-2 opacity-70">
          <BaseImage src="/icons/outcome.png" alt="outcome" size="small" />
          <div className="flex-1">
            <p className="">{i18n.t("empty_plan_week_category_text")}</p>
          </div>
        </div>
      </div>
    );
  };

  const RenderPlanWeekDocumentDTOItem = (documentDTOList, planWeekId) => {
    return (
      <div className="w-full flex flex-row items-center flex-wrap gap-x-3">
        {documentDTOList.map((document, index) => {
          const { path, fileName } = document;
          return (
            <div key={index} className="flex flex-row items-center gap-x-1">
              <div className="cursor-pointer underline">
                <a
                  href={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
                  target={"_blank"}
                >
                  {document.fileOriginalName}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderPlanWeekCategoryContent = (planWeekCategoryDTOList) => {
    let categoryIdList = planWeekCategoryDTOList.map(
      (planWeekCategory) => planWeekCategory.categoryId
    );
    return (
      <div className="mb-2">
        <div className="">
          <p className="font-bold">{i18n.t("plan_week_outcomes")}</p>
        </div>
        {categorySet
          .filter((categoryDTO) =>
            categoryIdList.includes(parseFloat(categoryDTO.id))
          )
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((categoryDTO, index) => {
            return (
              <div key={index} className="flex flex-1 flex-row">
                <div>
                  <p className="">
                    <span className={`text-catchup-blue font-bold mr-2`}>
                      {categoryDTO.code}
                    </span>
                    {categoryDTO.name}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const RenderPlanWeekContent = (planWeek, weekIndex, index) => {
    const beginDate = new Date(planWeek.beginAt);
    const endDate = new Date(planWeek.endAt);

    return (
      <>
        {new Date().getTime() > planWeek.endAt ? (
          planWeekState === "ALL" ? (
            index === 0 ? null : (
              <div className="mb-4 flex flex-col items-center justify-center ">
                <BlueVerticalDividerLine opacity="medium" />
              </div>
            )
          ) : null
        ) : index === 0 ? null : (
          <div className="mb-4 flex flex-col items-center justify-center ">
            <BlueVerticalDividerLine opacity="medium" />
          </div>
        )}

        <div
          className={`${
            planWeekState === "ALL"
              ? ""
              : new Date().getTime() > planWeek.endAt
              ? "hidden"
              : ""
          } ${
            new Date().getTime() > planWeek.beginAt &&
            new Date().getTime() < planWeek.endAt
              ? "border-4 border-catchup-green rounded-catchup-xlarge"
              : "rounded-catchup-xlarge border-opacity-50"
          } transition-all duration-300 my-2 relative bg-catchup-plan-item text-catchup-gray-400 p-3`}
        >
          <div className="flex flex-1 flex-col">
            <div className="flex flex-1 flex-row items-center justify-between">
              <div className="">
                <p className="font-semibold text-lg">
                  {weekIndex}. {i18n.t("week")}
                  <span className="font-normal px-2">
                    {constructWeekName(beginDate, endDate)}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex flex-1 flex-row gap-x-5 mt-5">
              <div className="flex-1">
                {planWeek.planWeekCategoryDTOList.length === 0
                  ? RenderPlanWeekEmptyCategoryContent()
                  : RenderPlanWeekCategoryContent(
                      planWeek.planWeekCategoryDTOList
                    )}
              </div>
            </div>
          </div>
          <div className="">
            <DividerLine />
            {loadingMap.documentPlanWeekMap[planWeek.id] ? (
              <BaseLoading
                size="small"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : documentMap[planWeek.id] ? (
              documentMap[planWeek.id].length === 0 ? (
                <div className="flex flex-row items-center gap-x-2 opacity-70">
                  <BaseImage
                    src="/icons/document.png"
                    alt="document"
                    size="small"
                  />
                  <div className="flex-1">
                    <p className="">
                      {i18n.t("empty_plan_week_document_text")}
                    </p>
                  </div>
                </div>
              ) : (
                RenderPlanWeekDocumentDTOItem(
                  documentMap[planWeek.id],
                  planWeek.id
                )
              )
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const RenderPlanWeekHolidayContent = (planWeek, index) => {
    const beginDate = new Date(planWeek.beginAt);
    const endDate = new Date(planWeek.endAt);
    return (
      <>
        {new Date().getTime() > planWeek.endAt ? (
          planWeekState === "ALL" ? (
            index === 1 ? null : (
              <div className="mb-4 flex flex-col items-center justify-center ">
                <BlueVerticalDividerLine opacity="medium" />
              </div>
            )
          ) : null
        ) : index === 1 ? null : (
          <div className="mb-4 flex flex-col items-center justify-center ">
            <BlueVerticalDividerLine opacity="medium" />
          </div>
        )}
        <div
          className={`${
            planWeekState === "ALL"
              ? ""
              : new Date().getTime() > planWeek.endAt
              ? "hidden"
              : ""
          } rounded-catchup-xlarge border-opacity-50 transition-all duration-300 my-2 relative bg-catchup-blue-50 text-catchup-gray-400`}
        >
          <div className="flex flex-1 flex-col p-3">
            <div className="flex flex-1 flex-row items-center justify-between">
              <div className="">
                <p className="font-semibold text-lg">
                  {i18n.t("week")}
                  <span className="font-normal px-2">
                    {constructWeekName(beginDate, endDate)}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex flex-1 flex-row gap-x-5 mt-5">
              <div className="flex-1 flex flex-col justify-center items-center">
                <BaseImage
                  src="/icons/holiday.png"
                  alt="holiday"
                  size="xlarge"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderPlanNotFoundContent = () => {
    return (
      <>
        {selectedVersionCode !== "DEFAULT_OPTION" ? (
          <>
            <DividerLine />
            <div className="flex justify-center items-center my -5">
              <BaseTitle title={i18n.t("plan_not_found")} />
            </div>
          </>
        ) : null}
      </>
    );
  };

  const RenderPlanFoundContent = () => {
    plan.planWeekDTOList.sort((a, b) => a.id - b.id);
    let weekIndex = 0;
    return (
      <>
        {plan.planWeekDTOList.map((planWeekDTO, index) => {
          if (planWeekDTO.isHoliday) {
            return (
              <div key={index}>
                {RenderPlanWeekHolidayContent(planWeekDTO, index)}
              </div>
            );
          } else {
            weekIndex++;
            return (
              <div key={index}>
                {RenderPlanWeekContent(planWeekDTO, weekIndex, index)}
              </div>
            );
          }
        })}
      </>
    );
  };

  const RenderMinimizedPlanFoundContent = () => {
    let weekIndex = 0;
    plan.planWeekDTOList.sort((a, b) => a.id - b.id);
    const previousPlanWeekDTOList = plan.planWeekDTOList.filter(
      (planWeekDTO) => new Date().getTime() > planWeekDTO.endAt
    );
    if (previousPlanWeekDTOList.length > 0) {
      weekIndex = previousPlanWeekDTOList.length - 1;
    }

    const filteredPlanWeekDTOList = plan.planWeekDTOList
      .filter((planWeekDTO) => new Date().getTime() <= planWeekDTO.endAt)
      .slice(0, 2);
    return (
      <>
        {filteredPlanWeekDTOList
          .sort((a, b) => a.id - b.id)
          .map((planWeekDTO, index) => {
            if (planWeekDTO.isHoliday) {
              return (
                <div key={index}>
                  {RenderPlanWeekHolidayContent(planWeekDTO, index)}
                </div>
              );
            } else {
              weekIndex++;
              return (
                <div key={index}>
                  {RenderPlanWeekContent(planWeekDTO, weekIndex, index)}
                </div>
              );
            }
          })}
      </>
    );
  };

  const RenderPlanContent = () => {
    if (canQueryPlan) {
      return (
        <>
          {loadingMap.plan ? (
            <BaseLoading
              size="large"
              color="#57C2D3"
              secondaryColor="#57C2D3"
            />
          ) : plan ? (
            plan === "NOT_FOUND" ? (
              RenderPlanNotFoundContent()
            ) : isMinimized ? (
              RenderMinimizedPlanFoundContent()
            ) : (
              RenderPlanFoundContent()
            )
          ) : null}
        </>
      );
    }
    return null;
  };

  const distinctCoterieTypeOptionList =
    retrieveDistintCoterieTypeFromCatchtivityApplicationDTO(
      catchtivityApplicationDTOList
    );
  const filteredCoterieTypeOptionList =
    filterCoterieTypeOptionBasedOnAvailablePlanList(
      distinctCoterieTypeOptionList
    );

  if (isMinimized) {
    return (
      <div className="w-full">
        <div className="flex flex-col">
          <InputGroup
            type="select"
            value={selectedCoterieType}
            optionList={filteredCoterieTypeOptionList}
            onChange={(event) => {
              handleOnChange("selectedCoterieType", event.target.value);
            }}
          />
          {selectedCoterieType !== "DEFAULT_OPTION" ? (
            <InputGroup
              type="select"
              value={selectedVersionCode}
              optionList={filterCategoryVersionCodeOptionList()}
              onChange={(event) => {
                handleOnChange("selectedVersionCode", event.target.value);
              }}
            />
          ) : null}
          {RenderPlanContent()}
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-full">
        <div className="flex-1 flex flex-row items-center flex-wrap">
          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
            <div className="mx-2">
              <InputGroup
                type="select"
                title={i18n.t("coterie_type")}
                placeholder={i18n.t("coterie_type")}
                value={selectedCoterieType}
                optionList={filteredCoterieTypeOptionList}
                onChange={(event) => {
                  handleOnChange("selectedCoterieType", event.target.value);
                }}
              />
            </div>
          </div>
          {selectedCoterieType !== "DEFAULT_OPTION" ? (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="mx-2">
                <InputGroup
                  type="select"
                  title={i18n.t("version_code")}
                  placeholder={i18n.t("version_code")}
                  value={selectedVersionCode}
                  optionList={filterCategoryVersionCodeOptionList()}
                  onChange={(event) => {
                    handleOnChange("selectedVersionCode", event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        {plan ? (
          <SelectionTab
            optionList={[
              {
                id: "PRESENT",
                title: i18n.t("present"),
              },
              { id: "ALL", title: i18n.t("all_weeks") },
            ]}
            selectedId={planWeekState}
            handleSelectOnClick={(selectedId) => {
              setPlanWeekState(selectedId);
            }}
          />
        ) : null}

        {RenderPlanContent()}
      </div>
    );
  }
};

export default PlanCardView;
