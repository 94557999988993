import { useEffect } from "react";
import { useApp } from "../../context/AppContextProvider";
import FullCard from "../../components/cards/FullCard";
import PlanCardView from "../../components/plans/PlanCardView";
import DashboardHeader from "../../components/headers/DashboardHeader";
import i18n from "../../language/i18n";

const PlanListScreen = () => {
  const { isFullScreen, setIsFullScreen } = useApp();

  useEffect(() => {
    if (isFullScreen === true) {
      setIsFullScreen(false);
    }
  }, []);

  const RenderMainContent = () => {
    return (
      <FullCard>
        <DashboardHeader title={i18n.t("syllabus")} />
        <PlanCardView />
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default PlanListScreen;
