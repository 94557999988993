const BaseCard = (props) => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="bg-catchup-white rounded-catchup-xlarge h-catchup-card w-catchup-card shadow-dropdown">
        <div className="flex flex-col mx-auto p-8 h-full">{props.children}</div>
      </div>
    </div>
  );
};

export default BaseCard;
