const CoterieLabel = (props) => {
  const { title } = props;
  return (
    <div className="px-3 py-1 gap-x-3 border border-brand-label-border bg-brand-label text-brand-label-text rounded-catchup-3xlarge">
      <p className="text-md">{title}</p>
    </div>
  );
};

export default CoterieLabel;
