import {
  parseBodyMapFromData,
  parseContentMapFromData,
} from "../../utilization/CatchtivityUtilization";
import VerticalDividerLine from "../divider/VerticalDividerLine";
import DividerLine from "../divider/DividerLine";
import ActivityBodyContent from "./body-content/ActivityBodyContent";
import OpenEndedActivityMaterialContent from "./material-content/OpenEndedActivityMaterialContent";

const OpenEndedActivityContent = (props) => {
  const {
    answer,
    data,
    canAnswerQuestion,
    changeAnswer,
    userId,
    userProfileId,
    catchtivityApplicationId,
    catchxamApplicationId,
    etudeId,
    activityId,
    activityTemplateId,
    showMaterialContent,
    storageStompClient,
  } = props;
  const contentMap = parseContentMapFromData(data);
  const openEndedBodyMap = parseBodyMapFromData(data, "OPEN_ENDED");
  // const openEndedMaterialMap = JSON.parse(data.openEndedMaterialMap);

  const handleOpenEndedAnswerOnChange = (answer, value) => {
    let foundIndex = answer.data.findIndex(
      (answerData) => answerData.type === "OPEN_ENDED"
    );
    const answerMap = answer.data[foundIndex].answerMap;
    answerMap["ANSWER"] = value;
    changeAnswer(answer);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div
        className={`${showMaterialContent ? "w-full md:w-[40%]" : "w-full"}`}
      >
        <ActivityBodyContent
          bodyMap={openEndedBodyMap}
          templateType={"OPEN_ENDED"}
        />
      </div>

      {showMaterialContent ? (
        <>
          <div className="contents md:hidden">
            <DividerLine />
          </div>
          <div className="hidden md:block">
            <VerticalDividerLine />
          </div>
          <div className="w-full md:flex-1">
            <OpenEndedActivityMaterialContent
              answer={answer}
              bodyMap={openEndedBodyMap}
              contentMap={contentMap}
              checkCanAnswerQuestion={canAnswerQuestion}
              onChange={handleOpenEndedAnswerOnChange}
              userId={userId}
              userProfileId={userProfileId}
              catchtivityApplicationId={catchtivityApplicationId}
              catchxamApplicationId={catchxamApplicationId}
              etudeId={etudeId}
              activityId={activityId}
              activityTemplateId={activityTemplateId}
              storageStompClient={storageStompClient}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OpenEndedActivityContent;
