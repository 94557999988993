import { Component, createContext, useContext } from "react";
import {
  removeAxiosInterceptor,
  setAxiosInterceptor,
} from "../utilization/AxiosUtilization";
import {
  createActivityReviewByActivityIdCatchtivityApplicationId,
  createActivityReviewByActivityIdCatchxamApplicationId,
  createActivityReviewByActivityIdStandardExamApplicationId,
} from "../requests/CatchtivityRequests";

export const UserContext = createContext();

class UserContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInformation: null,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "TR",
      categorySet: [],
      userProfileSet: [],
      userProfile: null,
      userProfileSeason: null,
      userProfileGrade: null,
      userProfileBranch: null,
      catchxamApplicationDTOList: [],
      catchxamApplicationListLoading: true,
      catchtivityApplicationDTOList: [],
      catchtivityApplicationListLoading: true,
      standardExamApplicationDTOList: [],
      standardExamApplicationListLoading: true,
      activeContentList: [],
      passiveContentList: [],
      etudeDTOList: [],
      etudeListLoading: true,
      activeEtudeList: [],
      passiveEtudeList: [],
      learnerPerformanceLoading: true,
      learnerPerformance: null,
      learnerCatchxamReportListLoading: true,
      learnerCatchxamReportList: [],
      reportDTOList: [],
      reportListLoading: true,
      individualModelList: [],
      individualModelLoading: true,
      numberOfUnreadAnnouncements: 0,
      contestList: [],
      contestListLoading: true,
      personalStatus: {
        remainingPoints: 0,
        totalPoints: 0,
        seasonRank: 0,
        branchRank: 0,
      },
      myWrongActivityDTOList: [],
      myWrongActivityListLoading: true,
      activityReviewDTOList: [],
      activityReviewListLoading: true,
      thisWeekRankingList: [],
      thisWeekRankingListLoading: true,
      allTimeRankingList: [],
      allTimeRankingListLoading: true,
      earnedBadgeDTOList: [],
      earnedBadgeDTOListLoading: true,
    };
    this.handleStateLogin = this.handleStateLogin.bind(this);
    this.handleStateLogout = this.handleStateLogout.bind(this);
    this.handleStateChooseProfile = this.handleStateChooseProfile.bind(this);
    this.handleStateChangeProfile = this.handleStateChangeProfile.bind(this);
    this.handleUpdateAndSetActivityReviewList =
      this.handleUpdateAndSetActivityReviewList.bind(this);
    this.setUserInformation = this.setUserInformation.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setCategorySet = this.setCategorySet.bind(this);
    this.setUserProfileSet = this.setUserProfileSet.bind(this);
    this.setUserProfile = this.setUserProfile.bind(this);
    this.setUserProfileSeason = this.setUserProfileSeason.bind(this);
    this.setUserProfileGrade = this.setUserProfileGrade.bind(this);
    this.setUserProfileBranch = this.setUserProfileBranch.bind(this);
    this.setCatchtivityApplicationDTOList =
      this.setCatchtivityApplicationDTOList.bind(this);
    this.setCatchtivityApplicationListLoading =
      this.setCatchtivityApplicationListLoading.bind(this);
    this.setCatchxamApplicationDTOList =
      this.setCatchxamApplicationDTOList.bind(this);
    this.setCatchxamApplicationListLoading =
      this.setCatchxamApplicationListLoading.bind(this);
    this.setStandardExamApplicationDTOList =
      this.setStandardExamApplicationDTOList.bind(this);
    this.setStandardExamApplicationListLoading =
      this.setStandardExamApplicationListLoading.bind(this);
    this.setActiveContentList = this.setActiveContentList.bind(this);
    this.setPassiveContentList = this.setPassiveContentList.bind(this);
    this.setActiveEtudeList = this.setActiveEtudeList.bind(this);
    this.setPassiveEtudeList = this.setPassiveEtudeList.bind(this);
    this.setEtudeDTOList = this.setEtudeDTOList.bind(this);
    this.setEtudeListLoading = this.setEtudeListLoading.bind(this);
    this.setLearnerPerformanceLoading =
      this.setLearnerPerformanceLoading.bind(this);
    this.setLearnerPerformance = this.setLearnerPerformance.bind(this);
    this.setLearnerCatchxamReportListLoading =
      this.setLearnerCatchxamReportListLoading.bind(this);
    this.setLearnerCatchxamReportList =
      this.setLearnerCatchxamReportList.bind(this);
    this.setReportDTOList = this.setReportDTOList.bind(this);
    this.setReportListLoading = this.setReportListLoading.bind(this);
    this.setIndividualModelList = this.setIndividualModelList.bind(this);
    this.setIndividualModelLoading = this.setIndividualModelLoading.bind(this);
    this.setNumberOfUnreadAnnouncements =
      this.setNumberOfUnreadAnnouncements.bind(this);
    this.setContestList = this.setContestList.bind(this);
    this.setContestListLoading = this.setContestListLoading.bind(this);
    this.setPersonalStatus = this.setPersonalStatus.bind(this);
    this.setMyWrongActivityListLoading =
      this.setMyWrongActivityListLoading.bind(this);
    this.setMyWrongActivityDTOList = this.setMyWrongActivityDTOList.bind(this);
    this.setActivityReviewListLoading =
      this.setActivityReviewListLoading.bind(this);
    this.setActivityReviewDTOList = this.setActivityReviewDTOList.bind(this);
    this.setThisWeekRankingList = this.setThisWeekRankingList.bind(this);
    this.setThisWeekRankingListLoading =
      this.setThisWeekRankingListLoading.bind(this);
    this.setAllTimeRankingList = this.setAllTimeRankingList.bind(this);
    this.setAllTimeRankingListLoading =
      this.setAllTimeRankingListLoading.bind(this);
    this.setEarnedBadgeDTOList = this.setEarnedBadgeDTOList.bind(this);
    this.setEarnedBadgeDTOListLoading =
      this.setEarnedBadgeDTOListLoading.bind(this);
  }

  handleStateLogin(accessToken, refreshToken, userInformation, userProfileSet) {
    if (accessToken) {
      removeAxiosInterceptor();
      setAxiosInterceptor(accessToken);
      localStorage.setItem("accessToken", accessToken);
    }
    if (userInformation) {
      localStorage.setItem("userId", userInformation.id);
      this.setUserInformation(userInformation);
    }
    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    }
    if (userProfileSet) {
      this.setUserProfileSet(userProfileSet);
    }
  }

  handleStateLogout() {
    removeAxiosInterceptor();
    this.setUserInformation(null);
    this.handleStateChangeProfile();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.setItem("appState", "LOGOUT");
  }

  handleStateChooseProfile(userProfileDTO, seasonDTO, gradeDTO, branchDTO) {
    localStorage.setItem("userProfileId", userProfileDTO.id);
    localStorage.setItem("seasonId", seasonDTO.id);
    localStorage.setItem("gradeId", gradeDTO.id);
    localStorage.setItem("branchId", branchDTO.id);
    this.setUserProfile(userProfileDTO);
    this.setUserProfileSeason(seasonDTO);
    this.setUserProfileGrade(gradeDTO);
    this.setUserProfileBranch(branchDTO);
  }

  handleStateChangeProfile() {
    localStorage.removeItem("userProfileId");
    localStorage.removeItem("seasonId");
    localStorage.removeItem("gradeId");
    localStorage.removeItem("branchId");
    localStorage.removeItem("practiseHomeworkCoterieType");
    localStorage.removeItem("practiseMyWrongActivitiesCoterieType");
    localStorage.removeItem("practiseMyWrongActivitiesMainCategoryCode");
    localStorage.removeItem("reportsCoterieType");
    localStorage.removeItem("plansCoterieType");
    localStorage.removeItem("plansVersionCode");
    this.setUserProfile(null);
    this.setUserProfileSeason(null);
    this.setUserProfileGrade(null);
    this.setUserProfileBranch(null);
  }

  async handleUpdateAndSetActivityReviewList(
    activityTemplateId,
    activityId,
    catchtivityApplicationId,
    catchxamApplicationId,
    standardExamApplicationId
  ) {
    const newActivityReview = {
      userId: this.state.userInformation.id,
      userProfileId: this.state.userProfile.id,
      branchId: this.state.userProfileBranch.id,
      gradeId: this.state.userProfileGrade.id,
      seasonId: this.state.userProfileSeason.id,
      isStarred: false,
      activityTemplateId,
      activity: {
        id: activityId,
      },
    };
    let data;
    let err;
    if (catchtivityApplicationId) {
      const { data: responseData, err: responseErr } =
        await createActivityReviewByActivityIdCatchtivityApplicationId({
          ...newActivityReview,
          catchtivityApplication: {
            id: catchtivityApplicationId,
          },
        });
      data = responseData;
      err = responseErr;
    } else if (catchxamApplicationId) {
      const { data: responseData, err: responseErr } =
        await createActivityReviewByActivityIdCatchxamApplicationId({
          ...newActivityReview,
          catchxamApplication: {
            id: catchxamApplicationId,
          },
        });
      data = responseData;
      err = responseErr;
    } else if (standardExamApplicationId) {
      const { data: responseData, err: responseErr } =
        await createActivityReviewByActivityIdStandardExamApplicationId({
          ...newActivityReview,
          standardExamApplication: {
            id: standardExamApplicationId,
          },
        });
      data = responseData;
      err = responseErr;
    }
    if (err) {
      console.log(err);
      return;
    }
    this.state.activityReviewDTOList.push(data);
    this.setActivityReviewDTOList(this.state.activityReviewDTOList);
  }

  setUserInformation(userInformation) {
    this.setState({ userInformation });
  }

  setLanguage(language) {
    this.setState({ language });
  }

  setCategorySet(categorySet) {
    this.setState({ categorySet });
  }

  setUserProfileSet(userProfileSet) {
    this.setState({ userProfileSet });
  }

  setUserProfile(userProfile) {
    this.setState({ userProfile });
  }

  setUserProfileSeason(userProfileSeason) {
    this.setState({ userProfileSeason });
  }

  setUserProfileGrade(userProfileGrade) {
    this.setState({ userProfileGrade });
  }

  setUserProfileBranch(userProfileBranch) {
    this.setState({ userProfileBranch });
  }

  setCatchtivityApplicationDTOList(catchtivityApplicationDTOList) {
    this.setState({ catchtivityApplicationDTOList });
  }

  setCatchtivityApplicationListLoading(catchtivityApplicationListLoading) {
    this.setState({ catchtivityApplicationListLoading });
  }

  setCatchxamApplicationDTOList(catchxamApplicationDTOList) {
    this.setState({ catchxamApplicationDTOList });
  }

  setCatchxamApplicationListLoading(catchxamApplicationListLoading) {
    this.setState({ catchxamApplicationListLoading });
  }

  setStandardExamApplicationDTOList(standardExamApplicationDTOList) {
    this.setState({ standardExamApplicationDTOList });
  }

  setStandardExamApplicationListLoading(standardExamApplicationListLoading) {
    this.setState({ standardExamApplicationListLoading });
  }

  setActiveContentList(activeContentList) {
    this.setState({ activeContentList });
  }

  setPassiveContentList(passiveContentList) {
    this.setState({ passiveContentList });
  }

  setEtudeDTOList(etudeDTOList) {
    this.setState({ etudeDTOList });
  }

  setEtudeListLoading(etudeListLoading) {
    this.setState({ etudeListLoading });
  }

  setActiveEtudeList(activeEtudeList) {
    this.setState({ activeEtudeList });
  }

  setPassiveEtudeList(passiveEtudeList) {
    this.setState({ passiveEtudeList });
  }

  setLearnerPerformanceLoading(learnerPerformanceLoading) {
    this.setState({ learnerPerformanceLoading });
  }

  setLearnerPerformance(learnerPerformance) {
    this.setState({ learnerPerformance });
  }

  setLearnerCatchxamReportListLoading(learnerCatchxamReportListLoading) {
    this.setState({ learnerCatchxamReportListLoading });
  }

  setLearnerCatchxamReportList(learnerCatchxamReportList) {
    this.setState({ learnerCatchxamReportList });
  }

  setReportDTOList(reportDTOList) {
    this.setState({ reportDTOList });
  }

  setReportListLoading(reportListLoading) {
    this.setState({ reportListLoading });
  }

  setIndividualModelList(individualModelList) {
    this.setState({ individualModelList });
  }

  setIndividualModelLoading(individualModelLoading) {
    this.setState({ individualModelLoading });
  }

  setNumberOfUnreadAnnouncements(numberOfUnreadAnnouncements) {
    this.setState({ numberOfUnreadAnnouncements });
  }

  setContestList(contestList) {
    this.setState({ contestList });
  }

  setContestListLoading(contestListLoading) {
    this.setState({ contestListLoading });
  }

  setPersonalStatus(personalStatus) {
    this.setState({ personalStatus });
  }

  setMyWrongActivityDTOList(myWrongActivityDTOList) {
    this.setState({ myWrongActivityDTOList });
  }

  setMyWrongActivityListLoading(myWrongActivityListLoading) {
    this.setState({ myWrongActivityListLoading });
  }

  setActivityReviewDTOList(activityReviewDTOList) {
    this.setState({ activityReviewDTOList });
  }

  setActivityReviewListLoading(activityReviewListLoading) {
    this.setState({ activityReviewListLoading });
  }

  setThisWeekRankingList(thisWeekRankingList) {
    this.setState({ thisWeekRankingList });
  }

  setThisWeekRankingListLoading(thisWeekRankingListLoading) {
    this.setState({ thisWeekRankingListLoading });
  }

  setAllTimeRankingList(allTimeRankingList) {
    this.setState({ allTimeRankingList });
  }

  setAllTimeRankingListLoading(allTimeRankingListLoading) {
    this.setState({ allTimeRankingListLoading });
  }

  setEarnedBadgeDTOList(earnedBadgeDTOList) {
    this.setState({ earnedBadgeDTOList });
  }

  setEarnedBadgeDTOListLoading(earnedBadgeDTOListLoading) {
    this.setState({ earnedBadgeDTOListLoading });
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          handleStateLogin: this.handleStateLogin,
          handleStateLogout: this.handleStateLogout,
          handleStateChooseProfile: this.handleStateChooseProfile,
          handleStateChangeProfile: this.handleStateChangeProfile,
          handleUpdateAndSetActivityReviewList:
            this.handleUpdateAndSetActivityReviewList,
          setUserInformation: this.setUserInformation,
          setLanguage: this.setLanguage,
          setUserProfileSet: this.setUserProfileSet,
          setCategorySet: this.setCategorySet,
          setUserProfile: this.setUserProfile,
          setUserProfileSeason: this.setUserProfileSeason,
          setUserProfileGrade: this.setUserProfileGrade,
          setUserProfileBranch: this.setUserProfileBranch,
          setCatchtivityApplicationDTOList:
            this.setCatchtivityApplicationDTOList,
          setCatchtivityApplicationListLoading:
            this.setCatchtivityApplicationListLoading,
          setCatchxamApplicationDTOList: this.setCatchxamApplicationDTOList,
          setCatchxamApplicationListLoading:
            this.setCatchxamApplicationListLoading,
          setStandardExamApplicationDTOList:
            this.setStandardExamApplicationDTOList,
          setStandardExamApplicationListLoading:
            this.setStandardExamApplicationListLoading,
          setActiveContentList: this.setActiveContentList,
          setPassiveContentList: this.setPassiveContentList,
          setEtudeDTOList: this.setEtudeDTOList,
          setEtudeListLoading: this.setEtudeListLoading,
          setActiveEtudeList: this.setActiveEtudeList,
          setPassiveEtudeList: this.setPassiveEtudeList,
          setLearnerPerformanceLoading: this.setLearnerPerformanceLoading,
          setLearnerPerformance: this.setLearnerPerformance,
          setLearnerCatchxamReportListLoading:
            this.setLearnerCatchxamReportListLoading,
          setLearnerCatchxamReportList: this.setLearnerCatchxamReportList,
          setReportDTOList: this.setReportDTOList,
          setReportListLoading: this.setReportListLoading,
          setIndividualModelList: this.setIndividualModelList,
          setIndividualModelLoading: this.setIndividualModelLoading,
          setNumberOfUnreadAnnouncements: this.setNumberOfUnreadAnnouncements,
          setContestList: this.setContestList,
          setContestListLoading: this.setContestListLoading,
          setPersonalStatus: this.setPersonalStatus,
          setMyWrongActivityDTOList: this.setMyWrongActivityDTOList,
          setMyWrongActivityListLoading: this.setMyWrongActivityListLoading,
          setActivityReviewDTOList: this.setActivityReviewDTOList,
          setActivityReviewListLoading: this.setActivityReviewListLoading,
          setThisWeekRankingList: this.setThisWeekRankingList,
          setThisWeekRankingListLoading: this.setThisWeekRankingListLoading,
          setAllTimeRankingList: this.setAllTimeRankingList,
          setAllTimeRankingListLoading: this.setAllTimeRankingListLoading,
          setEarnedBadgeDTOList: this.setEarnedBadgeDTOList,
          setEarnedBadgeDTOListLoading: this.setEarnedBadgeDTOListLoading,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const useAuth = () => {
  const context = useContext(UserContext);
  if (!context) throw Error("Context must be not null.");
  return context;
};

export default UserContextProvider;
