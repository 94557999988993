import { useEffect, useState } from "react";
import BaseImage from "../images/BaseImage";

const ClockWithTime = (props) => {
  const { remainingTotalSeconds, startTimer, handleOnTimerEnds } = props;
  const [timerRemainingTotalSeconds, setTimerRemainingTotalSeconds] = useState(
    remainingTotalSeconds
  );
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [isTimerEndCalledOnce, setIsTimerEndCalledOnce] = useState(false);
  let timerRemainingTotalSecondsValue = null;

  useEffect(() => {
    timerRemainingTotalSecondsValue = remainingTotalSeconds;
    setTimerRemainingTotalSeconds(timerRemainingTotalSecondsValue);
    if (timerIntervalId) {
      clearInterval(timerIntervalId);
    }
    if (startTimer) {
      const currentTimerIntervalId = setInterval(() => {
        timerRemainingTotalSecondsValue =
          timerRemainingTotalSecondsValue - 0.05;
        setTimerRemainingTotalSeconds(timerRemainingTotalSecondsValue);
      }, 50);
      setTimerIntervalId(currentTimerIntervalId);
    } else {
      if (timerIntervalId) {
        clearInterval(timerIntervalId);
        setTimerIntervalId(null);
      }
    }
  }, [remainingTotalSeconds, startTimer]);

  useEffect(() => {
    if (timerRemainingTotalSeconds < 0) {
      if (timerIntervalId) {
        clearInterval(timerIntervalId);
      }
      if (!isTimerEndCalledOnce) {
        handleOnTimerEnds(startTimer);
      }
      setIsTimerEndCalledOnce(true);
    }
  }, [timerRemainingTotalSeconds]);

  const retrieveHours = () => {
    return parseInt(timerRemainingTotalSeconds / 60 / 60);
  };

  const retrieveMinutes = (hours) => {
    const hourInSeconds = hours * 60 * 60;
    return parseInt((timerRemainingTotalSeconds - hourInSeconds) / 60);
  };

  const retrieveSeconds = (hours, minutes) => {
    const hourInSeconds = hours * 60 * 60;
    const minuteInSeconds = minutes * 60;
    return parseInt(
      timerRemainingTotalSeconds - hourInSeconds - minuteInSeconds
    );
  };

  const currentHours = retrieveHours();
  const currentMinutes = retrieveMinutes(currentHours);
  const currentSeconds = retrieveSeconds(currentHours, currentMinutes);

  return (
    <div className="flex flex-row items-center relative">
      <BaseImage
        src="/icons/clock.png"
        alt="clock"
        size="custom"
        className="absolute -translate-x-5 w-24 h-24"
      />
      <div className="flex flex-row items-center bg-catchup-dark-blue pl-16 pr-3 py-1 rounded-catchup-clock text-catchup-white text-2xl">
        <p className="mx-1">
          {currentHours < 0
            ? `00`
            : currentHours < 10
            ? `0${currentHours}`
            : currentHours}{" "}
        </p>
        :
        <p className="mx-1">
          {currentMinutes < 0
            ? `00`
            : currentMinutes < 10
            ? `0${currentMinutes}`
            : currentMinutes}{" "}
        </p>
        :
        <p className="mx-1">
          {currentSeconds < 0
            ? `00`
            : currentSeconds < 10
            ? `0${currentSeconds}`
            : currentSeconds}{" "}
        </p>
      </div>
    </div>
  );
};

export default ClockWithTime;
