import i18n from "../../language/i18n";
import connection_production from "../../config/connection.json";
import connection_development from "../../config/connection_local.json";
import PrimaryButton from "../buttons/PrimaryButton";
import BaseImage from "../images/BaseImage";
import DividerLine from "../divider/DividerLine";
import GradeLabel from "../labels/GradeLabel";
import BaseLoading from "../loading/BaseLoading";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

const ContentTransition = (props) => {
  const {
    language,
    name,
    description,
    type,
    level,
    activityCount,
    beginAt,
    endAt,
    durationType,
    durationInMinutes,
    answeredActivityCount,
    totalTimeSpent,
    loading,
    planWeekLoading,
    planWeekMap,
    onClick,
  } = props;

  const RenderWeeklyPlanDocuments = () => {
    return (
      <>
        {planWeekLoading.planWeek ? (
          <BaseLoading size="small" color="#57C2D3" secondaryColor="#57C2D3" />
        ) : (
          <>
            {totalTimeSpent ? (
              <div className="my-1 flex flex-row items-center gap-x-1">
                <BaseImage
                  src="/icons/document.png"
                  alt="document"
                  size="small"
                />
                <p className="">{i18n.t("weekly_plan_documents")}</p>
              </div>
            ) : null}
            {planWeekLoading.documents ? (
              <BaseLoading
                size="small"
                color="#57C2D3"
                secondaryColor="#57C2D3"
              />
            ) : (
              Object.keys(planWeekMap).map((planWeekId, index) => {
                const planWeek = planWeekMap[planWeekId];
                return (
                  <div className="w-full my-2" key={index}>
                    <div className="">
                      <p>{planWeek.coterieType}</p>
                    </div>
                    {planWeek.documentList.map((document) => {
                      const { path, fileName, fileOriginalName } = document;
                      return (
                        <div className="px-4 my-1 cursor-pointer underline">
                          <a
                            href={`${connection.base.storage.url}/storage-files/preview/file${path}/${fileName}`}
                            target={"_blank"}
                          >
                            {fileOriginalName}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
          </>
        )}
      </>
    );
  };

  const checkCanLoad = () => {
    if (loading) return false;
    if (beginAt && beginAt > new Date().getTime()) return false;
    if (endAt && endAt < new Date().getTime()) return false;
    return true;
  };

  const canLoad = checkCanLoad();

  return (
    <div className="h-full">
      <div className="flex flex-col mt-5">
        <div className="flex flex-row items-center gap-x-2">
          {type || activityCount ? (
            <div className="flex flex-row items-center gap-x-2 text-lg text-catchup-blue-800 my-1">
              {type ? (
                <>
                  <div>
                    <p>{i18n.t(type)}</p>
                  </div>
                  <img
                    className="w-1 h-1"
                    src="/icons/small-dot-gray.png"
                    alt="small-dot-gray"
                  />
                </>
              ) : null}
              {activityCount ? (
                <div>
                  <p>
                    {activityCount} {i18n.t("activity")}
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}
          {level ? (
            <div className="my-1">
              <div className="flex flex-row">
                <GradeLabel title={`${i18n.t("grade")}: ${level}`} />
              </div>
            </div>
          ) : null}
        </div>

        {durationType ? (
          <div className="flex flex-row items-center gap-x-1 my-1">
            <BaseImage src="/icons/timer.png" size="small" alt="timer" />
            <div>
              {durationType === "NONE" ? (
                <p className="">{i18n.t("indefinite")}</p>
              ) : (
                <p>
                  {i18n.t(durationType)} {durationInMinutes}
                </p>
              )}
            </div>
          </div>
        ) : null}

        {name ? (
          <div className="my-1">
            <p className="">{name}</p>
          </div>
        ) : null}

        {description ? (
          <div className="my-1">
            <p className="text-catchup-gray-400">{description}</p>
          </div>
        ) : null}

        {beginAt || endAt ? (
          <div className="flex flex-row my-1">
            <div className="flex flex-row text-catchup-blue-500 gap-x-1">
              {beginAt ? (
                <>
                  <p>
                    {i18n.t("begin_date")}:{" "}
                    {new Date(beginAt).toLocaleDateString(language)}
                  </p>
                  <div className="w-[1px] bg-catchup-blue-500" />
                </>
              ) : null}
              {endAt ? (
                <p>
                  {i18n.t("end_date")}:{" "}
                  {new Date(endAt).toLocaleDateString(language)}
                </p>
              ) : null}
            </div>
          </div>
        ) : null}

        <DividerLine />

        {activityCount ? (
          <div className="my-1">
            <p className="text-catchup-gray-300">
              {i18n.t("activity_count")}: {activityCount}
            </p>
          </div>
        ) : null}
        {answeredActivityCount ? (
          <div className="my-1">
            <p className="text-catchup-gray-300">
              {i18n.t("answered_activity_count")}: {answeredActivityCount}
            </p>
          </div>
        ) : null}
        {totalTimeSpent && answeredActivityCount ? (
          <div className="my-1">
            <p className="text-catchup-gray-300">
              {i18n.t("average_time_spent_in_minutes")}:{" "}
              {answeredActivityCount > 0
                ? (totalTimeSpent / answeredActivityCount).toFixed(2)
                : (0).toFixed(2)}
            </p>
          </div>
        ) : null}

        {totalTimeSpent ? (
          <div className="my-1">
            <p className="text-catchup-gray-300">
              {i18n.t("total_time_spent_in_minutes")}:{" "}
              {totalTimeSpent.toFixed(2)}
            </p>
          </div>
        ) : null}

        <DividerLine />

        {planWeekLoading && planWeekMap ? RenderWeeklyPlanDocuments() : null}
      </div>
      <div className="flex flex-row mt-10">
        {canLoad ? (
          <PrimaryButton
            title={
              answeredActivityCount === 0 ? i18n.t("start") : i18n.t("continue")
            }
            size="medium"
            onClick={onClick}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ContentTransition;
