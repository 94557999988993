import { useEffect } from "react";

export const useMutationObserver = (
  ref,
  callback,
  options = {
    CharacterData: true,
    childList: true,
    subtree: true,
    attributes: true,
  }
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [ref]);
};
