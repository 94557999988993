import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Learner Report
 */
export const queryLearnerCatchxamReportDTOByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchxams.context}${endpoints.reports.catchxams.query.learner["by-userId-userProfileId"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const retrieveCatchxamReportDTOById = async (id) => {
  try {
    const response = await axios.get(
      `${connection.base.report.url}${
        endpoints.reports.catchxams.context
      }${endpoints.reports.catchxams.learner.dto.id.context.replace(
        "{id}",
        id
      )}`
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const queryCatchxamReportDTOByParams = async (queryParams) => {
  try {
    const response = await axios.post(
      `${connection.base.report.url}${endpoints.reports.catchxams.context}${endpoints.reports.catchxams.query.learner.dto["by-userId-userProfileId-catchxamApplicationIdList"].context}`,
      queryParams
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
