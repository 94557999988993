import InputGroup from "./InputGroup";

const LeftTextRightInputGroup = (props) => {
  const { type, title, value, optionList, onChange, disabled, errorText } =
    props;

  return (
    <div className="w-full flex flex-row mx-2">
      <div className="w-catchup-input-group-title py-5">
        <p>{title}</p>
      </div>
      <div className="flex-1">
        <InputGroup
          type={type}
          value={value}
          optionList={optionList}
          onChange={onChange}
          disabled={disabled}
          errorText={errorText}
        />
      </div>
    </div>
  );
};

export default LeftTextRightInputGroup;
