import endpoints from "../config/endpoints.json";
import connection_production from "../config/connection.json";
import connection_development from "../config/connection_local.json";
import axios from "axios";

const connection =
  process.env.REACT_APP_NODE_ENV === "development"
    ? connection_development
    : connection_production;

/**
 * Announcement
 */
export const queryLearnerAnnouncementDTOList = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.actions.context}${endpoints.notifications.announcements.actions.learner.query.dto.context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

/**
 * Announcement Action
 */
export const retrieveLearnerNumberOfUnReadAnnouncements = async (params) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.actions.context}${endpoints.notifications.announcements.actions.learner["number-of-unread"].context}`,
      params
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};

export const createAnnouncementActionByAnnouncementId = async (
  newNotificationAction
) => {
  try {
    const response = await axios.post(
      `${connection.base.notification.url}${endpoints.notifications.announcements.actions.context}${endpoints.notifications.announcements.actions["by-announcementId"].context}`,
      newNotificationAction
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { err };
  }
};
