import { useEffect, useState } from "react";
import FullCard from "../../components/cards/FullCard";
import PreviousHomeworkListView from "../../components/views/PreviousHomeworkListView";
import DashboardHeader from "../../components/headers/DashboardHeader";
import SelectionTab from "../../components/tabs/SelectionTab";
import { useAuth } from "../../context/UserContextProvider";
import i18n from "../../language/i18n";
import MyWrongActivitiesByTopicView from "../../components/views/MyWrongActivitiesByTopicView";

const PractiseNowScreen = () => {
  const { passiveContentList, myWrongActivityDTOList } = useAuth();
  const [selectedId, setSelectedId] = useState("PREVIOUS_HOMEWORK");

  useEffect(() => {
    const practiseNowState = localStorage.getItem("practiseNowState");
    if (!practiseNowState) return;
    setSelectedId(practiseNowState);
  }, []);

  useEffect(() => {
    localStorage.setItem("practiseNowState", selectedId);
  }, [selectedId]);

  const RenderMainContent = () => {
    return (
      <FullCard>
        <DashboardHeader title={i18n.t("practise_now")} />
        <SelectionTab
          optionList={[
            {
              id: "PREVIOUS_HOMEWORK",
              title:
                passiveContentList.length === 0
                  ? i18n.t("no_previous_homework")
                  : `${i18n.t("previous_homeworks")} (${
                      passiveContentList.length
                    })`,
            },
            {
              id: "MY_WRONG_ACTIVITIES_BY_TOPIC",
              title:
                myWrongActivityDTOList.length === 0
                  ? `${i18n.t("my_wrong_activities_by_topic")}`
                  : `${i18n.t("my_wrong_activities_by_topic")}`,
            },
          ]}
          selectedId={selectedId}
          handleSelectOnClick={(itemId) => {
            setSelectedId(itemId);
          }}
        />
        <div
          className={`${
            selectedId === "PREVIOUS_HOMEWORK" ? "block" : "hidden"
          }`}
        >
          <PreviousHomeworkListView />
        </div>
        <div
          className={`${
            selectedId === "MY_WRONG_ACTIVITIES_BY_TOPIC" ? "block" : "hidden"
          }`}
        >
          <MyWrongActivitiesByTopicView />
        </div>
      </FullCard>
    );
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="m-4">{RenderMainContent()}</div>
    </div>
  );
};

export default PractiseNowScreen;
